import { ReactElement } from 'react';
import { CSSProperties } from '@material-ui/styles';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import InfoIcon from '@material-ui/icons/Info';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ListIcon from '@material-ui/icons/List';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import BuildIcon from '@material-ui/icons/Build';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import StoreIcon from '@material-ui/icons/Store';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PaymentIcon from '@material-ui/icons/Payment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SaveIcon from '@material-ui/icons/Save';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AttachmentIcon from '@material-ui/icons/Attachment';
import SendIcon from '@material-ui/icons/Send';
import TimelineIcon from '@material-ui/icons/Timeline';
import ReportIcon from '@material-ui/icons/Report';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export type IconColor = 'inherit' | 'disabled' | 'action' | 'primary' | 'secondary' | 'error';
export type IconFontSize = 'inherit' | 'default' | 'small' | 'large';

export type GetIconProps = {
    color?: IconColor;
    fontSize?: IconFontSize;
    style?: CSSProperties;
};

export function getIcon(name: string, props?: GetIconProps): ReactElement {
    if (name === 'HomeIcon') return <HomeIcon {...props} />;
    if (name === 'DashboardIcon') return <DashboardIcon {...props} />;
    if (name === 'GroupIcon') return <GroupIcon {...props} />;
    if (name === 'InfoIcon') return <InfoIcon {...props} />;
    if (name === 'AssignmentTurnedInIcon') return <AssignmentTurnedInIcon {...props} />;
    if (name === 'ListIcon') return <ListIcon {...props} />;
    if (name === 'ColorLensIcon') return <ColorLensIcon {...props} />;
    if (name === 'BuildIcon') return <BuildIcon {...props} />;
    if (name === 'MonetizationOnIcon') return <MonetizationOnIcon {...props} />;
    if (name === 'AssessmentIcon') return <AssessmentIcon {...props} />;
    if (name === 'SettingsIcon') return <SettingsIcon {...props} />;
    if (name === 'HelpIcon') return <HelpIcon {...props} />;
    if (name === 'ExitToAppIcon') return <ExitToAppIcon {...props} />;
    if (name === 'AccountCircleIcon') return <AccountCircleIcon {...props} />;
    if (name === 'NotificationsIcon') return <NotificationsIcon {...props} />;
    if (name === 'NotificationsActiveIcon') return <NotificationsActiveIcon {...props} />;
    if (name === 'CachedIcon') return <CachedIcon {...props} />;
    if (name === 'EditIcon') return <EditIcon {...props} />;
    if (name === 'DeleteIcon') return <DeleteIcon {...props} />;
    if (name === 'WarningIcon') return <WarningIcon {...props} />;
    if (name === 'CheckIcon') return <CheckIcon {...props} />;
    if (name === 'CloseIcon') return <CloseIcon {...props} />;
    if (name === 'AddIcon') return <AddIcon {...props} />;
    if (name === 'StoreIcon') return <StoreIcon {...props} />;
    if (name === 'PrintIcon') return <PrintIcon {...props} />;
    if (name === 'VisibilityIcon') return <VisibilityIcon {...props} />;
    if (name === 'CheckCircleIcon') return <CheckCircleIcon {...props} />;
    if (name === 'ArrowBackIcon') return <ArrowBackIcon {...props} />;
    if (name === 'ArrowRightAltIcon') return <ArrowRightAltIcon {...props} />;
    if (name === 'AddAPhotoIcon') return <AddAPhotoIcon {...props} />;
    if (name === 'MoreHorizIcon') return <MoreHorizIcon {...props} />;
    if (name === 'PaymentIcon') return <PaymentIcon {...props} />;
    if (name === 'AssignmentIcon') return <AssignmentIcon {...props} />;
    if (name === 'SaveIcon') return <SaveIcon {...props} />;
    if (name === 'PictureAsPdfIcon') return <PictureAsPdfIcon {...props} />;
    if (name === 'LockOpenIcon') return <LockOpenIcon {...props} />;
    if (name === 'MenuBookIcon') return <MenuBookIcon {...props} />;
    if (name === 'AttachmentIcon') return <AttachmentIcon {...props} />;
    if (name === 'SendIcon') return <SendIcon {...props} />;
    if (name === 'TimelineIcon') return <TimelineIcon {...props} />;
    if (name === 'ReportIcon') return <ReportIcon {...props} />;
    if (name === 'ExpandLessIcon') return <ExpandLessIcon {...props} />;
    if (name === 'ExpandMoreIcon') return <ExpandMoreIcon {...props} />;
    return <></>;
}
