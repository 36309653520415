type SetBuzonEntradaAction = {
    type: 'SET_BUZON_ENTRADA';
    payload: { buzonEntrada: number };
};
export const setBuzonEntrada = (buzonEntrada: number): SetBuzonEntradaAction => ({
    type: 'SET_BUZON_ENTRADA',
    payload: { buzonEntrada },
});

type OpenMessageAction = {
    type: 'OPEN_MESSAGE';
    payload: { type: MessageType; message: string };
};
export const openMessage = (type: MessageType, message: string): OpenMessageAction => ({
    type: 'OPEN_MESSAGE',
    payload: { type, message },
});

type CloseMessageAction = {
    type: 'CLOSE_MESSAGE';
    payload: { index?: number };
};
export const closeMessage = (index?: number): CloseMessageAction => ({
    type: 'CLOSE_MESSAGE',
    payload: { index },
});

export type NotificationAction = SetBuzonEntradaAction | OpenMessageAction | CloseMessageAction;
