import { BaseService } from 'services/http';

type ParametroTableModel = Costo.ParametroTableModel;
type ParametroFormModel = Costo.ParametroFormModel;
type ParametroFormParams = Costo.ParametroFormParams;
type ServicioFormModel = Costo.ServicioFormModel;
type ServicioTableModel = Costo.ServicioTableModel;
type ServicioFormParams = Costo.ServicioFormParams;

export const getParametros = async (queryParams: QueryParams = {}): Promise<BaseResponse<{ rows: ParametroTableModel[]; count: number }>> => {
    const queryParamsText = BaseService.buildQueryParamsText(queryParams);
    return BaseService.request('get', `/parametro_servicio${queryParamsText}`);
};

export const getParametroForm = async (parametroId?: string): Promise<BaseResponse<ParametroFormModel>> => {
    const queryParamsText = parametroId ? `?id=${parametroId}` : '';
    return BaseService.request('get', `/parametro_servicio/form${queryParamsText}`);
};

export const getParametroFormParams = async (): Promise<BaseResponse<ParametroFormParams>> => {
    return BaseService.request('get', '/parametro_servicio/form_params');
};

export const editParametro = async (parametroId: string, data: ParametroFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('put', `/parametro_servicio/${parametroId}`, data);
};

export const createParametro = async (data: ParametroFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', `/parametro_servicio`, data);
};

export const createOrUpdateParametro = async (data: ParametroFormModel): Promise<BaseResponse<boolean>> => {
    if (data.id) return editParametro(data.id, data);
    return createParametro(data);
};

export const deleteParametro = async (parametroId: string): Promise<BaseResponse<boolean>> => {
    return BaseService.request('delete', `/parametro_servicio/${parametroId}`);
};

export const getServicios = async (queryParams: QueryParams = {}): Promise<BaseResponse<{ rows: ServicioTableModel[]; count: number }>> => {
    const queryParamsText = BaseService.buildQueryParamsText(queryParams);
    return BaseService.request('get', `/servicios${queryParamsText}`);
};

export const getServicioForm = async (servicioId?: string): Promise<BaseResponse<ServicioFormModel>> => {
    const queryParamsText = servicioId ? `?id=${servicioId}` : '';
    return BaseService.request('get', `/servicios/form${queryParamsText}`);
};

export const getServicioFormParams = async (): Promise<BaseResponse<ServicioFormParams>> => {
    return BaseService.request('get', '/servicios/form_params');
};

export const createServicio = async (data: ServicioFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', '/servicios', data);
};

export const editServicio = async (servicioId: string, data: ServicioFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('put', `/servicios/${servicioId}`, data);
};

export const deleteServicio = async (servicioId: string): Promise<BaseResponse<boolean>> => {
    return BaseService.request('delete', `/servicios/${servicioId}`);
};

export const createOrUpdateServicio = async (data: ServicioFormModel): Promise<BaseResponse<boolean>> => {
    if (data.id) return editServicio(data.id, data);
    return createServicio(data);
};
