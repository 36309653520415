import React, { ReactElement, useState } from 'react';
import { Box, makeStyles, Switch, Theme } from '@material-ui/core';
import { ActiveColumnProps } from './Types';

export const ActiveColumn = ({ active, onActiveChange }: ActiveColumnProps): ReactElement => {
    const [loading, setLoading] = useState<boolean>(false);
    const classes = useStyles();

    const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        if (!onActiveChange) return;

        setLoading(true);
        onActiveChange(newValue)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    return (
        <Box className={classes.root}>
            <Switch
                className={classes.switch}
                checked={active}
                onChange={handleClick}
                disabled={loading}
                name="checked"
            />
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: theme.spacing(0),
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
    },
    switch: {},
}));
