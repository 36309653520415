import { ROLES } from 'base/authBase';
import { GetRows, DataTableRef, UpdateParams } from 'components/core/DataTable/Types';
import { useNotify, useSession } from 'hooks';
import { ReactElement, useEffect, useRef } from 'react';
import { ReclamoService } from 'modules/reclamos';
import { ReclamoTable } from './ReclamoTable';
import { DialogBoxRef } from 'components/core/DialogBox';
import { ReclamoFormDialog } from './ReclamoFormDialog';
import { FormDialogRef } from 'components/core/FormDialog';
import { InitialParams } from 'components/core/FormDialog/Types';
import { FlujoTimelineDialog } from '../ReclamosServicio/FlujoTimelineDialog';
import { RUTAS } from 'routes';
import { useHistory } from 'react-router-dom';

type ReclamoTableModel = Reclamos.ReclamoTableModel;
type ReclamoFormModel = Reclamos.ReclamoFormModel;

type TimelineModel = Solicitud.TimelineModel;
type TimelineParams = Solicitud.TimelineParams;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const history = useHistory();
    const session = useSession();
    const reclamosTableRef = useRef<DataTableRef>(null);
    const reclamoFormRef = useRef<FormDialogRef>(null);

    const timelineFormRef = useRef<DialogBoxRef<TimelineParams>>(null);

    const esPlataforma = session.roles.some((rol) => rol.id === ROLES.plataforma.id);
    const esCoordinador = session.roles.some((rol) => rol.id === ROLES.coordinador.id);

    const getReclamos: GetRows<ReclamoTableModel> = async (params: UpdateParams<ReclamoTableModel>) => {
        const newParams = {
            ...params,
            filters: {
                ...params.filters,
                pendientes: true,
            },
        };
        const response = await ReclamoService.getReclamos(newParams);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        response.data.rows.forEach((r, index) => (r.nro = r.nro || index + 1 + params.rowsPerPage * (params.page - 1)));
        return {
            ...newParams,
            rows: response.data.rows,
            count: response.data.count,
        };
    };

    const deleteReclamo = async (data: ReclamoTableModel): Promise<boolean> => {
        const response = await ReclamoService.deleteReclamo(data.id);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        return true;
    };

    const getData = async (params?: TimelineParams): Promise<TimelineModel | undefined> => {
        return params;
    };

    const getDataReclamo = async (params?: InitialParams): Promise<ReclamoFormModel | undefined> => {
        const response = await ReclamoService.getReclamoForm(params?.id);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };

    const submitReclamo = async (data: ReclamoFormModel): Promise<boolean> => {
        const response = await ReclamoService.createOrUpdateReclamo(data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Tarea completada exitosamente');
        return true;
    };

    useEffect(() => {
        reclamosTableRef.current?.refresh();
    }, []);

    return (
        <div>
            <FlujoTimelineDialog dialogRef={timelineFormRef} getData={getData} />

            <ReclamoFormDialog dialogRef={reclamoFormRef} getData={getDataReclamo} submit={submitReclamo} />

            <ReclamoTable
                tableRef={reclamosTableRef}
                getRows={getReclamos}
                modoCreacion={esPlataforma}
                modoAprobacion={esCoordinador}
                editReclamo={async (data: ReclamoTableModel) => {
                    reclamoFormRef.current?.open({
                        params: { id: data.id, title: 'Editar reclamo' },
                        actions: [{ on: 'AcceptButton', click: () => reclamosTableRef.current?.refresh() }],
                    });
                    return true;
                }}
                deleteReclamo={async (row) => {
                    const success = await deleteReclamo(row);
                    if (success) {
                        reclamosTableRef.current?.refresh();
                    }
                    return success;
                }}
                addReclamo={() => {
                    reclamoFormRef.current?.open({
                        params: { id: null, title: 'Agregar reclamo' },
                        actions: [{ on: 'AcceptButton', click: () => reclamosTableRef.current?.refresh() }],
                    });
                }}
                verReclamo={(row) => {
                    history.push(`${RUTAS.reclamosDetalle}?id=${row.id}`);
                }}
                showTimeline={(row) => {
                    timelineFormRef.current?.open({
                        params: { rows: row.historial_flujo },
                    });
                }}
            />
        </div>
    );
};

export const PendientesSection = PageComponent;
