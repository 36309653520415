import React, { ReactElement } from 'react';
import clsx from 'clsx';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { UpdateParams, MyTableModel } from 'components/core/DataTable';

type ActionSearchProps = {
    variant?: 'light' | 'dark';
    onSearchClick?: (searchText: string) => void;
    onSearchTextChange?: (searchText: string) => void;
    updateParams?: UpdateParams<MyTableModel>;
    onUpdate?: (params: UpdateParams<MyTableModel>) => void;
    loading?: boolean;
    placeholder?: string;
};

export const DataSearch = ({
    variant,
    updateParams,
    onSearchClick,
    onSearchTextChange,
    onUpdate,
    loading,
    placeholder,
}: ActionSearchProps): ReactElement => {
    const classes = useStyles();

    const [currentSearchText, setCurrentSearchText] = React.useState<string>(
        (updateParams && updateParams.searchText) || '',
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchText = e.target.value;
        setCurrentSearchText(newSearchText);

        if (onSearchTextChange) {
            onSearchTextChange(e.target.value);
        }
    };

    const handleSubmit = (event: React.MouseEvent) => {
        event.preventDefault();

        if (onUpdate && updateParams) {
            return onUpdate({
                rows: updateParams.rows,
                count: updateParams.count,
                rowsPerPage: updateParams.rowsPerPage,
                page: 1,
                order: updateParams.order,
                orderBy: updateParams.orderBy,
                searchText: currentSearchText,
                filters: updateParams.filters,
            });
        }

        if (onSearchClick) {
            onSearchClick(currentSearchText);
        }
    };

    return (
        <Paper className={clsx(classes.root, { light: variant === 'light' })}>
            <InputBase
                className={clsx(classes.input, { light: variant === 'light' })}
                placeholder={placeholder || 'Buscar'}
                disabled={loading}
                onChange={handleChange}
                value={currentSearchText}
            />
            <IconButton className={classes.iconButton} disabled={loading} onClick={handleSubmit}>
                <SearchIcon />
            </IconButton>
        </Paper>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(5),
        minWidth: theme.spacing(20),
        padding: theme.spacing(0),
        margin: theme.spacing(0),
        boxShadow: 'unset',
        border: `1px solid ${theme.palette.divider}`,
        '&.light': {
            background: theme.palette.background.default,
            border: `1px solid ${theme.palette.divider}`,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    input: {
        margin: theme.spacing(0, 1, 0, 1),
        flex: 1,
    },
    iconButton: {
        margin: 0,
        padding: theme.spacing(1),
        borderTopLeftRadius: theme.spacing(0),
        borderBottomLeftRadius: theme.spacing(0),
        borderTopRightRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
        background: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        '&:hover': {
            background: theme.palette.primary.dark,
        },
    },
}));
