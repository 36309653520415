/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useImperativeHandle, forwardRef } from 'react';
import {
    makeStyles,
    Theme,
    Dialog,
    DialogContent,
    Typography,
    LinearProgress,
    IconButton,
    DialogActions,
    useMediaQuery,
    useTheme,
    Box,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { useIsMounted, useLoading } from 'hooks';
import { DialogAction, DialogBoxProps, DialogBoxRef, OpenDialogOptions } from './Types';
import { getIcon } from 'helpers';
import { AcceptButton, BackButton, CancelButton, CloseButton, SaveButton } from '../Buttons';

const MyComponent = (props: DialogBoxProps, ref: React.Ref<DialogBoxRef<any>>): ReactElement => {
    const {
        titleIcon,
        title,
        subtitle,
        dialogContent,
        getData,
        dialogHeader,
        dialogFooter,
        actions,
        variant,
        maxWidth,
        fullwidth,
    } = props;
    const classes = useStyles();
    const isMounted = useIsMounted();
    const isPageVariant = variant && variant === 'page';

    const [data, setData] = useState();
    const [openOptions, setOpenOptions] = useState<OpenDialogOptions<any>>({});
    const [{ isLoading }, { start, stop }] = useLoading();

    const submitLoader = useLoading();
    const isSubmitting = submitLoader[0].isLoading;
    const startSubmit = submitLoader[1].start;
    const stopSubmit = submitLoader[1].stop;

    const [open, setOpen] = useState<boolean>(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpen(false);
        setData(undefined);
        stop();

        openOptions.actions?.forEach((action) => action.on === 'CloseButton' && action.click());
    };

    const handleActionButton = async (item: DialogAction) => {
        if (isSubmitting) return;

        if (item.click) {
            startSubmit();
            const success = await item.click(data);
            if (!isMounted()) return;

            stopSubmit();
            if (!success) return;
        }

        setOpen(false);
        setData(undefined);
        stop();

        openOptions.actions?.forEach((action) => action.on === item.on && action.click());
    };

    const renderDialogTitle = (): ReactElement => {
        return (
            <MuiDialogTitle disableTypography className={classes.dialogTitle}>
                <Typography variant="h6" className={classes.dialogTitleText} noWrap>
                    {titleIcon && (
                        <>
                            {getIcon(titleIcon, { color: 'inherit' })} &nbsp; {title}
                        </>
                    )}
                    {!titleIcon && title}
                </Typography>
                <Typography variant="body2" className={classes.dialogSubtitleText} noWrap>
                    {subtitle}
                </Typography>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose()}
                    disabled={isLoading || isSubmitting}
                >
                    <CloseIcon />
                </IconButton>
                <LinearProgress
                    className={classes.loader}
                    color="primary"
                    variant="indeterminate"
                    hidden={!isLoading}
                />
            </MuiDialogTitle>
        );
    };

    const renderDialogPageTitle = () => {
        return (
            <>
                <Typography variant="h4" noWrap align="center">
                    {title}
                </Typography>
                <Typography variant="h5" className={classes.dialogSubtitleText} noWrap align="center">
                    {subtitle}
                </Typography>
                {!isPageVariant && (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => handleClose()}
                        disabled={isLoading || isSubmitting}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
                <LinearProgress
                    className={classes.loader}
                    color="primary"
                    variant="indeterminate"
                    hidden={!isLoading}
                />
            </>
        );
    };

    const renderActionButtons = () => {
        if (!actions) return <></>;
        const buttons = (
            <>
                {actions.map((action, index) => {
                    if (action.on === 'AcceptButton') {
                        return (
                            <Box key={index} pl={1}>
                                <AcceptButton onClick={() => handleActionButton(action)} disabled={isSubmitting} />
                            </Box>
                        );
                    }
                    if (action.on === 'SaveButton') {
                        return (
                            <Box key={index} pl={1}>
                                <SaveButton onClick={() => handleActionButton(action)} disabled={isSubmitting} />
                            </Box>
                        );
                    }
                    if (action.on === 'CancelButton') {
                        return (
                            <Box key={index} pl={1}>
                                <CancelButton onClick={() => handleActionButton(action)} disabled={isSubmitting} />
                            </Box>
                        );
                    }
                    if (action.on === 'CloseButton') {
                        return (
                            <Box key={index} pl={1}>
                                <CloseButton onClick={() => handleActionButton(action)} disabled={isSubmitting} />
                            </Box>
                        );
                    }
                    if (action.on === 'BackButton') {
                        return (
                            <Box key={index} pl={1}>
                                <BackButton onClick={() => handleActionButton(action)} disabled={isSubmitting} />
                            </Box>
                        );
                    }
                    return <Box key={index} />;
                })}
            </>
        );
        return <div className={classes.actionButtons}>{buttons}</div>;
    };

    const renderBody = () => {
        return (
            <>
                {typeof dialogHeader !== 'undefined' && (
                    <div className={classes.headerComponent}>{dialogHeader(data)}</div>
                )}

                {dialogContent(data)}
            </>
        );
    };

    const renderFooter = () => {
        if (typeof dialogFooter === 'undefined') return <></>;
        return <div className={classes.footerComponent}>{dialogFooter(data)}</div>;
    };

    const refHandler: () => DialogBoxRef<any> = () => ({
        open: async (options) => {
            if (!isMounted()) return;
            if (isLoading) return;

            const newOptions = options ? options : {};
            setOpenOptions(newOptions);

            setOpen(true);
            let newData = data;

            if (getData) {
                start();
                newData = await getData(newOptions.params);
                if (!isMounted()) return;

                stop();
                setData(newData);
            }
        },
        refresh: async () => {
            let newData = data;
            if (getData) {
                start();
                newData = await getData(openOptions.params);
                if (!isMounted()) return;

                stop();
                setData(newData);
            }
        },
    });
    useImperativeHandle(ref, refHandler, [data, isLoading]);

    if (isPageVariant) {
        return (
            <div className={classes.dialogPage}>
                {!isPageVariant && renderDialogTitle()}
                {isPageVariant && renderDialogPageTitle()}

                {renderBody()}

                {renderFooter()}

                {renderActionButtons()}
            </div>
        );
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            disableBackdropClick
            maxWidth={maxWidth}
            fullWidth={fullwidth}
        >
            {renderDialogTitle()}
            <DialogContent dividers className={classes.dialogContent}>
                {renderBody()}
            </DialogContent>

            {(typeof dialogFooter !== 'undefined' || actions) && (
                <DialogActions className={classes.dialogActions}>
                    {renderFooter()}

                    {renderActionButtons()}
                </DialogActions>
            )}
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    dialogTitle: {
        position: 'relative',
        padding: theme.spacing(1),
    },
    dialogTitleText: {
        margin: theme.spacing(1, 5, 1, 1),
    },
    dialogSubtitleText: {
        margin: theme.spacing(1, 5, 1, 6),
    },
    dialogContent: {
        padding: theme.spacing(0),
        minWidth: theme.spacing(50),
        [theme.breakpoints.down('xs')]: {
            minWidth: theme.spacing(0),
        },
    },
    dialogActions: {
        padding: theme.spacing(1),
        margin: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: '4px',
        zIndex: 9,
    },
    headerComponent: {
        overflow: 'auto',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    footerComponent: {
        overflow: 'auto',
        borderBottom: `1px solid ${theme.palette.divider}`,
        maxHeight: '40vh',
        marginBottom: theme.spacing(1),
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(0.5),
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    },
    dialogPage: {
        maxWidth: '100%',
        minWidth: '100%',
    },
}));

export const DialogBox = forwardRef(MyComponent);
