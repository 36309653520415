import React, { ReactElement, useState, forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';

// material-ui
import {
    Paper,
    Table,
    TableContainer,
    TablePagination,
    makeStyles,
    LinearProgress,
    useMediaQuery,
    useTheme,
    Box,
} from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars-2';

// components
import { ActionBar } from './ActionBar';
import { DataTableHeader } from './DataTableHeader';
import { DataTableBody } from './DataTableBody';

// types
import { DataTableProps, DataTableRef, Filters, MyTableModel, UpdateParams } from './Types';
import { DataList } from './DataList';
import { useIsMounted } from 'hooks/useIsMounted';
import { DataTableFilters } from './DataTableFilters';
import { Typography } from '@material-ui/core';

const tableParamsInitialize: UpdateParams<MyTableModel> = {
    rows: [],
    count: 0,
    rowsPerPage: 50,
    page: 1,
};

const DataTableComponent = (props: DataTableProps, ref: React.Ref<DataTableRef>): ReactElement => {
    const {
        title,
        headers,
        hideActionBar,
        hideFilters,
        hideRefresh,
        hideSearch,
        hidePagination,
        getRows,
        rowStyles,
        onRowClick,
        onActionAddClick,
        collapsableComponent,
        mobileComponent,
        addLabel,
    } = props;
    const [tableParams, setTableParams] = useState<UpdateParams<MyTableModel>>(tableParamsInitialize);
    const { rows, count, rowsPerPage, page, order, orderBy, searchText, filters, data } = tableParams;
    const classes = useStyles();
    const isMounted = useIsMounted();

    const [filterOpen, setFilterOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [currentFilters, setCurrentFilters] = useState<Filters | undefined>(filters);
    const [currentSearchtext, setCurrentSearchtext] = useState<string | undefined>(searchText);

    const actionBarEnable = !hideActionBar;

    const handleChangePage = (event: unknown, newPage: number) => {
        if (!getRows) return;
        const refreshParams = {
            rows,
            count,
            rowsPerPage,
            page: newPage + 1,
            order,
            orderBy,
            searchText,
            filters: currentFilters,
            data,
        };
        setLoading(true);
        getRows(refreshParams).then((result) => {
            if (!isMounted()) return;
            setLoading(false);
            if (result) setTableParams(result);
        });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!getRows) return;
        const refreshParams = {
            rows,
            count,
            rowsPerPage: Number(event.target.value),
            page: 1,
            order,
            orderBy,
            searchText: currentSearchtext,
            filters: filterOpen ? currentFilters : {},
            data,
        };
        setLoading(true);
        getRows(refreshParams).then((result) => {
            if (!isMounted()) return;
            setLoading(false);
            if (result) setTableParams(result);
        });
    };

    const handleClickFilter = () => {
        setFilterOpen(!filterOpen);
    };

    const handleClickRefresh = () => {
        if (!getRows) return;
        const refreshParams = {
            rows,
            count,
            rowsPerPage,
            page: 1,
            order,
            orderBy,
            searchText: currentSearchtext,
            filters: filterOpen ? currentFilters : {},
            data,
        };
        setLoading(true);
        getRows(refreshParams).then((result) => {
            if (!isMounted()) return;
            setLoading(false);
            if (result) setTableParams(result);
        });
    };

    const handleChangeFilter = (newFilters: Filters) => {
        setCurrentFilters(newFilters);
    };

    const handleChangeOrder = (newOrder: 'asc' | 'desc', newOrderBy: string) => {
        if (!getRows) return;
        const refreshParams = {
            rows,
            count,
            rowsPerPage,
            page: 1,
            order: newOrder,
            orderBy: newOrderBy,
            searchText: currentSearchtext,
            filters: filterOpen ? currentFilters : {},
            data,
        };
        setLoading(true);
        getRows(refreshParams).then((result) => {
            if (!isMounted()) return;
            setLoading(false);
            if (result) setTableParams(result);
        });
    };

    const handleClickActionAdd = () => {
        if (onActionAddClick) {
            onActionAddClick();
        }
    };

    const handleClickSearch = () => {
        if (!getRows) return;
        const refreshParams = {
            rows,
            count,
            rowsPerPage,
            page: 1,
            order,
            orderBy,
            searchText: currentSearchtext,
            filters: filterOpen ? currentFilters : {},
            data,
        };
        setLoading(true);
        getRows(refreshParams).then((result) => {
            if (!isMounted()) return;
            setLoading(false);
            if (result) setTableParams(result);
        });
    };

    const handleChangeSearchText = (newSearchText: string) => {
        setCurrentSearchtext(newSearchText);
    };

    const refHandler: () => DataTableRef = () => ({
        refresh: (params) => {
            const refreshParams = params || tableParams;
            if (!getRows) return;
            setLoading(true);
            getRows(refreshParams).then((result) => {
                if (!isMounted()) return;
                setLoading(false);
                if (result) setTableParams(result);
            });
        },
        tableParams: tableParams,
    });
    useImperativeHandle(ref, refHandler, [tableParams]);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const actionBarHeight = actionBarEnable ? 70 : 0;
    const paginationHeight = hidePagination ? 0 : 52;
    const filterHeight = filterOpen ? 64 : 0;
    const tableHeightStyle = `calc(100% - ${actionBarHeight + paginationHeight + filterHeight}px)`;

    const renderTableData = () => {
        if (mobileComponent && isSmallScreen) {
            const dataList = (
                <DataList updateParams={tableParams} mobileComponent={mobileComponent} loading={loading} />
            );
            return (
                <Box className={clsx(classes.container)} style={{ height: tableHeightStyle }}>
                    <Scrollbars autoHeight autoHeightMax="60vh">
                        {dataList}
                    </Scrollbars>
                </Box>
            );
        }

        const table = (
            <Table stickyHeader style={{ marginBottom: '8px' }}>
                <DataTableHeader
                    headers={headers}
                    updateParams={tableParams}
                    filterOpen={filterOpen}
                    onFilterChange={handleChangeFilter}
                    onOrderChange={handleChangeOrder}
                />
                <DataTableBody
                    headers={headers}
                    updateParams={tableParams}
                    rowStyles={rowStyles}
                    onRowClick={onRowClick}
                    collapsableComponent={collapsableComponent}
                    loading={loading}
                />
            </Table>
        );
        return (
            <>
                <DataTableFilters
                    headers={headers}
                    updateParams={tableParams}
                    filterOpen={filterOpen}
                    onFilterChange={handleChangeFilter}
                    onOrderChange={handleChangeOrder}
                />
                <TableContainer className={clsx(classes.container)} style={{ height: tableHeightStyle }}>
                    <Scrollbars autoHeight autoHeightMax="60vh">
                        {table}
                    </Scrollbars>
                </TableContainer>
            </>
        );
    };

    const mainContent = (
        <Paper className={classes.root} variant="outlined" square>
            {actionBarEnable && (
                <ActionBar
                    onFilterClick={isSmallScreen || hideFilters ? undefined : handleClickFilter}
                    onResfreshClick={hideRefresh ? undefined : handleClickRefresh}
                    onSearchClick={hideSearch ? undefined : handleClickSearch}
                    onSearchTextChange={handleChangeSearchText}
                    onActionAddClick={onActionAddClick ? handleClickActionAdd : undefined}
                    addLabel={addLabel}
                    updateParams={tableParams}
                    headers={headers}
                />
            )}
            <LinearProgress className={classes.loader} color="secondary" variant="indeterminate" hidden={!loading} />
            {renderTableData()}
            {!hidePagination && (
                <Box className={classes.paginationContainer}>
                    <TablePagination
                        className={classes.pagination}
                        rowsPerPageOptions={[50, 100, 250]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Box>
            )}
        </Paper>
    );

    return (
        <>
            {title && (
                <Typography color="primary" variant="h6">
                    <strong>{title}</strong>
                </Typography>
            )}
            {mainContent}
        </>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    container: {
        overflowY: 'auto',
        transition: 'height .5s, marginTop .5s',
    },
    paginationContainer: {
        height: '52px',
        overflowX: 'auto',
        overflowY: 'hidden',
    },
    pagination: {
        overflow: 'unset',
    },
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        height: '4px',
        zIndex: 9,
    },
}));

export const DataTable = forwardRef(DataTableComponent);
