import { BaseService } from 'services/http';

type EquipoIndustrialTableModel = Mantenimiento.EquipoIndustrialTableModel;
type EquipoIndustrialFormModel = Mantenimiento.EquipoIndustrialFormModel;
type EquipoIndustrialFormParams = Mantenimiento.EquipoIndustrialFormParams;
type MantenimientoFormModel = Mantenimiento.MantenimientoFormModel;

export const getEquipos = async (queryParams: QueryParams = {}): Promise<BaseResponse<{ rows: EquipoIndustrialTableModel[]; count: number }>> => {
    const queryParamsText = BaseService.buildQueryParamsText(queryParams);
    return BaseService.request('get', `/equipoindustrial${queryParamsText}`);
};

export const getEquipoForm = async (equipoId?: string): Promise<BaseResponse<EquipoIndustrialFormModel>> => {
    const queryParamsText = equipoId ? `?id=${equipoId}` : '';
    return BaseService.request('get', `/equipoindustrial/form${queryParamsText}`);
};

export const getEquipoFormParams = async (): Promise<BaseResponse<EquipoIndustrialFormParams>> => {
    return BaseService.request('get', '/equipoindustrial/form_params');
};

export const editEquipo = async (equipoId: string, data: EquipoIndustrialFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('put', `/equipoindustrial/${equipoId}`, data);
};

export const createEquipo = async (data: EquipoIndustrialFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', '/equipoindustrial', data);
};

export const createOrUpdateEquipo = async (data: EquipoIndustrialFormModel): Promise<BaseResponse<boolean>> => {
    if (data.id) return editEquipo(data.id, data);
    return createEquipo(data);
};

export const deleteEquipo = async (equipoId: string): Promise<BaseResponse<boolean>> => {
    return BaseService.request('delete', `/equipoindustrial/${equipoId}`);
};

export const getMantenimientoForm = async (equipoId?: string): Promise<BaseResponse<MantenimientoFormModel>> => {
    return BaseService.request('get', `/equipoindustrial/mantenimiento/form?equipoId=${equipoId}`);
};

export const registrarMantenimiento = async (data: MantenimientoFormModel): Promise<BaseResponse<MantenimientoFormModel>> => {
    return BaseService.request('post', '/equipoindustrial/mantenimiento', data);
};
