import { useTheme } from '@material-ui/core';
import { ActionColumn, ActionItem } from 'components/core/Columns';
import { GetRows, DataTable, DataTableRef, TableHeader } from 'components/core/DataTable';
import { ReactElement, RefObject } from 'react';

type TableModel = Mantenimiento.EquipoIndustrialTableModel;

type Props = {
    tableRef: RefObject<DataTableRef>;
    getRows: GetRows<TableModel>;
    editEquipo: (row: TableModel) => Promise<boolean>;
    deleteEquipo: (row: TableModel) => Promise<boolean>;
    registrarMantenimiento: (row: TableModel) => Promise<boolean>;
    addEquipo: () => void;
};

export const TableComponent = (props: Props): ReactElement => {
    const { tableRef, getRows, editEquipo, deleteEquipo, addEquipo, registrarMantenimiento } = props;
    const tableHeaders: TableHeader<TableModel>[] = [
        { id: 'nro', label: 'Nro.', width: 80 },
        { id: 'codigo', label: 'Código', align: 'left', truncate: true, sort: true },
        { id: 'nombre', label: 'Nombre', align: 'left', sort: true },
        { id: 'marca', label: 'Marca', align: 'left', sort: true },
        { id: 'fecha_ultimo', label: 'Último mantenimiento', align: 'center' },
        { id: 'fecha_proximo', label: 'Próximo mantenimiento', align: 'center' },
        { id: 'actions', label: 'Acciones', render: renderColumnActions, width: 130 },
    ];

    const theme = useTheme();
    const isDarkMode = theme.palette.type === 'dark';
    const ADVERTIR_BACKGROUND = isDarkMode ? '#62a065' : '#d6f3c9';

    function renderColumnActions(data: TableModel): ReactElement {
        const confirmMessage = (
            <>
                ¿Desea eliminar al equipo de nombre <strong>{data.nombre}</strong>?
            </>
        );
        const items: ActionItem[] = [
            { icon: 'BuildIcon', onClick: async () => registrarMantenimiento(data), title: 'Registrar mantenimiento' },
            { icon: 'EditIcon', onClick: async () => editEquipo(data), title: 'Editar' },
            {
                icon: 'DeleteIcon',
                onClick: async () => deleteEquipo(data),
                confirm: true,
                confirmMessage,
                color: 'secondary',
                title: 'Eliminar',
            },
        ];
        return <ActionColumn items={items} size="small" />;
    }

    return (
        <DataTable
            title="Equipos industriales"
            ref={tableRef}
            headers={tableHeaders}
            getRows={getRows}
            onActionAddClick={addEquipo}
            hideFilters
            rowStyles={(row: TableModel) => ({
                background: row.advertir ? ADVERTIR_BACKGROUND : undefined,
            })}
        />
    );
};

export const EquipoTable = TableComponent;
