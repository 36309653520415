import { ROLES } from 'base/authBase';
import { GetRows, DataTableRef, UpdateParams } from 'components/core/DataTable/Types';
import { useNotify, useSession } from 'hooks';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { SolicitudServicioService } from 'modules/solicitudServicio';
import { SolicitantesTable } from './SolicitantesTable';
import { SolicitanteFormDialog } from './SolicitanteFormDialog';
import { DialogBoxRef } from 'components/core/DialogBox';
import { InitialParams } from 'components/core/FormDialog/Types';

type SolicitanteFormParams = Solicitud.SolicitanteFormParams;
type SolicitanteTableModel = Solicitud.SolicitanteTableModel;
type SolicitanteFormModel = Solicitud.SolicitanteFormModel;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const session = useSession();
    const solicitanteFormRef = useRef<DialogBoxRef<InitialParams>>(null);
    const solicitanteTableRef = useRef<DataTableRef>(null);
    const initialParams = { unidades_productivas: [] };
    const [formParams, setFormParams] = useState<SolicitanteFormParams>(initialParams);

    const esPlataforma = session.roles.some(rol => rol.id === ROLES.plataforma.id);

    const getSolicitantes: GetRows<SolicitanteTableModel> = async (params: UpdateParams<SolicitanteTableModel>) => {
        const response = await SolicitudServicioService.getSolicitantes(params);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        response.data.rows.forEach((r, index) => (r.nro = r.nro || index + 1 + params.rowsPerPage * (params.page - 1)));
        return {
            ...params,
            rows: response.data.rows,
            count: response.data.count,
        };
    };

    const deleteSolicitante = async (data: SolicitanteTableModel): Promise<boolean> => {
        const response = await SolicitudServicioService.deleteSolicitante(data.id);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        return true;
    };

    const getData = async (params?: InitialParams): Promise<SolicitanteFormModel | undefined> => {
        const response = await SolicitudServicioService.getSolicitanteForm(params?.id);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };

    const submit = async (data: SolicitanteFormModel): Promise<boolean> => {
        const response = await SolicitudServicioService.createOrUpdateSolicitante(data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Tarea completada exitosamente');
        return true;
    };

    const getFormParams = async (): Promise<SolicitanteFormParams | undefined> => {
        const response = await SolicitudServicioService.getSolicitanteFormParams();
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };
    const getFormParamsCallback = useCallback(getFormParams, []);

    useEffect(() => {
        solicitanteTableRef.current?.refresh();
        getFormParamsCallback().then((result) => {
            if (result) setFormParams(result);
        });
    }, []);

    return (
        <div>
            <SolicitanteFormDialog
                dialogRef={solicitanteFormRef}
                getData={getData}
                submit={submit}
                params={formParams}
            />
            <SolicitantesTable
                tableRef={solicitanteTableRef}
                getRows={getSolicitantes}
                modoCreacion={esPlataforma}
                editSolicitante={async (data: SolicitanteTableModel) => {
                    solicitanteFormRef.current?.open({
                        params: { id: data.id, title: 'Editar solicitante', titleIcon: 'EditIcon' },
                        actions: [{ on: 'AcceptButton', click: () => solicitanteTableRef.current?.refresh() }],
                    });
                    return true;
                }}
                deleteSolicitante={async (row) => {
                    const success = await deleteSolicitante(row);
                    if (success) {
                        solicitanteTableRef.current?.refresh();
                    }
                    return success;
                }}
                addSolicitante={() => {
                    solicitanteFormRef.current?.open({
                        params: { title: 'Agregar solicitante', titleIcon: 'AddIcon' },
                        actions: [{ on: 'AcceptButton', click: () => solicitanteTableRef.current?.refresh() }],
                    });
                }}
            />
        </div>
    );
};

export const SolicitantesSection = PageComponent;
