import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { configReducer, ConfigState } from './config';
import { notificationReducer, NotificationState } from './notification';
import { versionReducer, VersionState } from './version';

export type RootState = {
    configState: ConfigState;
    notificationState: NotificationState;
    versionState: VersionState;
};

export const useStateSelector: TypedUseSelectorHook<RootState> = useSelector;

const rootReducer = combineReducers<RootState>({
    configState: configReducer,
    notificationState: notificationReducer,
    versionState: versionReducer,
});

export default rootReducer;
