import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { ReactElement } from 'react';
import { getIcon } from 'helpers';
import { Tooltip, Zoom } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

type TimelineModel = Solicitud.TimelineModel;

type Props = {
    timeline: TimelineModel;
};

export const FlujoTimeline = ({ timeline }: Props): ReactElement => {
    const classes = useStyles();

    return (
        <Timeline align="alternate" style={{ margin: 0 }}>
            {timeline?.rows.map((item, rowIndex) => {
                let nextStep = undefined;
                if (rowIndex === 0 && item.siguiente !== null) {
                    nextStep = (
                        <TimelineItem key={`${rowIndex}-next`}>
                            <TimelineSeparator>
                                <TimelineDot style={{ background: 'lightgrey', color: 'black' }}>
                                    {getIcon('CheckIcon', { fontSize: 'inherit' })}
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={1} className={classes.paper}>
                                    <Typography variant="body2" component="h1">
                                        <strong>
                                            {item.siguienteId} - {item.siguiente}
                                        </strong>
                                    </Typography>
                                    <Typography variant="body2">Pendiente ...</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    );
                }
                const estaObservado = !!item.observacion;
                return (
                    <Fragment key={rowIndex}>
                        {nextStep ? nextStep : <></>}
                        <TimelineItem>
                            <TimelineSeparator>
                                <Tooltip
                                    title={
                                        <Typography variant="body2">
                                            {estaObservado ? 'Observado' : 'Enviado'}
                                        </Typography>
                                    }
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    enterDelay={200}
                                >
                                    <TimelineDot
                                        color={estaObservado ? 'secondary' : 'primary'}
                                        style={{ fontSize: '12px' }}
                                    >
                                        {estaObservado && getIcon('CloseIcon', { fontSize: 'inherit' })}
                                        {!estaObservado && getIcon('CheckIcon', { fontSize: 'inherit' })}
                                    </TimelineDot>
                                </Tooltip>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={1} className={classes.paper}>
                                    <Typography variant="body2" component="h1">
                                        <strong>
                                            {item.etapaId} - {item.etapa}
                                        </strong>
                                    </Typography>
                                    <Typography variant="body2">Responsable: {item.usuario}</Typography>
                                    {estaObservado && (
                                        <Typography variant="body2">Observación: {item.observacion}</Typography>
                                    )}
                                    <Typography variant="body2">Fecha: {item.fecha}</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    </Fragment>
                );
            })}
        </Timeline>
    );
};
