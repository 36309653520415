import { Button } from '@material-ui/core';
import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, SelectOption } from 'components/core/FormDialog/Types';
import { ReactElement, useRef } from 'react';
import { timer } from 'utils';
import * as yup from 'yup';

type FormModel = {
    id?: string;
    username: string;
    password: string;
    email: string;
    fid_rol: string;
    nombres: string;
    primer_apellido: string;
    segundo_apellido: string;
    direccion: string;
    ciudad: string;
    telefono: string;
    genero: string;
};

const MyComponent = (): ReactElement => {
    const dialogRef = useRef<FormDialogRef>(null);

    const rolOptions: SelectOption[] = [
        { value: '1', label: 'Administrador' },
        { value: '2', label: 'Facilitador' },
    ];
    const ciudadOptions: SelectOption[] = [
        { value: 'LP', label: 'La Paz' },
        { value: 'CBB', label: 'Cochabamba' },
        { value: 'SC', label: 'Santa Cruz' },
    ];
    const generoOptions: SelectOption[] = [
        { value: 'M', label: 'Hombre' },
        { value: 'F', label: 'Mujer' },
    ];

    const formLayout: FormGroup<FormModel>[] = [
        {
            title: 'Datos de usuario',
            grid: [
                [
                    { name: 'username', label: 'Nombre de usuario', type: 'text' },
                    { name: 'password', label: 'Contraseña', type: 'password' },
                ],
                [
                    { name: 'email', label: 'Correo electrónico', type: 'text' },
                    { name: 'fid_rol', label: 'Rol', type: 'select', options: rolOptions },
                ],
            ],
        },
        {
            title: 'Datos personales',
            grid: [
                [
                    { name: 'nombres', label: 'Nombre(s)', type: 'text' },
                    { name: 'primer_apellido', label: 'Primer apellido', type: 'text' },
                    { name: 'segundo_apellido', label: 'Segundo apellido', type: 'text' },
                ],
                [
                    { name: 'direccion', label: 'Dirección', type: 'text' },
                    { name: 'ciudad', label: 'Ciudad', type: 'select', options: ciudadOptions },
                ],
                [
                    { name: 'telefono', label: 'Teléfono', type: 'text' },
                    { name: 'genero', label: 'Género', type: 'select', options: generoOptions },
                ],
            ],
        },
    ];

    const validationSchema = yup.object({
        username: yup.string().required(),
        password: yup.string().required(),
        email: yup.string().required(),
        fid_rol: yup.string().required(),
        nombres: yup.string().required(),
        primer_apellido: yup.string().required(),
        segundo_apellido: yup.string().required(),
        direccion: yup.string().required(),
        ciudad: yup.string().required(),
        telefono: yup.string().required(),
        genero: yup.string().required(),
    });

    const getData = async (params?: { id?: string }): Promise<FormModel> => {
        await timer(2000);
        const ID = params?.id;

        if (ID) {
            return {
                ciudad: 'LP',
                direccion: 'abc123',
                email: 'admin@gmail.com',
                fid_rol: '1',
                genero: 'M',
                nombres: 'ADMIN',
                password: '123456',
                primer_apellido: 'abc',
                segundo_apellido: 'abc',
                telefono: '12345678',
                username: 'admin',
            };
        }

        return {
            ciudad: '',
            direccion: '',
            email: '',
            fid_rol: '',
            genero: '',
            nombres: '',
            password: '',
            primer_apellido: '',
            segundo_apellido: '',
            telefono: '',
            username: '',
        };
    };

    const submit = async (data: FormModel) => {
        console.log('saving... DATA = ', data);
        await timer(2000);
        return true;
    };

    return (
        <>
            <FormDialog
                ref={dialogRef}
                formLayout={formLayout}
                getData={getData}
                submit={submit}
                validationSchema={validationSchema}
            />
            <Button
                onClick={() => {
                    dialogRef.current?.open({
                        params: { title: 'Crear usuario', titleIcon: 'GroupIcon' },
                        actions: [
                            { on: 'AcceptButton', click: () => console.log('accept') },
                            { on: 'CancelButton', click: () => console.log('cancel') },
                            { on: 'CloseButton', click: () => console.log('close') },
                        ],
                    });
                }}
            >
                Crear
            </Button>
            <Button
                onClick={() => {
                    dialogRef.current?.open({
                        params: { id: '123', title: 'Editar usuario', titleIcon: 'EditIcon' },
                        actions: [
                            { on: 'AcceptButton', click: () => console.log('accept') },
                            { on: 'CancelButton', click: () => console.log('cancel') },
                            { on: 'CloseButton', click: () => console.log('close') },
                        ],
                    });
                }}
            >
                Editar
            </Button>
        </>
    );
};

export const FormDialogSample = MyComponent;
