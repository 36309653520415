import { Box, Typography, useTheme } from '@material-ui/core';
import { REGIONALES, ROLES } from 'base/authBase';
import { ActionItem, ActionColumn } from 'components/core/Columns';
import { StatusColumn } from 'components/core/Columns/StatusColumn';
import { GetRows, DataTable, DataTableRef, TableHeader } from 'components/core/DataTable';
import { useSession } from 'hooks';
import { ReactElement, RefObject } from 'react';

type TableModel = Solicitud.SolicitudServicioTableModel;

type Props = {
    tableRef: RefObject<DataTableRef>;
    modoCreacion?: boolean;
    modoAprobacion?: boolean;
    getRows: GetRows<TableModel>;
    editSolicitudServicio: (row: TableModel) => Promise<boolean>;
    deleteSolicitudServicio: (row: TableModel) => Promise<boolean>;
    verSolicitudServicio: (row: TableModel) => void;
    showTimeline: (row: TableModel) => void;
    addSolicitudServicio: () => void;
};

export const TableComponent = (props: Props): ReactElement => {
    const {
        tableRef,
        getRows,
        editSolicitudServicio,
        deleteSolicitudServicio,
        addSolicitudServicio,
        verSolicitudServicio,
        showTimeline,
    } = props;

    const theme = useTheme();
    const session = useSession();

    const esPlataforma = session.roles.some((rol) => rol.id === ROLES.plataforma.id);
    // const esTecnico = session.rol.includes(ROLES.tecnicooperador.id);

    const headersCreacion: TableHeader<TableModel>[] = [
        { id: 'actions', label: 'Acciones', render: renderColumnActions, width: 140 },
    ];

    const esLaboratorio = session.roles.some((rol) => rol.id === ROLES.tecnicolaboratorio.id);
    const esCochabamba = session.regional?.id === REGIONALES.cochabamba.id;
    const esLaboratorioCochabamba = esLaboratorio && esCochabamba;
    const headersSolicitante: TableHeader<TableModel>[] = [];
    if (!esLaboratorioCochabamba) {
        headersSolicitante.push({
            id: 'nombre_cliente',
            label: 'Solicitante',
            align: 'left',
            render: renderColumnNombre,
        });
    }

    const tableHeaders: TableHeader<TableModel>[] = [
        { id: 'nro', label: 'Nro.', width: 80 },
        { id: 'codigo_solicitud', label: 'Código de Solicitud', align: 'left', width: 150 },
        ...headersSolicitante,
        { id: 'fecha_solicitud', label: 'Fecha de registro', align: 'left', minWidth: 90 },
        { id: 'estado', label: 'Estado de flujo', align: 'center', render: renderEstado, width: 230 },
        { id: 'aprobado_coordinador', label: 'Aprobado', align: 'center', render: renderAprobado },
        ...headersCreacion,
    ];

    function renderColumnNombre(data: TableModel): ReactElement {
        return (
            <>
                <Typography variant="body2">
                    <strong>{data.nombre_cliente}</strong>
                </Typography>
                <Typography variant="caption">{data.cedula_identidad_cliente}</Typography>
            </>
        );
    }

    function renderColumnActions(data: TableModel): ReactElement {
        const confirmMessage = (
            <>
                ¿Desea eliminar la solicitud de servicio?
                <br />
                <Box my={3}>
                    <Typography align="center">Solicitante:</Typography>
                    <Typography align="center">
                        <strong>{data.nombre_cliente}</strong>
                    </Typography>
                </Box>
            </>
        );
        const items: ActionItem[] = [
            {
                title: 'Ver estado del flujo',
                icon: 'TimelineIcon',
                onClick: async () => {
                    showTimeline(data);
                    return true;
                },
            },
            {
                title: 'Ver detalle de la solicitud',
                icon: 'VisibilityIcon',
                onClick: async () => {
                    verSolicitudServicio(data);
                    return Promise.resolve(true);
                },
            },
        ];

        if (esPlataforma && data.aprobado_coordinador === null) {
            items.push({
                title: 'Editar solicitud',
                icon: 'EditIcon',
                color: 'primary',
                onClick: async () => editSolicitudServicio(data),
            });
            items.push({
                title: 'Eliminar solicitud',
                icon: 'DeleteIcon',
                color: 'secondary',
                onClick: async () => deleteSolicitudServicio(data),
                confirm: true,
                confirmMessage,
            });
        }

        return <ActionColumn items={items} size="small" />;
    }

    function renderAprobado(data: TableModel): ReactElement {
        let items: ActionItem[] = [];

        if (data.aprobado_coordinador === null) {
            return <></>;
        } else if (data.aprobado_coordinador) {
            if (data.aprobado_tecnico === null) {
                items = [{ title: 'Aprobado', icon: 'CheckIcon' }];
            } else if (data.aprobado_tecnico) {
                items = [{ title: 'Aprobado', icon: 'CheckIcon' }];
            } else {
                items = [{ title: 'Rechazado', icon: 'CloseIcon' }];
            }
        } else {
            items = [{ title: `Rechazado`, icon: 'CloseIcon', color: 'secondary' }];
        }

        return <ActionColumn items={items} size="small" />;
    }

    function renderEstado(data: TableModel): ReactElement {
        let background = theme.palette.primary.dark;
        if (data.estado === 'NUEVO') {
            background = theme.palette.success.dark;
        }
        if (data.estado === 'FINALIZADO') {
            background = theme.palette.background.default;
        }
        const color = theme.palette.getContrastText(background);
        return <StatusColumn label={data.estado} color={color} background={background} />;
    }

    return (
        <DataTable
            title="Solicitudes de Servicio"
            ref={tableRef}
            headers={tableHeaders}
            getRows={getRows}
            addLabel="Nueva solicitud"
            onActionAddClick={esPlataforma ? addSolicitudServicio : undefined}
            hideFilters
        />
    );
};

export const SolicitudServicioTable = TableComponent;
