import { ROLES } from 'base/authBase';
import { APP_NAME } from 'config/app.config';
import { RUTAS } from 'routes';
import { BaseService } from 'services/http';

export const DEFAULT_SESSION_INFO: SessionInfo = {
    userId: '',
    userName: '',
    displayName: '',
    genero: 'NINGUNO',
    email: '',
    roles: [],
    cedula_identidad: '',
    cedula_identidad_expedido_en: '',
    nombre: '',
};

export type LoginData = {
    token: string;
    info: SessionInfo;
};

export const login = async (username: string, password: string): Promise<BaseResponse<LoginData>> => {
    const DATA = { usuario: username, password };

    const response = await BaseService.request<LoginData>('post', '/auth/login', DATA);
    if (response.failure) return BaseService.sendError<LoginData>(response.error);

    const SESSION_INFO: SessionInfo = response.data.info;
    const TOKEN_ACCESO: string = response.data.token;

    localStorage.setItem(`${APP_NAME}_token`, TOKEN_ACCESO);
    localStorage.setItem(`${APP_NAME}_session`, JSON.stringify(SESSION_INFO));

    return response;
};

export const logout = async (): Promise<BaseResponse<unknown>> => {
    const response = await BaseService.request('post', '/auth/logout');
    localStorage.removeItem(`${APP_NAME}_token`);
    localStorage.removeItem(`${APP_NAME}_session`);
    return response;
};

export const isAuthenticated = (): boolean => {
    const token = localStorage.getItem(`${APP_NAME}_token`);
    return token ? true : false;
};

export const getToken = (): string => {
    const token = localStorage.getItem(`${APP_NAME}_token`);
    return token ? token : '';
};

export const getSessionInfoFromServer = async (): Promise<BaseResponse<SessionInfo>> => {
    return BaseService.request('get', '/auth/info');
};

export const getSessionInfo = (): SessionInfo => {
    const sessionInfo = localStorage.getItem(`${APP_NAME}_session`);
    return sessionInfo ? JSON.parse(sessionInfo) : DEFAULT_SESSION_INFO;
};

export const getMenu = (): MenuModel => {
    const session = getSessionInfo();

    const homeItem = { icon: 'HomeIcon', label: 'Inicio', path: RUTAS.inicio };

    const usuariosItem          = { color: '#396b8e', icon: 'GroupIcon',              label: 'Usuarios',              path: RUTAS.usuarios };
    const solicitudServicioItem = { color: '#009688', icon: 'AssignmentTurnedInIcon', label: 'Solicitud de servicio', path: RUTAS.solicitudServicio };
    const inventarioItem        = { color: '#ef6c00', icon: 'ListIcon',               label: 'Inventario',            path: RUTAS.inventario };
    const mantenimientoItem     = { color: '#607d8b', icon: 'BuildIcon',              label: 'Mantenimiento',         path: RUTAS.mantenimiento };
    const costoServicioItem     = { color: '#43a047', icon: 'MonetizationOnIcon',     label: 'Costo de Servicios',    path: RUTAS.costoServicios };
    const reporteItem           = { color: '#1e88e5', icon: 'AssessmentIcon',         label: 'Reportes',              path: RUTAS.reportes };
    const digitalizacionItem    = { color: '#396b8e', icon: 'MenuBookIcon',           label: 'Digitalización',        path: RUTAS.digitalizacion };
    const reclamosItem          = { color: '#d75f5f', icon: 'ReportIcon',             label: 'Reclamos',              path: RUTAS.reclamos };

    const perfilItem         = { icon: 'AccountCircleIcon', label: 'Perfil de usuario', path: RUTAS.perfil };
    const notificacionesItem = { icon: 'NotificationsIcon', label: 'Notificaciones',    path: RUTAS.notificaciones };
    const configItem         = { icon: 'SettingsIcon',      label: 'Configuración',     path: RUTAS.config };
    // const guiaEstilosItem    = { icon: 'ColorLensIcon',     label: 'Guía de estilos',   path: RUTAS.guiaEstilos };
    // const ayudaItem          = { icon: 'HelpIcon',          label: 'Ayuda',             path: RUTAS.ayuda };
    const acercaDeItem       = { icon: 'InfoIcon',          label: 'Acerca de',         path: RUTAS.acercaDe };

    // Menu INICIO
    const homeItems: MenuListModel = [homeItem];

    // Menu MODULOS
    const moduleItems: MenuListModel = [];
    const rolesId = session.roles.map((rol) => rol.id);
    if (rolesId.includes(ROLES.admin.id)) {
        addItem(usuariosItem, moduleItems);
    }
    if (rolesId.includes(ROLES.tecnicooperador.id)) {
        addItem(solicitudServicioItem, moduleItems);
        addItem(mantenimientoItem, moduleItems);
    }
    if (rolesId.includes(ROLES.tecnicolaboratorio.id)) {
        addItem(solicitudServicioItem, moduleItems);
        addItem(mantenimientoItem, moduleItems);
        addItem(reclamosItem, moduleItems);
    }
    if (rolesId.includes(ROLES.coordinador.id)) {
        addItem(solicitudServicioItem, moduleItems);
        addItem(reporteItem, moduleItems);
        addItem(reclamosItem, moduleItems);
    }
    if (rolesId.includes(ROLES.administracion.id)) {
        addItem(solicitudServicioItem, moduleItems);
        addItem(reclamosItem, moduleItems);
    }
    if (rolesId.includes(ROLES.almacen.id)) {
        addItem(inventarioItem, moduleItems);
    }
    if (rolesId.includes(ROLES.plataforma.id)) {
        addItem(solicitudServicioItem, moduleItems);
        addItem(costoServicioItem, moduleItems);
        addItem(reclamosItem, moduleItems);
    }
    if (rolesId.includes(ROLES.digitalizador.id)) {
        addItem(digitalizacionItem, moduleItems);
    }

    // Menu CONFIGURACION
    const configItems: MenuListModel = [
        perfilItem,
        notificacionesItem,
        configItem,
        // guiaEstilosItem,
        // ayudaItem,
        acercaDeItem,
    ];

    return [
        { name: 'home', items: homeItems },
        { name: 'modules', items: moduleItems },
        { name: 'config', items: configItems },
    ];
};

function addItem(item: MenuItemModel, list: MenuListModel) {
    if (list.some((it) => it.path === item.path)) return;
    list.push(item);
}
