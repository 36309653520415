import { BaseService } from 'services/http';
import { NotificationTableModel } from 'pages/Notifications/components/tables/NotificationTable';

export const getBuzonEntrada = async (queryParams: QueryParams = {}): Promise<BaseResponse<{ rows: NotificationTableModel[]; count: number }>> => {
    const queryParamsText = BaseService.buildQueryParamsText(queryParams);
    return BaseService.request('get', `/notificaciones${queryParamsText}`);
};

export const getNotificacionesPendientes = async (): Promise<BaseResponse<{ rows: NotificationTableModel[]; count: number }>> => {
    const queryParams: QueryParams = { filters: { visto: false } };
    return getBuzonEntrada(queryParams);
};

export const getNotificationAlert = async (): Promise<BaseResponse<NotificationAlert>> => {
    const response = await getNotificacionesPendientes();
    if (response.error) return BaseService.sendError(response.error);

    const result: NotificationAlert = {
        buzonEntrada: response.data.rows.length,
        messages: [],
    };
    return BaseService.sendSuccess(result);
};

export const marcarVisto = async (notificationId: string): Promise<BaseResponse<unknown>> => {
    return BaseService.request('put', `/notificaciones/${notificationId}/marcarvisto`);
};
