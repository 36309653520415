import { BaseService } from 'services/http';

type InsumoTableModel = Inventario.InsumoTableModel;
type InsumoFormModel = Inventario.InsumoFormModel;
type InsumoFormParams = Inventario.InsumoFormParams;

export const getInsumos = async (
    queryParams: QueryParams = {},
): Promise<BaseResponse<{ rows: InsumoTableModel[]; count: number }>> => {
    const queryParamsText = BaseService.buildQueryParamsText(queryParams);
    return BaseService.request('get', `/insumo${queryParamsText}`);
};

export const getInsumoForm = async (insumoId?: string): Promise<BaseResponse<InsumoFormModel>> => {
    const queryParamsText = insumoId ? `?id=${insumoId}` : '';
    return BaseService.request('get', `/insumo/form${queryParamsText}`);
};

export const getInsumoFormParams = async (): Promise<BaseResponse<InsumoFormParams>> => {
    return BaseService.request('get', `/insumo/form_params`);
};

export const editInsumo = async (insumoId: string, data: InsumoFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('put', `/insumo/${insumoId}`, data);
};

export const createInsumo = async (data: InsumoFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', '/insumo', data);
};

export const createOrUpdateInsumo = async (data: InsumoFormModel): Promise<BaseResponse<boolean>> => {
    if (data.id) return editInsumo(data.id, data);
    return createInsumo(data);
};

export const deleteInsumo = async (insumoId: string): Promise<BaseResponse<boolean>> => {
    return BaseService.request('delete', `/insumo/${insumoId}`);
};
