import { Box, Card, CardActionArea, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useNotify } from 'hooks';
import { DigitalizacionModule } from 'modules/digitalizacion';
import { useCallback, useState } from 'react';
import { ReactElement, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { ReporteService } from '.';

type ContadorModel = Reporte.ContadorModel;

const PageComponent = (): ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const notify = useNotify();

    const [menuItems, setMenuItems] = useState<MenuListModel>([]);

    const handleClickItem = (item: MenuItemModel) => {
        if (item.path) history.push(item.path);
    };

    const getContador = async (): Promise<ContadorModel | undefined> => {
        const response = await ReporteService.getContador();
        if (response.error) {
            notify.error(response.error);
            return;
        }
        const contador = response.data;

        const laPaz: MenuItemModel = {
            color: '#396b8e',
            icon: 'GroupIcon',
            label: 'La Paz',
            caption: `${contador.lp}`,
            path: '/reportes',
        };
        const cochabamba: MenuItemModel = {
            color: '#009688',
            icon: 'GroupIcon',
            label: 'Cochabamba',
            caption: `${contador.cb}`,
            path: '/reportes',
        };
        const potosi: MenuItemModel = {
            color: '#ef6c00',
            icon: 'GroupIcon',
            label: 'Potosí',
            caption: `${contador.pt}`,
            path: '/reportes',
        };

        const newMenuItems: MenuListModel = [laPaz, cochabamba, potosi];
        setMenuItems(newMenuItems);
        return response.data;
    };
    const getContadorCallback = useCallback(getContador, []);

    useEffect(() => {
        getContadorCallback();
    }, []);

    return (
        <div className={classes.root}>
            <Typography variant="h5" color="primary">
                Reportes
            </Typography>
            <Grid container className={classes.grid} spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={2}>
                        {menuItems.map((menuItem, index) => (
                            <Grid key={index} item xs={4} md={4}>
                                <Card className={classes.paper} elevation={0}>
                                    <CardActionArea
                                        className={classes.media}
                                        onClick={() => handleClickItem(menuItem)}
                                        style={{ background: menuItem.color }}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography color="inherit" variant="h5">
                                                {menuItem.label}
                                            </Typography>
                                            <Typography color="inherit" variant="h2">
                                                {menuItem.caption}
                                            </Typography>
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            <DigitalizacionModule />
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    grid: {
        flexGrow: 1,
        padding: theme.spacing(2, 0),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(0),
        },
    },
    media: {
        height: '100%',
        background: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        height: theme.spacing(15),
        background: 'unset',
    },
}));

export const ReportesModule = withRouter(PageComponent);
