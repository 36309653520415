import { ActionColumn, ActionItem } from 'components/core/Columns';
import { GetRows, DataTable, DataTableRef, TableHeader } from 'components/core/DataTable';
import { ReactElement, RefObject } from 'react';

type TableModel = Inventario.InsumoTableModel;

type Props = {
    tableRef: RefObject<DataTableRef>;
    getRows: GetRows<TableModel>;
    editInsumo: (row: TableModel) => Promise<boolean>;
    deleteInsumo: (row: TableModel) => Promise<boolean>;
    addInsumo: () => void;
};

export const TableComponent = (props: Props): ReactElement => {
    const { tableRef, getRows, editInsumo, deleteInsumo, addInsumo } = props;
    const tableHeaders: TableHeader<TableModel>[] = [
        { id: 'nro', label: 'Nro.', width: 80 },
        { id: 'codigo', label: 'Código', align: 'left', sort: true },
        { id: 'nombre', label: 'Nombre', align: 'left', sort: true },
        { id: 'unidad_medida', label: 'Unidad de medida', align: 'left', sort: true },
        { id: 'actions', label: 'Acciones', render: renderColumnActions, width: 130 },
    ];

    function renderColumnActions(data: TableModel): ReactElement {
        const confirmMessage = (
            <>
                ¿Desea eliminar el insumo <strong>{data.nombre}</strong>?
            </>
        );
        const items: ActionItem[] = [
            { icon: 'EditIcon', onClick: async () => editInsumo(data) },
            {
                icon: 'DeleteIcon',
                color: 'secondary',
                onClick: async () => deleteInsumo(data),
                confirm: true,
                confirmMessage,
            },
        ];
        return <ActionColumn items={items} size="small" />;
    }

    return (
        <DataTable
            title="Insumos"
            ref={tableRef}
            headers={tableHeaders}
            getRows={getRows}
            onActionAddClick={addInsumo}
            hideFilters
        />
    );
};

export const InsumoTable = TableComponent;
