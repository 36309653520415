import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams } from 'components/core/FormDialog/Types';
import { ReactElement, RefObject } from 'react';
import * as yup from 'yup';

type FormModel = Reclamos.RechazarFormModel;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
};

const FormDialogComponent = ({ dialogRef, getData, submit }: Props): ReactElement => {
    const formLayout: FormGroup<FormModel>[] = [
        {
            title: 'Observación',
            grid: [
                [
                    { name: 'observacion', label: 'Detalle de la observación', type: 'textarea', rows: 5 },
                ],
            ],
        },
    ];

    const validationSchema = yup.object({
        observacion: yup.string().required(),
    });

    return (
        <FormDialog
            ref={dialogRef}
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            validationSchema={validationSchema}
            // debug
        />
    );
};

export const RechazarFormDialog = FormDialogComponent;
