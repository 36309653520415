import { BaseService } from 'services/http';

type UsuarioTableModel = Usuario.UsuarioTableModel;
type UsuarioFormModel = Usuario.UsuarioFormModel;
type UsuarioDerivar = Usuario.UsuarioDerivar;

export const getUsers = async (queryParams: QueryParams = {}): Promise<BaseResponse<{ rows: UsuarioTableModel[]; count: number }>> => {
    const queryParamsText = BaseService.buildQueryParamsText(queryParams);
    return BaseService.request('get', `/admin/usuarios${queryParamsText}`);
};

export const getUserForm = async (userId?: string): Promise<BaseResponse<UsuarioFormModel>> => {
    const queryParamsText = userId ? `?id=${userId}` : '';
    return BaseService.request('get', `/admin/usuarios/form${queryParamsText}`);
};

export const editUser = async (userId: string, data: UsuarioFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('put', `/admin/usuarios/${userId}`, data);
};

export const createUser = async (data: UsuarioFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', '/admin/usuarios', data);
};

export const createOrUpdateUser = async (data: UsuarioFormModel): Promise<BaseResponse<boolean>> => {
    if (data.id) return editUser(data.id, data);
    return createUser(data);
};

export const deleteUser = async (userId: string): Promise<BaseResponse<boolean>> => {
    return BaseService.request('delete', `/admin/usuarios/${userId}`);
};

export const activeUser = async (userId: string, active: boolean): Promise<BaseResponse<boolean>> => {
    const data = { estado: active ? 'ACTIVO' : 'INACTIVO' };
    return BaseService.request('put', `/admin/usuarios/${userId}`, data);
};

export const resetPass = async (userId: string): Promise<BaseResponse<boolean>> => {
    return BaseService.request('put', `/admin/usuarios/reseteo/${userId}`);
};

export const usuariosDerivar = async (): Promise<BaseResponse<{ rows: UsuarioDerivar[]; count: number }>> => {
    return BaseService.request('get', '/usuarios-admin/derivar');
};
