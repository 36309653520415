import packageJson from '../../../package.json';
import { AppConfig } from 'pages/Settings/Types';

export type ConfigState = AppConfig;

const DEFAULT_CONFIG: AppConfig = {
    version: packageJson.version,
    theme: {
        mode: 'light',
    },
    notification: {
        active: true,
    },
};

const config = localStorage.getItem('config');
let currentConfig = config ? JSON.parse(config) : DEFAULT_CONFIG;

if (currentConfig.version !== DEFAULT_CONFIG.version) {
    currentConfig = DEFAULT_CONFIG;
}

export const INITIAL_STATE: ConfigState = currentConfig;
