/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import { useField, FormikProps, FormikValues } from 'formik';

import { makeStyles, Theme, MenuItem, TextField, Box, Typography } from '@material-ui/core';
import { FormValue, SelectOption, OnChangeFunction } from '../FormDialog/Types';
import Checkbox from '@material-ui/core/Checkbox';

const DEFAULT_SELECT = 'filled';
const SIN_OPCIONES_TEXT = ' >>> Sin opciones <<<';
const MARCAR_TODOS_TEXT = 'Marcar TODOS';
const DESMARCAR_TODOS_TEXT = 'Desmarcar TODOS';

type Props = {
    name: string;
    label: string;
    disabled?: boolean;
    value?: (number | string)[];
    error?: boolean;
    helperText?: string;
    formik?: FormikProps<FormikValues>;
    options: SelectOption[] | ((formValue: FormValue) => SelectOption[]);
    fieldRequired?: string;
    variant?: 'filled' | 'standard' | 'outlined';
    onChange?: OnChangeFunction;
};

export const MyMultipleSelect = (props: Props): ReactElement => {
    const { name, label, error, helperText, formik } = props;
    const [field, meta] = useField<(number | string)[]>(name);

    const classes = useStyles();
    const errorText = meta.error && meta.touched ? meta.error : '';

    const OPTIONS = typeof props.options === 'function' ? props.options(formik?.values as FormValue) : props.options;
    const OPTIONS_VALUE = OPTIONS.map((opt) => opt.value);
    const VARIANT_SELECT = props.variant || DEFAULT_SELECT;

    // controlled or uncontrolled
    const _val = typeof props.value !== 'undefined' ? props.value : field.value;
    const VALUE = OPTIONS.filter(opt => _val && _val.includes(opt.value)).map(opt => opt.value);

    const [allSelected, setAllSelected] = useState<boolean>(false);

    useEffect(() => {
        const newAllSelected =
        VALUE && VALUE.length > 0
            ? JSON.stringify(VALUE.sort()) === JSON.stringify(OPTIONS.map((opt) => opt.value).sort())
            : false;
        setAllSelected(newAllSelected);
    }, [VALUE, OPTIONS]);

    const FIELD_REQUIRED = props.fieldRequired;
    const [DISABLED, setDisabled] = useState(false);
    const requiredValue = FIELD_REQUIRED ? formik?.values[FIELD_REQUIRED] : undefined;
    useEffect(() => {
        const disableByProp = typeof props.disabled !== 'undefined' ? props.disabled : false;
        let newFieldDisabled = disableByProp;
        if (!disableByProp && FIELD_REQUIRED) {
            newFieldDisabled =
                requiredValue === undefined ||
                requiredValue === '' ||
                requiredValue === null ||
                requiredValue === '[]' || // Para los archivos adjuntos (MyDropzone)
                requiredValue === '{}' || // Para los grupos de checkbox (MyCheckboxGroup)
                (Array.isArray(requiredValue) && requiredValue.length === 0);
        }
        setDisabled(newFieldDisabled);
    }, [props.disabled, FIELD_REQUIRED, requiredValue]);

    const handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        let newValue = event.target.value as (number | string)[];
        if (newValue.includes(SIN_OPCIONES_TEXT)) return;

        const marcarTodosClicked = newValue.includes(MARCAR_TODOS_TEXT);
        if (marcarTodosClicked) {
            newValue = OPTIONS_VALUE;
        }

        const desmarcarTodosClicked = newValue.includes(DESMARCAR_TODOS_TEXT);
        if (desmarcarTodosClicked) {
            newValue = [];
        }

        if (props.onChange) props.onChange(newValue, formik);
        else formik?.setFieldValue(name, newValue);
    };

    return (
        <TextField
            variant={VARIANT_SELECT}
            error={error || !!errorText}
            className={classes.input}
            select
            label={label}
            helperText={helperText || errorText}
            disabled={DISABLED}
            SelectProps={{
                name: name,
                multiple: true,
                renderValue: (selected: any) => {
                    return selected
                        .map((value: any) => String(OPTIONS.find((opt) => opt.value === value)?.label))
                        .join(', ');
                },
                value: OPTIONS.length === 0 ? [] : VALUE || [],
                onChange: handleChange,
            }}
        >
            <MenuItem
                key="select_all"
                value={OPTIONS.length > 0 ? (allSelected ? DESMARCAR_TODOS_TEXT : MARCAR_TODOS_TEXT) : SIN_OPCIONES_TEXT}
                dense
                disableGutters
                style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderBottom: 'solid lightgrey 1px',
                    background: allSelected ? 'rgba(0, 0, 0, 0.08)' : 'unset',
                }}
            >
                {OPTIONS.length > 0 ? <Checkbox checked={allSelected} color="primary" style={{ padding: 6 }} /> : <></>}
                <Box p={OPTIONS.length > 0 ? 0 : 1}>
                    <Typography variant="body2">
                        {OPTIONS.length > 0 ? (allSelected ? DESMARCAR_TODOS_TEXT : MARCAR_TODOS_TEXT) : SIN_OPCIONES_TEXT}
                    </Typography>
                </Box>
            </MenuItem>
            {OPTIONS.map((option) => (
                <MenuItem
                    key={option.value}
                    value={option.value}
                    dense
                    disableGutters
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                    <Checkbox checked={VALUE.includes(option.value)} color="primary" style={{ padding: 6 }} />
                    <Box>
                        <Typography variant="body2">{option.label}</Typography>
                        {option.caption && (
                            <Typography style={{ color: '#aaaaaa' }} variant="caption">
                                {option.caption}
                            </Typography>
                        )}
                    </Box>
                </MenuItem>
            ))}
        </TextField>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    input: {
        width: '100%',
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        '& .MuiSelect-nativeInput': {
            width: 'unset',
        },
        '& .MuiSelect-select': {
            fontSize: '14px',
        },
    },
}));
