// import { DialogBoxRef } from 'components/core/DialogBox';
import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams, SelectOption } from 'components/core/FormDialog/Types';
import { isIntegerValidTest } from 'components/core/FormDialog/yup-tests';
import { ReactElement, RefObject } from 'react';
import * as yup from 'yup';

type FormModel = Solicitud.UnidadProductivaFormModel;
type FormParams = Solicitud.UnidadProductivaFormParams;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
    params: FormParams;
};

const FormDialogComponent = ({ dialogRef, getData, submit, params }: Props): ReactElement => {
    const rubroOptions: SelectOption[] = params.rubros.map((rubro) => ({
        value: rubro.id,
        label: rubro.nombre,
    }));

    const subtipoRubroOptions = (formValue: FormModel): SelectOption[] => {
        return params.subtipos_rubro
            .filter((sr) => sr.fid_rubro === formValue.rubro)
            .map((rubro) => ({
                value: rubro.id,
                label: rubro.nombre,
            }));
    };

    const formLayout: FormGroup<FormModel>[] = [
        {
            title: 'Datos de la unidad productiva',
            grid: [
                [
                    { name: 'nombre_razon_social', label: 'Razón Social', type: 'text', delay: 500 },
                    { name: 'numero_certificado', label: 'Número de certificado', type: 'text', delay: 500 },
                ],
                [
                    { name: 'direccion', label: 'Dirección', type: 'text', delay: 500 },
                    { name: 'asociacion_gremio', label: 'Asociación/Gremio', type: 'text', delay: 500 },
                ],
                [
                    {
                        name: 'rubro',
                        label: 'Rubro',
                        type: 'select',
                        options: rubroOptions,
                        onChange: (value, formik) => {
                            formik.setFieldValue('rubro', value);
                            formik.setFieldValue('subtipo_rubro', '');
                        },
                    },
                    {
                        name: 'subtipo_rubro',
                        label: 'Rubro específico',
                        type: 'select',
                        fieldRequired: 'rubro',
                        options: subtipoRubroOptions,
                    },
                ],
                [
                    {
                        name: 'producto',
                        label: 'Producto principal',
                        type: 'text',
                    },
                ],
            ],
        },
        {
            title: 'Antigüedad',
            grid: [
                [
                    { name: 'antiguedad_anios', label: 'Años', type: 'text', inputType: 'number', delay: 500 },
                    { name: 'antiguedad_meses', label: 'Meses', type: 'text', inputType: 'number', delay: 500 },
                ],
            ],
        },
    ];

    const validationSchema = yup.object({
        nombre_razon_social: yup.string().required(),
        rubro: yup.string().required(),
        direccion: yup.string().required(),
        antiguedad_anios: yup.string().required().test(isIntegerValidTest(0)),
        antiguedad_meses: yup.string().required().test(isIntegerValidTest(0, 11)),
    });

    return (
        <FormDialog
            ref={dialogRef}
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            validationSchema={validationSchema}
            maxWidth={'sm'}
            fullwidth={true}
            // debug
        />
    );
};

export const UnidadProductivaFormDialog = FormDialogComponent;
