import { Box, Button, CircularProgress, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CacheBuster, VersionInfo } from 'CacheBuster';
import { WEB_CLIENT_URL } from 'config/app.config';
import { getIcon } from 'helpers';
import { useLoading } from 'hooks';
import { ReactElement } from 'react';
import { withRouter } from 'react-router-dom';
import { RootState, useStateSelector } from 'redux/reducers';
import { getLocalDateString } from 'utils';
import { PageTitle } from 'components/core/PageTitle';

const msg1 = '¡Genial, ya estás usando la última versión de la aplicación!';
const msg2 = 'Existe una nueva versión, haz click en el botón ACTUALIZAR';

type TituloProps = { value: string };
const Titulo = ({ value }: TituloProps): ReactElement => {
    return (
        <Typography align="left" variant="body1">
            <strong>{value}</strong>
        </Typography>
    );
};

type SubtituloProps = { value: string };
const Subtitulo = ({ value }: SubtituloProps): ReactElement => {
    return (
        <Typography align="left" variant="body1" gutterBottom>
            {value}
        </Typography>
    );
};

type VersionProps = { versionInfo: VersionInfo };
const Version = ({ versionInfo }: VersionProps): ReactElement => {
    const version = `Versión ${versionInfo.buildVersion}`;
    const isLatestVersion = versionInfo.isLatestVersion;
    return (
        <Typography align="left" variant="body1" style={{ display: 'flex', alignItems: 'center' }}>
            {version} &nbsp;
            {isLatestVersion ? (
                <Tooltip title={<Typography>{msg1}</Typography>} placement="right">
                    <IconButton size="small">
                        <CheckCircleIcon style={{ fontSize: '14px', color: '#4caf50' }} />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title={<Typography>{msg2}</Typography>} placement="right">
                    <IconButton size="small">
                        <WarningIcon style={{ fontSize: '14px', color: '#ff9800' }} />
                    </IconButton>
                </Tooltip>
            )}
        </Typography>
    );
};

const PageComponent = (): ReactElement => {
    const classes = useStyles();
    const [{ isLoading }, { start }] = useLoading();
    const versionState = useStateSelector((state: RootState) => state.versionState);

    const handleUpdateVersion = async () => {
        if (isLoading) return;
        start();
        await CacheBuster.updateToLastVersion(true);
    };

    return (
        <div className={classes.root}>
            <PageTitle value="Acerca de" />

            <div className={classes.pageContent}>
                <Box className={classes.logo}>
                    <img src="./cetip256.png" width="150px" />
                </Box>

                <Titulo value="Plataforma Virtual CETIP" />
                <Version versionInfo={versionState} />

                <Box p={1} />

                <Titulo value="Última actualización" />
                <Subtitulo value={getLocalDateString(new Date(versionState.date))} />

                <Box p={1} />

                <Titulo value="Sitio web" />
                <Subtitulo value={WEB_CLIENT_URL} />

                <Box p={1} />

                <Button
                    color="primary"
                    onClick={handleUpdateVersion}
                    variant={'text'}
                    startIcon={isLoading ? <CircularProgress size={12} /> : getIcon('CachedIcon')}
                >
                    {isLoading ? 'Actualizando ...' : 'Actualizar'}
                </Button>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0),
    },
    pageContent: {},
    logo: {
        display: 'inline-block',
        margin: theme.spacing(1, 0, 5, 0),
        padding: theme.spacing(2, 5),
        backgroundColor: theme.palette.primary.main,
    },
}));

export const AboutPage = withRouter(PageComponent);
