import { Button } from '@material-ui/core';
import { ConfirmDialog, ConfirmDialogRef } from 'components/core/ConfirmDialog';
import { ReactElement, useRef } from 'react';
import { timer } from 'utils';

const MyComponent = (): ReactElement => {
    const dialogRef = useRef<ConfirmDialogRef>(null);

    const handleClickAccept = async () => {
        console.log('long task ...');
        await timer(2000);
        return true;
    };

    const confirmMessage = <>¿Desea continuar?</>;

    return (
        <>
            <ConfirmDialog dialogRef={dialogRef} onAcceptClick={handleClickAccept} confirmMessage={confirmMessage} />
            <Button
                onClick={() => {
                    dialogRef.current?.open({
                        actions: [
                            { on: 'AcceptButton', click: () => console.log('accept complete') },
                            { on: 'CancelButton', click: () => console.log('cancel complete') },
                            { on: 'CloseButton', click: () => console.log('close complete') },
                        ],
                    });
                }}
            >
                Abrir
            </Button>
        </>
    );
};

export const ConfirmDialogSample = MyComponent;
