import { ROLES } from 'base/authBase';
import { GetRows, DataTableRef, UpdateParams } from 'components/core/DataTable/Types';
import { useNotify, useSession } from 'hooks';
import { ReactElement, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { RUTAS } from 'routes';
import { SolicitudServicioService } from 'modules/solicitudServicio';
import { SolicitudServicioTable } from './SolicitudServicioTable';
import { FlujoTimelineDialog } from '../SolicitudesServicios/FlujoTimelineDialog';
import { DialogBoxRef } from 'components/core/DialogBox';

type SolicitudServicioTableModel = Solicitud.SolicitudServicioTableModel;
type TimelineModel = Solicitud.TimelineModel;
type TimelineParams = Solicitud.TimelineParams;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const history = useHistory();
    const session = useSession();
    const solicitudServicioTableRef = useRef<DataTableRef>(null);

    const timelineFormRef = useRef<DialogBoxRef<TimelineParams>>(null);

    const esPlataforma = session.roles.some((rol) => rol.id === ROLES.plataforma.id);
    const esCoordinador = session.roles.some((rol) => rol.id === ROLES.coordinador.id);

    const getSolicitudServicios: GetRows<SolicitudServicioTableModel> = async (
        params: UpdateParams<SolicitudServicioTableModel>,
    ) => {
        const newParams = {
            ...params,
            filters: {
                ...params.filters,
                pendientes: true,
            },
        };
        const response = await SolicitudServicioService.getSolicitudServicios(newParams);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        response.data.rows.forEach((r, index) => (r.nro = r.nro || index + 1 + params.rowsPerPage * (params.page - 1)));
        return {
            ...newParams,
            rows: response.data.rows,
            count: response.data.count,
        };
    };

    const deleteSolicitudServicio = async (data: SolicitudServicioTableModel): Promise<boolean> => {
        const response = await SolicitudServicioService.deleteSolicitudServicio(data.id);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        return true;
    };

    const getData = async (params?: TimelineParams): Promise<TimelineModel | undefined> => {
        return params;
    };

    useEffect(() => {
        solicitudServicioTableRef.current?.refresh();
    }, []);

    return (
        <div>
            <FlujoTimelineDialog dialogRef={timelineFormRef} getData={getData} />
            <SolicitudServicioTable
                tableRef={solicitudServicioTableRef}
                getRows={getSolicitudServicios}
                modoCreacion={esPlataforma}
                modoAprobacion={esCoordinador}
                editSolicitudServicio={async (data: SolicitudServicioTableModel) => {
                    history.push(`${RUTAS.solicitudServicioFormulario}?id=${data.id}`);
                    return true;
                }}
                deleteSolicitudServicio={async (row) => {
                    const success = await deleteSolicitudServicio(row);
                    if (success) {
                        solicitudServicioTableRef.current?.refresh();
                    }
                    return success;
                }}
                addSolicitudServicio={() => {
                    history.push(RUTAS.solicitudServicioFormulario);
                }}
                verSolicitudServicio={(row) => {
                    history.push(`${RUTAS.solicitudServicioDetalle}?id=${row.id}`);
                }}
                showTimeline={(row) => {
                    timelineFormRef.current?.open({
                        params: { rows: row.historial_flujo },
                    });
                }}
            />
        </div>
    );
};

export const PendientesSection = PageComponent;
