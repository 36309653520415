import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams, SelectOption } from 'components/core/FormDialog/Types';
import { dateTest, requiredFileItemTest } from 'components/core/FormDialog/yup-tests';
import { ReactElement, RefObject } from 'react';
import * as yup from 'yup';

type FormModel = Solicitud.BoletaPagoFormModel;
type FormParams = Solicitud.BoletaFormParams;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
    params: FormParams;
};

const FormDialogComponent = ({ dialogRef, getData, submit, params }: Props): ReactElement => {
    const cuentaOptions: SelectOption[] = params.cuentas.map((cta) => ({
        value: cta,
        label: cta,
    }));

    const formLayout: FormGroup<FormModel>[] = [
        {
            title: '',
            grid: [
                [
                    { name: 'nro_boleta_pago', label: 'Nro de boleta', type: 'text' },
                    { name: 'fecha_pago', label: 'Fecha de boleta', type: 'date' },
                ],
                [
                    { name: 'monto_pago', label: 'Monto (Bs)', type: 'text', disabled: true },
                    { name: 'cuenta', label: 'Nro de cuenta', type: 'select', options: cuentaOptions },
                ],
                [{ name: 'escaneado', label: 'Imagen de la boleta de pago', type: 'dropzone' }],
            ],
        },
    ];

    const DATE_FORMAT = 'dd/MM/yyyy';
    const validationSchema = yup.object({
        nro_boleta_pago: yup.string().required(),
        fecha_pago: yup.string().required().test(dateTest(DATE_FORMAT)),
        monto_pago: yup.string().required(),
        cuenta: yup.string().required(),
        escaneado: yup.string().required().test(requiredFileItemTest()),
    });

    return (
        <FormDialog
            ref={dialogRef}
            variant="page"
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            submitButton="SaveButton"
            cancelButton="BackButton"
            validationSchema={validationSchema}
            // debug
        />
    );
};

export const BoletaPagoFormDialog = FormDialogComponent;
