import { ReactElement } from 'react';
import { Button } from '@material-ui/core';
import { getIcon } from 'helpers';

type Props = {
    onClick?: () => Promise<void>;
    disabled?: boolean;
};

export const CancelButton = ({ onClick, disabled }: Props): ReactElement => {
    return (
        <Button
            variant="contained"
            color="secondary"
            onClick={disabled ? undefined : onClick}
            disabled={disabled}
            startIcon={getIcon('CloseIcon', { fontSize: 'small' })}
        >
            Cancelar
        </Button>
    );
};
