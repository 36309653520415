// import { DialogBoxRef } from 'components/core/DialogBox';
import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams, SelectOption } from 'components/core/FormDialog/Types';
import { isNumberValidTest } from 'components/core/FormDialog/yup-tests';
import { ReactElement, RefObject } from 'react';
import * as yup from 'yup';

type FormModel = Costo.ParametroFormModel;
type FormParams = Costo.ParametroFormParams;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
    params: FormParams;
};

const FormDialogComponent = ({ dialogRef, getData, submit, params }: Props): ReactElement => {
    const servicioOptions: SelectOption[] = params.servicios.map((serv) => ({
        value: serv.id,
        label: serv.nombre,
        caption: serv.codigo_servicio,
    }));

    const formLayout: FormGroup<FormModel>[] = [
        {
            title: 'Datos del parámetro',
            grid: [
                [{ name: 'parametro', label: 'Parámetro', type: 'text' }],
                [{ name: 'precio_parametro', label: 'Precio', type: 'text', inputType: 'number' }],
                [{ name: 'fid_servicio', label: 'Servicio', type: 'autocomplete', options: servicioOptions }],
            ],
        },
    ];

    const validationSchema = yup.object({
        parametro: yup.string().required(),
        precio_parametro: yup.string().required().test(isNumberValidTest(0)),
        fid_servicio: yup.string().required(),
    });

    return (
        <FormDialog
            ref={dialogRef}
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            validationSchema={validationSchema}
            // debug
        />
    );
};

export const ParametroFormDialog = FormDialogComponent;
