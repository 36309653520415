import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams, SelectOption } from 'components/core/FormDialog/Types';
import { ReactElement, RefObject } from 'react';
import * as yup from 'yup';

type FormModel = Inventario.InsumoFormModel;
type FormParams = Inventario.InsumoFormParams;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
    params: FormParams;
};

const FormDialogComponent = ({ dialogRef, getData, submit, params }: Props): ReactElement => {
    const rubroOptions: SelectOption[] = params.rubros.map((rubro) => ({
        value: rubro.id,
        label: rubro.nombre,
    }));

    const formLayout: FormGroup<FormModel>[] = [
        {
            title: 'Datos del insumo',
            grid: [
                [{ name: 'codigo', label: 'Código', type: 'text' }],
                [{ name: 'nombre', label: 'Nombre', type: 'text' }],
                [{ name: 'unidad_medida', label: 'Unidad de medida', type: 'text' }],
                [{ name: 'fid_rubro', label: 'Rubro', type: 'select', options: rubroOptions }],
            ],
        },
    ];

    const validationSchema = yup.object({
        codigo: yup.string().required(),
        nombre: yup.string().required(),
        unidad_medida: yup.string().required(),
        fid_rubro: yup.string().required(),
    });

    return (
        <FormDialog
            ref={dialogRef}
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            validationSchema={validationSchema}
            maxWidth={'sm'}
            fullwidth={true}
            // debug
        />
    );
};

export const InsumoFormDialog = FormDialogComponent;
