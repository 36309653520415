import { NotificationAction } from './notificationActions';
import { INITIAL_STATE, NotificationState } from './notificationState';

export const notificationReducer = (state = INITIAL_STATE, action: NotificationAction): NotificationState => {
    const newState = { ...state };
    switch (action.type) {
        case 'SET_BUZON_ENTRADA':
            newState.buzonEntrada = action.payload.buzonEntrada;
            return newState;

        case 'OPEN_MESSAGE':
            newState.messages = [{ type: action.payload.type, message: action.payload.message }, ...newState.messages];
            return newState;

        case 'CLOSE_MESSAGE':
            const removeAll = typeof action.payload.index === 'undefined';
            const newMessages = removeAll ? [] : newState.messages.filter((x, index) => index !== action.payload.index);
            newState.messages = newMessages;
            return newState;

        default:
            return state;
    }
};
