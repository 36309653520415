import { ReactElement, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { CssBaseline, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { Footer, Sidebar, Topbar } from './components';
import { AuthService } from 'services/authentication';
import { useHistory } from 'react-router';
import { RUTAS } from 'routes';
import { NotificationService } from 'services/notification';
import { useDispatch } from 'react-redux';
import { RootState, useStateSelector } from 'redux/reducers';
import { MessageBox } from './components/MessageBox';
import { closeMessage, openMessage, setBuzonEntrada } from 'redux/notification/notificationActions';
import { useNotify } from 'hooks';

const drawerWidth = 240;

function isMobile(): boolean {
    return window.innerWidth < 960;
}

type Props = {
    children: ReactElement;
};

export const MainLayout = ({ children }: Props): ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const notify = useNotify();
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
    const [open, setOpen] = useState(false);
    const [menu, setMenu] = useState<MenuModel>([]);
    const dispatch = useDispatch();
    const notificationState = useStateSelector((state: RootState) => state.notificationState);
    const versionState = useStateSelector((state: RootState) => state.versionState);

    const handleDrawerOpen = () => {
        const newOpen = !open;
        setOpen(newOpen);
    };

    const handleClickClose = async () => {
        await AuthService.logout();
        dispatch(closeMessage());
        history.push(RUTAS.login);
    };

    const handleClickProfile = async () => {
        history.push(RUTAS.perfil);
    };

    const handleClickNotifications = async () => {
        history.push(RUTAS.notificaciones);
    };

    const handleClickMenuItem = async (item: MenuItemModel) => {
        if (item.path) history.push(item.path);
    };

    useEffect(() => {
        setOpen(!isMobile());
    }, [matchesMD]);

    useEffect(() => {
        const isLatestVersion = versionState.isLatestVersion;
        if (!isLatestVersion) {
            menu.forEach((group) =>
                group.items.forEach((item) => {
                    if (item.path === RUTAS.acercaDe) item.badge = '-';
                }),
            );
            setMenu(menu);
        }
    }, [menu, versionState]);

    const load = async () => {
        const newMenu = AuthService.getMenu();
        const onClick = (item: MenuItemModel) => () => {
            handleClickMenuItem(item);
            if (isMobile()) {
                const newOpen = false;
                setOpen(newOpen);
            }
        };
        newMenu.forEach((group) => group.items.forEach((item) => (item.onClick = onClick(item))));
        setMenu(newMenu);

        const response = await NotificationService.getNotificationAlert();
        if (response.error) notify.error(response.error);
        if (response.success) {
            dispatch(setBuzonEntrada(response.data.buzonEntrada));
            const count = response.data.buzonEntrada;
            if (count > 0) {
                const msg = `Tiene ${count} ${count === 1 ? 'nueva notificación' : 'nuevas notificaciones'}`;
                dispatch(openMessage('info', msg));
            }
        }
    };

    // INI - loadCallback
    const loadCallback = useCallback(load, []);
    useEffect(() => {
        loadCallback();
    }, [loadCallback]);
    // END - loadCallback

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Topbar
                handleDrawerOpen={handleDrawerOpen}
                handleClickClose={handleClickClose}
                handleClickProfile={handleClickProfile}
                handleClickNotifications={handleClickNotifications}
                notificationAlert={notificationState}
                menu={menu}
            />
            <Sidebar open={open} menu={menu} />
            <main className={clsx(classes.content, { [classes.contentShift]: open })}>
                <div className={classes.drawerHeader} />
                <div className={classes.drawerContent}>
                    <Scrollbars>
                        <div className={classes.wrapper}>
                            <MessageBox notificationAlert={notificationState} />
                            <div className={classes.childrenWrapper}>{children}</div>
                        </div>
                        <div className={classes.footerContainer}>
                            <Footer />
                        </div>
                    </Scrollbars>
                </div>
            </main>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    drawerContent: {
        padding: theme.spacing(0),
        height: 'calc(100vh - 64px)',
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100vh - 56px)',
        },
    },
    wrapper: {
        minHeight: `calc(100vh - 64px - 40px)`,
    },
    childrenWrapper: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        },
    },
    footerContainer: {
        left: 0,
        right: 0,
        bottom: 0,
    },
}));
