import { ReactElement } from 'react';

import { makeStyles, Theme } from '@material-ui/core';
import { DialogBox } from '../DialogBox';
import { ConfirmDialogProps } from './Types';

export const ConfirmDialog = (props: ConfirmDialogProps): ReactElement => {
    const { dialogRef, confirmMessage, onAcceptClick, onCancelClick } = props;
    const classes = useStyles();

    const dialogContent = (): ReactElement => {
        return <div className={classes.confirmContent}>{confirmMessage ? confirmMessage : '¿Desea continuar?'}</div>;
    };

    return (
        <DialogBox
            ref={dialogRef}
            title="Confirmar"
            titleIcon="WarningIcon"
            dialogContent={dialogContent}
            actions={[
                { on: 'CancelButton', click: onCancelClick },
                { on: 'AcceptButton', click: onAcceptClick },
            ]}
        />
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    confirmContent: {
        padding: theme.spacing(3, 2),
        minHeight: theme.spacing(15),
    },
}));
