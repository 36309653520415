import { Box, Typography, useTheme } from '@material-ui/core';
import { ROLES } from 'base/authBase';
import { ActionItem, ActionColumn } from 'components/core/Columns';
import { StatusColumn } from 'components/core/Columns/StatusColumn';
import { GetRows, DataTable, DataTableRef, TableHeader } from 'components/core/DataTable';
import { useSession } from 'hooks';
import { ReactElement, RefObject } from 'react';

type TableModel = Reclamos.ReclamoTableModel;

type Props = {
    tableRef: RefObject<DataTableRef>;
    modoCreacion?: boolean;
    modoAprobacion?: boolean;
    getRows: GetRows<TableModel>;
    addReclamo: () => void;
    editReclamo: (row: TableModel) => Promise<boolean>;
    deleteReclamo: (row: TableModel) => Promise<boolean>;
    verReclamo: (row: TableModel) => void;
    showTimeline: (row: TableModel) => void;
};

export const TableComponent = (props: Props): ReactElement => {
    const {
        tableRef,
        getRows,
        verReclamo,
        showTimeline,
        addReclamo,
        deleteReclamo,
        editReclamo,
    } = props;

    const theme = useTheme();
    const session = useSession();

    const esPlataforma = session.roles.some((rol) => rol.id === ROLES.plataforma.id);

    const headersCreacion: TableHeader<TableModel>[] = [
        { id: 'actions', label: 'Acciones', render: renderColumnActions, width: 140 },
    ];

    const tableHeaders: TableHeader<TableModel>[] = [
        { id: 'nro', label: 'Nro.', width: 80 },
        { id: 'codigo_reclamo', label: 'Código de reclamo', align: 'left', width: 150 },
        { id: 'persona_referencia', label: 'Persona de referencia', align: 'left', minWidth: 150 },
        { id: 'codigo_solicitud', label: 'Código de Solicitud', align: 'left', width: 150 },
        { id: 'detalle', label: 'Detalle', align: 'left', minWidth: 150 },
        { id: 'fecha_reclamo', label: 'Fecha de reclamo', align: 'left', minWidth: 90 },
        { id: 'estado', label: 'Estado de flujo', align: 'center', render: renderEstado, width: 230 },
        { id: 'aprobado_coordinador', label: 'Aprobado', align: 'center', render: renderAprobado },
        ...headersCreacion,
    ];

    function renderColumnActions(data: TableModel): ReactElement {
        const confirmMessage = (
            <>
                ¿Desea eliminar la solicitud de servicio?
                <br />
                <Box my={3}>
                    <Typography align="center">Solicitante:</Typography>
                    <Typography align="center">
                        <strong>{data.persona_referencia}</strong>
                    </Typography>
                </Box>
            </>
        );
        const items: ActionItem[] = [
            {
                title: 'Ver estado del flujo',
                icon: 'TimelineIcon',
                onClick: async () => {
                    showTimeline(data);
                    return true;
                },
            },
            {
                title: 'Ver detalle de la solicitud',
                icon: 'VisibilityIcon',
                onClick: async () => {
                    verReclamo(data);
                    return Promise.resolve(true);
                },
            },
        ];

        if (esPlataforma && data.aprobado_coordinador === null) {
            items.push({
                title: 'Editar reclamo',
                icon: 'EditIcon',
                color: 'primary',
                onClick: async () => editReclamo(data),
            });
            items.push({
                title: 'Eliminar solicitud',
                icon: 'DeleteIcon',
                color: 'secondary',
                onClick: async () => deleteReclamo(data),
                confirm: true,
                confirmMessage,
            });
        }

        return <ActionColumn items={items} size="small" />;
    }

    function renderAprobado(data: TableModel): ReactElement {
        let items: ActionItem[] = [];

        if (data.aprobado_coordinador === null) {
            return <></>;
        } else if (data.aprobado_coordinador) {
            items = [{ title: 'Aprobado', icon: 'CheckIcon' }];
        } else {
            items = [{ title: `Rechazado`, icon: 'CloseIcon', color: 'secondary' }];
        }

        return <ActionColumn items={items} size="small" />;
    }

    function renderEstado(data: TableModel): ReactElement {
        let background = theme.palette.primary.dark;
        if (data.estado === 'NUEVO') {
            background = theme.palette.success.dark;
        }
        if (data.estado === 'FINALIZADO') {
            background = theme.palette.background.default;
        }
        const color = theme.palette.getContrastText(background);
        return <StatusColumn label={data.estado} color={color} background={background} />;
    }

    return (
        <DataTable
            title="Reclamos de Servicio"
            ref={tableRef}
            headers={tableHeaders}
            getRows={getRows}
            addLabel="Nuevo reclamo"
            onActionAddClick={esPlataforma ? addReclamo : undefined}
            hideFilters
        />
    );
};

export const ReclamosServicioTable = TableComponent;
