import { BaseService } from 'services/http';

type ReclamoTableModel = Reclamos.ReclamoTableModel;
type ReclamoFormModel = Reclamos.ReclamoFormModel;
type DetalleReclamoModel = Reclamos.DetalleReclamoModel;
type DerivarFormModel = Reclamos.DerivarFormModel;
type DerivarFormParams = Reclamos.DerivarFormParams;

export const getReclamos = async (queryParams: QueryParams = {}): Promise<BaseResponse<{ rows: ReclamoTableModel[]; count: number }>> => {
    const queryParamsText = BaseService.buildQueryParamsText(queryParams);
    return BaseService.request('get', `/reclamo${queryParamsText}`);
};

export const getReclamoForm = async (reclamoId?: string): Promise<BaseResponse<ReclamoFormModel>> => {
    const queryParamsText = reclamoId ? `?id=${reclamoId}` : '';
    return BaseService.request('get', `/reclamo/form${queryParamsText}`);
};

export const editReclamo = async (reclamoId: string, data: ReclamoFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('put', `/reclamo/${reclamoId}`, data);
};

export const createReclamo = async (data: ReclamoFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', '/reclamo', data);
};

export const createOrUpdateReclamo = async (data: ReclamoFormModel): Promise<BaseResponse<boolean>> => {
    if (data.id) return editReclamo(data.id, data);
    return createReclamo(data);
};

export const deleteReclamo = async (reclamoId: string): Promise<BaseResponse<boolean>> => {
    return BaseService.request('delete', `/reclamo/${reclamoId}`);
};

export const getDetalleFormValue = async (reclamoId: string): Promise<BaseResponse<DetalleReclamoModel>> => {
    return BaseService.request('get', `/reclamo/detalle/${reclamoId}`);
};

export const aprobarRechazarReclamo = async (reclamoId: string, aprobado: boolean, observacion?: string): Promise<BaseResponse<boolean>> => {
    const data = {
        id_reclamo: reclamoId,
        aprobado: aprobado,
        observacion: observacion ? observacion : undefined,
    };
    return BaseService.request('post', `/reclamo/aprobar`, data);
};

export const finalizarFlujo = async (reclamoId: string): Promise<BaseResponse<boolean>> => {
    const data = {
        id_reclamo: reclamoId,
    };
    return BaseService.request('post', `/reclamo/finalizar`, data);
};

export const agregarResultado = async (reclamoId: string, mensaje: string): Promise<BaseResponse<boolean>> => {
    const data = {
        id_reclamo: reclamoId,
        mensaje: mensaje,
    };
    return BaseService.request('post', `/reclamo/resultado`, data);
};

export const derivarParams = async (): Promise<BaseResponse<DerivarFormParams>> => {
    return BaseService.request('get', `/reclamo/derivar/form-params`);
};

export const derivar = async (data: DerivarFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', '/reclamo/derivar', data);
};
