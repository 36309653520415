import { ReactElement, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    FormControlLabel,
    makeStyles,
    Switch,
    Theme,
    Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { AppConfig } from '../../Types';
import { setNotificationActive } from 'redux/config/configActions';
import { openMessage } from 'redux/notification/notificationActions';
import Scrollbars from 'react-custom-scrollbars-2';

type Props = {
    config: AppConfig;
};

const MyComponent = ({ config }: Props): ReactElement => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [active, setActive] = useState<boolean>(config.notification.active);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        if (event.target.name === 'active') {
            dispatch(setNotificationActive(newValue));
            return setActive(newValue);
        }
    };

    const handleClickPrueba = (type: MessageType) => {
        const msg = 'Mensaje de prueba';
        dispatch(openMessage(type, msg));
    };

    return (
        <Card>
            <div className={classes.sectionTitle}>Envío de notificaciones</div>
            <CardContent>
                <Box pb={2}>
                    <FormControlLabel
                        className={classes.formControl}
                        control={<Switch checked={active} onChange={handleChange} name="active" />}
                        label={active ? 'Activado' : 'Desactivado'}
                    />
                </Box>
                <Box pb={2}>
                    <Typography variant="h5" gutterBottom>
                        Ejemplos de notificación
                    </Typography>
                </Box>
                <Box width="100%" overflow="auto">
                    <Scrollbars autoHeight autoHeightMax="100%">
                        <Box display="flex" mb={2}>
                            <Box px={1}>
                                <Button
                                    className={classes.errorBtn}
                                    variant="contained"
                                    color="default"
                                    onClick={() => handleClickPrueba('error')}
                                >
                                    Error
                                </Button>
                            </Box>
                            <Box px={1}>
                                <Button
                                    className={classes.warningBtn}
                                    variant="contained"
                                    color="default"
                                    onClick={() => handleClickPrueba('warning')}
                                >
                                    Advertencia
                                </Button>
                            </Box>
                            <Box px={1}>
                                <Button
                                    className={classes.infoBtn}
                                    variant="contained"
                                    color="default"
                                    onClick={() => handleClickPrueba('info')}
                                >
                                    Informativo
                                </Button>
                            </Box>
                            <Box px={1}>
                                <Button
                                    className={classes.successBtn}
                                    variant="contained"
                                    color="default"
                                    onClick={() => handleClickPrueba('success')}
                                >
                                    Éxito
                                </Button>
                            </Box>
                        </Box>
                    </Scrollbars>
                </Box>
            </CardContent>
        </Card>
    );
};

const ERROR_COLOR = '#f44336';
const WARNING_COLOR = '#ff9800';
const INFO_COLOR = '#2196f3';
const SUCCESS_COLOR = '#4caf50';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0),
    },
    formControl: {
        width: theme.spacing(30),
        padding: theme.spacing(0, 0),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(0, 0),
        },
    },
    sectionTitle: {
        fontWeight: 'bold',
        margin: theme.spacing(0, 0, 1, 0),
        padding: theme.spacing(0.5, 1),
        backgroundColor: theme.palette.grey[700],
        color: theme.palette.getContrastText(theme.palette.grey[700]),
    },
    errorBtn: {
        backgroundColor: ERROR_COLOR,
        borderColor: ERROR_COLOR,
        color: 'white',
        '&:hover': {
            backgroundColor: ERROR_COLOR,
            borderColor: ERROR_COLOR,
            boxShadow: 'none',
        },
    },
    warningBtn: {
        backgroundColor: WARNING_COLOR,
        borderColor: WARNING_COLOR,
        color: 'white',
        '&:hover': {
            backgroundColor: WARNING_COLOR,
            borderColor: WARNING_COLOR,
            boxShadow: 'none',
        },
    },
    infoBtn: {
        backgroundColor: INFO_COLOR,
        borderColor: INFO_COLOR,
        color: 'white',
        '&:hover': {
            backgroundColor: INFO_COLOR,
            borderColor: INFO_COLOR,
            boxShadow: 'none',
        },
    },
    successBtn: {
        backgroundColor: SUCCESS_COLOR,
        borderColor: SUCCESS_COLOR,
        color: 'white',
        '&:hover': {
            backgroundColor: SUCCESS_COLOR,
            borderColor: SUCCESS_COLOR,
            boxShadow: 'none',
        },
    },
}));

export const NotificationSection = MyComponent;
