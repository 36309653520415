import { ROLES } from 'base/authBase';
import { DataTableRef, GetRows, UpdateParams } from 'components/core/DataTable';
import { useNotify, useSession } from 'hooks';
import { ReactElement, useEffect, useRef } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { RUTAS } from 'routes';
import { DigitalizacionService } from '.';
import { DigitalTable } from './DigitalTable';

type DigitalTableModel = Digital.DigitalTableModel;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const history = useHistory();
    const session = useSession();
    const solicitudServicioTableRef = useRef<DataTableRef>(null);

    const esPlataforma = session.roles.some(rol => rol.id === ROLES.plataforma.id);
    const esCoordinador = session.roles.some(rol => rol.id === ROLES.coordinador.id);

    const getSolicitudServicios: GetRows<DigitalTableModel> = async (params: UpdateParams<DigitalTableModel>) => {
        const response = await DigitalizacionService.getDigitalTable(params);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        response.data.rows.forEach((r, index) => r.nro = r.nro || (index + 1) + (params.rowsPerPage * (params.page - 1)));
        return {
            ...params,
            rows: response.data.rows,
            count: response.data.count,
        };
    };

    const deleteSolicitudServicio = async (data: DigitalTableModel): Promise<boolean> => {
        const response = await DigitalizacionService.deleteDigital(data.id);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        return true;
    };

    useEffect(() => {
        solicitudServicioTableRef.current?.refresh();
    }, []);

    return (
        <div>
            <DigitalTable
                tableRef={solicitudServicioTableRef}
                getRows={getSolicitudServicios}
                modoCreacion={esPlataforma}
                modoAprobacion={esCoordinador}
                editSolicitudServicio={async (data: DigitalTableModel) => {
                    history.push(`${RUTAS.digitalizacionFormulario}?id=${data.id}`);
                    return true;
                }}
                deleteSolicitudServicio={async (row) => {
                    const success = await deleteSolicitudServicio(row);
                    if (success) {
                        solicitudServicioTableRef.current?.refresh();
                    }
                    return success;
                }}
                addSolicitudServicio={() => {
                    history.push(RUTAS.digitalizacionFormulario);
                }}
            />
        </div>
    );
};

export const DigitalizacionModule = withRouter(PageComponent);
