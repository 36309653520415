import { DataTableRef, GetRows, UpdateParams } from 'components/core/DataTable';
import { DialogBoxRef } from 'components/core/DialogBox';
import { InitialParams } from 'components/core/FormDialog/Types';
import { useNotify } from 'hooks';
import { ReactElement, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { UsuariosService } from '.';
import { UsuarioFormDialog } from './components/forms/UsuarioFormDialog';
import { UsuarioTable } from './components/tables/UsuarioTable';

type UsuarioTableModel = Usuario.UsuarioTableModel;
type UsuarioFormModel = Usuario.UsuarioFormModel;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const usuarioFormRef = useRef<DialogBoxRef<InitialParams>>(null);
    const usuarioTableRef = useRef<DataTableRef>(null);

    const getUsers: GetRows<UsuarioTableModel> = async (params: UpdateParams<UsuarioTableModel>) => {
        const response = await UsuariosService.getUsers(params);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        response.data.rows.forEach((r, index) => r.nro = r.nro || (index + 1) + (params.rowsPerPage * (params.page - 1)));
        return {
            ...params,
            rows: response.data.rows,
            count: response.data.count,
        };
    };

    const deleteUser = async (data: UsuarioTableModel): Promise<boolean> => {
        const response = await UsuariosService.deleteUser(data.id);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        return true;
    };

    const activeUser = async (userId: string, active: boolean): Promise<boolean> => {
        const response = await UsuariosService.activeUser(userId, active);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        return true;
    };

    const resetPass = async (data: UsuarioTableModel): Promise<boolean> => {
        const response = await UsuariosService.resetPass(data.id);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Contraseña restaurada con éxito');
        return true;
    };

    const getData = async (params?: InitialParams): Promise<UsuarioFormModel | undefined> => {
        const response = await UsuariosService.getUserForm(params?.id);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };

    const submit = async (data: UsuarioFormModel): Promise<boolean> => {
        const response = await UsuariosService.createOrUpdateUser(data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Tarea completada exitosamente');
        return true;
    };

    useEffect(() => {
        usuarioTableRef.current?.refresh();
    }, []);

    return (
        <div>
            <UsuarioFormDialog dialogRef={usuarioFormRef} getData={getData} submit={submit} />
            <UsuarioTable
                tableRef={usuarioTableRef}
                getRows={getUsers}
                editUser={async (row) => {
                    usuarioFormRef.current?.open({
                        params: { id: row.id, title: 'Editar Usuario', titleIcon: 'EditIcon' },
                        actions: [{ on: 'AcceptButton', click: () => usuarioTableRef.current?.refresh() }],
                    });
                    return true;
                }}
                deleteUser={async (row) => {
                    const success = await deleteUser(row);
                    if (success) {
                        usuarioTableRef.current?.refresh();
                    }
                    return success;
                }}
                resetPass={async (row) => {
                    const success = await resetPass(row);
                    return success;
                }}
                activeUser={async (userId, active) => {
                    const success = await activeUser(userId, active);
                    if (success) {
                        usuarioTableRef.current?.refresh();
                    }
                    return success;
                }}
                addUser={() => {
                    usuarioFormRef.current?.open({
                        params: { title: 'Agregar Usuario', titleIcon: 'AddIcon' },
                        actions: [{ on: 'AcceptButton', click: () => usuarioTableRef.current?.refresh() }],
                    });
                }}
            />
        </div>
    );
};

export const UsuariosModule = withRouter(PageComponent);
