/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import { useField, FormikProps, FormikValues } from 'formik';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

// material-ui
import {
    makeStyles,
    Theme,
    FormHelperText,
    FormControl,
    FormLabel,
    Chip,
    Box,
    CircularProgress,
    LinearProgress,
    Typography,
} from '@material-ui/core';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';
import CloseIcon from '@material-ui/icons/Close';
import { useIsMounted } from 'hooks/useIsMounted';
import { BaseService } from 'services/http';
import { useNotify } from 'hooks';
import { STORAGE_URL } from 'config/app.config';

export type FileItem = {
    id: string;
    fileName: string;
    filePath: string;
    fileType: string; // All types: https://www.freeformatter.com/mime-types-list.html
    fileObject?: FileObject;
};

export type OnChangeFunction = (
    value: string | number | boolean | null | (string | number | boolean | null)[],
    formik?: FormikProps<any>,
    fieldValue?: unknown,
) => void | Promise<void>;

const DEFAULT_FILES_LIMIT = 15;
const DEFAULT_ACCEPTED_FILES: string[] = []; // More info: https://react-dropzone.js.org/#section-accepting-specific-file-types Ej: ['image/*', 'video/*', 'application/*']
const DEFAULT_MAX_FILE_SIZE = 150 * 1024 * 1024; // bytes
const DEFAULT_DROPZONE_TEXT = `Arrastre y suelte un archivo aquí o haga click.`;
// const DEFAULT_VARIANT = 'filled';

type ProgressState = { id: string; value: number } | null;

type Props = {
    name: string;
    label: string;
    value?: string;
    disabled?: boolean;
    fieldRequired?: string;
    error?: boolean;
    helperText?: string;
    formik?: FormikProps<FormikValues>;
    filesLimit?: number;
    filesExt?: string[];
    acceptedFiles?: string[];
    maxFileSize?: number;
    dropzoneText?: string;
    variant?: 'filled' | 'standard' | 'outlined';
    onChange?: OnChangeFunction;
};

const MyDropzoneComponent = (props: Props): ReactElement => {
    const { label, name, error, helperText, formik } = props;

    const classes = useStyles();
    const notify = useNotify();
    const isMounted = useIsMounted();

    const [field, meta] = useField<string>(name);
    const errorText = meta.error && meta.touched ? meta.error : '';

    // controlled or uncontrolled
    const _val = typeof props.value !== 'undefined' ? props.value : field.value;
    let VALUE: FileItem[] = [];
    try {
        VALUE = _val ? JSON.parse(_val || '[]') : [];
    } catch (err) {
        console.log(err);
    }

    const FILES_EXT = props.filesExt;
    const FILES_LIMIT = props.filesLimit || DEFAULT_FILES_LIMIT;
    const MAX_FILE_SIZE = props.maxFileSize || DEFAULT_MAX_FILE_SIZE;
    const ACCEPTED_FILES = props.acceptedFiles || DEFAULT_ACCEPTED_FILES;
    const DROPZONE_TEXT = props.dropzoneText || DEFAULT_DROPZONE_TEXT;

    // const VARIANT = props.variant  || DEFAULT_VARIANT;
    const FIELD_REQUIRED = props.fieldRequired;
    const [DISABLED, setDisabled] = useState(false);
    const requiredValue = FIELD_REQUIRED ? formik?.values[FIELD_REQUIRED] : undefined;
    useEffect(() => {
        const disableByProp = typeof props.disabled !== 'undefined' ? props.disabled : false;
        let newFieldDisabled = disableByProp;
        if (!disableByProp && FIELD_REQUIRED) {
            newFieldDisabled =
                requiredValue === undefined ||
                requiredValue === '' ||
                requiredValue === null ||
                requiredValue === '[]' || // Para los archivos adjuntos (MyDropzone)
                requiredValue === '{}' || // Para los grupos de checkbox (MyCheckboxGroup)
                (Array.isArray(requiredValue) && requiredValue.length === 0);
        }
        setDisabled(newFieldDisabled);
    }, [props.disabled, FIELD_REQUIRED, requiredValue]);

    const progressList = [
        useState<ProgressState>(null),
        useState<ProgressState>(null),
        useState<ProgressState>(null),
        useState<ProgressState>(null),
        useState<ProgressState>(null),
        useState<ProgressState>(null),
        useState<ProgressState>(null),
        useState<ProgressState>(null),
        useState<ProgressState>(null),
        useState<ProgressState>(null),
    ];

    async function uploadFile(
        fileItem: FileItem,
        setProgress: (state: ProgressState) => void,
    ): Promise<BaseResponse<UploadResponseModel>> {
        if (!fileItem.fileObject) return BaseService.sendError();
        const FILE = fileItem.fileObject.file;
        const FILENAME = fileItem.fileName;
        const FILE_ID = FILENAME.substr(0, 36);
        const REAL_FILENAME = FILENAME.replace(`${FILE_ID}-`, '');
        const onProgress = (progress: number, uploaded: boolean) => {
            setProgress(uploaded ? null : { id: FILE_ID, value: progress });
        };
        const tieneExtension = FILENAME.indexOf('.') >= 0;
        if (!tieneExtension) {
            setProgress(null);
            return BaseService.sendError(
                `El nombre del archivo es incorrecto (No tiene extensión)\n"${REAL_FILENAME}"`,
            );
        }
        const uploadResponse = await BaseService.uploadFile(FILE, FILENAME, onProgress);
        setProgress(null);
        if (!uploadResponse.success) {
            return BaseService.sendError(uploadResponse.error);
        }
        return BaseService.sendSuccess(uploadResponse.data);
    }

    async function removeFile(fileItem: FileItem) {
        return BaseService.removeFile(fileItem.fileName);
    }

    const uploadPromiseField = uploadFile;
    const removePromiseField = removeFile;

    const getExt = (obj: FileObject): string => {
        const dotIndex = obj.file.name.indexOf('.');
        if (dotIndex > -1) {
            return obj.file.name.split('.').pop() || '';
        }
        return '';
    };

    const isUploading = (fileItem?: FileItem): boolean => {
        if (!fileItem) return progressList.some((state) => state[0] !== null);
        return progressList.some((state) => state[0] !== null && state[0].id === fileItem.id);
    };

    const handleDropzoneOnAdd = async (newFileObjs: FileObject[]) => {
        if (isUploading()) {
            notify.info('Espere a que se guarden todos los archivos.');
            return;
        }
        if (newFileObjs.length > FILES_LIMIT) {
            notify.error('Solo puede cargar ' + FILES_LIMIT + ' archivo(s).');
            return;
        }
        if (VALUE.length + newFileObjs.length > FILES_LIMIT) {
            notify.error('Solo puede cargar ' + FILES_LIMIT + ' archivo(s).');
            return;
        }
        if (FILES_EXT) {
            const EXT_VALIDAS = FILES_EXT.map((ext) => `*.${ext}`).join(', ');
            const archivosNoValidos = newFileObjs.filter((obj) => {
                const ext = getExt(obj);
                const esValido = FILES_EXT.includes(ext);
                if (!esValido) {
                    notify.error(
                        `Formato de archivo incorrecto (${obj.file.name}). Extensiones válidas: ${EXT_VALIDAS}`,
                    );
                }
                return !esValido;
            });
            if (archivosNoValidos.length > 0) return;
        }
        const promises: Promise<BaseResponse<UploadResponseModel>>[] = [];
        const emptyArray: FileItem[] = [];
        const newFileItemValue = emptyArray.concat(VALUE);

        newFileObjs.forEach((fileObject, index) => {
            // Verifica que no se carguen más de 10 arhivos a la vez.
            if (index >= 10) {
                notify.error('Solo puede cargar 10 archivos al mismo tiempo.');
                return;
            }
            const stateFree = progressList[index];

            const FILE_ID = uuid();
            const newFileItem: FileItem = {
                id: FILE_ID,
                fileName: `${FILE_ID}-${fileObject.file.name}`,
                filePath: '', // ya no se utiliza
                fileType: '*',
                fileObject: fileObject,
            };
            const cb = (state: ProgressState) => {
                if (isMounted()) stateFree[1](state);
            };
            cb({ id: FILE_ID, value: 0 });
            promises.push(uploadPromiseField(newFileItem, cb));
            newFileItemValue.push(newFileItem);
        });

        const newValue = JSON.stringify(
            newFileItemValue.map(({ id, fileName, filePath, fileType }) => ({ id, fileName, filePath, fileType })),
        );

        if (props.onChange) props.onChange(newValue, formik);
        else formik?.setFieldValue(name, newValue);

        await Promise.all(promises).then((results) => {
            for (const result of results) {
                if (!result.success) {
                    notify.error(result.data.msg);
                    formik?.setFieldValue(
                        name,
                        JSON.stringify(
                            VALUE.map(({ id, fileName, filePath, fileType }) => ({ id, fileName, filePath, fileType })),
                        ),
                    );
                    break;
                }
            }
        });
    };

    const handleDropzoneOnDelete = async (deleteFileItem: FileItem) => {
        if (isUploading(deleteFileItem)) {
            notify.info('Por favor espere a que se guarde el archivo.');
            return;
        }
        const newFileItemValue = VALUE.filter((item: FileItem) => item.id !== deleteFileItem.id);
        const newValue = JSON.stringify(
            newFileItemValue.map(({ id, fileName, filePath, fileType }) => ({ id, fileName, filePath, fileType })),
        );

        if (props.onChange) props.onChange(newValue, formik);
        else formik?.setFieldValue(name, newValue);

        await removePromiseField(deleteFileItem).then((result) => {
            // if (!result.success) notify.error(result.msg);
            console.log(result);
        });
    };

    const handleClick = (fileItem: FileItem) => {
        if (isUploading(fileItem)) {
            notify.info('Por favor espere a que se guarde el archivo.');
            return;
        }
        const FILE_URL = `${STORAGE_URL}/${fileItem.fileName}`;
        window.open(FILE_URL);
    };

    const handleOnAlert = (message: string, variant: string) => {
        if (variant === 'error') {
            if (message.includes('File is too big')) {
                const maxSize = Number(MAX_FILE_SIZE / 1024 / 1024);
                return notify.error(`El archivo es demasiado grande. Tamaño máximo: ${maxSize}MB`);
            }
            console.log(`Error al adjuntar el archivo. ${message}`);
        }
    };

    const truncateText = (text: string, max = 20) => {
        if (text.length <= max) return text;
        const middle = parseInt(`${max / 2}`);
        const a = text.substr(0, middle);
        const b = text.substr(text.length - middle);
        const truncated = `${a} ... ${b}`;
        return truncated;
    };

    // const refHandler = () => ({
    //     isPrepared: () => !isUploading(),
    // });
    // useImperativeHandle(ref, refHandler, [isUploading]);

    return (
        <FormControl className={classes.formControl} error={error || !!errorText}>
            <div className={classes.formContainer}>
                <FormLabel component="legend" className={classes.formLabel}>
                    {label}
                </FormLabel>
                {isUploading() && (
                    <Box className={classes.dropzoneUploadingContainer}>
                        <Typography align="center" variant="h6">
                            Guardando archivos...
                        </Typography>
                        <Typography align="center" variant="body1">
                            Por favor espere a que se guarden todos los archivos antes de continuar.
                        </Typography>
                    </Box>
                )}
                <DropzoneAreaBase
                    fileObjects={[]}
                    onAdd={handleDropzoneOnAdd}
                    dropzoneClass={clsx(classes.dropzoneContainer, {
                        [classes.dropzoneContainerHidden]: isUploading(),
                    })}
                    dropzoneParagraphClass={clsx(classes.dropzoneParagraph, { [classes.error]: !!errorText })}
                    dropzoneText={DROPZONE_TEXT}
                    showPreviewsInDropzone={false}
                    useChipsForPreview={false}
                    showAlerts={false}
                    filesLimit={FILES_LIMIT}
                    acceptedFiles={ACCEPTED_FILES}
                    maxFileSize={MAX_FILE_SIZE}
                    onAlert={handleOnAlert}
                    inputProps={{ disabled: DISABLED }}
                />
                <Box className={classes.chipContainer}>
                    {VALUE.map((item) => {
                        const state = progressList.find((state) => state[0] !== null && state[0].id === item.id);
                        const value = state && state[0] !== null ? state[0].value : null;
                        return (
                            <Box key={item.id} className={classes.itemContainer}>
                                <Chip
                                    className={clsx(classes.chip, { [classes.chipLoaded]: !value })}
                                    label={truncateText(item.fileName.replace(`${item.id}-`, ''))}
                                    variant="outlined"
                                    deleteIcon={value !== null ? <CircularProgress size="small" /> : <CloseIcon />}
                                    onDelete={() => handleDropzoneOnDelete(item)}
                                    onClick={() => handleClick(item)}
                                    disabled={DISABLED}
                                />
                                {value !== null ? (
                                    <LinearProgress className={classes.loaderBar} variant="determinate" value={value} />
                                ) : (
                                    <></>
                                )}
                            </Box>
                        );
                    })}
                </Box>
            </div>
            <FormHelperText className={classes.helperText}>{helperText || errorText}</FormHelperText>
        </FormControl>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        width: '100%',
        paddingTop: theme.spacing(0),
    },
    formContainer: {
        borderTopLeftRadius: theme.spacing(0.5),
        borderTopRightRadius: theme.spacing(0.5),
        background: theme.palette.background.default,
    },
    dropzoneContainer: {
        minHeight: theme.spacing(0),
        padding: theme.spacing(0, 1, 1, 1),
    },
    dropzoneContainerHidden: {
        display: 'none',
    },
    dropzoneUploadingContainer: {
        border: 'dashed',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        background: '#fff',
        color: 'rgba(34,47,62,.7)',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80px',
        padding: theme.spacing(1),

        backgroundColor: '#a3cc72',
        backgroundImage: `linear-gradient(
          -45deg,
          rgba(255, 255, 255, .2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, .2) 50%,
          rgba(255, 255, 255, .2) 75%,
          transparent 75%,
          transparent
        )`,
        backgroundSize: '50px 50px',
        animation: '$move 2s linear infinite',
    },
    dropzoneParagraph: {
        color: theme.palette.text.hint,
        fontSize: '18px',
        padding: theme.spacing(0),
        margin: theme.spacing(1, 0, 0, 0),
    },
    error: {
        color: 'red',
    },
    formLabel: {
        padding: theme.spacing(1, 1, 1, 1.5),
    },
    helperText: {
        paddingLeft: theme.spacing(1.5),
    },
    '@keyframes move': {
        '0%': {
            backgroundPosition: '0 0',
        },
        '100%': {
            backgroundPosition: '50px 50px',
        },
    },
    itemContainer: {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
    },
    chipContainer: {
        minHeight: theme.spacing(1),
    },
    chip: {
        zIndex: 2,
    },
    chipLoaded: {
        backgroundColor: theme.palette.background.default,
    },
    loaderBar: {
        position: 'absolute',
        width: 'calc(100% - 10px)',
        height: 'calc(100% - 10px)',
        bottom: '4px',
        left: '5px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#a3cc72',
            backgroundImage: `linear-gradient(
              -45deg,
              rgba(255, 255, 255, .2) 25%,
              transparent 25%,
              transparent 50%,
              rgba(255, 255, 255, .2) 50%,
              rgba(255, 255, 255, .2) 75%,
              transparent 75%,
              transparent
            )`,
            backgroundSize: '50px 50px',
            animation: '$move 2s linear infinite',
        },
    },
}));

export const MyDropzone = MyDropzoneComponent;
