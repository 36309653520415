import { DialogBoxRef } from 'components/core/DialogBox';
import { InitialParams } from 'components/core/FormDialog/Types';
import { GetRows, DataTableRef, UpdateParams } from 'components/core/DataTable/Types';
import { useNotify } from 'hooks';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { SolicitudServicioService } from 'modules/solicitudServicio';
import { UnidadProductivaFormDialog } from './UnidadProductivaFormDialog';
import { UnidadProductivaTable } from './UnidadProductivaTable';

type UnidadProductivaFormParams = Solicitud.UnidadProductivaFormParams;
type UnidadProductivaTableModel = Solicitud.UnidadProductivaTableModel;
type UnidadProductivaFormModel = Solicitud.UnidadProductivaFormModel;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const UnidadProductivaFormRef = useRef<DialogBoxRef<InitialParams>>(null);
    const UnidadProductivaTableRef = useRef<DataTableRef>(null);
    const initialParams = { rubros: [], subtipos_rubro: [] };
    const [formParams, setFormParams] = useState<UnidadProductivaFormParams>(initialParams);

    const getUnidadProductivas: GetRows<UnidadProductivaTableModel> = async (params: UpdateParams<UnidadProductivaTableModel>) => {
        const response = await SolicitudServicioService.getUnidadProductivas(params);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        response.data.rows.forEach((r, index) => r.nro = r.nro || (index + 1) + (params.rowsPerPage * (params.page - 1)));
        return {
            ...params,
            rows: response.data.rows,
            count: response.data.count,
        };
    };

    const deleteUnidadProductiva = async (data: UnidadProductivaTableModel): Promise<boolean> => {
        const response = await SolicitudServicioService.deleteUnidadProductiva(data.id);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        return true;
    };

    const getData = async (params?: InitialParams): Promise<UnidadProductivaFormModel | undefined> => {
        const response = await SolicitudServicioService.getUnidadProductivaForm(params?.id);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };

    const submit = async (data: UnidadProductivaFormModel): Promise<boolean> => {
        const response = await SolicitudServicioService.createOrUpdateUnidadProductiva(data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Tarea completada exitosamente');
        return true;
    };

    const getFormParams = async (): Promise<UnidadProductivaFormParams | undefined> => {
        const response = await SolicitudServicioService.getUnidadProductivaFormParams();
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };
    const getFormParamsCallback = useCallback(getFormParams, []);

    useEffect(() => {
        UnidadProductivaTableRef.current?.refresh();
        getFormParamsCallback().then((result) => {
            if (result) setFormParams(result);
        });
    }, []);

    return (
        <div>
            <UnidadProductivaFormDialog dialogRef={UnidadProductivaFormRef} getData={getData} submit={submit} params={formParams} />
            <UnidadProductivaTable
                tableRef={UnidadProductivaTableRef}
                getRows={getUnidadProductivas}
                editUnidadProductiva={async (data: UnidadProductivaTableModel) => {
                    UnidadProductivaFormRef.current?.open({
                        params: { id: data.id, title: 'Editar unidad productiva', titleIcon: 'EditIcon' },
                        actions: [{ on: 'AcceptButton', click: () => UnidadProductivaTableRef.current?.refresh() }],
                    });
                    return true;
                }}
                deleteUnidadProductiva={async (row) => {
                    const success = await deleteUnidadProductiva(row);
                    if (success) {
                        UnidadProductivaTableRef.current?.refresh();
                    }
                    return success;
                }}
                onAddUnidadProductiva={() => {
                    UnidadProductivaFormRef.current?.open({
                        params: { title: 'Agregar unidad productiva', titleIcon: 'AddIcon' },
                        actions: [{ on: 'AcceptButton', click: () => UnidadProductivaTableRef.current?.refresh() }],
                    });
                }}
            />
        </div>
    );
};

export const UnidadesProductivasSection = PageComponent;
