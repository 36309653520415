import { VersionAction } from './versionActions';
import { INITIAL_STATE, VersionState } from './versionState';

export const versionReducer = (state = INITIAL_STATE, action: VersionAction): VersionState => {
    switch (action.type) {
        case 'SET_VESION_INFO':
            const newState1 = {
                ...state,
                ...action.payload.versionInfo,
            };
            return newState1;

        default:
            return state;
    }
};
