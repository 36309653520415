import { Typography, useTheme } from '@material-ui/core';
import { DataTable, DataTableRef, GetRows, TableHeader } from 'components/core/DataTable';
import { ReactElement, RefObject } from 'react';

type TableModel = {
    id: string;
    remitente: string;
    asunto: string;
    contenido: string;
    fecha: string;
    visto: boolean;
    actions?: unknown;
};

type Props = {
    tableRef: RefObject<DataTableRef>;
    getRows: GetRows<TableModel>;
    showNotification: (row: TableModel) => void;
};

const TableComponent = (props: Props): ReactElement => {
    const { tableRef, getRows, showNotification } = props;

    const theme = useTheme();
    const isDarkMode = theme.palette.type === 'dark';
    const NO_VISTO_BACKGROUND = isDarkMode ? '#62a065' : '#d6f3c9';
    const NO_VISTO_COLOR = isDarkMode ? 'white' : 'inherit';

    const tableHeaders: TableHeader<TableModel>[] = [
        {
            id: 'remitente',
            label: 'Remitente',
            align: 'left',
            onCellClick: handleClickCell,
            render: renderRemitenteColumn,
        },
        { id: 'asunto', label: 'Asunto', align: 'left', onCellClick: handleClickCell, render: renderAsuntoColumn },
        { id: 'fecha', label: 'Fecha', align: 'center', onCellClick: handleClickCell, render: renderFechaColumn },
    ];

    function renderRemitenteColumn(row: TableModel) {
        const color = !row.visto ? NO_VISTO_COLOR : undefined;
        return <Typography style={{ color }}>{row.remitente}</Typography>;
    }

    function renderAsuntoColumn(row: TableModel) {
        const color = !row.visto ? NO_VISTO_COLOR : undefined;
        return <Typography style={{ color }}>{row.asunto}</Typography>;
    }

    function renderFechaColumn(row: TableModel) {
        const color = !row.visto ? NO_VISTO_COLOR : undefined;
        return <Typography style={{ color }}>{row.fecha}</Typography>;
    }

    function handleClickCell(row: TableModel) {
        showNotification(row);
    }

    return (
        <DataTable
            title="Bandeja de entrada"
            ref={tableRef}
            headers={tableHeaders}
            getRows={getRows}
            hideFilters
            rowStyles={(row: TableModel) => ({
                background: !row.visto ? NO_VISTO_BACKGROUND : undefined,
            })}
        />
    );
};

export const NotificationTable = TableComponent;
export type NotificationTableModel = TableModel;
