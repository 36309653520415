import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams, SelectOption, FormRow } from 'components/core/FormDialog/Types';
import { FormikProps } from 'formik';
import { ReactElement, RefObject } from 'react';
import { getFullName } from 'utils';
import * as yup from 'yup';

type FormModel = Digital.DigitalFormModel;
type FormParams = Digital.DigitalFormParams;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
    params: FormParams;
};

const FormDialogComponent = ({ dialogRef, getData, submit, params }: Props): ReactElement => {
    const solicitanteOptions: SelectOption[] = params.solicitantes.map((sol) => ({
        value: sol.id,
        label: getFullName(sol.nombre, sol.apellido_paterno, sol.apellido_materno),
        caption: sol.cedula_identidad,
    }));

    const unidadProductivaOptions: SelectOption[] = params.unidades_productivas.map((up) => ({
        value: up.id,
        label: up.razon_social,
        caption: up.numero_certificado,
    }));

    const regionalOptions: SelectOption[] = params.regionales.map((reg) => ({
        value: reg.id,
        label: reg.nombre,
    }));

    const formLayout = (formValue: FormModel, formik: FormikProps<FormModel>) => {
        if (Object.keys(formValue).length === 0) return [];

        const rubroOptions: SelectOption[] = params.rubros
            .filter((ru) => ru.id_regional === formValue.id_regional)
            .map((rubro) => ({
                value: rubro.id,
                label: rubro.nombre,
            }));

        const servicioOptions: SelectOption[] = params.servicios
            .filter((serv) => serv.id_regional === formValue.id_regional && serv.id_rubro === formValue.id_rubro)
            .map((serv) => {
                return {
                    value: serv.id,
                    label: serv.nombre,
                };
            });

        const formularioSolicitante: FormRow<FormModel>[] = [
            [
                {
                    name: 'id_solicitante',
                    label: 'Buscar Solicitante ...',
                    type: 'autocomplete',
                    options: solicitanteOptions,
                    onChange: (value) => {
                        formik.setFieldValue('id_solicitante', value);
                        if (value) {
                            const solicitante = params.solicitantes.find((sol) => sol.id === value);
                            if (!solicitante) return;
                            formik.setFieldValue('solicitante_nombre', solicitante.nombre);
                            formik.setFieldValue('solicitante_apellido_paterno', solicitante.apellido_paterno);
                            formik.setFieldValue('solicitante_apellido_materno', solicitante.apellido_materno);
                            formik.setFieldValue('solicitante_cedula_identidad', solicitante.cedula_identidad);
                            if (solicitante.id_unidad_productiva) {
                                const up = params.unidades_productivas.find((up) => up.id === solicitante.id_unidad_productiva);
                                if (!up) return;
                                formik.setFieldValue('id_unidad_productiva', up.id);
                                formik.setFieldValue('unidad_productiva_razon_social', up.razon_social);
                                formik.setFieldValue('unidad_productiva_direccion', up.direccion);
                                formik.setFieldValue('unidad_productiva_numero_certificado', up.numero_certificado);
                            }
                        }
                    },
                },
                { type: 'empty' },
            ],
            [
                { name: 'solicitante_nombre', label: 'Nombre', type: 'text', delay: 500 },
                { name: 'solicitante_apellido_paterno', label: 'Apellido Paterno', type: 'text', delay: 500 },
                { name: 'solicitante_apellido_materno', label: 'Apellido Materno', type: 'text', delay: 500 },
            ],
            [{ name: 'solicitante_cedula_identidad', label: 'Cédula de identidad', type: 'text', delay: 500 }],
        ];

        const formularioUnidadProductiva: FormRow<FormModel>[] = [
            [
                {
                    name: 'id_unidad_productiva',
                    label: 'Buscar Unidad Productiva ...',
                    type: 'autocomplete',
                    options: unidadProductivaOptions,
                    onChange: (value) => {
                        formik.setFieldValue('id_unidad_productiva', value);
                        if (value) {
                            const up = params.unidades_productivas.find((up) => up.id === value);
                            if (!up) return;
                            formik.setFieldValue('unidad_productiva_razon_social', up.razon_social);
                            formik.setFieldValue('unidad_productiva_direccion', up.direccion);
                            formik.setFieldValue('unidad_productiva_numero_certificado', up.numero_certificado);
                        }
                    },
                },
                { type: 'empty' },
            ],
            [
                { name: 'unidad_productiva_razon_social', label: 'Razón Social', type: 'text', delay: 500 },
                { name: 'unidad_productiva_direccion', label: 'Dirección', type: 'text', delay: 500 },
            ],
            [
                {
                    name: 'unidad_productiva_numero_certificado',
                    label: 'Número de certificado',
                    type: 'text',
                    delay: 500,
                },
            ],
        ];

        const formularioServicioRequerido: FormRow<FormModel>[] = [
            [
                {
                    name: 'id_regional',
                    label: 'Regional',
                    type: 'autocomplete',
                    options: regionalOptions,
                    onChange: (value) => {
                        formik.setFieldValue('id_regional', value);
                        formik.setFieldValue('id_rubro', '');
                        formik.setFieldValue('id_servicio', '');
                        formik.setFieldValue('servicio_nombre', '');
                    },
                },
                {
                    name: 'id_rubro',
                    label: 'Rubro',
                    type: 'autocomplete',
                    fieldRequired: 'id_regional',
                    options: rubroOptions,
                    onChange: (value) => {
                        formik.setFieldValue('id_rubro', value);
                        formik.setFieldValue('id_servicio', '');
                        formik.setFieldValue('servicio_nombre', '');
                    },
                },
            ],
            [
                {
                    name: 'id_servicio',
                    label: 'Servicio solicitado',
                    type: 'autocomplete',
                    fieldRequired: 'id_rubro',
                    options: servicioOptions,
                    onChange: (value) => {
                        formik.setFieldValue('id_servicio', value);
                        if (value) {
                            const servicio = params.servicios.find((serv) => serv.id === value);
                            if (!servicio) return;
                            formik.setFieldValue('servicio_nombre', servicio.nombre);
                        }
                    },
                },
            ],
            [
                {
                    name: 'servicio_nombre',
                    label: 'Servicio solicitado',
                    type: 'text',
                },
            ],
            [
                {
                    name: 'fecha_solicitud',
                    label: 'Fecha de solicitud',
                    type: 'date',
                },
            ],
        ];

        const formularioAdjuntos: FormRow<FormModel>[] = [
            [
                {
                    name: 'adjuntos',
                    label: 'Archivos adjuntos',
                    type: 'dropzone',
                },
            ],
        ];

        const formGroup: FormGroup<FormModel>[] = [
            {
                title: '1. DATOS GENERALES DEL SOLICITANTE',
                grid: [...formularioSolicitante],
            },
            {
                title: '2. DATOS DE LA UNIDAD PRODUCTIVA',
                grid: [...formularioUnidadProductiva],
            },
            {
                title: '3. SERVICIO REQUERIDO',
                grid: [...formularioServicioRequerido],
            },
            {
                title: '4. ADJUNTOS',
                grid: [...formularioAdjuntos],
            },
        ];

        return formGroup;
    };

    // const DATE_FORMAT = 'dd/MM/yyyy';
    const validationSchema = yup.object({
        // fecha_solicitud: yup.string().test(dateTest(DATE_FORMAT)),
        solicitante_nombre: yup.string().required(),
        id_regional: yup.string().required(),
        id_rubro: yup.string().required(),
        servicio_nombre: yup.string().required(),
    });

    return (
        <FormDialog
            ref={dialogRef}
            variant="page"
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            validationSchema={validationSchema}
            // debug
        />
    );
};

export const DigitalFormDialog = FormDialogComponent;
