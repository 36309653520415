/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, IconButton, makeStyles, Theme, Tooltip, Typography, Zoom } from '@material-ui/core';
import { DialogBoxRef } from 'components/core/DialogBox';
import { InitialParams } from 'components/core/FormDialog/Types';
import { getIcon } from 'helpers';
import { useNotify } from 'hooks';
import { SolicitudServicioService } from 'modules/solicitudServicio';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { InformeProduccionFormDialog } from './InformeProduccionFormDialog';

type InformeProduccionParams = Solicitud.InformeProduccionParams;
type InformeProduccionModel = Solicitud.InformeProduccionModel;
type HorarioModel = Solicitud.HorarioModel;

const PageComponent = (): ReactElement => {
    const history = useHistory();
    const notify = useNotify();
    const classes = useStyles();

    const informeProduccionFormRef = useRef<DialogBoxRef<InitialParams>>(null);

    const searchParams = new URLSearchParams(history.location.search.replace('?', ''));
    const idParam = searchParams.get('id');
    const rubroParam = searchParams.get('rubro');
    const servicioParam = searchParams.get('servicio');
    const SOLICITUD_SERVICIO_ID = idParam ? decodeURIComponent(String(idParam)) : '';
    const RUBRO = rubroParam ? decodeURIComponent(String(rubroParam)) : '';
    const SERVICIO = servicioParam ? decodeURIComponent(String(servicioParam)) : '';

    const [formParams, setFormParams] = useState<InformeProduccionParams>({
        maquinaria: [],
        parametros: [],
        rubro: { id: '', nombre: '' },
        servicio: { id: '', categoria: '', nombre: '', columnas_informe_prod: [] },
        solicitante: { id: '', nombre_completo: '', fid_unidad_productiva: null, unidad_productiva: null },
        usuario_registro: { id: '', nombre_completo: '' },
    });

    const getData = async (): Promise<InformeProduccionModel | undefined> => {
        const response = await SolicitudServicioService.getInformeProduccionForm(SOLICITUD_SERVICIO_ID);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };

    const getFormParams = async (): Promise<InformeProduccionParams | undefined> => {
        const response = await SolicitudServicioService.getInformeProduccionParams(SOLICITUD_SERVICIO_ID);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };
    const getFormParamsCallback = useCallback(getFormParams, []);

    const submit = async (data: InformeProduccionModel): Promise<boolean> => {
        const response = await SolicitudServicioService.createOrUpdateInformeProduccion(SOLICITUD_SERVICIO_ID, data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Tarea completada exitosamente');
        return true;
    };

    const horarioSubmit = async (data: any): Promise<boolean> => {
        const dataToSave = { rows: data.datos.valores };
        dataToSave.rows.forEach((row: HorarioModel) => delete row._nuevo);
        const response = await SolicitudServicioService.createOrUpdateHorario(SOLICITUD_SERVICIO_ID, dataToSave);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Tarea completada exitosamente');
        return true;
    };

    useEffect(() => {
        informeProduccionFormRef.current?.open({
            params: {
                title: `Informe de Producción${RUBRO ? ` - ${RUBRO}` : ''}`,
                subtitle: SERVICIO,
            },
            actions: [
                {
                    on: 'SaveButton',
                    click: () => {
                        informeProduccionFormRef.current?.refresh();
                    },
                },
                {
                    on: 'BackButton',
                    click: () => {
                        history.goBack();
                    },
                },
            ],
        });
        getFormParamsCallback().then((result) => {
            if (result) setFormParams(result);
        });
    }, []);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center">
            <Box className={classes.actionBox} display="flex" alignItems="center">
                <Box mx="auto" />
                <Box>
                    <Tooltip
                        title={<Typography variant="body2">Descargar el informe en formato PDF</Typography>}
                        placement="left"
                        TransitionComponent={Zoom}
                        enterDelay={200}
                    >
                        <IconButton
                            onClick={() => {
                                SolicitudServicioService.imprimirInformeProduccionPDF(SOLICITUD_SERVICIO_ID);
                            }}
                        >
                            {getIcon('PictureAsPdfIcon')}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <InformeProduccionFormDialog
                dialogRef={informeProduccionFormRef}
                getData={getData}
                submit={submit}
                horarioSubmit={horarioSubmit}
                params={formParams}
            />
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    actionBox: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export const InformeProduccionPage = withRouter(PageComponent);
