import { createContext, ReactElement, ReactNode } from 'react';
import { AuthService } from 'services/authentication';

export const SessionContext = createContext<SessionInfo>(AuthService.DEFAULT_SESSION_INFO);

type Props = {
    children: ReactNode;
};

export const SessionProvider = ({ children }: Props): ReactElement => {
    const sessionInfo: SessionInfo = AuthService.getSessionInfo();
    return <SessionContext.Provider value={sessionInfo}>{children}</SessionContext.Provider>;
};
