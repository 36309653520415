import { BaseService } from 'services/http';

type DigitalTableModel = Digital.DigitalTableModel;
type DigitalFormModel = Digital.DigitalFormModel;
type DigitalFormParams = Digital.DigitalFormParams;

export const getDigitalTable = async (queryParams: QueryParams = {}): Promise<BaseResponse<{ rows: DigitalTableModel[]; count: number }>> => {
    const queryParamsText = BaseService.buildQueryParamsText(queryParams);
    return BaseService.request('get', `/digitalizacion/solicitudes/table${queryParamsText}`);
};

export const getDigitalForm = async (digitalId?: string): Promise<BaseResponse<DigitalFormModel>> => {
    const queryParamsText = digitalId ? `?id=${digitalId}` : '';
    return BaseService.request('get', `/digitalizacion/solicitudes/form${queryParamsText}`);
};

export const getDigitalFormParams = async (): Promise<BaseResponse<DigitalFormParams>> => {
    return BaseService.request('get', `/digitalizacion/solicitudes/form_params`);
};

export const editDigital = async (digitalId: string, data: DigitalFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('put', `/digitalizacion/solicitudes/${digitalId}`, data);
};

export const createDigital = async (data: DigitalFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', '/digitalizacion/solicitudes', data);
};

export const createOrUpdateDigital = async (data: DigitalFormModel): Promise<BaseResponse<boolean>> => {
    if (data.id) return editDigital(data.id, data);
    return createDigital(data);
};

export const deleteDigital = async (solicitudServicioId: string): Promise<BaseResponse<boolean>> => {
    return BaseService.request('delete', `/digitalizacion/solicitudes/${solicitudServicioId}`);
};
