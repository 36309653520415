import { ENUM_GENERO, ENUM_SI_NO, ENUM_TIPO_TRABAJADOR } from 'base/enums';
import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams, SelectOption } from 'components/core/FormDialog/Types';
import { isEmailValidTest } from 'components/core/FormDialog/yup-tests';
import { ReactElement, RefObject } from 'react';
import * as yup from 'yup';

type FormModel = Solicitud.SolicitanteFormModel;
type FormParams = Solicitud.SolicitanteFormParams;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
    params: FormParams;
};

const FormDialogComponent = ({ dialogRef, getData, submit, params }: Props): ReactElement => {
    const generoOptions: SelectOption[] = Object.keys(ENUM_GENERO)
        .map((key) => ({
            value: (ENUM_GENERO as EnumValue)[key].value,
            label: (ENUM_GENERO as EnumValue)[key].label,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const tipoTrabajadorOptions: SelectOption[] = Object.keys(ENUM_TIPO_TRABAJADOR)
        .map((key) => ({
            value: (ENUM_TIPO_TRABAJADOR as EnumValue)[key].value,
            label: (ENUM_TIPO_TRABAJADOR as EnumValue)[key].label,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const EsDuenioOptions: SelectOption[] = Object.keys(ENUM_SI_NO)
        .map((key) => ({
            value: (ENUM_SI_NO as EnumValue)[key].value,
            label: (ENUM_SI_NO as EnumValue)[key].label,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const unidadProductivaOptions: SelectOption[] = params.unidades_productivas.map((up) => ({
        value: up.id,
        label: up.nombre_razon_social,
        caption: up.numero_certificado,
    }));

    const formLayout: FormGroup<FormModel>[] = [
        {
            title: 'Datos generales del solicitante',
            grid: [
                [
                    { name: 'nombre', label: 'Nombre', type: 'text', delay: 500 },
                    { name: 'apellido_paterno', label: 'Apellido Paterno', type: 'text', delay: 500 },
                    { name: 'apellido_materno', label: 'Apellido Materno', type: 'text', delay: 500 },
                ],
                [
                    { name: 'cedula_identidad', label: 'Cédula de identidad', type: 'text', delay: 500 },
                    { name: 'sexo', label: 'Sexo', type: 'select', options: generoOptions },
                ],
                [
                    { name: 'direccion', label: 'Dirección', type: 'text', delay: 500 },
                    { name: 'telefono', label: 'Teléfono', type: 'text', delay: 500 },
                ],
                [{ name: 'email', label: 'Correo electrónico', type: 'text', delay: 500 }],
            ],
        },
        {
            title: 'Datos de la Unidad Productiva',
            grid: [
                [
                    {
                        name: 'fid_unidad_productiva',
                        label: 'Unidad Productiva',
                        type: 'autocomplete',
                        options: unidadProductivaOptions,
                    },
                ],
                [
                    {
                        name: 'es_duenio_up',
                        label: '¿Es dueño de la UP?',
                        type: 'radio-group',
                        inlineDisplay: true,
                        options: EsDuenioOptions,
                    },
                    {
                        name: 'tipo_trabajador',
                        label: 'Tipo de trabajador',
                        type: 'select',
                        options: tipoTrabajadorOptions,
                        delay: 500,
                    },
                ],
            ],
        },
    ];

    const validationSchema = yup.object({
        nombre: yup.string().required(),
        sexo: yup.string().required(),
        cedula_identidad: yup.string().required(),
        email: yup.string().test(isEmailValidTest()),
        direccion: yup.string().required(),
        telefono: yup.string().required(),
        tipo_trabajador: yup.string().nullable(),
        es_duenio_up: yup.string().required(),
    });

    return (
        <FormDialog
            ref={dialogRef}
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            validationSchema={validationSchema}
            // debug
        />
    );
};

export const SolicitanteFormDialog = FormDialogComponent;
