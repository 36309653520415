import React, { ReactElement, useRef, useEffect, useState } from 'react';
import { Formik, FormikProps, Form, FormikValues } from 'formik';
import { makeStyles, Theme, Box, Typography, Button } from '@material-ui/core';
import * as yup from 'yup';

import {
    MyTextField,
    MyPasswordTextField,
    MySelect,
    MyMultipleSelect,
    // MyDropzone,
    MyDateTimePicker,
    MyChipInput,
    MyCheckbox,
    MyTextArea,
    // MyEditor,
    MyDatePicker,
    MyTimePicker,
    MyAutocomplete,
    MyRadioGroup,
    MyCheckboxGroup,
} from 'components/core/FormFields';
import { SelectOption, FormValue } from 'components/core/FormDialog/Types';
import { dateTest, afterDateTest } from 'components/core/FormDialog/yup-tests';
import Scrollbars from 'react-custom-scrollbars-2';

const DATE_FORMAT = 'dd/MM/yyyy';
const TIME_FORMAT = 'HH:mm';
const DATETIME_FORMAT = 'dd/MM/yyyy HH:mm';

type FormModel = {
    name: string;
    password: string;
    ciudad: number | string;
    escuelas: number[];
    facilitador: number | string;
    mensaje: string;
    adjuntos: string;
    dia: string;
    hora: string;
    fecha: string;
    fecha_inicial: string;
    fecha_final: string;
    telefono: string[];
    enviar: boolean | null;
    texto: string;
    redes_sociales: string; // JSON { opcion1: true, opcion2: false, opcion3: null }
    tipo_modulo: string;
};

export const FormFieldsSample = (): ReactElement => {
    const classes = useStyles();

    const [formModel, setFormModel] = useState<FormModel>({} as FormModel);
    const formikRef = useRef<FormikProps<FormikValues>>(null);
    useEffect(() => formikRef.current?.resetForm({}), [formikRef, formModel]);

    const ciudades = [
        { id: 1, name: 'La Paz' },
        { id: 2, name: 'Cochabamba' },
        { id: 3, name: 'Santa Cruz' },
    ];

    const escuelas = [
        { id: 1, name: 'Franz Tamayo A\nLa Paz', id_ciudad: 1 },
        { id: 2, name: 'Franz Tamayo B\nLa Paz', id_ciudad: 1 },
        { id: 3, name: 'Franz Tamayo C\nCochabamba', id_ciudad: 2 },
    ];

    const facilitadores = [
        { id: 1, name: 'John Smith' },
        { id: 2, name: 'Rosa Flores' },
        { id: 3, name: 'Carlos Mendoza' },
        { id: 4, name: 'Jesus Romero' },
        { id: 5, name: 'Julia Paredez' },
        { id: 6, name: 'Rosa Hidalgo' },
        { id: 7, name: 'Pedro Perez' },
        { id: 8, name: 'Carlos Perez' },
        { id: 9, name: 'Carlos Jimenez' },
        { id: 10, name: 'Ana Mendoza' },
        { id: 11, name: 'Rosa Flores 2' },
        { id: 12, name: 'Carlos Mendoza 2' },
        { id: 13, name: 'Jesus Romero 2' },
        { id: 14, name: 'Julia Paredez 2' },
        { id: 15, name: 'Rosa Hidalgo 2' },
        { id: 16, name: 'Pedro Perez 2' },
        { id: 17, name: 'Carlos Perez 2' },
        { id: 18, name: 'Carlos Jimenez 2' },
        { id: 19, name: 'Ana Mendoza 2' },
        { id: 20, name: 'John Smith 2' },
    ];

    // 1ra Forma
    const ciudadOptions: SelectOption[] = ciudades.map((item) => ({ value: item.id, label: item.name }));
    // const escuelasOptions: SelectOption[] = escuelas.map((item) => ({ value: item.id, label: item.name }));
    // const facilitadorOptions: SelectOption[] = facilitadores.map((item) => ({ value: item.id, label: item.name }));
    const redSocialOptions: SelectOption[] = [
        { value: '1', label: 'Facebook' },
        { value: '2', label: 'Twitter' },
        { value: '4', label: 'Instagram' },
    ];
    const tipoModuloOptions: SelectOption[] = [
        { value: 'OBLIGATORIO', label: 'Módulo obligatorio' },
        { value: 'OPTATIVO', label: 'Módulo optativo' },
    ];

    // 2da Forma
    // const ciudadOptions = (formValue: FormValue): SelectOption[] => {
    //     return ciudades.map((item) => ({ value: item.id, label: item.name }));
    // };
    // const escuelasOptions = (formValue: FormValue): SelectOption[] => {
    //     return escuelas.map((item) => ({ value: item.id, label: item.name }));
    // };
    const escuelasOptions = (formValue: FormValue): SelectOption[] => {
        return escuelas
            .filter((item) => item.id_ciudad === Number(formValue.ciudad))
            .map((item) => ({
                value: item.id,
                label: item.name,
            }));
    };

    const facilitadorOptions = (): SelectOption[] => {
        return facilitadores.map((item) => ({ value: item.id, label: item.name }));
    };

    const variant: 'filled' | 'standard' | 'outlined' = 'filled';

    const zeroValues: FormModel = {
        name: '',
        password: '',
        ciudad: '',
        escuelas: [],
        facilitador: '',
        mensaje: '',
        adjuntos: '',
        dia: '',
        hora: '',
        fecha: '',
        fecha_inicial: '',
        fecha_final: '',
        telefono: [],
        enviar: null,
        redes_sociales: '',
        texto: '',
        tipo_modulo: '',
    };

    const exampleData: FormModel = {
        name: 'John Smith Smith',
        password: '123456',
        ciudad: 1,
        escuelas: [1, 2],
        facilitador: 4,
        mensaje:
            '<p>Jueves 4 de Junio 10:30 - 12:30</p><p>Bienvenidos todos al evento.</p><p>Contamos con tu presencia.</p>',
        adjuntos: `[{"id":"3090c071-cdfc-4e3b-928b-de750ee7821f","fileName":"my-picture.jpg","filePath":"/store/my-picture.jpg","fileType":"image/jpeg"},{"id":"5be137cd-b7ad-4939-adef-9dec1c248814","fileName":"my-document.pdf","filePath":"/store/my-document.pdf","fileType":"application/pdf"},{"id":"7e3bf679-8a79-444e-85bb-63cfee3fbca5","fileName":"my-table.xls","filePath":"/store/my-table.xls","fileType":"application/vnd.ms-excel"}]`,
        dia: '28/02/2021',
        hora: '15:30',
        fecha: '28/02/2021 15:30',
        fecha_inicial: '02/02/2021',
        fecha_final: '28/02/2021',
        telefono: ['78837465', '2236453'],
        enviar: true,
        redes_sociales: '{"1":true,"2":true}',
        texto: 'Jueves 4 de Junio 10:30 - 12:30\n\nBienvenidos todos al evento.\n\nContamos con tu presencia.',
        tipo_modulo: 'OBLIGATORIO',
    };

    const whenFechaInicial = (fechaInicialValue: string, schema: yup.StringSchema) => {
        return schema.test(afterDateTest(fechaInicialValue, DATE_FORMAT, 'Debe ser posterior a la fecha inicial'));
    };

    const validationSchema = yup
        .object({
            name: yup.string().required(),
            password: yup.string().required(),
            ciudad: yup.number().required(),
            escuelas: yup.array().of(yup.number().required()).required(),
            facilitador: yup.number().required(),
            mensaje: yup.string().required(),
            adjuntos: yup.string().required(),
            dia: yup.string().required().test(dateTest(DATE_FORMAT)),
            hora: yup.string().required().test(dateTest(TIME_FORMAT)),
            fecha: yup.string().required().test(dateTest(DATETIME_FORMAT)),
            fecha_inicial: yup.string().required().test(dateTest(DATE_FORMAT)),
            fecha_final: yup.string().required().test(dateTest(DATE_FORMAT)).when('fecha_inicial', whenFechaInicial),
            telefono: yup.array().of(yup.string().required()).required(),
            enviar: yup.boolean().required().nullable(),
            redes_sociales: yup.string().required(),
            texto: yup.string().required(),
            tipo_modulo: yup.string().required(),
        })
        .defined();

    // const [myValue, setMyValue] = useState<string>('123');
    const [disabled, setDisabled] = useState<boolean>(false);

    function renderFormLayout(formik: FormikProps<FormikValues>): ReactElement {
        return (
            <>
                <Box className={classes.row}>
                    <Box className={classes.col2}>
                        <Typography variant="h6">TextFeld</Typography>
                        <MyTextField
                            label="Nombre(s)"
                            name="name"
                            disabled={disabled}
                            type="text"
                            formik={formik}
                            variant={variant}
                            // value={myValue}
                            // onChange={(value) => {
                            //     setMyValue(value as string);
                            // }}
                        />
                    </Box>
                    <Box className={classes.col2}>
                        <Typography variant="h6">PasswordTextFeld</Typography>
                        <MyPasswordTextField
                            label="Contraseña"
                            name="password"
                            disabled={disabled}
                            formik={formik}
                            variant={variant}
                            // value={myValue}
                            // onChange={(value) => {
                            //     setMyValue(value as string);
                            // }}
                        />
                    </Box>
                </Box>

                <Box className={classes.row}>
                    <Box className={classes.col3}>
                        <Typography variant="h6">Select</Typography>
                        <MySelect
                            label="Ciudad"
                            name="ciudad"
                            disabled={disabled}
                            options={ciudadOptions}
                            formik={formik}
                            variant={variant}
                            onChange={(value, formik) => {
                                formik.setFieldValue('ciudad', value);
                                formik.setFieldValue('escuelas', '');
                                formik.setFieldValue('facilitador', '');
                            }}
                            // value={myValue}
                            // onChange={(value) => {
                            //     setMyValue(value as number);
                            // }}
                        />
                    </Box>
                    <Box className={classes.col3}>
                        <Typography variant="h6">MultipleSelect</Typography>
                        <MyMultipleSelect
                            label="Escuelas"
                            name="escuelas"
                            disabled={disabled}
                            options={escuelasOptions}
                            formik={formik}
                            fieldRequired="ciudad"
                            variant={variant}
                            onChange={(value, formik) => {
                                formik.setFieldValue('escuelas', value);
                                formik.setFieldValue('facilitador', '');
                            }}
                            // value={myValue}
                            // onChange={(value) => {
                            //     setMyValue(value as number[]);
                            // }}
                        />
                    </Box>
                    <Box className={classes.col3}>
                        <Typography variant="h6">Autocomplete</Typography>
                        <MyAutocomplete
                            label="Facilitador"
                            name="facilitador"
                            disabled={disabled}
                            variant={variant}
                            options={facilitadorOptions}
                            formik={formik}
                            fieldRequired="escuelas"
                        />
                    </Box>
                </Box>

                <Box className={classes.row}>
                    <Box className={classes.col2}>
                        <Typography variant="h6">TextArea</Typography>
                        <MyTextArea
                            label="Mensaje"
                            name="texto"
                            disabled={disabled}
                            formik={formik}
                            variant={variant}
                            // value={myValue}
                            // onChange={(value) => {
                            //     setMyValue(value as string);
                            // }}
                        />
                    </Box>
                    {/* <Box className={classes.col2}>
                        <Typography variant="h6">Editor</Typography>
                        <MyEditor
                            label="Mensaje"
                            name="mensaje"
                            disabled={disabled}
                            formik={formik}
                            variant={variant}
                            // value={myValue}
                            // onChange={(value) => {
                            //     setMyValue(value as string);
                            // }}
                        />
                    </Box> */}
                </Box>

                <Box className={classes.row}>
                    {/* <Box className={classes.col1}>
                        <Typography variant="h6">Dropzone</Typography>
                        <MyDropzone
                            label="Adjuntos"
                            name="adjuntos"
                            disabled={disabled}
                            formik={formik}
                            variant={variant}
                            // value={myValue}
                            // onChange={(value) => {
                            //     setMyValue(String(value));
                            // }}
                        />
                    </Box> */}
                </Box>

                <Box className={classes.row}>
                    <Box className={classes.col3}>
                        <Typography variant="h6">DatePicker</Typography>
                        <MyDatePicker
                            label="Día de envío"
                            name="dia"
                            disabled={disabled}
                            formik={formik}
                            variant={variant}
                            format={DATE_FORMAT}
                        />
                    </Box>
                    <Box className={classes.col3}>
                        <Typography variant="h6">TimePicker</Typography>
                        <MyTimePicker
                            label="Hora de envío"
                            name="hora"
                            disabled={disabled}
                            formik={formik}
                            variant={variant}
                            format={TIME_FORMAT}
                        />
                    </Box>
                    <Box className={classes.col3}>
                        <Typography variant="h6">DateTimePicker</Typography>
                        <MyDateTimePicker
                            label="Fecha de envío"
                            name="fecha"
                            disabled={disabled}
                            formik={formik}
                            variant={variant}
                            format={DATETIME_FORMAT}
                        />
                    </Box>
                </Box>

                <Box className={classes.row}>
                    <Box className={classes.col2}>
                        <Typography variant="h6">DatePicker</Typography>
                        <MyDatePicker
                            label="Fecha inicial"
                            name="fecha_inicial"
                            disabled={disabled}
                            formik={formik}
                            format={DATE_FORMAT}
                            variant={variant}
                            disablePast={true}
                            onChange={(value, formik) => {
                                formik.setFieldValue('fecha_inicial', value);
                                formik.setFieldValue('fecha_final', value);
                            }}
                        />
                    </Box>
                    <Box className={classes.col2}>
                        <Typography variant="h6">DatePicker</Typography>
                        <MyDatePicker
                            label="Fecha final"
                            name="fecha_final"
                            disabled={disabled}
                            formik={formik}
                            variant={variant}
                            format={DATE_FORMAT}
                            disablePast={true}
                            fieldRequired="fecha_inicial"
                        />
                    </Box>
                </Box>

                <Box className={classes.row}>
                    <Box className={classes.col2}>
                        <Typography variant="h6">ChipInput</Typography>
                        <MyChipInput
                            label="Teléfono(s)"
                            name="telefono"
                            disabled={disabled}
                            formik={formik}
                            variant={variant}
                            onChange={(value, formik) => {
                                formik.setFieldValue('telefono', value);
                                formik.setFieldValue('enviar', null);
                            }}
                            // value={myValue}
                            // onChange={(value) => {
                            //     setMyValue(value ? value as string[] : []);
                            // }}
                        />
                    </Box>
                    <Box className={classes.col2}>
                        <Typography variant="h6">Checkbox</Typography>
                        <MyCheckbox
                            label="Enviar notificación"
                            name="enviar"
                            disabled={disabled}
                            variant={variant}
                            formik={formik}
                            // value={myValue}
                            // onChange={(value) => {
                            //     setMyValue(Boolean(value));
                            // }}
                        />
                    </Box>
                </Box>

                <Box className={classes.row}>
                    <Box className={classes.col2}>
                        <Typography variant="h6">CheckboxGroup</Typography>
                        <MyCheckboxGroup
                            label="Seleccione una o varias opciones"
                            name="redes_sociales"
                            disabled={disabled}
                            variant={variant}
                            options={redSocialOptions}
                            formik={formik}
                        />
                    </Box>
                    <Box className={classes.col2}>
                        <Typography variant="h6">CheckboxGroup (Horizontal)</Typography>
                        <MyCheckboxGroup
                            label="Seleccione una o varias opciones"
                            name="redes_sociales"
                            disabled={disabled}
                            options={redSocialOptions}
                            variant={variant}
                            formik={formik}
                            inlineDisplay
                        />
                    </Box>
                </Box>

                <Box className={classes.row}>
                    <Box className={classes.col2}>
                        <Typography variant="h6">RadioGroup</Typography>
                        <MyRadioGroup
                            label="Tipo de módulo"
                            name="tipo_modulo"
                            disabled={disabled}
                            options={tipoModuloOptions}
                            variant={variant}
                            formik={formik}
                        />
                    </Box>
                    <Box className={classes.col2}>
                        <Typography variant="h6">RadioGroup (horizontal)</Typography>
                        <MyRadioGroup
                            label="Tipo de módulo"
                            name="tipo_modulo"
                            disabled={disabled}
                            options={tipoModuloOptions}
                            variant={variant}
                            formik={formik}
                            inlineDisplay
                        />
                    </Box>
                </Box>
            </>
        );
    }

    return (
        <Formik
            initialValues={formModel || zeroValues}
            onSubmit={(data) => {
                console.log('submitting... FORM_VALUE = ', JSON.stringify(data, null, 2));
            }}
            innerRef={formikRef}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {(formik) => {
                return (
                    <Form style={{ backgroundColor: 'unset', padding: '0px', textAlign: 'left' }}>
                        {renderFormLayout(formik)}
                        <Box display="flex" justifyContent="flex-end" my={5} p={2} border="solid lightgrey 1px">
                            <Button variant="text" onClick={() => setDisabled(!disabled)}>
                                Habilitar/Deshabilitar
                            </Button>
                            <Box px={1} />
                            <Button variant="text" onClick={() => setFormModel({} as FormModel)}>
                                Vaciar formulario
                            </Button>
                            <Box px={1} />
                            <Button variant="text" onClick={() => setFormModel(exampleData)}>
                                Llenar formulario
                            </Button>
                            <Box px={1} />
                            <Button variant="contained" color="primary" onClick={() => formik.handleSubmit()}>
                                Enviar formulario
                            </Button>
                        </Box>
                        <Box overflow="auto" maxWidth="100%" textAlign="left" border="solid lightgrey 1px">
                            <Scrollbars autoHeight autoHeightMax="50vh">
                                <Box p={2}>
                                    {/* <p>Custom VALUE = {JSON.stringify(myValue)}</p> */}
                                    <strong>FormValue:</strong>
                                    <pre>{JSON.stringify(formik.values, null, 2)}</pre>
                                    <strong>Errors:</strong>
                                    <pre>{JSON.stringify(formik.errors, null, 2)}</pre>
                                </Box>
                            </Scrollbars>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    row: {
        width: '100%',
        display: 'flex',
        margin: theme.spacing(0, 0, 2, 0),
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    col1: {
        width: '100%',
        padding: theme.spacing(0, 1),
    },
    col2: {
        width: '50%',
        padding: theme.spacing(0, 1),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    col3: {
        width: '33.33%',
        padding: theme.spacing(0, 1),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));
