import { ROLES } from './authBase';

export const ETAPA_INICIO = {
    id: '1',
    titulo: 'INICIO',
    roles: [ROLES.plataforma.id],
    anterior: null,
    siguiente: '2',
};

export const ETAPA_REGISTRO_SOLICITUD = {
    id: '2',
    titulo: 'Registro de solicitud',
    roles: [ROLES.plataforma.id],
    anterior: '1',
    siguiente: '3',
};

export const ETAPA_APROBACION_COORDINADOR = {
    id: '3',
    titulo: 'Aprobación del coordinador',
    roles: [ROLES.coordinador.id],
    anterior: '2',
    siguiente: '4',
};

export const ETAPA_APROBACION_TECNICO = {
    id: '4',
    titulo: 'Aprobación del técnico',
    roles: [ROLES.tecnicooperador.id, ROLES.tecnicolaboratorio.id],
    anterior: '3',
    siguiente: '5',
};

export const ETAPA_REGISTRO_INFORME_PRODUCCION = {
    id: '5',
    titulo: 'Registro de informe de producción',
    roles: [ROLES.tecnicooperador.id, ROLES.tecnicolaboratorio.id],
    anterior: '4',
    siguiente: '6',
};

export const ETAPA_REGISTRO_BOLETA_PAGO = {
    id: '6',
    titulo: 'Registro de boleta de pago',
    roles: [ROLES.administracion.id],
    anterior: '5',
    siguiente: '7',
};

export const ETAPA_FIN = {
    id: '7',
    titulo: 'FIN',
    roles: [ROLES.coordinador.id],
    anterior: '6',
    siguiente: null,
};

export const ETAPAS = [
    ETAPA_INICIO,
    ETAPA_REGISTRO_SOLICITUD,
    ETAPA_APROBACION_COORDINADOR,
    ETAPA_APROBACION_TECNICO,
    ETAPA_REGISTRO_INFORME_PRODUCCION,
    ETAPA_REGISTRO_BOLETA_PAGO,
    ETAPA_FIN,
];
