import { ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { StatusColumnProps } from './Types';

export const StatusColumn = ({ label, color, background }: StatusColumnProps): ReactElement => {
    const classes = useStyles();

    const renderButton = () => {
        return (
            <div className={classes.root} style={{ color, background }}>
                {label}
            </div>
        );
    };

    return <div className={clsx(classes.root)}>{renderButton()}</div>;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0, 2),
        textTransform: 'uppercase',
        textAlign: 'center',
    },
}));
