import { Box, Card, CardContent, makeStyles, Theme, Typography } from '@material-ui/core';
import { ReactElement } from 'react';
import { DataTableSampleModel } from './DataTableSample';

type Props = {
    data: DataTableSampleModel;
};

const MyComponent = ({ data }: Props): ReactElement => {
    const classes = useStyles();

    return (
        <Card className={classes.root} elevation={3}>
            <CardContent className={classes.cardContent}>
                <Box>
                    <Typography variant="body1" display="block">
                        <strong>{data.id}</strong>
                    </Typography>
                    <Typography variant="caption" display="block">
                        {data.nombre_completo}
                    </Typography>
                    <Typography variant="caption" display="block">
                        {data.roles}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    cardContent: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&:last-child': {
            paddingBottom: theme.spacing(1),
        },
    },
    statusBox: {
        padding: theme.spacing(0.5),
        borderRadius: theme.spacing(0.5),
        cursor: 'pointer',
    },
}));

export const DataTableItemSample = MyComponent;
