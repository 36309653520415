import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { ROLES } from 'base/authBase';
import { MyButton } from 'components/core/Buttons';
import { PageLoader } from 'components/core/PageLoader';
import { useLoading, useNotify, useSession } from 'hooks';
import { ReclamoService } from 'modules/reclamos';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { RUTAS } from 'routes';
import { DetalleReclamo } from './DetalleReclamo';
import clsx from 'clsx';
import { RechazarFormDialog } from './RechazarFormDialog';
import { FormDialogRef } from 'components/core/FormDialog';
import { ETAPA_APROBACION_COORDINADOR, ETAPA_ATENCION_RECLAMO } from 'base/flujoReclamo';
import { DerivarFormDialog } from './DerivarFormDialog';

type DetalleReclamoModel = Reclamos.DetalleReclamoModel;
type RechazarFormModel = Reclamos.RechazarFormModel;
type DerivarFormModel = Reclamos.DerivarFormModel;
type DerivarFormParams = Reclamos.DerivarFormParams;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const history = useHistory();
    const session = useSession();
    const classes = useStyles();

    const [{ isLoading }, { start, stop }] = useLoading();

    const submitLoader = useLoading();
    const isSubmitting = submitLoader[0].isLoading;
    const startSubmit = submitLoader[1].start;
    const stopSubmit = submitLoader[1].stop;
    const rechazarRef = useRef<FormDialogRef>(null);
    const derivarRef = useRef<FormDialogRef>(null);

    const [formValue, setFormValue] = useState<DetalleReclamoModel>();
    const [derivarParams, setDerivarParams] = useState<DerivarFormParams>({ usuarios: [] });

    const esCoordinador = session.roles.some((rol) => rol.id === ROLES.coordinador.id);
    const esLaboratorio = session.roles.some((rol) => rol.id === ROLES.tecnicolaboratorio.id);
    const esAdministracion = session.roles.some((rol) => rol.id === ROLES.administracion.id);
    const esPlataforma = session.roles.some((rol) => rol.id === ROLES.plataforma.id);

    const searchParams = new URLSearchParams(history.location.search.replace('?', ''));
    const idparam = searchParams.get('id');
    const RECLAMO_ID = idparam ? decodeURIComponent(String(idparam)) : '';

    const getDerivarFormParams = async (): Promise<DerivarFormParams | undefined> => {
        start();
        const response = await ReclamoService.derivarParams();
        stop();
        if (response.error) {
            notify.error(response.error);
            return;
        }
        setDerivarParams(response.data);
        return response.data;
    };
    const getDerivarFormParamsCallback = useCallback(getDerivarFormParams, []);

    const getFormValue = async (): Promise<DetalleReclamoModel | undefined> => {
        start();
        const response = await ReclamoService.getDetalleFormValue(RECLAMO_ID);
        stop();
        if (response.error) {
            notify.error(response.error);
            return;
        }
        setFormValue(response.data);
        return response.data;
    };
    const getFormValueCallback = useCallback(getFormValue, []);

    useEffect(() => {
        getFormValueCallback().then((result) => {
            if (result && result.estado !== 'FINALIZADO' && derivarParams.usuarios.length === 0) {
                getDerivarFormParamsCallback();
            }
        });
    }, []);

    const aprobarReclamo = async (): Promise<boolean> => {
        const response = await ReclamoService.aprobarRechazarReclamo(RECLAMO_ID, true);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        let msg = 'Solicitud Aprobada';
        if (formValue?.etapa_actual?.id === ETAPA_APROBACION_COORDINADOR.id) msg = 'Solicitud Aprobada por Coordinador';
        notify.success(msg);
        return true;
    };

    const rechazarReclamo = async (observacion: string): Promise<boolean> => {
        const response = await ReclamoService.aprobarRechazarReclamo(RECLAMO_ID, false, observacion);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Solicitud Rechazada');
        return true;
    };

    const finalizarFlujo = async (): Promise<boolean> => {
        const response = await ReclamoService.finalizarFlujo(RECLAMO_ID);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Solicitud Finalizada');
        return true;
    };

    const agregarResultado = async (mensaje: string): Promise<boolean> => {
        const response = await ReclamoService.agregarResultado(RECLAMO_ID, mensaje);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Resultado registrado');
        return true;
    };

    const derivarReclamo = async (data: DerivarFormModel): Promise<boolean> => {
        const response = await ReclamoService.derivar(data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Resultado registrado');
        return true;
    };

    const handleClickAprobarRechazar = async (aprobado: boolean, observacion = ''): Promise<void> => {
        if (isSubmitting) return;
        startSubmit();
        const success = aprobado ? await aprobarReclamo() : rechazarReclamo(observacion);
        stopSubmit();
        if (success) {
            getFormValueCallback();
        }
    };

    const handleClickFinalizarFlujo = async () => {
        if (isSubmitting) return;
        startSubmit();
        const success = await finalizarFlujo();
        stopSubmit();
        if (success) {
            getFormValueCallback();
        }
    };

    const handleClickAgregarResultado = async (mensaje: string) => {
        if (isSubmitting) return true;
        startSubmit();
        const success = await agregarResultado(mensaje);
        stopSubmit();
        if (success) {
            getFormValueCallback();
        }
        return success;
    };

    const handleClickVolver = async () => {
        history.goBack();
    };

    const renderEstado = () => {
        if (formValue && formValue.estado === 'FINALIZADO') {
            return (
                <Box className={clsx(classes.estadoBtn, classes.estadoFinalizado)}>
                    {/* <Typography variant="caption">Estado</Typography> */}
                    <Typography variant="h5">FINALIZADO</Typography>
                </Box>
            );
        }
        if (formValue && formValue.aprobado_coordinador === null) {
            return (
                <Box className={clsx(classes.estadoBtn, classes.estadoNuevo)}>
                    {/* <Typography variant="caption">Estado</Typography> */}
                    <Typography variant="h5">NUEVO</Typography>
                </Box>
            );
        }
        if (formValue && formValue.aprobado_coordinador) {
            return (
                <Box className={clsx(classes.estadoBtn, classes.estadoAprobado)}>
                    {/* <Typography variant="caption">Estado</Typography> */}
                    <Typography variant="h5">APROBADO</Typography>
                </Box>
            );
        }
        return <></>;
    };

    const getData = async (): Promise<RechazarFormModel | undefined> => {
        return { observacion: '', id_reclamo: RECLAMO_ID };
    };

    const submit = async (data: RechazarFormModel): Promise<boolean> => {
        return rechazarReclamo(data.observacion);
    };

    const getDataDerivar = async (): Promise<DerivarFormModel | undefined> => {
        return { id_reclamo: RECLAMO_ID, para: '' };
    };

    const submitDerivar = async (data: DerivarFormModel): Promise<boolean> => {
        return derivarReclamo(data);
    };

    return (
        <Box className={classes.root} display="flex" flexDirection="column" pb={3}>
            <PageLoader isLoading={isLoading} />
            <RechazarFormDialog dialogRef={rechazarRef} getData={getData} submit={submit} />
            <DerivarFormDialog
                dialogRef={derivarRef}
                params={derivarParams}
                getData={getDataDerivar}
                submit={submitDerivar}
            />
            <Box className={classes.actionBox} display="flex" alignItems="center">
                <Box>{renderEstado()}</Box>
                <Box mx="auto" />
                <Box>
                    {/* <Tooltip
                        title={<Typography variant="body2">Descargar el informe en formato PDF</Typography>}
                        placement="left"
                        TransitionComponent={Zoom}
                        enterDelay={200}
                    >
                        <IconButton
                            onClick={() => {
                                ReclamoService.imprimirDetalleServicioPDF(RECLAMO_ID);
                            }}
                        >
                            {getIcon('PictureAsPdfIcon')}
                        </IconButton>
                    </Tooltip> */}
                </Box>
            </Box>
            {formValue && (
                <DetalleReclamo
                    data={formValue}
                    agregarResultado={
                        (esLaboratorio || esAdministracion || esPlataforma) &&
                        formValue.etapa_actual?.id === ETAPA_ATENCION_RECLAMO.id &&
                        (!formValue.fid_usuario_actual || formValue.fid_usuario_actual === session.userId)
                            ? handleClickAgregarResultado
                            : undefined
                    }
                />
            )}
            <Box display="flex" justifyContent="center">
                <Box mx={1}>
                    <MyButton
                        label="Volver"
                        color="default"
                        icon="ArrowBackIcon"
                        onClick={() => handleClickVolver()}
                        disabled={isSubmitting}
                    />
                </Box>
                {formValue && esCoordinador && formValue.etapa_actual?.id === ETAPA_APROBACION_COORDINADOR.id && (
                    <Box mx={1}>
                        <MyButton
                            label="Aprobar"
                            color="primary"
                            icon="CheckIcon"
                            onClick={() => handleClickAprobarRechazar(true)}
                            disabled={isSubmitting}
                        />
                    </Box>
                )}
                {formValue && esCoordinador && formValue.etapa_actual?.id === ETAPA_APROBACION_COORDINADOR.id && (
                    <Box mx={1}>
                        <MyButton
                            label="Rechazar"
                            color="secondary"
                            icon="CloseIcon"
                            onClick={async () => {
                                rechazarRef.current?.open({
                                    params: { title: 'Rechazar reclamo', titleIcon: 'EditIcon' },
                                    actions: [
                                        {
                                            on: 'AcceptButton',
                                            click: () => history.push(RUTAS.reclamos),
                                        },
                                    ],
                                });
                            }}
                            disabled={isSubmitting}
                        />
                    </Box>
                )}
                {formValue &&
                    (esCoordinador || esAdministracion || esLaboratorio || esPlataforma) &&
                    formValue.etapa_actual?.id === ETAPA_ATENCION_RECLAMO.id &&
                    (!formValue.fid_usuario_actual || formValue.fid_usuario_actual === session.userId) && (
                        <Box mx={1}>
                            <MyButton
                                label="Derivar"
                                color="primary"
                                icon="SendIcon"
                                onClick={() => {
                                    return Promise.resolve(
                                        derivarRef.current?.open({
                                            params: { title: 'Derivar', titleIcon: 'SendIcon' },
                                            actions: [
                                                {
                                                    on: 'AcceptButton',
                                                    click: () => history.push(RUTAS.reclamos),
                                                },
                                            ],
                                        }),
                                    );
                                }}
                                disabled={isSubmitting}
                            />
                        </Box>
                    )}
                {formValue && esPlataforma && formValue.etapa_actual?.id === ETAPA_ATENCION_RECLAMO.id && (
                    <Box mx={1}>
                        <MyButton
                            label="Finalizar"
                            color="secondary"
                            icon="ExitToAppIcon"
                            onClick={() => handleClickFinalizarFlujo()}
                            disabled={isSubmitting}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0, 3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 0),
        },
    },
    actionBox: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    estadoBtn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        background: theme.palette.background.default,
        color: theme.palette.getContrastText(theme.palette.background.default),
    },
    estadoNuevo: {
        background: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
    estadoAprobado: {
        background: theme.palette.success.dark,
        color: theme.palette.getContrastText(theme.palette.success.dark),
    },
    estadoFinalizado: {
        background: theme.palette.background.default,
        color: theme.palette.getContrastText(theme.palette.background.default),
    },
}));

export const DetallePage = withRouter(PageComponent);
