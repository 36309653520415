export const timer = async (delay: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(() => resolve(), delay));
};

export const Bomb = (): void => {
    throw new Error('💥 CABOOM 💥');
};

export const closeLoader = (): void => {
    const $loader = document.querySelector('.app-loader');
    if ($loader) {
        $loader.classList.add('app-loader--hide');
        setTimeout(() => $loader.parentElement?.removeChild($loader), 300);
    }
};

export const getRandomInt = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min)) + min;
};

export const getLocalDateString = (fecha: Date): string => {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return fecha.toLocaleDateString('es-ES', options);
};

export const normalizeText = (text: string): string => {
    let result = text.replace(/^\s+|\s+$/g, '').toLowerCase();
    const from = 'ãàáäâèéëêìíïîõòóöôùúüûç·/_,:;';
    const to = 'aaaaaeeeeiiiiooooouuuuc------';
    for (let i = 0; i < from.length; i++) {
        result = result.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    return result
        .replace(/[^a-z0-9ñ -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
};

export const getFileName = (title: string, subtitle?: string): string => {
    const primaryTitle = `${normalizeText(title)}_`;
    const secondaryTitle = subtitle ? `${normalizeText(subtitle)}_` : '';
    const date = Date.now();
    const filename = `${primaryTitle}${secondaryTitle}${date}`;
    return filename;
};

export const getFullName = (nombre?: string, paterno?: string, materno?: string): string => {
    const NOMBRE = nombre || '';
    const PATERNO = paterno || '';
    const MATERNO = materno || '';
    return `${`${NOMBRE} ${PATERNO}`.trim()} ${MATERNO}`.trim();
};
