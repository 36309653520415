import { DialogBoxRef } from 'components/core/DialogBox';
import { InitialParams } from 'components/core/FormDialog/Types';
import { GetRows, DataTableRef, UpdateParams } from 'components/core/DataTable/Types';
import { useNotify } from 'hooks';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { MantenimientoService } from '.';
import { EquipoFormDialog } from './components/forms/EquipoFormDialog';
import { EquipoTable } from './components/tables/EquipoTable';
import { MantenimientoFormDialog } from './components/forms/MantenimientoFormDialog';

type EquipoIndustrialFormParams = Mantenimiento.EquipoIndustrialFormParams;
type EquipoIndustrialTableModel = Mantenimiento.EquipoIndustrialTableModel;
type EquipoIndustrialFormModel = Mantenimiento.EquipoIndustrialFormModel;
type MantenimientoFormModel = Mantenimiento.MantenimientoFormModel;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const equipoFormRef = useRef<DialogBoxRef<InitialParams>>(null);
    const mantenimientoFormRef = useRef<DialogBoxRef<InitialParams>>(null);
    const equipoTableRef = useRef<DataTableRef>(null);
    const [formParams, setFormParams] = useState<EquipoIndustrialFormParams>({ rubros: [] });

    const getEquipos: GetRows<EquipoIndustrialTableModel> = async (params: UpdateParams<EquipoIndustrialTableModel>) => {
        const response = await MantenimientoService.getEquipos(params);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        response.data.rows.forEach((r, index) => r.nro = r.nro || (index + 1) + (params.rowsPerPage * (params.page - 1)));
        return {
            ...params,
            rows: response.data.rows,
            count: response.data.count,
        };
    };

    const deleteEquipo = async (data: EquipoIndustrialTableModel): Promise<boolean> => {
        const response = await MantenimientoService.deleteEquipo(data.id);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        return true;
    };

    const getData = async (params?: InitialParams): Promise<EquipoIndustrialFormModel | undefined> => {
        const response = await MantenimientoService.getEquipoForm(params?.id);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };

    const submit = async (data: EquipoIndustrialFormModel): Promise<boolean> => {
        const response = await MantenimientoService.createOrUpdateEquipo(data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Tarea completada exitosamente');
        return true;
    };

    const getDataMantenimiento = async (params?: InitialParams): Promise<MantenimientoFormModel | undefined> => {
        const response = await MantenimientoService.getMantenimientoForm(params?.id);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };

    const submitMantenimiento = async (data: MantenimientoFormModel): Promise<boolean> => {
        const response = await MantenimientoService.registrarMantenimiento(data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Tarea completada exitosamente');
        return true;
    };

    const getFormParams = async (): Promise<EquipoIndustrialFormParams | undefined> => {
        const response = await MantenimientoService.getEquipoFormParams();
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return {
            rubros: response.data.rubros,
        };
    };
    const getFormParamsCallback = useCallback(getFormParams, []);

    useEffect(() => {
        equipoTableRef.current?.refresh();
        getFormParamsCallback().then((result) => {
            if (result) setFormParams(result);
        });
    }, []);

    return (
        <div>
            <EquipoFormDialog dialogRef={equipoFormRef} getData={getData} submit={submit} params={formParams} />
            <MantenimientoFormDialog dialogRef={mantenimientoFormRef} getData={getDataMantenimiento} submit={submitMantenimiento} />
            <EquipoTable
                tableRef={equipoTableRef}
                getRows={getEquipos}
                editEquipo={async (data: EquipoIndustrialTableModel) => {
                    equipoFormRef.current?.open({
                        params: { id: data.id, title: 'Editar Equipo', titleIcon: 'EditIcon' },
                        actions: [{ on: 'AcceptButton', click: () => equipoTableRef.current?.refresh() }],
                    });
                    return true;
                }}
                deleteEquipo={async (row) => {
                    const success = await deleteEquipo(row);
                    if (success) {
                        equipoTableRef.current?.refresh();
                    }
                    return success;
                }}
                addEquipo={() => {
                    equipoFormRef.current?.open({
                        params: { title: 'Agregar Equipo', titleIcon: 'AddIcon' },
                        actions: [{ on: 'AcceptButton', click: () => equipoTableRef.current?.refresh() }],
                    });
                }}
                registrarMantenimiento={async (data) => {
                    mantenimientoFormRef.current?.open({
                        params: { id: data.id, title: 'Registrar Mantenimiento', titleIcon: 'BuildIcon' },
                        actions: [{ on: 'AcceptButton', click: () => equipoTableRef.current?.refresh() }],
                    });
                    return true;
                }}
            />
        </div>
    );
};

export const MantenimientoModule = withRouter(PageComponent);
