// import { DialogBoxRef } from 'components/core/DialogBox';
import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams, SelectOption } from 'components/core/FormDialog/Types';
import { dateTest } from 'components/core/FormDialog/yup-tests';
import { ReactElement, RefObject } from 'react';
import * as yup from 'yup';

type FormModel = Mantenimiento.EquipoIndustrialFormModel;
type FormParams = Mantenimiento.EquipoIndustrialFormParams;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
    params: FormParams;
};

const FormDialogComponent = ({ dialogRef, getData, submit, params }: Props): ReactElement => {
    const rubroOptions: SelectOption[] = params.rubros.map((rubro) => ({
        value: rubro.id,
        label: rubro.nombre,
    }));

    const formLayout: FormGroup<FormModel>[] = [
        {
            title: 'Datos del equipo',
            grid: [
                [{ name: 'codigo', label: 'Código', type: 'text' }],
                [{ name: 'nombre', label: 'Nombre', type: 'text' }],
                [{ name: 'marca', label: 'Marca', type: 'text' }],
                [{ name: 'fid_rubro', label: 'Rubro', type: 'select', options: rubroOptions }],
            ],
        },
        {
            title: 'Próximo mantenimiento',
            grid: [[{ name: 'fecha_proximo', label: 'Fecha próximo', type: 'date' }]],
        },
    ];

    const DATE_FORMAT = 'dd/MM/yyyy';
    const validationSchema = yup.object({
        codigo: yup.string().required(),
        nombre: yup.string().required(),
        marca: yup.string().required(),
        fecha_proximo: yup.string().nullable().test(dateTest(DATE_FORMAT)),
        fid_rubro: yup.string().required(),
    });

    return (
        <FormDialog
            ref={dialogRef}
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            validationSchema={validationSchema}
            maxWidth={'sm'}
            fullwidth={true}
            // debug
        />
    );
};

export const EquipoFormDialog = FormDialogComponent;
