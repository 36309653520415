import { DialogBoxRef } from 'components/core/DialogBox';
import { InitialParams } from 'components/core/FormDialog/Types';
import { GetRows, DataTableRef, UpdateParams } from 'components/core/DataTable/Types';
import { useNotify } from 'hooks';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { InventarioService } from '.';
import { InsumoFormDialog } from './components/forms/InsumoFormDialog';
import { InsumoTable } from './components/tables/InsumoTable';

type InsumoTableModel = Inventario.InsumoTableModel;
type InsumoFormModel = Inventario.InsumoFormModel;
type InsumoFormParams = Inventario.InsumoFormParams;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const insumoFormRef = useRef<DialogBoxRef<InitialParams>>(null);
    const insumoTableRef = useRef<DataTableRef>(null);
    const [formParams, setFormParams] = useState<InsumoFormParams>({ rubros: [] });

    const getInsumos: GetRows<InsumoTableModel> = async (params: UpdateParams<InsumoTableModel>) => {
        const response = await InventarioService.getInsumos(params);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        response.data.rows.forEach((r, index) => (r.nro = r.nro || index + 1 + params.rowsPerPage * (params.page - 1)));
        return {
            ...params,
            rows: response.data.rows,
            count: response.data.count,
        };
    };

    const deleteInsumo = async (data: InsumoTableModel): Promise<boolean> => {
        const response = await InventarioService.deleteInsumo(data.id);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        return true;
    };

    const getData = async (params?: InitialParams): Promise<InsumoFormModel | undefined> => {
        const response = await InventarioService.getInsumoForm(params?.id);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };

    const submit = async (data: InsumoFormModel): Promise<boolean> => {
        const response = await InventarioService.createOrUpdateInsumo(data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Tarea completada exitosamente');
        return true;
    };

    const getFormParams = async (): Promise<InsumoFormParams | undefined> => {
        const response = await InventarioService.getInsumoFormParams();
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return {
            rubros: response.data.rubros,
        };
    };
    const getFormParamsCallback = useCallback(getFormParams, []);

    useEffect(() => {
        insumoTableRef.current?.refresh();
        getFormParamsCallback().then((result) => {
            if (result) setFormParams(result);
        });
    }, []);

    return (
        <div>
            <InsumoFormDialog dialogRef={insumoFormRef} getData={getData} submit={submit} params={formParams} />
            <InsumoTable
                tableRef={insumoTableRef}
                getRows={getInsumos}
                editInsumo={async (data: InsumoTableModel) => {
                    insumoFormRef.current?.open({
                        params: { id: data.id, title: 'Editar Insumo', titleIcon: 'EditIcon' },
                        actions: [{ on: 'AcceptButton', click: () => insumoTableRef.current?.refresh() }],
                    });
                    return true;
                }}
                deleteInsumo={async (row) => {
                    const success = await deleteInsumo(row);
                    if (success) {
                        insumoTableRef.current?.refresh();
                    }
                    return success;
                }}
                addInsumo={() => {
                    insumoFormRef.current?.open({
                        params: { title: 'Agregar Insumo', titleIcon: 'AddIcon' },
                        actions: [{ on: 'AcceptButton', click: () => insumoTableRef.current?.refresh() }],
                    });
                }}
            />
        </div>
    );
};

export const InventarioModule = withRouter(PageComponent);
