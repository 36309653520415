import { ReactElement } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { ThemeSection, NotificationSection } from './components/sections';
import { RootState, useStateSelector } from 'redux/reducers';
import { PageTitle } from 'components/core/PageTitle';

const PageComponent = (): ReactElement => {
    const classes = useStyles();
    const configState = useStateSelector((state: RootState) => state.configState);
    return (
        <div>
            <PageTitle value="Configuración" />

            <Box className={classes.section}>
                <ThemeSection config={configState} />
            </Box>

            <Box className={classes.section}>
                <NotificationSection config={configState} />
            </Box>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        marginBottom: theme.spacing(4),
    },
    display: {
        display: 'flex',
        width: '100%',
        padding: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
        background: theme.palette.grey[700],
        color: theme.palette.getContrastText(theme.palette.grey[700]),
    },
}));

export const SettingsPage = withRouter(PageComponent);
