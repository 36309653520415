import { createMuiTheme, responsiveFontSizes, Theme } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';
import { esES } from '@material-ui/core/locale';
import { PaletteType } from '@material-ui/core';

export const createTheme = (type: PaletteType): Theme => {
    return responsiveFontSizes(
        createMuiTheme(
            {
                typography: {
                    // Tell Material-UI what's the font-size on the html element is.
                    htmlFontSize: 10,
                },
                palette: {
                    // primary: indigo,
                    secondary: pink,

                    type,

                    primary: {
                        light: '#3f7ac3',
                        main: '#2d5d98',
                        dark: '#24456f',
                        contrastText: '#fff',
                    },
                    // secondary: {
                    //     light: '#ff7961',
                    //     main: '#f44336',
                    //     dark: '#ba000d',
                    //     contrastText: '#fff',
                    // },

                    // background: {
                    //       default: 'red',
                    //       paper: 'blue',
                    // },
                },
            },
            esES,
        ),
    );
};
