import { Box } from '@material-ui/core';
import { DialogBoxRef } from 'components/core/DialogBox';
import { InitialParams } from 'components/core/FormDialog/Types';
import { useNotify } from 'hooks';
import { SolicitudServicioService } from 'modules/solicitudServicio';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { RUTAS } from 'routes';
import { SolicitudServicioFormDialog } from './SolicitudServicioFormDialog';

type SolicitudServicioFormParams = Solicitud.SolicitudServicioFormParams;
type SolicitudServicioFormModel = Solicitud.SolicitudServicioFormModel;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const history = useHistory();
    const solicitudServicioFormRef = useRef<DialogBoxRef<InitialParams>>(null);

    const searchParams = new URLSearchParams(history.location.search.replace('?', ''));
    const idparam = searchParams.get('id');
    const SOLICITUD_SERVICIO_ID = idparam ? decodeURIComponent(String(idparam)) : '';

    const initialParams = {
        rubros_up: [],
        rubros: [],
        subtipos_rubro: [],
        servicios: [],
        maquinarias: [],
        parametros: [],
        solicitantes: [],
        unidades_productivas: [],
    };
    const [formParams, setFormParams] = useState<SolicitudServicioFormParams>(initialParams);

    const getData = async (): Promise<SolicitudServicioFormModel | undefined> => {
        const response = await SolicitudServicioService.getSolicitudServicioForm(SOLICITUD_SERVICIO_ID);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };

    const getFormParams = async (): Promise<SolicitudServicioFormParams | undefined> => {
        const response = await SolicitudServicioService.getSolicitudServicioFormParams();
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };
    const getFormParamsCallback = useCallback(getFormParams, []);

    const submit = async (data: SolicitudServicioFormModel): Promise<boolean> => {
        const response = await SolicitudServicioService.createOrUpdateSolicitudServicio(data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Tarea completada exitosamente');
        return true;
    };

    useEffect(() => {
        solicitudServicioFormRef.current?.open({
            params: {
                title: `Formulario de Solicitud de Servicio${SOLICITUD_SERVICIO_ID ? ' (ACTUALIZACIÓN)' : ''}`,
            },
            actions: [
                { on: 'AcceptButton', click: () => history.push(RUTAS.solicitudServicio) },
                { on: 'CancelButton', click: () => history.push(RUTAS.solicitudServicio) },
            ],
        });
        getFormParamsCallback().then((result) => {
            if (result) setFormParams(result);
        });
    }, []);

    return (
        <Box display="flex" justifyContent="center">
            <SolicitudServicioFormDialog
                dialogRef={solicitudServicioFormRef}
                getData={getData}
                submit={submit}
                params={formParams}
            />
        </Box>
    );
};

export const FormularioPage = withRouter(PageComponent);
