import React, { ReactElement } from 'react';

// material-ui
import { makeStyles, Theme, Button, Box } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';

// components
import { DataSearch } from 'components/core/DataSearch';
import { ActionBarProps, TableHeader, MyTableModel } from './Types';

export const ActionBar = ({
    updateParams,
    headers,
    addLabel,
    onFilterClick,
    onResfreshClick,
    onSearchClick,
    onSearchTextChange,
    onActionAddClick,
}: ActionBarProps): ReactElement => {
    const classes = useStyles();

    const hasHeaderFilters = headers.filter((column: TableHeader<MyTableModel>) => column.filter).length > 0;

    return (
        <div className={classes.buttonsBar}>
            {onActionAddClick && (
                <Button
                    className={classes.btn}
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={onActionAddClick}
                >
                    <AddIcon /> <span className={classes.buttonText}> &nbsp; {addLabel || 'Agregar'}</span>
                </Button>
            )}
            {onFilterClick && (
                <Button
                    className={classes.iconButton}
                    variant="contained"
                    onClick={onFilterClick}
                    disabled={!hasHeaderFilters}
                    disableElevation
                >
                    <FilterListIcon /> <span className={classes.buttonText}> &nbsp; Filtrar</span>
                </Button>
            )}
            {onResfreshClick && (
                <Button className={classes.iconButton} variant="contained" disableElevation onClick={onResfreshClick}>
                    <RefreshIcon /> <span className={classes.buttonText}> &nbsp; Refrescar</span>
                </Button>
            )}
            <Box mx="auto" />
            {onSearchClick && (
                <DataSearch
                    variant={'light'}
                    updateParams={updateParams}
                    onSearchClick={onSearchClick}
                    onSearchTextChange={onSearchTextChange}
                />
            )}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    btn: {
        color: '#fff',
        marginRight: theme.spacing(1),
    },
    iconButton: {
        color: '#000',
        marginRight: theme.spacing(1),
    },
    buttonsBar: {
        minHeight: theme.spacing(8),
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    buttonText: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));
