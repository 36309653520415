import { ElementType, ReactElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { SessionProvider } from 'hooks/useSession';
import { isAuthenticated } from 'services/authentication/AuthService';
import { CssBaseline } from '@material-ui/core';
import { RUTAS } from 'routes';
import { AuthService } from 'services/authentication';

type Props = {
    layout: ElementType;
    component: ElementType;
    exact: boolean;
    path: string;
};

export const PrivateRoute = (props: Props): ReactElement => {
    const { layout: Layout, component: Component, exact, path } = props;

    const routeComponent = () => {
        const currentPath = location.pathname;
        const isAuthorized = AuthService.getMenu().some((group) => {
            return group.items.some((item) => currentPath.startsWith(`${item.path}`));
        });
        if (isAuthenticated() && isAuthorized) {
            return (
                <SessionProvider>
                    <CssBaseline />
                    <Layout>
                        <Component />
                    </Layout>
                </SessionProvider>
            );
        }
        return <Redirect to={{ pathname: RUTAS.login }} />;
    };

    return <Route exact={exact} path={path} render={routeComponent} />;
};
