import { Typography } from '@material-ui/core';
import { ActionColumn, ActionItem } from 'components/core/Columns';
import { GetRows, DataTable, DataTableRef, TableHeader } from 'components/core/DataTable';
import { ReactElement, RefObject } from 'react';

type TableModel = Costo.ParametroTableModel;

type Props = {
    tableRef: RefObject<DataTableRef>;
    getRows: GetRows<TableModel>;
    editParametro: (row: TableModel) => Promise<boolean>;
    deleteParametro: (row: TableModel) => Promise<boolean>;
    onAddParametro: () => void;
};

export const TableComponent = (props: Props): ReactElement => {
    const { tableRef, getRows, editParametro, deleteParametro, onAddParametro } = props;
    const tableHeaders: TableHeader<TableModel>[] = [
        { id: 'nro', label: 'Nro.', width: 80 },
        { id: 'parametro', label: 'Parámetro', align: 'left', sort: true },
        { id: 'precio_parametro', label: 'Precio (Bs)', align: 'right', width: 130, sort: true },
        { id: 'servicio', label: 'Servicio', align: 'left', render: renderColumnServicio },
        { id: 'actions', label: 'Acciones', render: renderColumnActions, width: 130 },
    ];

    function renderColumnServicio(data: TableModel): ReactElement {
        return (
            <>
                <Typography variant="body2">{data.servicio}</Typography>
                <Typography variant="caption" color="textSecondary">
                    {data.codigo_servicio}
                </Typography>
            </>
        );
    }

    function renderColumnActions(data: TableModel): ReactElement {
        const confirmMessage = (
            <>
                ¿Desea eliminar el parámetro <strong>{data.parametro}</strong>?
            </>
        );
        const items: ActionItem[] = [
            { icon: 'EditIcon', onClick: async () => editParametro(data) },
            {
                icon: 'DeleteIcon',
                color: 'secondary',
                onClick: async () => deleteParametro(data),
                confirm: true,
                confirmMessage,
            },
        ];
        return <ActionColumn items={items} size="small" />;
    }

    return (
        <DataTable
            title="Parámetros de Servicio"
            ref={tableRef}
            headers={tableHeaders}
            getRows={getRows}
            onActionAddClick={onAddParametro}
            hideFilters
        />
    );
};

export const ParametroTable = TableComponent;
