import { Box, Card, CardActionArea, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { ENUM_GENERO } from 'base/enums';
import { getIcon } from 'helpers';
import { useSession } from 'hooks';
import { ReactElement } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { AuthService } from 'services/authentication';
import { getLocalDateString } from 'utils';

const PageComponent = (): ReactElement => {
    const session = useSession();
    const classes = useStyles();
    const history = useHistory();
    const menuItems = AuthService.getMenu().find((x) => x.name === 'modules')?.items || [];

    const handleClickItem = (item: MenuItemModel) => {
        if (item.path) history.push(item.path);
    };

    const bienvenidoText =
        session.genero === ENUM_GENERO.MASCULINO.value
            ? 'Bienvenido'
            : session.genero === ENUM_GENERO.FEMENINO.value
            ? 'Bienvenida'
            : 'Bienvenido(a)';

    return (
        <div className={classes.root}>
            <Typography align="center" variant="h4">
                {bienvenidoText}
            </Typography>

            <Typography align="center" variant="h4" gutterBottom>
                <strong>{session.displayName}</strong>
            </Typography>

            <Typography align="center" variant="body1" gutterBottom>
                Hoy es {getLocalDateString(new Date())}
            </Typography>

            <Grid container className={classes.grid} spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={2}>
                        {menuItems.map((menuItem, index) => (
                            <Grid key={index} item xs={12} md={6}>
                                <Card className={classes.paper} elevation={0}>
                                    <CardActionArea
                                        className={classes.media}
                                        onClick={() => handleClickItem(menuItem)}
                                        style={{ background: menuItem.color }}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography style={{ fontSize: '48px' }}>
                                                {getIcon(menuItem.icon, { fontSize: 'inherit' })}
                                            </Typography>
                                            <Typography color="inherit">{menuItem.label}</Typography>
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    grid: {
        flexGrow: 1,
        padding: theme.spacing(2, 0),
        marginTop: theme.spacing(10),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(0),
        },
    },
    media: {
        height: '100%',
        background: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        height: theme.spacing(15),
        background: 'unset',
    },
}));

export const HomePage = withRouter(PageComponent);
