import { ReactElement } from 'react';
import { Box, Divider, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { closeMessage } from 'redux/notification/notificationActions';

type Props = {
    notificationAlert: NotificationAlert;
};

export const MessageBox = (props: Props): ReactElement => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClose = (index: number) => {
        dispatch(closeMessage(index));
    };

    if (props.notificationAlert.messages.length === 0) {
        return <></>;
    }

    return (
        <div className={classes.root}>
            {props.notificationAlert.messages.map((message, index) => {
                return (
                    <Box key={index} className={classes.alert}>
                        <Alert variant="filled" severity={message.type} onClose={() => handleClose(index)}>
                            {message.message}
                        </Alert>
                        <Divider />
                    </Box>
                );
            })}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(0),
    },
    alert: {
        padding: theme.spacing(1, 3, 0, 3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 1, 0, 1),
        },
    },
}));
