/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button } from '@material-ui/core';
import { SaveButton } from 'components/core/Buttons/SaveButton';
import { ActionColumn, ActionItem } from 'components/core/Columns';
import { MyAutocomplete, MyDatePicker, MyTextField, MyTimePicker } from 'components/core/FormFields';
import { SimpleTable, TableHeader } from 'components/core/SimpleTable';
import { Formik, FormikProps, FormikValues } from 'formik';
import { getIcon } from 'helpers';
import { useEffect } from 'react';
import { ReactElement, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

type EditParams = Solicitud.EditParams;
type EditColumn = Solicitud.EditColumn;

type Props = {
    submit?: (values: any) => Promise<boolean>;
    params: EditParams;
    onChange?: (values: any) => void;
    disableAdd?: boolean;
};

const PageComponent = ({ submit, params, onChange, disableAdd }: Props): ReactElement => {
    const [cellValue, setCellValue] = useState<{ [key: string]: string }>({});
    const formikRef = useRef<FormikProps<FormikValues>>(null);

    const saveValues = (formik: FormikProps<any>, newValue: any) => {
        formik.setFieldValue('datos', newValue);
        if (onChange) onChange(newValue);
    };

    const handleNuevoResultado = (formik: FormikProps<any>, headers: TableHeader<any>[]) => {
        const currentValue = formik.values.datos;
        const newDatos = { ...currentValue };

        const newRow: any = {
            id: uuid(),
            _nuevo: true,
        };
        headers.forEach((header) => {
            newRow[header.id] = '';
        });

        newDatos.valores.push(newRow);
        saveValues(formik, newDatos);
    };

    const getRows = (formik: FormikProps<any>, headers: TableHeader<any>[]): any[] => {
        if (Object.keys(formik.values).length === 0) return [];

        const keys = headers.map((header) => header.id as string);

        const rows = formik?.values.datos.valores?.map((item: any) => {
            const row: any = {
                id: item.id,
                _nuevo: item._nuevo,
            };
            keys.forEach((key) => {
                row[key] = item[key];
            });
            return row;
        });
        return rows;
    };

    const renderColumnActions =
        (formik: FormikProps<any>) =>
        (row: any): ReactElement => {
            const items: ActionItem[] = [
                {
                    icon: 'DeleteIcon',
                    onClick: async () => {
                        const newDatos = { ...formik.values.datos };
                        newDatos.valores = newDatos.valores.filter((it: any) => it.id !== row.id);
                        saveValues(formik, newDatos);
                        return Promise.resolve(true);
                    },
                },
            ];
            return <ActionColumn items={items} size="small" />;
        };

    const renderColumnEditable =
        (col: EditColumn, formik: FormikProps<any>) =>
        (row: any): ReactElement => {
            if (!formik || !formik.values) return <></>;
            const CELL_ID = `i_${col.id}_${row.id}`;
            if (!formik.values.datos) return <></>;
            const resultadoItem = formik.values.datos.valores.find((it: any) => it.id === row.id);
            if (!resultadoItem) return <></>;

            if (!row._nuevo) {
                if (col.type === 'select') {
                    const opt = col.options?.find((x) => x.value === row[col.id]);
                    return <>{opt?.label || '-'}</>;
                }

                return <>{row[col.id] === '' ? '-' : row[col.id]}</>;
            }

            if (!col.readonly && col.type === 'select') {
                return (
                    <MyAutocomplete
                        name={CELL_ID}
                        value={cellValue[CELL_ID]}
                        label=" "
                        variant="standard"
                        disabled={col.readonly}
                        options={col.options || []}
                        onChange={(newValue) => {
                            setCellValue({ ...cellValue, [CELL_ID]: String(newValue) });

                            const newDatos = { ...formik.values.datos };
                            newDatos.valores = [...newDatos.valores].map((item) => {
                                if (item.id === row.id) item[col.id] = newValue;
                                return item;
                            });
                            saveValues(formik, newDatos);
                        }}
                    />
                );
            }
            if (!col.readonly && (col.type === 'text' || col.type === 'number')) {
                return (
                    <MyTextField
                        name={CELL_ID}
                        label=" "
                        variant="standard"
                        type={col.type}
                        value={cellValue[CELL_ID]}
                        multiline
                        delay={500}
                        onChange={(newValue) => {
                            setCellValue({ ...cellValue, [CELL_ID]: String(newValue) });

                            const newDatos = { ...formik.values.datos };
                            newDatos.valores = [...newDatos.valores].map((item) => {
                                if (item.id === row.id) item[col.id] = newValue;
                                return item;
                            });
                            saveValues(formik, newDatos);
                        }}
                    />
                );
            }
            if (!col.readonly && col.type === 'date') {
                return (
                    <MyDatePicker
                        name={CELL_ID}
                        label=" "
                        variant="standard"
                        value={cellValue[CELL_ID]}
                        delay={500}
                        onChange={(newValue) => {
                            setCellValue({ ...cellValue, [CELL_ID]: String(newValue) });

                            const newDatos = { ...formik.values.datos };
                            newDatos.valores = [...newDatos.valores].map((item) => {
                                if (item.id === row.id) item[col.id] = newValue;
                                return item;
                            });
                            saveValues(formik, newDatos);
                        }}
                    />
                );
            }
            if (!col.readonly && col.type === 'time') {
                return (
                    <MyTimePicker
                        name={CELL_ID}
                        label=" "
                        variant="standard"
                        value={cellValue[CELL_ID]}
                        delay={500}
                        onChange={(newValue) => {
                            setCellValue({ ...cellValue, [CELL_ID]: String(newValue) });

                            const newDatos = { ...formik.values.datos };
                            newDatos.valores = [...newDatos.valores].map((item) => {
                                if (item.id === row.id) item[col.id] = newValue;
                                return item;
                            });
                            formik.setFieldValue('datos', newDatos);
                        }}
                    />
                );
            }

            if (col.readonly && col.type === 'select') {
                const optionSelected = col.options?.find((opt) => opt.value === cellValue[CELL_ID]);
                return <>{optionSelected?.label || '-'}</>;
            }

            return <>{row[col.id] === '' ? '-' : row[col.id]}</>;
        };

    useEffect(() => {
        const newCellValue: { [key: string]: string } = {};
        params.columnas.forEach((col) => {
            if (col.hidden) return;
            params.valores.forEach((row) => {
                const CELL_ID = `i_${col.id}_${row.id}`;
                const newValue = row[col.id];
                newCellValue[CELL_ID] = String(newValue);
            });
        });
        setCellValue(newCellValue);
    }, [params]);

    return (
        <Formik
            initialValues={{ datos: { columnas: params.columnas, valores: params.valores } }}
            onSubmit={() => {
                // console.log('submitting... FORM_VALUE = ', JSON.stringify(data, null, 2));
            }}
            innerRef={formikRef}
            // enableReinitialize
        >
            {(formik) => {
                const headersPorServicio: TableHeader<any>[] = [];
                params.columnas.forEach((item) => {
                    if (item.hidden) return;
                    return headersPorServicio.push({
                        id: item.id,
                        label: item.name,
                        align: 'left',
                        render: formikRef.current ? renderColumnEditable(item, formikRef.current) : undefined,
                    });
                });

                const headers: TableHeader<any>[] = [...headersPorServicio];
                if (!disableAdd) {
                    headers.push({
                        id: 'actions',
                        label: 'Acciones',
                        render: formikRef.current ? renderColumnActions(formikRef.current) : undefined,
                        width: 80,
                    });
                }
                const rows: any = formikRef.current ? getRows(formikRef.current, headersPorServicio) : [];
                return (
                    <>
                        {!disableAdd && (
                            <Box display="flex" pr={1} py={1}>
                                <Box pr={1}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={getIcon('AddIcon')}
                                        onClick={() => handleNuevoResultado(formik, headers)}
                                    >
                                        Agregar
                                    </Button>
                                </Box>
                                {submit && (
                                    <Box pr={1}>
                                        <SaveButton
                                            label="Guardar"
                                            onClick={async () => {
                                                await submit(formik.values);
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                        )}
                        <Box width="100%" overflow="auto">
                            <SimpleTable headers={headers} rows={rows} />
                        </Box>
                    </>
                );
            }}
        </Formik>
    );
};

export const EditTable = PageComponent;
