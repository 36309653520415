import { Box, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { ROLES } from 'base/authBase';
import { MyButton } from 'components/core/Buttons';
import { PageLoader } from 'components/core/PageLoader';
import Zoom from '@material-ui/core/Zoom';
import { getIcon } from 'helpers';
import { useLoading, useNotify, useSession } from 'hooks';
import { SolicitudServicioService } from 'modules/solicitudServicio';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { RUTAS } from 'routes';
import { DetalleSolicitud } from './DetalleSolicitud';
import clsx from 'clsx';
import { RechazarFormDialog } from './RechazarFormDialog';
import { FormDialogRef } from 'components/core/FormDialog';
import {
    ETAPA_APROBACION_COORDINADOR,
    ETAPA_APROBACION_TECNICO,
    ETAPA_FIN,
    ETAPA_REGISTRO_BOLETA_PAGO,
    ETAPA_REGISTRO_INFORME_PRODUCCION,
} from 'base/flujoSolicitud';

type DetalleSolicitudModel = Solicitud.DetalleSolicitudModel;
type RechazarFormModel = Solicitud.RechazarFormModel;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const history = useHistory();
    const session = useSession();
    const classes = useStyles();

    const [{ isLoading }, { start, stop }] = useLoading();

    const submitLoader = useLoading();
    const isSubmitting = submitLoader[0].isLoading;
    const startSubmit = submitLoader[1].start;
    const stopSubmit = submitLoader[1].stop;
    const rechazarRef = useRef<FormDialogRef>(null);

    const [formValue, setFormValue] = useState<DetalleSolicitudModel>();

    const esCoordinador = session.roles.some((rol) => rol.id === ROLES.coordinador.id);
    const esTecnico = session.roles.some((rol) => rol.id === ROLES.tecnicooperador.id);
    const esLaboratorio = session.roles.some((rol) => rol.id === ROLES.tecnicolaboratorio.id);
    const esAdministracion = session.roles.some((rol) => rol.id === ROLES.administracion.id);

    const searchParams = new URLSearchParams(history.location.search.replace('?', ''));
    const idparam = searchParams.get('id');
    const SOLICITUD_SERVICIO_ID = idparam ? decodeURIComponent(String(idparam)) : '';

    const getFormValue = async (): Promise<DetalleSolicitudModel | undefined> => {
        start();
        const response = await SolicitudServicioService.getDetalleFormValue(SOLICITUD_SERVICIO_ID);
        stop();
        if (response.error) {
            notify.error(response.error);
            return;
        }
        setFormValue(response.data);
        return response.data;
    };
    const getFormValueCallback = useCallback(getFormValue, []);

    useEffect(() => {
        getFormValueCallback();
    }, []);

    const aprobarSolicitudServicio = async (): Promise<boolean> => {
        const response = await SolicitudServicioService.aprobarRechazarSolicitudServicio(SOLICITUD_SERVICIO_ID, true);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        let msg = 'Solicitud Aprobada';
        if (formValue?.etapa_actual?.id === ETAPA_APROBACION_COORDINADOR.id) msg = 'Solicitud Aprobada por Coordinador';
        if (formValue?.etapa_actual?.id === ETAPA_APROBACION_TECNICO.id) msg = 'Solicitud Aprobada por Técnico';
        notify.success(msg);
        return true;
    };

    const rechazarSolicitudServicio = async (observacion: string): Promise<boolean> => {
        const response = await SolicitudServicioService.aprobarRechazarSolicitudServicio(
            SOLICITUD_SERVICIO_ID,
            false,
            observacion,
        );
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Solicitud Rechazada');
        return true;
    };

    const finalizarFlujo = async (): Promise<boolean> => {
        const response = await SolicitudServicioService.finalizarFlujo(SOLICITUD_SERVICIO_ID);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Solicitud Finalizada');
        return true;
    };

    const enviarInforme = async (): Promise<boolean> => {
        const response = await SolicitudServicioService.enviarInforme(SOLICITUD_SERVICIO_ID);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Informe enviado');
        return true;
    };

    const enviarBoleta = async (): Promise<boolean> => {
        const response = await SolicitudServicioService.enviarBoleta(SOLICITUD_SERVICIO_ID);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Boleta enviada');
        return true;
    };

    const handleClickAprobarRechazar = async (aprobado: boolean, observacion = ''): Promise<void> => {
        if (isSubmitting) return;
        startSubmit();
        const success = aprobado ? await aprobarSolicitudServicio() : rechazarSolicitudServicio(observacion);
        stopSubmit();
        if (success) {
            getFormValueCallback();
        }
    };

    const handleClickFinalizarFlujo = async () => {
        if (isSubmitting) return;
        startSubmit();
        const success = await finalizarFlujo();
        stopSubmit();
        if (success) {
            getFormValueCallback();
        }
    };

    const handleClickEnviarInforme = async () => {
        if (isSubmitting) return;
        startSubmit();
        const success = await enviarInforme();
        stopSubmit();
        if (success) {
            getFormValueCallback();
        }
    };

    const handleClickEnviarBoleta = async () => {
        if (isSubmitting) return;
        startSubmit();
        const success = await enviarBoleta();
        stopSubmit();
        if (success) {
            getFormValueCallback();
        }
    };

    const handleClickVolver = async () => {
        history.goBack();
    };

    const handleClickBoletaPago = async () => {
        history.push(`${RUTAS.solicitudServicioBoleta}?id=${SOLICITUD_SERVICIO_ID}`);
    };

    const handleClickInformeProduccion = async () => {
        const RUBRO = encodeURIComponent(formValue?.rubro.nombre || '');
        const SERVICIO = encodeURIComponent(formValue?.servicio.nombre || '');
        history.push(
            `${RUTAS.solicitudServicioInformeProduccion}?id=${SOLICITUD_SERVICIO_ID}&rubro=${RUBRO}&servicio=${SERVICIO}`,
        );
    };

    const renderEstado = () => {
        if (formValue && formValue.estado === 'FINALIZADO') {
            return (
                <Box className={clsx(classes.estadoBtn, classes.estadoFinalizado)}>
                    {/* <Typography variant="caption">Estado</Typography> */}
                    <Typography variant="h5">FINALIZADO</Typography>
                </Box>
            );
        }
        if (formValue && formValue.aprobado_coordinador === null) {
            return (
                <Box className={clsx(classes.estadoBtn, classes.estadoNuevo)}>
                    {/* <Typography variant="caption">Estado</Typography> */}
                    <Typography variant="h5">NUEVO</Typography>
                </Box>
            );
        }
        if (formValue && formValue.aprobado_coordinador) {
            return (
                <Box className={clsx(classes.estadoBtn, classes.estadoAprobado)}>
                    {/* <Typography variant="caption">Estado</Typography> */}
                    <Typography variant="h5">APROBADO</Typography>
                </Box>
            );
        }
        return <></>;
    };

    const getData = async (): Promise<RechazarFormModel | undefined> => {
        return { observacion: '', id_solicitud_servicio: SOLICITUD_SERVICIO_ID };
    };

    const submit = async (data: RechazarFormModel): Promise<boolean> => {
        return rechazarSolicitudServicio(data.observacion);
    };

    return (
        <Box className={classes.root} display="flex" flexDirection="column" pb={3}>
            <PageLoader isLoading={isLoading} />
            <RechazarFormDialog dialogRef={rechazarRef} getData={getData} submit={submit} />
            <Box className={classes.actionBox} display="flex" alignItems="center">
                <Box>{renderEstado()}</Box>
                <Box mx="auto" />
                <Box>
                    <Tooltip
                        title={<Typography variant="body2">Descargar el informe en formato PDF</Typography>}
                        placement="left"
                        TransitionComponent={Zoom}
                        enterDelay={200}
                    >
                        <IconButton
                            onClick={() => {
                                SolicitudServicioService.imprimirDetalleServicioPDF(SOLICITUD_SERVICIO_ID);
                            }}
                        >
                            {getIcon('PictureAsPdfIcon')}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            {formValue && (
                <DetalleSolicitud
                    data={formValue}
                    enviarInforme={
                        (esTecnico || esLaboratorio) &&
                        formValue.etapa_actual?.id === ETAPA_REGISTRO_INFORME_PRODUCCION.id
                            ? handleClickEnviarInforme
                            : undefined
                    }
                    enviarBoleta={
                        esAdministracion && formValue.etapa_actual?.id === ETAPA_REGISTRO_BOLETA_PAGO.id
                            ? handleClickEnviarBoleta
                            : undefined
                    }
                />
            )}
            <Box display="flex" justifyContent="center">
                <Box mx={1}>
                    <MyButton
                        label="Volver"
                        color="default"
                        icon="ArrowBackIcon"
                        onClick={() => handleClickVolver()}
                        disabled={isSubmitting}
                    />
                </Box>
                {formValue &&
                    ((esCoordinador && formValue.etapa_actual?.id === ETAPA_APROBACION_COORDINADOR.id) ||
                        ((esTecnico || esLaboratorio) &&
                            formValue.etapa_actual?.id === ETAPA_APROBACION_TECNICO.id)) && (
                        <Box mx={1}>
                            <MyButton
                                label="Aprobar"
                                color="primary"
                                icon="CheckIcon"
                                onClick={() => handleClickAprobarRechazar(true)}
                                disabled={isSubmitting}
                            />
                        </Box>
                    )}
                {formValue &&
                    ((esCoordinador && formValue.etapa_actual?.id === ETAPA_APROBACION_COORDINADOR.id) ||
                        ((esTecnico || esLaboratorio) &&
                            formValue.etapa_actual?.id === ETAPA_APROBACION_TECNICO.id)) && (
                        <Box mx={1}>
                            <MyButton
                                label="Rechazar"
                                color="secondary"
                                icon="CloseIcon"
                                onClick={async () => {
                                    rechazarRef.current?.open({
                                        params: { title: 'Rechazar solicitud', titleIcon: 'EditIcon' },
                                        actions: [
                                            {
                                                on: 'AcceptButton',
                                                click: () => history.push(RUTAS.solicitudServicio),
                                            },
                                        ],
                                    });
                                }}
                                disabled={isSubmitting}
                            />
                        </Box>
                    )}
                {formValue &&
                    (esTecnico || esLaboratorio) &&
                    formValue.etapa_actual?.id === ETAPA_REGISTRO_INFORME_PRODUCCION.id && (
                        <Box mx={1}>
                            <MyButton
                                label="Informe"
                                color="primary"
                                icon="AssignmentIcon"
                                onClick={() => handleClickInformeProduccion()}
                                disabled={isSubmitting}
                            />
                        </Box>
                    )}
                {formValue && esAdministracion && formValue.etapa_actual?.id === ETAPA_REGISTRO_BOLETA_PAGO.id && (
                    <Box mx={1}>
                        <MyButton
                            label="Boleta"
                            color="secondary"
                            icon="PaymentIcon"
                            onClick={() => handleClickBoletaPago()}
                            disabled={isSubmitting}
                        />
                    </Box>
                )}
                {formValue && esCoordinador && formValue.etapa_actual?.id === ETAPA_FIN.id && (
                    <Box mx={1}>
                        <MyButton
                            label="Finalizar"
                            color="secondary"
                            icon="ExitToAppIcon"
                            onClick={() => handleClickFinalizarFlujo()}
                            disabled={isSubmitting}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0, 3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 0),
        },
    },
    actionBox: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    estadoBtn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        background: theme.palette.background.default,
        color: theme.palette.getContrastText(theme.palette.background.default),
    },
    estadoNuevo: {
        background: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
    estadoAprobado: {
        background: theme.palette.success.dark,
        color: theme.palette.getContrastText(theme.palette.success.dark),
    },
    estadoFinalizado: {
        background: theme.palette.background.default,
        color: theme.palette.getContrastText(theme.palette.background.default),
    },
}));

export const DetallePage = withRouter(PageComponent);
