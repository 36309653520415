import { createRef, ReactElement, ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { NotifyProvider } from './NotifyProvider';

type Props = {
    children: ReactNode;
};

export const NotistackProvider = ({ children }: Props): ReactElement => {
    const notistackRef = createRef<SnackbarProvider>();

    const onClickDismiss = (key: string | number) => () => {
        notistackRef.current?.closeSnackbar(key);
    };

    const action = (key: string | number) => {
        return (
            <IconButton onClick={onClickDismiss(key)} style={{ color: 'white' }}>
                <CloseIcon />
            </IconButton>
        );
    };

    return (
        <SnackbarProvider
            ref={notistackRef}
            maxSnack={3}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            action={action}
        >
            <NotifyProvider>{children}</NotifyProvider>
        </SnackbarProvider>
    );
};
