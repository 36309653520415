/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from '@material-ui/core';
import { ENUM_CATEGORIA_SERVICIO } from 'base/enums';
import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams, SelectOption } from 'components/core/FormDialog/Types';
import { FormikProps } from 'formik';
import { ReactElement, RefObject } from 'react';
import * as yup from 'yup';
import { EditTable } from '../../EditTable';

type FormModel = Solicitud.InformeProduccionModel;
type FormParams = Solicitud.InformeProduccionParams;
type EditParams = Solicitud.EditParams;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
    horarioSubmit: (data: any) => Promise<boolean>;
    params: FormParams;
};

const FormDialogComponent = ({ dialogRef, getData, submit, params }: Props): ReactElement => {
    const formLayout = (formValue: FormModel, formik: FormikProps<FormModel>) => {
        if (Object.keys(formValue).length === 0) return [];

        const servicioSeleccionado = params.servicio;

        const resultadoTableParams: EditParams = {
            columnas: servicioSeleccionado?.columnas_informe_prod || [],
            valores: formValue.items_informe_prod,
        };

        const parametroOptions: SelectOption[] = params.parametros.map((par) => ({
            value: par.id,
            label: par.parametro,
        }));
        resultadoTableParams.columnas.forEach((col) => {
            if (col.id === 'parametro') {
                col.options = parametroOptions;
                col.readonly = true;
            }
        });

        const formGroup: FormGroup<FormModel>[] = [
            {
                title: '',
                grid: [
                    [
                        {
                            type: 'empty',
                            render: () => {
                                return (
                                    <>
                                        <Typography>Responsable Pro Bolivia</Typography>
                                        <Typography>
                                            <strong>{params.usuario_registro.nombre_completo}</strong>
                                        </Typography>
                                    </>
                                );
                            },
                        },
                        {
                            type: 'empty',
                            render: () => {
                                return (
                                    <>
                                        <Typography align="right">Fecha</Typography>
                                        <Typography align="right">
                                            <strong>{formValue.fecha_informe}</strong>
                                        </Typography>
                                    </>
                                );
                            },
                        },
                    ],
                    [
                        {
                            type: 'empty',
                            render: () => {
                                if (!params.solicitante) return <></>;
                                return (
                                    <>
                                        <Typography>Solicitante</Typography>
                                        <Typography>
                                            <strong>{params.solicitante.nombre_completo}</strong>
                                        </Typography>
                                    </>
                                );
                            },
                        },
                        {
                            type: 'empty',
                            render: () => {
                                if (!params.solicitante) return <></>;
                                return (
                                    <>
                                        <Typography>Unidad Productiva</Typography>
                                        <Typography>
                                            <strong>
                                                {params.solicitante.unidad_productiva?.nombre_razon_social || '-'}
                                            </strong>
                                        </Typography>
                                    </>
                                );
                            },
                        },
                    ],
                ],
            },
            {
                title: '',
                grid: [
                    [
                        {
                            type: 'empty',
                            render: () => {
                                return (
                                    <>
                                        <Box>
                                            <Typography variant="h5" color="primary" gutterBottom>
                                                <strong>Resultado del servicio</strong>
                                            </Typography>
                                        </Box>
                                        <Box width="100%" overflow="auto">
                                            <EditTable
                                                params={resultadoTableParams}
                                                onChange={(datos) => {
                                                    formik.setFieldValue('items_informe_prod', datos.valores);
                                                }}
                                                disableAdd
                                            />
                                            <Typography align="right">
                                                <strong>Precio total:</strong> {formValue.precio_final} Bs.
                                            </Typography>
                                        </Box>
                                    </>
                                );
                            },
                        },
                    ],
                ],
            },
        ];

        if (servicioSeleccionado?.categoria === ENUM_CATEGORIA_SERVICIO.PRESTAMO_MAQUINARIA.value) {
            const maquinariaOptions: SelectOption[] = params.maquinaria.map((m) => ({
                value: m.id,
                label: m.nombre,
            }));

            const horarioTableParams: EditParams = {
                columnas: [
                    { id: 'fecha', name: 'Fecha', type: 'date' },
                    {
                        id: 'fid_maquinaria',
                        name: 'Maquinaria/Herramienta',
                        type: 'select',
                        options: maquinariaOptions,
                    },
                    { id: 'hora_inicio', name: 'Hora inicio', type: 'time' },
                    { id: 'hora_fin', name: 'Hora fin', type: 'time' },
                    { id: 'observacion', name: 'Observación', type: 'text' },
                ],
                valores: formValue.horario,
            };

            formGroup.push({
                title: '',
                grid: [
                    [
                        {
                            type: 'empty',
                            render: () => {
                                return (
                                    <>
                                        <Box>
                                            <Typography variant="h5" color="primary" gutterBottom>
                                                <strong>Registro de uso de maquinaria</strong>
                                            </Typography>
                                        </Box>
                                        <EditTable
                                            params={horarioTableParams}
                                            onChange={(datos) => {
                                                formik.setFieldValue('horario', datos.valores);
                                            }}
                                        />
                                        <Typography align="right">
                                            <strong>Tiempo total:</strong> {formValue.total_horas} horas
                                        </Typography>
                                    </>
                                );
                            },
                        },
                    ],
                ],
            });
        }

        formGroup.push({
            title: 'Resultado adjunto',
            grid: [[{ name: 'adjuntos', label: 'Archivos adjuntos', type: 'dropzone' }]],
        });

        return formGroup;
    };

    const validationSchema = yup.object({});

    return (
        <FormDialog
            ref={dialogRef}
            variant="page"
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            submitButton="SaveButton"
            cancelButton="BackButton"
            validationSchema={validationSchema}
            // debug
        />
    );
};

export const InformeProduccionFormDialog = FormDialogComponent;
