import { makeStyles, Theme, Typography } from '@material-ui/core';
import { ReactElement } from 'react';
import { withRouter } from 'react-router-dom';
import { PageTitle } from 'components/core/PageTitle';
import { ButtonSample } from './components/ButtonSample';
import { SimpleTableSample } from './components/SimpleTableSample';
import { ConfirmDialogSample } from './components/ConfirmDialogSample';
import { FormDialogSample } from './components/FormDialogSample';
import { DataTableSample } from './components/DataTableSample';
import { DataSearchSample } from './components/DataSearchSample';
import { FormFieldsSample } from './components/FormFieldsSample';

const PageComponent = (): ReactElement => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <PageTitle value="Guía de diseño" />

            <div className={classes.pageContent}>
                <div className={classes.section}>
                    <Typography variant="h5" gutterBottom>Buttons</Typography>
                    <ButtonSample />
                </div>

                <div className={classes.section}>
                    <Typography variant="h5" gutterBottom>SimpleTable</Typography>
                    <SimpleTableSample />
                </div>

                <div className={classes.section}>
                    <Typography variant="h5" gutterBottom>ConfirmDialog</Typography>
                    <ConfirmDialogSample />
                </div>

                <div className={classes.section}>
                    <Typography variant="h5" gutterBottom>FormDialog</Typography>
                    <FormDialogSample />
                </div>

                <div className={classes.section}>
                    <Typography variant="h5" gutterBottom>DataTable</Typography>
                    <DataTableSample />
                </div>

                <div className={classes.section}>
                    <Typography variant="h5" gutterBottom>DataSearch</Typography>
                    <DataSearchSample />
                </div>

                <div className={classes.section}>
                    <Typography variant="h5" gutterBottom>FormFields</Typography>
                    <FormFieldsSample />
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0),
    },
    pageContent: {},
    section: {
        padding: theme.spacing(1, 0),
        margin: theme.spacing(1, 0),
    },
}));

export const GuidelinesPage = withRouter(PageComponent);
