import { Fragment, ReactElement, useRef, useState } from 'react';
import { makeStyles, Theme, IconButton, Tooltip, Typography } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { getIcon } from 'helpers';
import clsx from 'clsx';
import { ActionColumnProps, ActionItem } from './Types';
import { ConfirmDialog, ConfirmDialogRef } from '../ConfirmDialog';

export const ActionColumn = ({ items, size }: ActionColumnProps): ReactElement => {
    const classes = useStyles();
    const dialogRef = useRef<ConfirmDialogRef>(null);
    const [itemSelected, setItemSelected] = useState<ActionItem>();

    const renderButton = (item: ActionItem) => {
        if (item.confirm) {
            return (
                <IconButton
                    color={item.color || 'primary'}
                    component="span"
                    onClick={() => {
                        setItemSelected(item);
                        dialogRef.current?.open();
                    }}
                    size={size}
                >
                    {getIcon(item.icon)}
                </IconButton>
            );
        }
        return (
            <IconButton color={item.color || 'primary'} component="span" onClick={item.onClick} size={size}>
                {getIcon(item.icon)}
            </IconButton>
        );
    };

    return (
        <div className={clsx(classes.root)}>
            <ConfirmDialog
                dialogRef={dialogRef}
                confirmMessage={<>{itemSelected?.confirmMessage || '¿Desea continuar?'}</>}
                onAcceptClick={itemSelected?.onClick}
            />

            {items.map((item, index) => (
                <Fragment key={index}>
                    {item.title && (
                        <Tooltip
                            title={<Typography variant="body2">{item.title}</Typography>}
                            placement="top"
                            TransitionComponent={Zoom}
                            enterDelay={200}
                        >
                            {renderButton(item)}
                        </Tooltip>
                    )}
                    {!item.title && renderButton(item)}
                </Fragment>
            ))}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0, 1),
        margin: theme.spacing(0, 2),
        display: 'flex',
        justifyContent: 'center',
    },
}));
