import { ROLES } from 'base/authBase';
import { GetRows, DataTableRef, UpdateParams } from 'components/core/DataTable/Types';
import { useNotify, useSession } from 'hooks';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { CostoServiciosService } from 'modules/costoServicios';
import { ServicioTable } from './ServicioTable';
import { ServicioFormDialog } from './ServicioFormDialog';
import { DialogBoxRef } from 'components/core/DialogBox';
import { InitialParams } from 'components/core/FormDialog/Types';

type ServicioFormParams = Costo.ServicioFormParams;
type ServicioTableModel = Costo.ServicioTableModel;
type ServicioFormModel = Costo.ServicioFormModel;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const session = useSession();
    const servicioFormRef = useRef<DialogBoxRef<InitialParams>>(null);
    const servicioTableRef = useRef<DataTableRef>(null);
    const [formParams, setFormParams] = useState<ServicioFormParams>({ rubros: [], categorias: [] });

    const esPlataforma = session.roles.some(rol => rol.id === ROLES.plataforma.id);

    function getCategoriaLabel (categoriaName: string) {
        if (categoriaName === 'CURSO_CAPACITACION') return 'Curso de capacitación';
        if (categoriaName === 'PRESTAMO_MAQUINARIA') return 'Préstamo de maquinaria';
        if (categoriaName === 'GENERAL') return 'General';
        if (categoriaName === 'LABORATORIO') return 'Laboratorio';
        return categoriaName;
    }

    const getServicios: GetRows<ServicioTableModel> = async (params: UpdateParams<ServicioTableModel>) => {
        const response = await CostoServiciosService.getServicios(params);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        response.data.rows.forEach((r, index) => {
            r.nro = r.nro || index + 1 + params.rowsPerPage * (params.page - 1);
            r.categoria = getCategoriaLabel(r.categoria);
        });
        return {
            ...params,
            rows: response.data.rows,
            count: response.data.count,
        };
    };

    const deleteServicio = async (data: ServicioTableModel): Promise<boolean> => {
        const response = await CostoServiciosService.deleteServicio(data.id);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        return true;
    };

    const getData = async (params?: InitialParams): Promise<ServicioFormModel | undefined> => {
        const response = await CostoServiciosService.getServicioForm(params?.id);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };

    const submit = async (data: ServicioFormModel): Promise<boolean> => {
        const response = await CostoServiciosService.createOrUpdateServicio(data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Tarea completada exitosamente');
        return true;
    };

    const getFormParams = async (): Promise<ServicioFormParams | undefined> => {
        const response = await CostoServiciosService.getServicioFormParams();
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };
    const getFormParamsCallback = useCallback(getFormParams, []);

    useEffect(() => {
        servicioTableRef.current?.refresh();
        getFormParamsCallback().then((result) => {
            if (result) setFormParams(result);
        });
    }, []);

    return (
        <div>
            <ServicioFormDialog dialogRef={servicioFormRef} getData={getData} submit={submit} params={formParams} />
            <ServicioTable
                tableRef={servicioTableRef}
                getRows={getServicios}
                modoCreacion={esPlataforma}
                editServicio={async (data: ServicioTableModel) => {
                    servicioFormRef.current?.open({
                        params: { id: data.id, title: 'Editar servicio', titleIcon: 'EditIcon' },
                        actions: [{ on: 'AcceptButton', click: () => servicioTableRef.current?.refresh() }],
                    });
                    return true;
                }}
                deleteServicio={async (row) => {
                    const success = await deleteServicio(row);
                    if (success) {
                        servicioTableRef.current?.refresh();
                    }
                    return success;
                }}
                addServicio={() => {
                    servicioFormRef.current?.open({
                        params: { title: 'Agregar servicio', titleIcon: 'AddIcon' },
                        actions: [{ on: 'AcceptButton', click: () => servicioTableRef.current?.refresh() }],
                    });
                }}
            />
        </div>
    );
};

export const ServiciosSection = PageComponent;
