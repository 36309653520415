export const ENUM_CATEGORIA_SERVICIO = {
    GENERAL: {
        value: 'GENERAL',
        label: 'General',
    },
    LABORATORIO: {
        value: 'LABORATORIO',
        label: 'Laboratorio',
    },
    CURSO_CAPACITACION: {
        value: 'CURSO_CAPACITACION',
        label: 'Curso de capacitación',
    },
    PRESTAMO_MAQUINARIA: {
        value: 'PRESTAMO_MAQUINARIA',
        label: 'Préstamo de maquinara',
    },
};

export const ENUM_DEPARTAMENTO = {
    BN: { value: 'BN', label: 'BN - Beni' },
    CH: { value: 'CH', label: 'CH - Chuquisaca' },
    CB: { value: 'CB', label: 'CB - Cochabamba' },
    LP: { value: 'LP', label: 'LP - La Paz' },
    OR: { value: 'OR', label: 'OR - Oruro' },
    PN: { value: 'PN', label: 'PN - Pando' },
    PT: { value: 'PT', label: 'PT - Potosi' },
    SC: { value: 'SC', label: 'SC - Santa Cruz' },
    TJ: { value: 'TJ', label: 'TJ - Tarija' },
};

export const ENUM_GENERO = {
    MASCULINO: { value: 'MASCULINO', label: 'Hombre' },
    FEMENINO: { value: 'FEMENINO', label: 'Mujer' },
    NINGUNO: { value: 'NINGUNO', label: 'Ninguno' },
};

export const ENUM_TIPO_TRABAJADOR = {
    DEPENDIENTE: {
        value: 'DEPENDIENTE',
        label: 'Dependiente',
    },
    INDEPENDIENTE: {
        value: 'INDEPENDIENTE',
        label: 'Independiente',
    },
    APRENDIZ: {
        value: 'APRENDIZ',
        label: 'Aprendíz',
    },
    FAMILIAR: {
        value: 'FAMILIAR',
        label: 'Familiar',
    },
};

export const ENUM_SI_NO = {
    SI: { value: 'SI', label: 'Si' },
    NO: { value: 'NO', label: 'No' },
};
