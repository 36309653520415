import { Box, Typography } from '@material-ui/core';
import { DataTableRef, GetRows, UpdateParams } from 'components/core/DataTable';
import { DialogBox, DialogBoxRef } from 'components/core/DialogBox';
import { PageTitle } from 'components/core/PageTitle';
import { useNotify } from 'hooks';
import { ReactElement, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setBuzonEntrada } from 'redux/notification/notificationActions';
import { RootState, useStateSelector } from 'redux/reducers';
import { NotificationService } from 'services/notification';
import { NotificationTable, NotificationTableModel } from './components/tables/NotificationTable';

const PageComponent = (): ReactElement => {
    const notify = useNotify();

    const notificacionTableRef = useRef<DataTableRef>(null);
    const dialogRef = useRef<DialogBoxRef<unknown>>(null);
    const dispatch = useDispatch();
    const notificationState = useStateSelector((state: RootState) => state.notificationState);

    const getBuzon: GetRows<NotificationTableModel> = async (params: UpdateParams<NotificationTableModel>) => {
        const response = await NotificationService.getBuzonEntrada(params);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return {
            ...params,
            rows: response.data.rows,
            count: response.data.count,
        };
    };

    const marcarVisto = async (buzonNotificacionesId: string): Promise<boolean> => {
        const response = await NotificationService.marcarVisto(buzonNotificacionesId);
        if (response.error) {
            return false;
        }
        dispatch(setBuzonEntrada(notificationState.buzonEntrada - 1));
        return true;
    };

    const dialogContent = (row: NotificationTableModel) => {
        return (
            <Box p={3}>
                <Typography variant="body2" gutterBottom>
                    <strong>{row?.asunto}</strong>
                </Typography>
                <Typography variant="body2">{row?.contenido}</Typography>
            </Box>
        );
    };

    useEffect(() => {
        notificacionTableRef.current?.refresh();
    }, []);

    return (
        <div>
            <PageTitle value="Notificaciones" />

            <DialogBox
                ref={dialogRef}
                title="Notificación"
                titleIcon="NotificationsIcon"
                dialogContent={dialogContent}
                getData={async (data: NotificationTableModel) => data}
                actions={[
                    {
                        on: 'AcceptButton',
                        click: async (data: NotificationTableModel) => {
                            if (data.visto) return true;
                            return marcarVisto(data.id);
                        },
                    },
                ]}
            />
            <NotificationTable
                tableRef={notificacionTableRef}
                getRows={getBuzon}
                showNotification={(row: NotificationTableModel) => {
                    dialogRef.current?.open({
                        params: row,
                        actions: [
                            {
                                on: 'AcceptButton',
                                click: () => {
                                    notificacionTableRef.current?.refresh();
                                },
                            },
                        ],
                    });
                }}
            />
        </div>
    );
};

export const NotificationsPage = withRouter(PageComponent);
