import React, { ReactElement, useState, useEffect } from 'react';
import { useField, FormikValues, FormikProps } from 'formik';

// material-ui
import {
    makeStyles,
    Theme,
    FormControl,
    FormHelperText,
    FormControlLabel,
    FormLabel,
    Checkbox,
    FormGroup,
} from '@material-ui/core';
import clsx from 'clsx';

import { FormValue, SelectOption, OnChangeFunction } from '../FormDialog/Types';

const DEFAULT_INLINE_DISPLAY = false;

// const DEFAULT_VARIANT = 'filled';

type Props = {
    name: string;
    label: string;
    value?: string;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    formik?: FormikProps<FormikValues>;
    options: SelectOption[] | ((formValue: FormValue) => SelectOption[]);
    inlineDisplay?: boolean;
    fieldRequired?: string;
    onChange?: OnChangeFunction;
    variant?: 'filled' | 'standard' | 'outlined';
};

export const MyCheckboxGroup = (props: Props): ReactElement => {
    const { name, label, error, helperText, formik } = props;
    const [field, meta] = useField<string>(name);

    const classes = useStyles();
    const errorText = meta.error && meta.touched ? meta.error : '';

    const OPTIONS = typeof props.options === 'function' ? props.options(formik?.values as FormValue) : props.options;
    const INLINE_DISPLAY = typeof props.inlineDisplay === 'boolean' ? props.inlineDisplay : DEFAULT_INLINE_DISPLAY;
    const FIELD_REQUIRED = props.fieldRequired;
    const [DISABLED, setDisabled] = useState(false);
    const requiredValue = FIELD_REQUIRED ? formik?.values[FIELD_REQUIRED] : undefined;
    useEffect(() => {
        const disableByProp = typeof props.disabled !== 'undefined' ? props.disabled : false;
        let newFieldDisabled = disableByProp;
        if (!disableByProp && FIELD_REQUIRED) {
            newFieldDisabled =
                requiredValue === undefined ||
                requiredValue === '' ||
                requiredValue === null ||
                requiredValue === '[]' || // Para los archivos adjuntos (MyDropzone)
                requiredValue === '{}' || // Para los grupos de checkbox (MyCheckboxGroup)
                (Array.isArray(requiredValue) && requiredValue.length === 0);
        }
        setDisabled(newFieldDisabled);
    }, [props.disabled, FIELD_REQUIRED, requiredValue]);

    // controlled or uncontrolled
    const _val = typeof props.value !== 'undefined' ? props.value : field.value;
    let VALUE: { [key: string]: boolean } = {};
    try { VALUE = _val ? JSON.parse(_val || '{}') : {}; } catch(err) {console.log(err)}

    const handleChange = (option: SelectOption, checked: boolean) => {
        const newFieldValue = { ...VALUE, [option.value]: checked };
        const newValue = JSON.stringify(newFieldValue);
        if (props.onChange) props.onChange(newValue, formik);
        else formik?.setFieldValue(name, newValue);
    };

    return (
        <FormControl className={classes.formControl} component="fieldset" error={error || !!errorText}>
            <FormLabel component="legend">{label}</FormLabel>
            <FormGroup className={clsx(classes.formGroup, { [classes.inlineDisplay]: INLINE_DISPLAY })}>
                {OPTIONS.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        control={
                            <Checkbox
                                checked={VALUE[option.value] || false}
                                onChange={(event) => handleChange(option, event.target.checked)}
                                name={name}
                                disabled={DISABLED}
                                indeterminate={VALUE[option.value] === null}
                            />
                        }
                        label={option.label}
                    />
                ))}
            </FormGroup>
            <FormHelperText>{helperText || errorText}</FormHelperText>
        </FormControl>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        width: '100%',
    },
    formGroup: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    inlineDisplay: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    formControlLabel: {
        padding: theme.spacing(0, 5, 0, 0),
    },
}));
