import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter, Router } from 'react-router-dom';
import { NotistackProvider } from 'hooks/useNotify';

import { createTheme } from 'styles';
import { Routes, RUTAS } from 'routes';
import { closeLoader } from 'utils';
import { useMediaQuery } from '@material-ui/core';
import { RootState, useStateSelector } from 'redux/reducers';
import { CacheBuster } from 'CacheBuster';
import { useDispatch } from 'react-redux';
import { setVersionInfo } from 'redux/version/versionActions';
import { AuthService } from 'services/authentication';
import { closeMessage } from 'redux/notification/notificationActions';
import { createBrowserHistory } from "history";

export const App = (): ReactElement => {
    const dispatch = useDispatch();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const appConfig = useStateSelector((state: RootState) => state.configState);
    const history = createBrowserHistory({ basename: `${process.env.PUBLIC_URL}` });

    const theme = useMemo(() => {
        const themeMode =
            appConfig.theme.mode === 'automatic' ? (prefersDarkMode ? 'dark' : 'light') : appConfig.theme.mode;
        return createTheme(themeMode);
    }, [prefersDarkMode, appConfig]);

    const load = async () => {
        // await timer(3000);
        CacheBuster.getVersionInfo().then((result) => {
            dispatch(setVersionInfo(result));
        });

        if (AuthService.isAuthenticated()) {
            const localSession = AuthService.getSessionInfo();
            const serverSession = await AuthService.getSessionInfoFromServer();
            if (JSON.stringify(localSession) !== JSON.stringify(serverSession.data)) {
                await AuthService.logout();
                dispatch(closeMessage());
                window.location.href = RUTAS.login;
            }
        }

        return closeLoader();
    };

    // INI - loadCallback
    const loadCallback = useCallback(load, []);
    useEffect(() => {
        loadCallback();
    }, [loadCallback]);
    // END - loadCallback

    return (
        <ThemeProvider theme={theme}>
            <NotistackProvider>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <Router history={history}>
                        <Routes />
                    </Router>
                </BrowserRouter>
            </NotistackProvider>
        </ThemeProvider>
    );
};
