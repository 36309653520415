import { Box, Typography } from '@material-ui/core';
import { ROLES } from 'base/authBase';
import { ActionColumn, ActionItem } from 'components/core/Columns';
import { GetRows, DataTable, DataTableRef, TableHeader } from 'components/core/DataTable';
import { useSession } from 'hooks';
import { ReactElement, RefObject } from 'react';

type TableModel = Digital.DigitalTableModel;

type Props = {
    tableRef: RefObject<DataTableRef>;
    modoCreacion?: boolean;
    modoAprobacion?: boolean;
    getRows: GetRows<TableModel>;
    editSolicitudServicio: (row: TableModel) => Promise<boolean>;
    deleteSolicitudServicio: (row: TableModel) => Promise<boolean>;
    addSolicitudServicio: () => void;
};

export const TableComponent = (props: Props): ReactElement => {
    const { tableRef, getRows, editSolicitudServicio, deleteSolicitudServicio, addSolicitudServicio } = props;

    const session = useSession();
    const esDigitalizador = session.roles.some((rol) => rol.id === ROLES.digitalizador.id);

    const headersCreacion: TableHeader<TableModel>[] = [];
    if (esDigitalizador) {
        headersCreacion.push({ id: 'actions', label: 'Acciones', render: renderColumnActions, width: 130 });
    }

    const tableHeaders: TableHeader<TableModel>[] = [
        { id: 'nro', label: 'Nro.', width: 80 },
        { id: 'solicitante', label: 'Solicitante', align: 'left' },
        { id: 'regional', label: 'Regional', align: 'left' },
        { id: 'rubro', label: 'Rubro', align: 'left' },
        { id: 'servicio', label: 'Servicio', align: 'left' },
        { id: 'fecha_solicitud', label: 'Fecha de solicitud', align: 'left' },
        ...headersCreacion,
    ];

    function renderColumnActions(data: TableModel): ReactElement {
        const confirmMessage = (
            <>
                ¿Desea eliminar la solicitud de servicio?
                <br />
                <Box my={3}>
                    <Typography align="center">Solicitante:</Typography>
                    <Typography align="center">
                        <strong>{data.solicitante}</strong>
                    </Typography>
                </Box>
            </>
        );
        const items: ActionItem[] = [
            {
                title: 'Editar',
                icon: 'EditIcon',
                color: 'primary',
                onClick: async () => editSolicitudServicio(data),
            },
            {
                title: 'Eliminar',
                icon: 'DeleteIcon',
                color: 'secondary',
                onClick: async () => deleteSolicitudServicio(data),
                confirm: true,
                confirmMessage,
            },
        ];
        return <ActionColumn items={items} size="small" />;
    }

    return (
        <DataTable
            title="Solicitudes de Servicio"
            ref={tableRef}
            headers={tableHeaders}
            getRows={getRows}
            addLabel="Nueva solicitud"
            onActionAddClick={esDigitalizador ? addSolicitudServicio : undefined}
            hideFilters
        />
    );
};

export const DigitalTable = TableComponent;
