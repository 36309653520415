import React, { ReactElement } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Badge, IconButton, ListItemText } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getIcon } from 'helpers';
import { useHistory } from 'react-router';

type Props = {
    items: MenuListModel;
};

export const ConfigMenu = (props: Props): ReactElement => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClickItem = (item: MenuItemModel): void => {
        setAnchorEl(null);
        if (item.onClick) return item.onClick();
        if (item.path) return history.push(item.path);
        return;
    };

    return (
        <>
            <IconButton onClick={handleClickMenu} style={{ color: '#fff' }}>
                <MoreVertIcon color="inherit" />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                variant="selectedMenu"
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                {props.items.map((item, index) => {
                    return (
                        <MenuItem key={index} onClick={() => handleClickItem(item)}>
                            <ListItemIcon>
                                {item.badge ? (
                                    <Badge badgeContent={item.badge} color="error">
                                        {getIcon(item.icon)}
                                    </Badge>
                                ) : (
                                    getIcon(item.icon)
                                )}
                            </ListItemIcon>
                            <ListItemText>{item.label}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};
