export const ROLES = {
    admin: {
        id: 'ead9d636-e7be-40f3-afe8-e2ff25608960',
        nombre: 'Administrador',
    },
    coordinador: {
        id: 'aa301e7a-c9bf-4300-8ffa-037df9793f77',
        nombre: 'Coordinador',
    },
    tecnicooperador: {
        id: 'aa301e7a-c9bf-4300-8ffa-037df9793f78',
        nombre: 'Técnico Operador',
    },
    tecnicolaboratorio: {
        id: 'aa301e7a-c9bf-4300-8ffa-037df9793f82',
        nombre: 'Técnico Laboratorio',
    },
    plataforma: {
        id: 'aa301e7a-c9bf-4300-8ffa-037df9793f79',
        nombre: 'Plataforma',
    },
    almacen: {
        id: 'aa301e7a-c9bf-4300-8ffa-037df9793f80',
        nombre: 'Almacén',
    },
    administracion: {
        id: 'aa301e7a-c9bf-4300-8ffa-037df9793f81',
        nombre: 'Administración',
    },
    digitalizador: {
        id: 'aa301e7a-c9bf-4300-8ffa-037df9793f83',
        nombre: 'Digitalizador',
    },
};

export const REGIONALES = {
    laPaz: {
        id: '86db0297-aba1-4578-8a47-1e3240935ec1',
        ciudad: 'La Paz',
    },
    cochabamba: {
        id: '86db0297-aba1-4578-8a47-1e3240935ec2',
        ciudad: 'Cochabamba',
    },
    potosi: {
        id: '86db0297-aba1-4578-8a47-1e3240935ec3',
        ciudad: 'Potosí',
    },
};

export const RUBROS = {
    textil: {
        id: '86db0297-aba1-4578-8a47-1e3240935e01',
        nombre: 'Textil',
    },
    madera: {
        id: '86db0297-aba1-4578-8a47-1e3240935e02',
        nombre: 'Madera',
    },
    cuero: {
        id: '86db0297-aba1-4578-8a47-1e3240935e03',
        nombre: 'Cuero',
    },
};
