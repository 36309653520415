import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { useField, FormikProps, FormikValues } from 'formik';

// material-ui
import { makeStyles, Theme, FormControl, FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { parse as dateParse } from 'date-fns';
import { OnChangeFunction } from '../FormDialog/Types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useDebouncedCallback } from 'use-debounce/lib';

// tiempo en milisegundos para que se guarden los datos
const DEFAULT_DELAY = 500;

const DEFAULT_FORMAT = 'HH:mm';
const DEFAULT_AMPM = false;
const DEFAULT_VARIANT = 'filled';

type Props = {
    name: string;
    label: string;
    value?: string;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    formik?: FormikProps<FormikValues>;
    format?: string;
    ampm?: boolean;
    fieldRequired?: string;
    variant?: 'filled' | 'standard' | 'outlined';
    onChange?: OnChangeFunction;
    delay?: number;
};

export const MyTimePicker = (props: Props): ReactElement => {
    const { name, label, error, helperText, formik, delay } = props;
    const [field, meta] = useField<string>(name);

    const classes = useStyles();
    const errorText = meta.error && meta.touched ? meta.error : '';

    const AMPM = typeof props.ampm === 'boolean' ? props.ampm : DEFAULT_AMPM;
    const FORMAT = props.format || DEFAULT_FORMAT;
    const VARIANT = props.variant  || DEFAULT_VARIANT;
    const DELAY = delay || DEFAULT_DELAY;

    const FIELD_REQUIRED = props.fieldRequired;
    const [DISABLED, setDisabled] = useState(false);
    const requiredValue = FIELD_REQUIRED ? formik?.values[FIELD_REQUIRED] : undefined;
    useEffect(() => {
        const disableByProp = typeof props.disabled !== 'undefined' ? props.disabled : false;
        let newFieldDisabled = disableByProp;
        if (!disableByProp && FIELD_REQUIRED) {
            newFieldDisabled =
                requiredValue === undefined ||
                requiredValue === '' ||
                requiredValue === null ||
                requiredValue === '[]' || // Para los archivos adjuntos (MyDropzone)
                requiredValue === '{}' || // Para los grupos de checkbox (MyCheckboxGroup)
                (Array.isArray(requiredValue) && requiredValue.length === 0);
        }
        setDisabled(newFieldDisabled);
    }, [props.disabled, FIELD_REQUIRED, requiredValue]);

    const handleChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
        const newValue = value as string;

        if (props.onChange) return props.onChange(newValue, formik);
        formik?.setFieldValue(name, newValue);
    };

    // controlled or uncontrolled
    const VALUE = typeof props.value !== 'undefined' ? props.value : field.value;
    const [innerValue, setInnerValue] = useState<Date | null>(VALUE ? dateParse(VALUE, FORMAT, new Date()) : null);

    const debounced = useDebouncedCallback((date: MaterialUiPickersDate, value?: string | null | undefined) => {
        handleChange(date, value);
    }, DELAY);

    const handleOnChange = useCallback(
        (date: MaterialUiPickersDate, value?: string | null | undefined) => {
            debounced(date, value);
        },
        [debounced],
    );

    useEffect(() => {
        setInnerValue(VALUE ? dateParse(VALUE, FORMAT, new Date()) : null);
    }, [VALUE, FORMAT]);

    return (
        <FormControl className={classes.formControl} variant={VARIANT} error={error || !!errorText}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardTimePicker
                    variant="inline"
                    className={classes.input}
                    id={name}
                    label={label}
                    autoOk
                    ampm={AMPM}
                    format={FORMAT}
                    value={delay === 0 ? VALUE : innerValue}
                    disabled={DISABLED}
                    inputVariant={VARIANT}
                    error={error || !!errorText}
                    onChange={delay === 0 ? handleChange : handleOnChange}
                    helperText={''}
                />
                <FormHelperText>{helperText || errorText}</FormHelperText>
            </MuiPickersUtilsProvider>
        </FormControl>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        width: '100%',
    },
    input: {
        margin: theme.spacing(0),
    },
}));
