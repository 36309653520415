import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams, SelectOption } from 'components/core/FormDialog/Types';
import { ReactElement, RefObject } from 'react';
import * as yup from 'yup';

type FormModel = Reclamos.DerivarFormModel;
type FormParams = Reclamos.DerivarFormParams;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    params: FormParams;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
};

const FormDialogComponent = ({ dialogRef, params, getData, submit }: Props): ReactElement => {
    const paraOptions: SelectOption[] = params.usuarios.map((u) => {
        return {
            label: u.nombre_completo,
            caption: u.cargo,
            value: u.id,
        };
    });

    const formLayout: FormGroup<FormModel>[] = [
        {
            title: 'Destinatario',
            grid: [[{ name: 'para', label: 'Para:', type: 'autocomplete', options: paraOptions }]],
        },
    ];

    const validationSchema = yup.object({
        para: yup.string().required(),
    });

    return (
        <FormDialog
            ref={dialogRef}
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            validationSchema={validationSchema}
            // debug
        />
    );
};

export const DerivarFormDialog = FormDialogComponent;
