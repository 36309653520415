import { DialogBoxRef } from 'components/core/DialogBox';
import { InitialParams } from 'components/core/FormDialog/Types';
import { GetRows, DataTableRef, UpdateParams } from 'components/core/DataTable/Types';
import { useNotify } from 'hooks';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { CostoServiciosService } from 'modules/costoServicios';
import { ParametroFormDialog } from './ParametroFormDialog';
import { ParametroTable } from './ParametroTable';

type ParametroFormModel = Costo.ParametroFormModel;
type ParametroFormParams = Costo.ParametroFormParams;
type ParametroTableModel = Costo.ParametroTableModel;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const ParametroFormRef = useRef<DialogBoxRef<InitialParams>>(null);
    const ParametroTableRef = useRef<DataTableRef>(null);
    const [formParams, setFormParams] = useState<ParametroFormParams>({ servicios: [] });

    const getParametros: GetRows<ParametroTableModel> = async (params: UpdateParams<ParametroTableModel>) => {
        const response = await CostoServiciosService.getParametros(params);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        response.data.rows.forEach((r, index) => (r.nro = r.nro || index + 1 + params.rowsPerPage * (params.page - 1)));
        return {
            ...params,
            rows: response.data.rows,
            count: response.data.count,
        };
    };

    const deleteParametro = async (data: ParametroTableModel): Promise<boolean> => {
        const response = await CostoServiciosService.deleteParametro(data.id);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        return true;
    };

    const getData = async (params?: InitialParams): Promise<ParametroFormModel | undefined> => {
        const response = await CostoServiciosService.getParametroForm(params?.id);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };

    const submit = async (data: ParametroFormModel): Promise<boolean> => {
        data.precio_parametro = parseFloat(`${data.precio_parametro}`);
        const response = await CostoServiciosService.createOrUpdateParametro(data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Tarea completada exitosamente');
        return true;
    };

    const getFormParams = async (): Promise<ParametroFormParams | undefined> => {
        const response = await CostoServiciosService.getParametroFormParams();
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };
    const getFormParamsCallback = useCallback(getFormParams, []);

    useEffect(() => {
        ParametroTableRef.current?.refresh();
        getFormParamsCallback().then((result) => {
            if (result) setFormParams(result);
        });
    }, []);

    return (
        <div>
            <ParametroFormDialog dialogRef={ParametroFormRef} getData={getData} submit={submit} params={formParams} />
            <ParametroTable
                tableRef={ParametroTableRef}
                getRows={getParametros}
                editParametro={async (data: ParametroTableModel) => {
                    ParametroFormRef.current?.open({
                        params: { id: data.id, title: 'Editar parámetro', titleIcon: 'EditIcon' },
                        actions: [{ on: 'AcceptButton', click: () => ParametroTableRef.current?.refresh() }],
                    });
                    return true;
                }}
                deleteParametro={async (row) => {
                    const success = await deleteParametro(row);
                    if (success) {
                        ParametroTableRef.current?.refresh();
                    }
                    return success;
                }}
                onAddParametro={() => {
                    ParametroFormRef.current?.open({
                        params: { title: 'Agregar parámetro', titleIcon: 'AddIcon' },
                        actions: [{ on: 'AcceptButton', click: () => ParametroTableRef.current?.refresh() }],
                    });
                }}
            />
        </div>
    );
};

export const ParametrosSection = PageComponent;
