import { Box, Card, CardContent, makeStyles, Theme, Typography } from '@material-ui/core';
import { ReactElement } from 'react';

type UsuarioTableModel = Usuario.UsuarioTableModel;

type Props = {
    data: UsuarioTableModel;
    renderActions: (data: UsuarioTableModel) => ReactElement;
};

const MyComponent = ({ data, renderActions }: Props): ReactElement => {
    const classes = useStyles();

    return (
        <Card className={classes.root} elevation={3}>
            <CardContent className={classes.cardContent}>
                <Box>
                    <Typography variant="body1" display="block">
                        <strong>{data.nombre_completo}</strong>
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                        {data.username}
                    </Typography>
                    <Typography variant="caption" display="block">
                        <i>{data.roles.join(', ')}</i>
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography variant="caption" display="block">
                            {data.estado}
                        </Typography>
                    </Box>
                    <Box />
                    <Box display="flex">{renderActions(data)}</Box>
                </Box>
            </CardContent>
        </Card>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    cardContent: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&:last-child': {
            paddingBottom: theme.spacing(1),
        },
    },
    statusBox: {
        padding: theme.spacing(0.5),
        borderRadius: theme.spacing(0.5),
        cursor: 'pointer',
    },
}));

export const UsuarioTableItem = MyComponent;
