import compareVersions from 'compare-versions';
import packageJson from '../package.json';

export type VersionInfo = {
    version: string;
    date: string;
    latestVersion: string;
    buildVersion: string;
    isLatestVersion: boolean;
};

export const DEFAULT_VERSION_INFO: VersionInfo = {
    version: '0.0.0',
    date: new Date('2000-01-01').toISOString(),
    isLatestVersion: true,
    latestVersion: '0.0.0',
    buildVersion: '0.0.0',
};

export class CacheBuster {
    static async getVersionInfo(): Promise<VersionInfo> {
        const versionInfo: VersionInfo = DEFAULT_VERSION_INFO;
        await fetch(`${process.env.PUBLIC_URL}/meta.json`, { cache: 'no-cache' })
            .then((response) => response.json())
            .then((meta: VersionInfo) => {
                const latestVersion = meta.version;
                const buildVersion = packageJson.version;
                versionInfo.latestVersion = latestVersion;
                versionInfo.buildVersion = buildVersion;
                versionInfo.version = meta.version;
                versionInfo.date = new Date(meta.date).toISOString();
                versionInfo.isLatestVersion = !compareVersions.compare(latestVersion, buildVersion, '>');
            })
            .catch(() => ({}));
        return versionInfo;
    }

    static async isLatestVersion(): Promise<boolean> {
        return (await CacheBuster.getVersionInfo()).isLatestVersion;
    }

    static async updateToLastVersion(force = false): Promise<boolean> {
        const isLatestVersion = (await CacheBuster.getVersionInfo()).isLatestVersion;
        if (isLatestVersion && !force) {
            return false;
        }

        // Remueve los serviceworkers registrados
        const serviceWorkersToRemove: Promise<boolean>[] = [];
        await navigator.serviceWorker?.getRegistrations().then((registrations) => {
            for (const registration of registrations) {
                serviceWorkersToRemove.push(registration.unregister());
            }
        });
        for (const i in serviceWorkersToRemove) {
            await serviceWorkersToRemove[i].catch(() => ({}));
        }

        // Elimina la cache
        const cachesToRemove: Promise<boolean>[] = [];
        await window.caches?.keys().then((keys) => {
            for (const key of keys) {
                cachesToRemove.push(window.caches.delete(key));
            }
        });
        for (const i in cachesToRemove) {
            await cachesToRemove[i].catch(() => ({}));
        }

        // Refresca la ventana
        window.location.reload();
        return true;
    }
}
