/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from '@material-ui/core';
import { ENUM_CATEGORIA_SERVICIO } from 'base/enums';
import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams, SelectOption, FormRow } from 'components/core/FormDialog/Types';
import { dateTest, isEmailValidTest, isNumberValidTest } from 'components/core/FormDialog/yup-tests';
import { FormikProps } from 'formik';
import { ReactElement, RefObject } from 'react';
import { getFullName } from 'utils';
import * as yup from 'yup';
import { EditTable } from '../../EditTable';
import { ENUM_GENERO, ENUM_SI_NO, ENUM_TIPO_TRABAJADOR } from 'base/enums';

type FormModel = Solicitud.SolicitudServicioFormModel;
type FormParams = Solicitud.SolicitudServicioFormParams;
type EditParams = Solicitud.EditParams;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
    params: FormParams;
};

const FormDialogComponent = ({ dialogRef, getData, submit, params }: Props): ReactElement => {
    const solicitanteOptions: SelectOption[] = params.solicitantes.map((sol) => ({
        value: sol.id,
        label: getFullName(sol.nombre, sol.apellido_paterno, sol.apellido_materno),
        caption: sol.cedula_identidad,
    }));

    const unidadProductivaOptions: SelectOption[] = params.unidades_productivas.map((up) => ({
        value: up.id,
        label: up.nombre_razon_social,
        caption: up.numero_certificado,
    }));

    const formLayout = (formValue: FormModel, formik: FormikProps<FormModel>) => {
        if (Object.keys(formValue).length === 0) return [];

        const rubroUPOptions: SelectOption[] = params.rubros_up.map((rubro) => ({
            value: rubro.id,
            label: rubro.nombre,
        }));

        const rubroOptions: SelectOption[] = params.rubros.map((rubro) => ({
            value: rubro.id,
            label: rubro.nombre,
        }));

        const subtipoRubroOptions = (formValue: FormModel): SelectOption[] => {
            return params.subtipos_rubro
                .filter((sr) => sr.fid_rubro === formValue.up_rubro)
                .map((rubro) => ({
                    value: rubro.id,
                    label: rubro.nombre,
                }));
        };

        const generoOptions: SelectOption[] = Object.keys(ENUM_GENERO)
            .map((key) => ({
                value: (ENUM_GENERO as EnumValue)[key].value,
                label: (ENUM_GENERO as EnumValue)[key].label,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

        const tipoTrabajadorOptions: SelectOption[] = Object.keys(ENUM_TIPO_TRABAJADOR)
            .map((key) => ({
                value: (ENUM_TIPO_TRABAJADOR as EnumValue)[key].value,
                label: (ENUM_TIPO_TRABAJADOR as EnumValue)[key].label,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

        const EsDuenioOptions: SelectOption[] = Object.keys(ENUM_SI_NO)
            .map((key) => ({
                value: (ENUM_SI_NO as EnumValue)[key].value,
                label: (ENUM_SI_NO as EnumValue)[key].label,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

        const rubroSelect = params.rubros.find((rubro) => rubro.id === formValue.fid_rubro);
        const materialTableParams: EditParams = {
            columnas: rubroSelect?.material_columns || [],
            valores: formValue.material_items,
        };

        const tipoMaterialOptions: SelectOption[] =
            params.rubros
                .find((rubro) => rubro.id === formValue.fid_rubro)
                ?.tipo_material?.map((tipo) => {
                    return {
                        value: tipo.id,
                        label: tipo.name,
                    };
                }) || [];

        const tipoServicioOptions: SelectOption[] = params.servicios
            .filter((serv) => serv.fid_rubro === formValue.fid_rubro)
            .map((serv) => {
                return {
                    value: serv.id,
                    label: serv.nombre,
                    caption: serv.codigo_servicio,
                };
            });

        const servicioParametroOptions: SelectOption[] = params.parametros
            .filter((param) => param.fid_servicio === formValue.fid_servicio)
            .map((param) => {
                return {
                    value: param.id,
                    label: param.parametro,
                };
            });

        const reservaMaquinariaOptions: SelectOption[] = params.maquinarias
            .filter((maq) => maq.fid_rubro === formValue.fid_rubro)
            .map((maq) => {
                return {
                    value: maq.id,
                    label: maq.nombre,
                };
            });

        const reservaTableParams: EditParams = {
            columnas: [
                { id: 'fecha', name: 'Fecha', type: 'date' },
                {
                    id: 'fid_maquinaria',
                    name: 'Maquinaria/Herramienta',
                    type: 'select',
                    options: reservaMaquinariaOptions,
                },
                { id: 'hora_inicio', name: 'Hora inicio', type: 'time' },
                { id: 'hora_fin', name: 'Hora fin', type: 'time' },
                { id: 'observacion', name: 'Observación', type: 'text' },
            ],
            valores: formik.values.reserva_items,
        };

        const datosEncabezado: FormRow<FormModel>[] = [
            [
                {
                    type: 'empty',
                    render: () => {
                        return (
                            <>
                                <Typography>Responsable Pro Bolivia</Typography>
                                <Typography>
                                    <strong>{formValue.responsable_probolivia}</strong>
                                </Typography>
                            </>
                        );
                    },
                },
                {
                    type: 'empty',
                    render: () => {
                        return (
                            <>
                                <Typography align="right">Fecha de solicitud de servicio</Typography>
                                <Typography align="right">
                                    <strong>{formValue.fecha_solicitud}</strong>
                                </Typography>
                            </>
                        );
                    },
                },
            ],
        ];

        const formularioSolicitante: FormRow<FormModel>[] = [
            [
                {
                    name: 'fid_solicitante',
                    label: 'Buscar Solicitante ...',
                    type: 'autocomplete',
                    options: solicitanteOptions,
                    onChange: (value) => {
                        formik.setFieldValue('fid_solicitante', value);
                        if (value) {
                            const solicitante = params.solicitantes.find((sol) => sol.id === value);
                            if (!solicitante) return;
                            formik.setFieldValue('solicitante_nombre', solicitante.nombre);
                            formik.setFieldValue('solicitante_apellido_paterno', solicitante.apellido_paterno);
                            formik.setFieldValue('solicitante_apellido_materno', solicitante.apellido_materno);
                            formik.setFieldValue('solicitante_sexo', solicitante.sexo);
                            formik.setFieldValue('solicitante_cedula_identidad', solicitante.cedula_identidad);
                            formik.setFieldValue('solicitante_telefono', solicitante.telefono);
                            formik.setFieldValue('solicitante_email', solicitante.email);
                            formik.setFieldValue('solicitante_direccion', solicitante.direccion);
                            formik.setFieldValue('solicitante_tipo_trabajador', solicitante.tipo_trabajador);
                            formik.setFieldValue('solicitante_es_duenio_up', solicitante.es_duenio_up);
                            if (solicitante.fid_unidad_productiva) {
                                const up = params.unidades_productivas.find(
                                    (up) => up.id === solicitante.fid_unidad_productiva,
                                );
                                if (!up) return;
                                formik.setFieldValue('up_rubro', up.fid_rubro);
                                formik.setFieldValue('up_razon_social', up.nombre_razon_social);
                                formik.setFieldValue('up_direccion', up.direccion);
                                formik.setFieldValue('up_antiguedad_anios', up.antiguedad_anios);
                                formik.setFieldValue('up_antiguedad_meses', up.antiguedad_meses);
                                formik.setFieldValue('up_numero_certificado', up.numero_certificado);
                                formik.setFieldValue('up_subtipo_rubro', up.fid_subtipo_rubro);
                                formik.setFieldValue('up_producto', up.producto);
                                formik.setFieldValue('up_asociacion_gremio', up.asociacion_gremio);
                                formik.setFieldValue('fid_unidad_productiva', up.id);
                            }
                        }
                    },
                },
                { type: 'empty' },
            ],
            [
                { name: 'solicitante_nombre', label: 'Nombre', type: 'text', delay: 500 },
                { name: 'solicitante_apellido_paterno', label: 'Apellido Paterno', type: 'text', delay: 500 },
                { name: 'solicitante_apellido_materno', label: 'Apellido Materno', type: 'text', delay: 500 },
            ],
            [
                { name: 'solicitante_cedula_identidad', label: 'Cédula de identidad', type: 'text', delay: 500 },
                { name: 'solicitante_sexo', label: 'Sexo', type: 'select', options: generoOptions },
            ],
            [
                { name: 'solicitante_direccion', label: 'Dirección', type: 'text', delay: 500 },
                { name: 'solicitante_telefono', label: 'Teléfono', type: 'text', delay: 500 },
                { name: 'solicitante_email', label: 'Correo electrónico', type: 'text', delay: 500 },
            ],
            [
                {
                    name: 'solicitante_es_duenio_up',
                    label: '¿Es dueño de la UP?',
                    type: 'radio-group',
                    inlineDisplay: true,
                    options: EsDuenioOptions,
                },
                {
                    name: 'solicitante_tipo_trabajador',
                    label: 'Tipo de trabajador',
                    type: 'select',
                    options: tipoTrabajadorOptions,
                    delay: 500,
                },
            ],
        ];

        const formularioUnidadProductiva: FormRow<FormModel>[] = [
            [
                {
                    name: 'fid_unidad_productiva',
                    label: 'Buscar Unidad Productiva ...',
                    type: 'autocomplete',
                    options: unidadProductivaOptions,
                    onChange: (value) => {
                        formik.setFieldValue('fid_unidad_productiva', value);
                        if (value) {
                            const up = params.unidades_productivas.find((up) => up.id === value);
                            if (!up) return;
                            formik.setFieldValue('up_rubro', up.fid_rubro);
                            formik.setFieldValue('up_razon_social', up.nombre_razon_social);
                            formik.setFieldValue('up_direccion', up.direccion);
                            formik.setFieldValue('up_antiguedad_anios', up.antiguedad_anios);
                            formik.setFieldValue('up_antiguedad_meses', up.antiguedad_meses);
                            formik.setFieldValue('up_numero_certificado', up.numero_certificado);
                            formik.setFieldValue('up_subtipo_rubro', up.fid_subtipo_rubro);
                            formik.setFieldValue('up_asociacion_gremio', up.asociacion_gremio);
                            formik.setFieldValue('up_producto', up.producto);
                        }
                    },
                },
                { type: 'empty' },
            ],
            [
                { name: 'up_razon_social', label: 'Razón Social', type: 'text', delay: 500 },
                { name: 'up_numero_certificado', label: 'Número de certificado', type: 'text', delay: 500 },
            ],
            [
                { name: 'up_direccion', label: 'Dirección', type: 'text', delay: 500 },
                { name: 'up_asociacion_gremio', label: 'Asociación/Gremio', type: 'text', delay: 500 },
            ],
            [
                {
                    name: 'up_rubro',
                    label: 'Rubro',
                    type: 'select',
                    options: rubroUPOptions,
                    onChange: (value, formik) => {
                        formik.setFieldValue('up_rubro', value);
                        formik.setFieldValue('up_subtipo_rubro', '');
                    },
                },
                {
                    name: 'up_subtipo_rubro',
                    label: 'Rubro específico',
                    type: 'select',
                    fieldRequired: 'up_rubro',
                    options: subtipoRubroOptions,
                },
                {
                    name: 'up_producto',
                    label: 'Producto principal',
                    type: 'text',
                },
            ],
            [
                {
                    name: 'up_antiguedad_anios',
                    label: 'Antigüedad (años)',
                    type: 'text',
                    inputType: 'number',
                    delay: 500,
                },
                {
                    name: 'up_antiguedad_meses',
                    label: 'Antigüedad (meses)',
                    type: 'text',
                    inputType: 'number',
                    delay: 500,
                },
            ],
        ];

        const materialOptionsVisible = tipoMaterialOptions.length > 0;
        const formularioMaterialEntrada: FormRow<FormModel>[] = [];
        if (materialOptionsVisible) {
            formularioMaterialEntrada.push([
                {
                    name: 'material_tipo',
                    label: 'Tipo de material',
                    type: 'radio-group',
                    inlineDisplay: true,
                    options: tipoMaterialOptions,
                    onChange: (value) => {
                        formik.setFieldValue('material_tipo', value);
                        formik.setFieldValue('material_tipo_otro', '');
                    },
                },
            ]);
        }
        formularioMaterialEntrada.push([
            {
                name: 'material_tipo_otro',
                label: 'Tipo de material (Otro)',
                type: formik.values.material_tipo.toUpperCase() === 'OTRO' ? 'text' : 'empty',
            },
            {
                type: 'empty',
            },
        ]);

        formularioMaterialEntrada.push([
            {
                type: 'empty',
                render: () => {
                    return (
                        <Box width="100%" overflow="auto">
                            <EditTable
                                params={materialTableParams}
                                onChange={(datos) => {
                                    formik.setFieldValue('material_items', datos.valores);
                                }}
                            />
                        </Box>
                    );
                },
            },
        ]);

        const formularioServicioRequerido: FormRow<FormModel>[] = [
            [
                {
                    name: 'fid_rubro',
                    label: 'Rubro',
                    type: 'autocomplete',
                    options: rubroOptions,
                    onChange: (value) => {
                        formik.setFieldValue('fid_rubro', value);
                        formik.setFieldValue('fid_servicio', '');
                        formik.setFieldValue('material_tipo', '');
                        formik.setFieldValue('material_tipo_otro', '');
                        formik.setFieldValue('material_items', []);
                    },
                },
                {
                    name: 'fid_servicio',
                    label: 'Tipo de servicio',
                    type: 'autocomplete',
                    fieldRequired: 'fid_rubro',
                    options: tipoServicioOptions,
                    onChange: (value) => {
                        formik.setFieldValue('fid_servicio', value);
                        formik.setFieldValue('material_tipo', '');
                        formik.setFieldValue('material_tipo_otro', '');
                        formik.setFieldValue('material_items', []);
                    },
                },
            ],
            [
                {
                    name: 'servicio_parametros',
                    label: 'Parámetros',
                    type: 'multiselect',
                    fieldRequired: 'fid_servicio',
                    options: servicioParametroOptions,
                    onChange: (value) => {
                        formik.setFieldValue('servicio_parametros', value);
                    },
                },
                {
                    name: 'fecha_entrega',
                    label: 'Fecha estimada de entrega',
                    type: 'date',
                    disablePast: true,
                },
            ],
        ];

        const formularioReservaMaquinaria: FormRow<FormModel>[] = [
            [
                {
                    type: 'empty',
                    render: () => {
                        return (
                            <Box width="100%" overflow="auto">
                                <EditTable
                                    params={reservaTableParams}
                                    onChange={(datos) => {
                                        formik.setFieldValue('reserva_items', datos.valores);
                                    }}
                                />
                            </Box>
                        );
                    },
                },
            ],
        ];

        const formGroup: FormGroup<FormModel>[] = [
            {
                title: '',
                grid: [...datosEncabezado],
            },
            {
                title: '1. DATOS GENERALES DEL SOLICITANTE',
                grid: [...formularioSolicitante],
            },
            {
                title: '2. DATOS DE LA UNIDAD PRODUCTIVA',
                grid: [...formularioUnidadProductiva],
            },
            {
                title: '3. SERVICIO REQUERIDO',
                grid: [...formularioServicioRequerido],
            },
            {
                title: '4. MATERIAL DE ENTRADA',
                grid: [...formularioMaterialEntrada],
            },
        ];

        const servicioSeleccionado = params.servicios.find((serv) => serv.id === formValue.fid_servicio);
        if (servicioSeleccionado?.categoria === ENUM_CATEGORIA_SERVICIO.PRESTAMO_MAQUINARIA.value) {
            formGroup.push({
                title: 'RESERVA DE MAQUINARIA',
                grid: [...formularioReservaMaquinaria],
            });
        }

        return formGroup;
    };

    const DATE_FORMAT = 'dd/MM/yyyy';
    const validationSchema = yup.object({
        solicitante_nombre: yup.string().required(),
        solicitante_sexo: yup.string().required(),
        solicitante_cedula_identidad: yup.string().required(),
        solicitante_email: yup.string().required().test(isEmailValidTest()),
        solicitante_direccion: yup.string().required(),
        solicitante_telefono: yup.string().required(),
        solicitante_tipo_trabajador: yup.string().nullable(),
        solicitante_es_duenio_up: yup.string().required(),

        // unidad productiva
        up_razon_social: yup.string().nullable(),
        up_direccion: yup.string().nullable(),
        up_rubro: yup.string().nullable(),
        up_subtipo_rubro: yup.string().nullable(),
        up_antiguedad_anios: yup.string().test(isNumberValidTest(0)),
        up_antiguedad_meses: yup.string().test(isNumberValidTest(0, 11)),

        // material
        material_tipo: yup.string().nullable(),
        material_tipo_otro: yup.string().nullable(),

        // servicio requerido
        fid_rubro: yup.string().required(),
        fid_servicio: yup.string().required(),
        servicio_parametros: yup.array().of(yup.string()).min(1, 'Debe seleccionar al menos 1 item'),
        fecha_entrega: yup.string().required().test(dateTest(DATE_FORMAT)),
    });

    return (
        <FormDialog
            ref={dialogRef}
            variant="page"
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            validationSchema={validationSchema}
            // debug
        />
    );
};

export const SolicitudServicioFormDialog = FormDialogComponent;
