import { ActionColumn, ActionItem } from 'components/core/Columns';
import { GetRows, DataTable, DataTableRef, TableHeader } from 'components/core/DataTable';
import { ReactElement, RefObject } from 'react';

type TableModel = Solicitud.UnidadProductivaTableModel;

type Props = {
    tableRef: RefObject<DataTableRef>;
    getRows: GetRows<TableModel>;
    editUnidadProductiva: (row: TableModel) => Promise<boolean>;
    deleteUnidadProductiva: (row: TableModel) => Promise<boolean>;
    onAddUnidadProductiva: () => void;
};

export const TableComponent = (props: Props): ReactElement => {
    const { tableRef, getRows, editUnidadProductiva, deleteUnidadProductiva, onAddUnidadProductiva } = props;
    const tableHeaders: TableHeader<TableModel>[] = [
        { id: 'nro', label: 'Nro.', width: 80 },
        { id: 'nombre_razon_social', label: 'Razón Social', align: 'left' },
        { id: 'numero_certificado', label: 'Nro de certificado', align: 'left' },
        { id: 'rubro', label: 'Rubro', align: 'left' },
        { id: 'actions', label: 'Acciones', render: renderColumnActions, width: 130 },
    ];

    function renderColumnActions(data: TableModel): ReactElement {
        const confirmMessage = (
            <>
                ¿Desea eliminar la unidad productiva <strong>{data.nombre_razon_social}</strong>?
            </>
        );
        const items: ActionItem[] = [
            { icon: 'EditIcon', onClick: async () => editUnidadProductiva(data) },
            {
                icon: 'DeleteIcon',
                color: 'secondary',
                onClick: async () => deleteUnidadProductiva(data),
                confirm: true,
                confirmMessage,
            },
        ];
        return <ActionColumn items={items} size="small" />;
    }

    return (
        <DataTable
            title="Unidades Productivas"
            ref={tableRef}
            headers={tableHeaders}
            getRows={getRows}
            onActionAddClick={onAddUnidadProductiva}
            hideFilters
        />
    );
};

export const UnidadProductivaTable = TableComponent;
