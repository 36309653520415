import { Box, Typography } from '@material-ui/core';
import { ActionColumn, ActionItem } from 'components/core/Columns';
import { GetRows, DataTable, DataTableRef, TableHeader } from 'components/core/DataTable';
import { ReactElement, RefObject } from 'react';

type TableModel = Solicitud.SolicitanteTableModel;

type Props = {
    tableRef: RefObject<DataTableRef>;
    modoCreacion?: boolean;
    getRows: GetRows<TableModel>;
    editSolicitante: (row: TableModel) => Promise<boolean>;
    deleteSolicitante: (row: TableModel) => Promise<boolean>;
    addSolicitante: () => void;
};

export const TableComponent = (props: Props): ReactElement => {
    const { tableRef, getRows, deleteSolicitante, addSolicitante, editSolicitante, modoCreacion } = props;

    const headersCreacion: TableHeader<TableModel>[] = [
        { id: 'actions', label: 'Acciones', render: renderColumnActions, width: 130 },
    ];

    const tableHeaders: TableHeader<TableModel>[] = [
        { id: 'nro', label: 'Nro.', width: 80 },
        { id: 'nombre_completo', label: 'Nombre', align: 'left' },
        { id: 'cedula_identidad', label: 'Cédula de identidad', align: 'left', minWidth: 100 },
        { id: 'email', label: 'Correo electrónico', align: 'left' },
        { id: 'unidad_productiva', label: 'Unidad Productiva', align: 'left' },
        ...headersCreacion,
    ];

    function renderColumnActions(data: TableModel): ReactElement {
        const confirmMessage = (
            <>
                ¿Desea eliminar al solicitante?
                <br />
                <Box my={3}>
                    <Typography align="center">
                        <strong>{data.nombre_completo}</strong>
                    </Typography>
                </Box>
            </>
        );
        const items: ActionItem[] = [];

        if (modoCreacion) {
            items.push({
                title: 'Editar',
                icon: 'EditIcon',
                color: 'primary',
                onClick: async () => editSolicitante(data),
            });
            items.push({
                title: 'Eliminar',
                icon: 'DeleteIcon',
                color: 'secondary',
                onClick: async () => deleteSolicitante(data),
                confirm: true,
                confirmMessage,
            });
        }

        return <ActionColumn items={items} size="small" />;
    }

    return (
        <DataTable
            title="Solicitantes"
            ref={tableRef}
            headers={tableHeaders}
            getRows={getRows}
            onActionAddClick={modoCreacion ? addSolicitante : undefined}
            hideFilters
        />
    );
};

export const SolicitantesTable = TableComponent;
