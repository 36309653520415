export const RESPONSE_MESSAGES = {
    DEFAULT_SUCCESS: 'Tarea completada exitosamente',
    DEFAULT_ERROR: '¡Ups! Algo salió mal',
    FETCH_ERROR: 'El servicio no se encuentra disponible, inténtalo más tarde',

    ERROR_404: 'El recurso solicitado no existe o ha sido eliminado',
    ERROR_401: 'Debe autenticarse para acceder al recurso',
    ERROR_403: 'No cuenta con los permisos suficientes para acceder al recurso',
    ERROR_400: 'Hubo un error al procesar su solicitud, revise el formato en el envío de datos e inténtelo nuevamente',
    ERROR_409: 'Hubo un error durante el proceso, inténtelo nuevamente',
    ERROR_412: 'No se cumple con algunas condiciones que son necesarias para completar la tarea',
    ERROR_500: '¡Ups! Ocurrió un error inesperado, inténtalo más tarde',
};
