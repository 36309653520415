import { VersionInfo } from 'CacheBuster';

type SetVersionInfoAction = {
    type: 'SET_VESION_INFO';
    payload: { versionInfo: VersionInfo };
};
export const setVersionInfo = (versionInfo: VersionInfo): SetVersionInfoAction => ({
    type: 'SET_VESION_INFO',
    payload: { versionInfo },
});

export type VersionAction = SetVersionInfoAction;
