import { ReactElement } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { RUTAS } from 'routes';

const PageComponent = (): ReactElement => {
    const history = useHistory();

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" px={2}>
            <Typography variant="h1" color="primary" align="center" gutterBottom>
                Error 404
            </Typography>
            <Typography variant="h4" align="center" gutterBottom>
                La página solicitada no existe
            </Typography>
            <Box p={2} />
            <Button variant="contained" color="primary" onClick={() => history.push(RUTAS.inicio)}>
                <HomeIcon /> &nbsp; Volver al inicio
            </Button>
        </Box>
    );
};

export const NotFoundPage = withRouter(PageComponent);
