import { ReactElement } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { getIcon } from 'helpers';
import { useLoading } from 'hooks';

type Props = {
    onClick?: () => Promise<void>;
    disabled?: boolean;
    color?: 'default' | 'inherit' | 'primary' | 'secondary';
    icon?: string;
    label?: string;
};

export const MyButton = ({ onClick, disabled, color, icon, label }: Props): ReactElement => {
    const [{ isLoading }, { start, stop }] = useLoading();

    const handleClick = async () => {
        if (isLoading) return;
        if (!onClick) return;
        start();
        await onClick();
        stop();
    };

    return (
        <Button
            variant="contained"
            color={color || 'primary'}
            onClick={handleClick}
            disabled={disabled || isLoading}
            startIcon={
                isLoading ? (
                    <CircularProgress size={18} color="inherit" />
                ) : (
                    getIcon(icon || 'CheckIcon', { fontSize: 'small' })
                )
            }
        >
            {label || 'Aceptar'}
        </Button>
    );
};
