import { ReactElement, RefObject } from 'react';

import { makeStyles, Theme } from '@material-ui/core';
import { DialogBox } from 'components/core/DialogBox';
import { FormDialogRef } from 'components/core/FormDialog/Types';
import { FlujoTimeline } from './FlujoTimeline';

type FormModel = Solicitud.TimelineModel;
type TimelineParams = Solicitud.TimelineParams;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData?: (params?: TimelineParams) => Promise<FormModel | undefined>;
};

const MyComponent = (props: Props): ReactElement => {
    const { getData, dialogRef } = props;
    const classes = useStyles();

    const handleGetData = async (params: TimelineParams) => {
        const data = getData ? await getData(params) : undefined;
        return data;
    };

    const dialogContent = (data: FormModel): ReactElement => {
        return (
            <div className={classes.formContent}>
                <FlujoTimeline timeline={data} />
            </div>
        );
    };

    return (
        <DialogBox
            ref={dialogRef}
            title="Flujo de solicitud de servicio"
            titleIcon={'TimelineIcon'}
            maxWidth={'sm'}
            fullwidth
            dialogContent={dialogContent}
            getData={handleGetData}
        />
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    formContent: {
        padding: theme.spacing(0),
        backgroundColor: theme.palette.background.default,
    },
}));

export const FlujoTimelineDialog = MyComponent;
