import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Link,
    makeStyles,
    Theme,
    Typography,
    useTheme,
} from '@material-ui/core';
import { ENUM_TIPO_TRABAJADOR } from 'base/enums';
import { MyButton } from 'components/core/Buttons';
import { SelectOption } from 'components/core/FormDialog/Types';
import { MyTextArea } from 'components/core/FormFields';
import { Formik } from 'formik';
import { getIcon } from 'helpers';
import { Fragment, useState } from 'react';
import { ReactElement } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

type DetalleReclamoModel = Reclamos.DetalleReclamoModel;
type DetalleSolicitudModel = Solicitud.DetalleSolicitudModel;

type Props = {
    data: DetalleReclamoModel;
    agregarResultado?: (mensaje: string) => Promise<boolean>;
};

const MyComponent = ({ data, agregarResultado }: Props): ReactElement => {
    const classes = useStyles();
    const theme = useTheme();
    const [mensaje, setMensaje] = useState<string>('');
    const [verMas, setVerMas] = useState<boolean>(false);

    const tipoTrabajadorOptions: SelectOption[] = Object.keys(ENUM_TIPO_TRABAJADOR)
        .map((key) => ({
            value: (ENUM_TIPO_TRABAJADOR as EnumValue)[key].value,
            label: (ENUM_TIPO_TRABAJADOR as EnumValue)[key].label,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const encabezado = (data: DetalleReclamoModel) => (
        <>
            <Box mb={5} mt={3}>
                <Typography variant="h4" noWrap align="center">
                    Reclamo de Servicio
                </Typography>
                <Box my={1}>
                    {/* <Typography variant="h5" noWrap align="center">
                        CIP - {data.rubro.nombre}
                    </Typography> */}
                </Box>
            </Box>
            <Box display="flex" width="100%" mb={3}>
                <Box>
                    <Typography>Responsable Pro Bolivia</Typography>
                    <Typography>
                        <strong>{data.usuario_registro_reclamo.nombre_completo}</strong>
                    </Typography>
                </Box>
                <Box ml="auto">
                    <Typography align="right">Fecha de reclamo</Typography>
                    <Typography align="right">
                        <strong>{data.fecha_reclamo}</strong>
                    </Typography>
                </Box>
            </Box>
            <Box display="flex" width="100%" mb={5}>
                <Box>
                    <Typography>Código de solicitud</Typography>
                    <Typography>
                        <strong>{data.detalle_solicitud.codigo}</strong>
                    </Typography>
                </Box>
                <Box ml="auto">
                    <Typography align="right">Código de reclamo</Typography>
                    <Typography align="right">
                        <strong>{data.codigo_reclamo}</strong>
                    </Typography>
                </Box>
            </Box>
        </>
    );

    const detalleReclamoCard = (data: DetalleReclamoModel) => (
        <Card>
            <div className={classes.sectionTitle}>Detalle del reclamo</div>
            <CardContent>
                <Box width="100%" overflow="auto">
                    <Scrollbars autoHeight autoHeightMax="100%">
                        <table width="100%" cellPadding={2}>
                            <thead>
                                <tr>
                                    <td>
                                        <Typography variant="body2">
                                            <strong>Persona de referencia</strong>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            {data.persona_referencia}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography variant="body2">
                                            <strong>Detalle</strong>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            {data.detalle}
                                        </Typography>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </Scrollbars>
                </Box>
            </CardContent>
        </Card>
    );

    const resultadoCard = (resultado: typeof data.resultado) => (
        <Card>
            <div className={classes.sectionTitle}>Resultados del reclamo</div>
            <CardContent>
                <Box>
                    <Box pb={1}>
                        <Divider />
                    </Box>
                    {resultado.map((item, index) => (
                        <Fragment key={index}>
                            <Box>
                                <Typography variant="body2" gutterBottom>
                                    <strong>Responsable Pro Bolivia: </strong> {item.nombre_responsable}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <strong>Respuesta: </strong> {item.mensaje}
                                </Typography>
                                <Typography variant="body2" gutterBottom align="right">
                                    {item.fecha}
                                </Typography>
                            </Box>
                            <Box pb={1}>
                                <Divider />
                            </Box>
                        </Fragment>
                    ))}
                    {resultado.length === 0 && <>Aún no se atendió este reclamo</>}
                </Box>
                {agregarResultado && (
                    <>
                        <Box display="flex" mt={2}>
                            <Formik initialValues={{}} onSubmit={(data) => console.log(data)}>
                                {() => {
                                    return (
                                        <Box display="flex" flexDirection="column" width="100%">
                                            <MyTextArea
                                                name="mensaje"
                                                label="Describa aquí brevemente la acción correctiva"
                                                rows={5}
                                                value={mensaje}
                                                onChange={(value) => setMensaje(String(value))}
                                                delay={500}
                                            />
                                            <Box mt={2} display="flex" justifyContent="flex-end">
                                                <MyButton
                                                    label="Agregar resultado"
                                                    color="secondary"
                                                    icon="AddIcon"
                                                    disabled={!mensaje}
                                                    onClick={async () => {
                                                        const success = await agregarResultado(mensaje);
                                                        if (success) {
                                                            setMensaje('');
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    );
                                }}
                            </Formik>
                        </Box>
                    </>
                )}
            </CardContent>
        </Card>
    );

    const solicitanteCard = (solicitante: typeof data.detalle_solicitud.solicitante) => (
        <Card>
            <div className={classes.sectionTitle2}>Solicitante</div>
            <CardContent>
                <Box width="100%" overflow="auto">
                    <Scrollbars autoHeight autoHeightMax="100%">
                        <table width="100%" cellPadding={2}>
                            <thead>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Nombre</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.nombre}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Apellido Paterno</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.apellido_paterno || '-'}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Apellido materno</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.apellido_materno || '-'}</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Cédula de identidad</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.cedula_identidad || '-'}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Teléfono</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.telefono || '-'}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Correo electrónico</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.email || '-'}</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography variant="body2">¿Es dueño de la UP?</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.es_duenio_up}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Tipo de trabajador</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>
                                                {tipoTrabajadorOptions.find(
                                                    (x) => x.value === solicitante.tipo_trabajador,
                                                )?.label || '-'}
                                            </strong>
                                        </Typography>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </Scrollbars>
                </Box>
            </CardContent>
        </Card>
    );

    const unidadProductivaCard = (up: typeof data.detalle_solicitud.solicitante.unidad_productiva) => (
        <Card>
            <div className={classes.sectionTitle2}>Unidad Productiva</div>
            <CardContent>
                <Box width="100%" overflow="auto">
                    <Scrollbars autoHeight autoHeightMax="100%">
                        <table width="100%" cellPadding={2}>
                            <thead>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Nombre o Razón Social</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{up?.nombre_razon_social}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Dirección</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{up?.direccion || '-'}</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Rubro</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{up?.rubro?.nombre || '-'}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Rubro específico</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{up?.subtipo_rubro?.nombre || '-'}</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Producto principal</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{up?.producto || '-'}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Antigüedad</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>
                                                {up?.antiguedad_anios || '-'} años {up?.antiguedad_meses || '-'} meses
                                            </strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Asociación/Gremio</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{up?.asociacion_gremio || '-'}</strong>
                                        </Typography>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </Scrollbars>
                </Box>
            </CardContent>
        </Card>
    );

    const servicioRequeridoCard = (data: DetalleSolicitudModel) => (
        <Card>
            <div className={classes.sectionTitle2}>Servicio requerido</div>
            <CardContent>
                <Box width="100%" overflow="auto">
                    <table width="100%" cellPadding={2}>
                        <tbody>
                            <tr>
                                <td>
                                    <Typography variant="body2">Rubro</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{data.rubro.nombre}</strong>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="body2">Tipo de servicio</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{data.servicio.nombre}</strong>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="body2">Fecha estimada de entrega</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{data.fecha_entrega}</strong>
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>

                <Box width="100%" overflow="auto" my={3}>
                    <Typography variant="body2">Parámetros</Typography>
                    <table width="100%" cellPadding={2}>
                        <tbody>
                            {data.parametros.map((param, index) => (
                                <tr key={index}>
                                    <td>
                                        <Typography variant="body2">
                                            <strong> - {param.parametro}</strong>
                                        </Typography>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            </CardContent>
        </Card>
    );

    const materialEntregadoCard = (material: typeof data.detalle_solicitud.material_entregado) => (
        <Card>
            <div className={classes.sectionTitle2}>Material entregado</div>
            <CardContent>
                {material?.tipo && (
                    <Box width="100%" overflow="auto">
                        <table width="100%" cellPadding={2}>
                            <thead>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Tipo de material</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{material?.tipo || '-'}</strong>
                                        </Typography>
                                    </td>
                                    {material?.tipo.toUpperCase() === 'OTRO' && (
                                        <td>
                                            <Typography variant="body2">Tipo de material (Otro)</Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <strong>{material?.tipo_otro || ''}</strong>
                                            </Typography>
                                        </td>
                                    )}
                                </tr>
                            </thead>
                        </table>
                    </Box>
                )}
                <Box width="100%" overflow="auto" my={3}>
                    <Typography variant="body2" gutterBottom>
                        Materiales
                    </Typography>
                    <Scrollbars autoHeight autoHeightMax="100%">
                        <table width="100%" cellPadding={2} style={{ borderCollapse: 'collapse', marginBottom: '8px' }}>
                            <thead>
                                <tr>
                                    {material?.material_columns.map((col, index) => {
                                        return (
                                            <td
                                                key={index}
                                                style={{
                                                    border: `solid 1px ${theme.palette.divider}`,
                                                    padding: '0 8px',
                                                    backgroundColor: theme.palette.background.default,
                                                }}
                                            >
                                                <strong>{col}</strong>
                                            </td>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {material?.material_items.length === 0 && (
                                    <tr key="0">
                                        <td
                                            colSpan={999}
                                            style={{
                                                border: `solid 1px ${theme.palette.divider}`,
                                                padding: '0 8px',
                                                backgroundColor: theme.palette.background.paper,
                                                textAlign: 'center',
                                            }}
                                        >
                                            Sin registros
                                        </td>
                                    </tr>
                                )}
                                {material?.material_items.map((row, rowIndex) => {
                                    return (
                                        <tr key={rowIndex}>
                                            {row.map((value, colIndex) => {
                                                return (
                                                    <td
                                                        key={colIndex}
                                                        style={{
                                                            border: `solid 1px ${theme.palette.divider}`,
                                                            padding: '0 8px',
                                                            backgroundColor: theme.palette.background.paper,
                                                        }}
                                                    >
                                                        {value}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Scrollbars>
                </Box>
            </CardContent>
        </Card>
    );

    const reservaEquiposCard = (data: DetalleSolicitudModel) => (
        <Card>
            <div className={classes.sectionTitle2}>Reserva de equipos</div>
            <CardContent>
                <Box width="100%" overflow="auto" my={3}>
                    <Scrollbars autoHeight autoHeightMax="100%">
                        <table width="100%" cellPadding={2} style={{ borderCollapse: 'collapse', marginBottom: '8px' }}>
                            <thead>
                                <tr>
                                    <td className={classes.headerCell}>
                                        <strong>Fecha</strong>
                                    </td>
                                    <td className={classes.headerCell}>
                                        <strong>Hora inicio</strong>
                                    </td>
                                    <td className={classes.headerCell}>
                                        <strong>Hora fin</strong>
                                    </td>
                                    <td className={classes.headerCell}>
                                        <strong>Maquinaria</strong>
                                    </td>
                                    <td className={classes.headerCell}>
                                        <strong>Observacion</strong>
                                    </td>
                                </tr>
                                {data.reserva_items.map((item, rowIndex) => {
                                    return (
                                        <tr key={rowIndex}>
                                            <td className={classes.bodyCell}>{item.fecha}</td>
                                            <td className={classes.bodyCell}>{item.hora_inicio}</td>
                                            <td className={classes.bodyCell}>{item.hora_fin}</td>
                                            <td className={classes.bodyCell}>{item.maquinaria.nombre}</td>
                                            <td className={classes.bodyCell}>{item.observacion}</td>
                                        </tr>
                                    );
                                })}
                            </thead>
                        </table>
                    </Scrollbars>
                </Box>
            </CardContent>
        </Card>
    );

    const informeProduccionCard = (informe: typeof data.detalle_solicitud.informe_produccion) => (
        <Card>
            <div className={classes.sectionTitle2}>Informe de Producción</div>
            <CardContent>
                <Box width="100%" overflow="auto">
                    <table width="100%" cellPadding={2}>
                        <thead>
                            <tr>
                                <td>
                                    <Typography variant="body2">Responsable Pro Bolivia</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{informe?.usuario_registro.nombre_completo || '-'}</strong>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="body2" align="right">
                                        Fecha de registro de informe
                                    </Typography>
                                    <Typography variant="body2" align="right" gutterBottom>
                                        <strong>{informe?.fecha_informe || '-'}</strong>
                                    </Typography>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </Box>

                <Divider />

                <Box width="100%" overflow="auto" my={3}>
                    <Typography variant="body2" gutterBottom>
                        Resultados
                    </Typography>
                    <Scrollbars autoHeight autoHeightMax="100%">
                        <table width="100%" cellPadding={2} style={{ borderCollapse: 'collapse', marginBottom: '8px' }}>
                            <thead>
                                <tr>
                                    {informe?.resultado_columns.map((col, index) => {
                                        return (
                                            <td
                                                key={index}
                                                style={{
                                                    border: `solid 1px ${theme.palette.divider}`,
                                                    padding: '0 8px',
                                                    backgroundColor: theme.palette.background.default,
                                                }}
                                            >
                                                <strong>{col}</strong>
                                            </td>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {informe?.resultado_items.length === 0 && (
                                    <tr key="0">
                                        <td
                                            colSpan={999}
                                            style={{
                                                border: `solid 1px ${theme.palette.divider}`,
                                                padding: '0 8px',
                                                backgroundColor: theme.palette.background.paper,
                                                textAlign: 'center',
                                            }}
                                        >
                                            Sin registros
                                        </td>
                                    </tr>
                                )}
                                {informe?.resultado_items.map((row, rowIndex) => {
                                    return (
                                        <tr key={rowIndex}>
                                            {row.map((value, colIndex) => {
                                                return (
                                                    <td
                                                        key={colIndex}
                                                        style={{
                                                            border: `solid 1px ${theme.palette.divider}`,
                                                            padding: '0 8px',
                                                            backgroundColor: theme.palette.background.paper,
                                                        }}
                                                    >
                                                        {value}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Scrollbars>
                    <Typography align="right">
                        <strong>Precio total:</strong> {informe?.precio_final} Bs.
                    </Typography>
                </Box>

                {informe?.horario_uso_maquinaria && informe?.horario_uso_maquinaria.length > 0 && (
                    <>
                        <Box width="100%" overflow="auto" my={3}>
                            <Typography variant="body2" gutterBottom>
                                Registro de uso de maquinaria
                            </Typography>
                            <Scrollbars autoHeight autoHeightMax="100%">
                                <table
                                    width="100%"
                                    cellPadding={2}
                                    style={{ borderCollapse: 'collapse', marginBottom: '8px' }}
                                >
                                    <thead>
                                        <tr>
                                            <td className={classes.headerCell}>
                                                <strong>Fecha</strong>
                                            </td>
                                            <td className={classes.headerCell}>
                                                <strong>Maquinaria</strong>
                                            </td>
                                            <td className={classes.headerCell}>
                                                <strong>Hora inicio</strong>
                                            </td>
                                            <td className={classes.headerCell}>
                                                <strong>Hora fin</strong>
                                            </td>
                                            <td className={classes.headerCell}>
                                                <strong>Cantidad de horas</strong>
                                            </td>
                                            <td className={classes.headerCell}>
                                                <strong>Observacion</strong>
                                            </td>
                                        </tr>
                                        {informe?.horario_uso_maquinaria.map((item, rowIndex) => {
                                            return (
                                                <tr key={rowIndex}>
                                                    <td className={classes.bodyCell}>{item.fecha}</td>
                                                    <td className={classes.bodyCell}>{item.maquinaria.nombre}</td>
                                                    <td className={classes.bodyCell}>{item.hora_inicio}</td>
                                                    <td className={classes.bodyCell}>{item.hora_fin}</td>
                                                    <td className={classes.bodyCell}>{item.cant_horas}</td>
                                                    <td className={classes.bodyCell}>{item.observacion}</td>
                                                </tr>
                                            );
                                        })}
                                    </thead>
                                </table>
                            </Scrollbars>
                            <Typography align="right">
                                <strong>Tiempo total:</strong> {informe?.total_horas} horas
                            </Typography>
                        </Box>
                    </>
                )}

                {informe && informe.adjuntos && informe.adjuntos.length > 0 && (
                    <Box width="100%" overflow="auto">
                        <table width="100%" cellPadding={2}>
                            <thead>
                                <tr>
                                    <td>
                                        <Typography variant="body2" gutterBottom>
                                            Archivos adjuntos
                                        </Typography>
                                        {informe.adjuntos.map((adjuntoItem, index) => {
                                            return (
                                                <Typography variant="body2" gutterBottom key={index}>
                                                    <strong>
                                                        <Link
                                                            color="textPrimary"
                                                            href={adjuntoItem.url}
                                                            target="_blank"
                                                        >
                                                            {getIcon('AttachmentIcon', {
                                                                fontSize: 'small',
                                                                color: 'secondary',
                                                            })}{' '}
                                                            {adjuntoItem.title}
                                                        </Link>
                                                    </strong>
                                                </Typography>
                                            );
                                        })}
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </Box>
                )}
            </CardContent>
        </Card>
    );

    const boletaPagoCard = (boleta: typeof data.detalle_solicitud.boleta) => (
        <Card>
            <div className={classes.sectionTitle2}>Boleta de pago</div>
            <CardContent>
                <Box width="100%" overflow="auto">
                    <table width="100%" cellPadding={2}>
                        <thead>
                            <tr>
                                <td>
                                    <Typography variant="body2">Responsable Pro Bolivia</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{boleta?.usuario_registro.nombre_completo || '-'}</strong>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="body2" align="right">
                                        Fecha de registro de boleta
                                    </Typography>
                                    <Typography variant="body2" align="right" gutterBottom>
                                        <strong>{boleta?.fecha_registro || '-'}</strong>
                                    </Typography>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </Box>

                <Divider />

                <Box width="100%" overflow="auto" my={3}>
                    <table width="100%" cellPadding={2}>
                        <thead>
                            <tr>
                                <td>
                                    <Typography variant="body2">Nro. boleta</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{boleta?.nro_boleta_pago || '-'}</strong>
                                    </Typography>
                                </td>
                                {/* <td>
                                                <Typography variant="body2">Nro. factura</Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{boleta?.nro_factura || '-'}</strong>
                                                </Typography>
                                            </td> */}
                                <td>
                                    <Typography variant="body2">Fecha pago</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{boleta?.fecha_pago || '-'}</strong>
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography variant="body2">Monto (Bs)</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{boleta?.monto_pago || '-'}</strong>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="body2">Nro. cuenta</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{boleta?.cuenta || '-'}</strong>
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography variant="body2" gutterBottom>
                                        Escaneado
                                    </Typography>
                                    {boleta?.escaneado.map((adjuntoItem, index) => {
                                        return (
                                            <Typography variant="body2" gutterBottom key={index}>
                                                <strong>
                                                    <Link color="textPrimary" href={adjuntoItem.url} target="_blank">
                                                        {getIcon('AttachmentIcon', {
                                                            fontSize: 'small',
                                                            color: 'secondary',
                                                        })}{' '}
                                                        {adjuntoItem.title}
                                                    </Link>
                                                </strong>
                                            </Typography>
                                        );
                                    })}
                                </td>
                            </tr>
                        </thead>
                    </table>
                </Box>
            </CardContent>
        </Card>
    );

    return (
        <div className={classes.root}>
            <Divider />

            {encabezado(data)}

            <Box mb={2}>{detalleReclamoCard(data)}</Box>

            <Box mb={2}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setVerMas(!verMas)}
                    endIcon={getIcon(verMas ? 'ExpandLessIcon' : 'ExpandMoreIcon')}
                >
                    {verMas ? 'Ocultar detalle del Servicio solicitado' : 'Ver detalle del Servicio solicitado'}
                </Button>
            </Box>

            {verMas && (
                <>
                    {data.detalle_solicitud.solicitante && (
                        <>
                            <Box mb={5}>{solicitanteCard(data.detalle_solicitud.solicitante)}</Box>

                            {data.detalle_solicitud.solicitante.unidad_productiva && (
                                <Box mb={5}>
                                    {unidadProductivaCard(data.detalle_solicitud.solicitante.unidad_productiva)}
                                </Box>
                            )}
                        </>
                    )}

                    <Box mb={5}>{servicioRequeridoCard(data.detalle_solicitud)}</Box>

                    {data.detalle_solicitud.material_entregado && data.detalle_solicitud.rubro.material_columns && (
                        <Box mb={5}>{materialEntregadoCard(data.detalle_solicitud.material_entregado)}</Box>
                    )}

                    {data.detalle_solicitud.reserva_items && data.detalle_solicitud.reserva_items.length > 0 && (
                        <Box mb={5}>{reservaEquiposCard(data.detalle_solicitud)}</Box>
                    )}

                    {data.detalle_solicitud.informe_produccion && (
                        <Box mb={5}>{informeProduccionCard(data.detalle_solicitud.informe_produccion)}</Box>
                    )}

                    {data.detalle_solicitud.boleta && <Box mb={5}>{boletaPagoCard(data.detalle_solicitud.boleta)}</Box>}
                </>
            )}

            {data.resultado && <Box mb={5}>{resultadoCard(data.resultado)}</Box>}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& td': { verticalAlign: 'top' },
    },
    sectionTitle: {
        fontWeight: 'bold',
        margin: theme.spacing(0, 0, 1, 0),
        padding: theme.spacing(0.5, 1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    sectionTitle2: {
        fontWeight: 'bold',
        margin: theme.spacing(0, 0, 1, 0),
        padding: theme.spacing(0.5, 1),
        backgroundColor: theme.palette.grey[700],
        color: theme.palette.getContrastText(theme.palette.grey[700]),
    },
    sectionTitle3: {
        fontWeight: 'bold',
        margin: theme.spacing(0, 0, 1, 0),
        padding: theme.spacing(0.5, 1),
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.getContrastText(theme.palette.secondary.dark),
    },
    headerCell: {
        border: `solid 1px ${theme.palette.divider}`,
        padding: '0 8px',
        backgroundColor: theme.palette.background.default,
    },
    bodyCell: {
        border: `solid 1px ${theme.palette.divider}`,
        padding: '0 8px',
        backgroundColor: theme.palette.background.paper,
    },
}));

export const DetalleReclamo = MyComponent;
