import { ThemeMode } from 'pages/Settings/Types';

type SetThemeModeAction = {
    type: 'SET_THEME_MODE';
    payload: { mode: ThemeMode };
};
export const setThemeMode = (mode: ThemeMode): SetThemeModeAction => ({
    type: 'SET_THEME_MODE',
    payload: { mode },
});

type SetNotificationActiveAction = {
    type: 'SET_NOTIFICATION_ACTIVE';
    payload: { active: boolean };
};
export const setNotificationActive = (active: boolean): SetNotificationActiveAction => ({
    type: 'SET_NOTIFICATION_ACTIVE',
    payload: { active },
});

export type ConfigAction = SetThemeModeAction | SetNotificationActiveAction;
