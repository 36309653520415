import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams, SelectOption } from 'components/core/FormDialog/Types';
import { ReactElement, RefObject } from 'react';
import * as yup from 'yup';

type FormModel = Costo.ServicioFormModel;
type FormParams = Costo.ServicioFormParams;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
    params: FormParams;
};

const FormDialogComponent = ({ dialogRef, getData, submit, params }: Props): ReactElement => {
    const categoriaOptions: SelectOption[] = params.categorias.map((categoria) => ({
        value: categoria.id,
        label: categoria.nombre,
    }));

    const rubroOptions: SelectOption[] = params.rubros.map((rubro) => ({
        value: rubro.id,
        label: rubro.nombre,
    }));

    const formLayout: FormGroup<FormModel>[] = [
        {
            title: 'Datos del servicio',
            grid: [
                [
                    { name: 'codigo_servicio', label: 'Código de servicio', type: 'text', delay: 500 },
                    { name: 'nombre', label: 'Nombre del servicio', type: 'text', delay: 500 },
                ],
                [{ name: 'categoria', label: 'Categoría', type: 'select', options: categoriaOptions }],
                [{ name: 'fid_rubro', label: 'Rubro', type: 'select', options: rubroOptions }],
            ],
        },
    ];

    const validationSchema = yup.object({
        codigo_servicio: yup.string().required(),
        nombre: yup.string().required(),
        categoria: yup.string().required(),
        fid_rubro: yup.string().required(),
    });

    return (
        <FormDialog
            ref={dialogRef}
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            validationSchema={validationSchema}
            maxWidth={'sm'}
            fullwidth={true}
            // debug
        />
    );
};

export const ServicioFormDialog = FormDialogComponent;
