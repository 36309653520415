import { ConfigAction } from './configActions';
import { ConfigState, INITIAL_STATE } from './configState';

export const configReducer = (state = INITIAL_STATE, action: ConfigAction): ConfigState => {
    switch (action.type) {
        case 'SET_THEME_MODE':
            const newState1 = {
                ...state,
                theme: { ...state.theme, mode: action.payload.mode },
            };
            localStorage.setItem('config', JSON.stringify(newState1));
            return newState1;

        case 'SET_NOTIFICATION_ACTIVE':
            const newState2 = {
                ...state,
                notification: { ...state.notification, active: action.payload.active },
            };
            localStorage.setItem('config', JSON.stringify(newState2));
            return newState2;

        default:
            return state;
    }
};
