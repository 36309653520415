import { ROLES } from 'base/authBase';
import { SectionItem, SectionNav } from 'components/core/SectionNav';
import { useSession } from 'hooks';
import { ReactElement } from 'react';
import { withRouter } from 'react-router-dom';
import { ParametrosSection, ServiciosSection } from './sections';

const PageComponent = (): ReactElement => {
    const sections: SectionItem[] = [];
    const session = useSession();

    const esTecnico = session.roles.some(rol => rol.id === ROLES.tecnicooperador.id);
    const esPlataforma = session.roles.some(rol => rol.id === ROLES.plataforma.id);
    const esCoordinador = session.roles.some(rol => rol.id === ROLES.coordinador.id);

    if (esPlataforma || esTecnico || esCoordinador) {
        sections.push({
            id: 'servicios',
            label: 'Servicios',
            icon: 'AssignmentTurnedInIcon',
            content: <ServiciosSection />,
        });
        sections.push({
            id: 'parametros',
            label: 'Parámetros de servicio',
            icon: 'AssignmentTurnedInIcon',
            content: <ParametrosSection />,
        });
    }

    return (
        <div>
            <SectionNav sections={sections} />
        </div>
    );
};

export const CostoServiciosModule = withRouter(PageComponent);
