import { Box, Button } from '@material-ui/core';
import { AcceptButton, CancelButton } from 'components/core/Buttons';
import { ReactElement } from 'react';
import { timer } from 'utils';

const MyComponent = (): ReactElement => {
    const handleClickAccept = async () => {
        console.log('long task ...');
        await timer(2000);
    };

    return (
        <>
            <Box display="flex" justifyContent="center">
                <AcceptButton onClick={handleClickAccept} />
                <Box p={1} />
                <CancelButton />
                <Box p={1} />
                <Button variant="contained" color="default">
                    Default
                </Button>
                <Box p={1} />
            </Box>
        </>
    );
};

export const ButtonSample = MyComponent;
