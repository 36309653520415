import { BaseService } from 'services/http';
import { getFileName } from 'utils';

type SolicitudServicioTableModel = Solicitud.SolicitudServicioTableModel;
type SolicitudServicioFormModel = Solicitud.SolicitudServicioFormModel;
type SolicitudServicioFormParams = Solicitud.SolicitudServicioFormParams;
type UnidadProductivaTableModel = Solicitud.UnidadProductivaTableModel;
type UnidadProductivaFormModel = Solicitud.UnidadProductivaFormModel;
type UnidadProductivaFormParams = Solicitud.UnidadProductivaFormParams;
type DetalleSolicitudModel = Solicitud.DetalleSolicitudModel;
type BoletaPagoFormModel = Solicitud.BoletaPagoFormModel;
type InformeProduccionModel = Solicitud.InformeProduccionModel;
type InformeProduccionParams = Solicitud.InformeProduccionParams;
type HorarioModel = Solicitud.HorarioModel;
type SolicitanteTableModel = Solicitud.SolicitanteTableModel;
type SolicitanteFormModel = Solicitud.SolicitanteFormModel;
type SolicitanteFormParams = Solicitud.SolicitanteFormParams;
type BoletaFormParams = Solicitud.BoletaFormParams;

export const getSolicitudServicios = async (queryParams: QueryParams = {}): Promise<BaseResponse<{ rows: SolicitudServicioTableModel[]; count: number }>> => {
    const queryParamsText = BaseService.buildQueryParamsText(queryParams);
    return BaseService.request('get', `/solicitud_servicio${queryParamsText}`);
};

export const getSolicitudServicioForm = async (solicitudServicioId?: string): Promise<BaseResponse<SolicitudServicioFormModel>> => {
    const queryParamsText = solicitudServicioId ? `?id=${solicitudServicioId}` : '';
    return BaseService.request('get', `/solicitud_servicio/form${queryParamsText}`);
};

export const getSolicitudServicioFormParams = async (): Promise<BaseResponse<SolicitudServicioFormParams>> => {
    return BaseService.request('get', `/solicitud_servicio/form_params`);
};

export const editSolicitudServicio = async (solicitusServicioId: string, data: SolicitudServicioFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('put', `/solicitud_servicio/${solicitusServicioId}`, data);
};

export const createSolicitudServicio = async (data: SolicitudServicioFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', '/solicitud_servicio', data);
};

export const createOrUpdateSolicitudServicio = async (data: SolicitudServicioFormModel): Promise<BaseResponse<boolean>> => {
    if (data.id) return editSolicitudServicio(data.id, data);
    return createSolicitudServicio(data);
};

export const deleteSolicitudServicio = async (solicitudServicioId: string): Promise<BaseResponse<boolean>> => {
    return BaseService.request('delete', `/solicitud_servicio/${solicitudServicioId}`);
};

export const aprobarRechazarSolicitudServicio = async (solicitudServicioId: string, aprobado: boolean, observacion?: string): Promise<BaseResponse<boolean>> => {
    const data = {
        id_solicitud_servicio: solicitudServicioId,
        aprobado: aprobado,
        observacion: observacion ? observacion : undefined,
    };
    return BaseService.request('post', `/solicitud_servicio/aprobado`, data);
};

export const finalizarFlujo = async (solicitudServicioId: string): Promise<BaseResponse<boolean>> => {
    const data = {
        id_solicitud_servicio: solicitudServicioId,
    };
    return BaseService.request('post', `/solicitud_servicio/finalizar`, data);
};

export const getUnidadProductivas = async (queryParams: QueryParams = {}): Promise<BaseResponse<{ rows: UnidadProductivaTableModel[]; count: number }>> => {
    const queryParamsText = BaseService.buildQueryParamsText(queryParams);
    return BaseService.request('get', `/unidad_productiva${queryParamsText}`);
};

export const getUnidadProductivaForm = async (unidadProductivaId?: string): Promise<BaseResponse<UnidadProductivaFormModel>> => {
    const queryParamsText = unidadProductivaId ? `?id=${unidadProductivaId}` : '';
    return BaseService.request('get', `/unidad_productiva/form${queryParamsText}`);
};

export const getUnidadProductivaFormParams = async (): Promise<BaseResponse<UnidadProductivaFormParams>> => {
    return BaseService.request('get', `/unidad_productiva/form_params`);
};

export const editUnidadProductiva = async (unidadProductivaId: string, data: UnidadProductivaFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('put', `/unidad_productiva/${unidadProductivaId}`, data);
};

export const createUnidadProductiva = async (data: UnidadProductivaFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', '/unidad_productiva', data);
};

export const createOrUpdateUnidadProductiva = async (data: UnidadProductivaFormModel): Promise<BaseResponse<boolean>> => {
    if (data.id) return editUnidadProductiva(data.id, data);
    return createUnidadProductiva(data);
};

export const deleteUnidadProductiva = async (unidadProductivaId: string): Promise<BaseResponse<boolean>> => {
    return BaseService.request('delete', `/unidad_productiva/${unidadProductivaId}`);
};

export const getDetalleFormValue = async (solicitudServicioId: string): Promise<BaseResponse<DetalleSolicitudModel>> => {
    return BaseService.request('get', `/solicitud_servicio/detalle/${solicitudServicioId}`);
};

export const getBoletaPagoForm = async (solicitudServicioId: string): Promise<BaseResponse<BoletaPagoFormModel>> => {
    return BaseService.request('get', `/boleta/form/${solicitudServicioId}`);
};

export const getboletaFormParams = async (): Promise<BaseResponse<BoletaFormParams>> => {
    return BaseService.request('get', `/boleta/form_params`);
};

export const enviarBoleta = async (solicitudServicioId: string): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', `/boleta/enviar/${solicitudServicioId}`);
};

export const editBoletaPago = async (boletaId: string, data: BoletaPagoFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('put', `/boleta/${boletaId}`, data);
};

export const createBoletaPago = async (data: BoletaPagoFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', '/boleta', data);
};

export const createOrUpdateBoletaPago = async (data: BoletaPagoFormModel): Promise<BaseResponse<boolean>> => {
    if (data.id) return editBoletaPago(data.id, data);
    return createBoletaPago(data);
};

export const getInformeProduccionForm = async (solicitudServicioId: string): Promise<BaseResponse<InformeProduccionModel>> => {
    return BaseService.request('get', `/informe_produccion/form/${solicitudServicioId}`);
};

export const getInformeProduccionParams = async (solicitudServicioId: string): Promise<BaseResponse<InformeProduccionParams>> => {
    return BaseService.request('get', `/informe_produccion/form_params/${solicitudServicioId}`);
};

export const createOrUpdateInformeProduccion = async (solicitudServicioId: string, data: InformeProduccionModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', `/informe_produccion/${solicitudServicioId}`, data);
};

export const imprimirInformeProduccionPDF = async (solicitudServicioId: string): Promise<BaseResponse<unknown>> => {
    const filename = getFileName('Reporte Producción');
    return BaseService.download('get', `/informe_produccion/reporte/${solicitudServicioId}`, undefined, filename);
};

export const enviarInforme = async (solicitudServicioId: string): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', `/informe_produccion/enviar/${solicitudServicioId}`);
};

export const imprimirDetalleServicioPDF = async (solicitudServicioId: string): Promise<BaseResponse<unknown>> => {
    const filename = getFileName('Reporte Producción');
    return BaseService.download('get', `/solicitud_servicio/reporte/${solicitudServicioId}`, undefined, filename);
};

export const createOrUpdateHorario = async (solicitudServicioId: string, data: { rows: HorarioModel[] }): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', `/equipoindustrial/prestamo/${solicitudServicioId}`, data);
};

export const getSolicitantes = async (queryParams: QueryParams = {}): Promise<BaseResponse<{ rows: SolicitanteTableModel[]; count: number }>> => {
    const queryParamsText = BaseService.buildQueryParamsText(queryParams);
    return BaseService.request('get', `/solicitante${queryParamsText}`);
};

export const getSolicitanteForm = async (solicitanteId?: string): Promise<BaseResponse<SolicitanteFormModel>> => {
    const queryParamsText = solicitanteId ? `?id=${solicitanteId}` : '';
    return BaseService.request('get', `/solicitante/form${queryParamsText}`);
};

export const createSolicitante = async (data: SolicitanteFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('post', '/solicitante', data);
};

export const editSolicitante = async (solicitanteId: string, data: SolicitanteFormModel): Promise<BaseResponse<boolean>> => {
    return BaseService.request('put', `/solicitante/${solicitanteId}`, data);
};

export const deleteSolicitante = async (solicitanteId: string): Promise<BaseResponse<boolean>> => {
    return BaseService.request('delete', `/solicitante/${solicitanteId}`);
};

export const createOrUpdateSolicitante = async (data: SolicitanteFormModel): Promise<BaseResponse<boolean>> => {
    if (data.id) return editSolicitante(data.id, data);
    return createSolicitante(data);
};

export const getSolicitanteFormParams = async (): Promise<BaseResponse<SolicitanteFormParams>> => {
    return BaseService.request('get', '/solicitante/form_params');
};
