import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams } from 'components/core/FormDialog/Types';
import { ReactElement, RefObject } from 'react';
import * as yup from 'yup';

type FormModel = Reclamos.ReclamoFormModel;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
};

const FormDialogComponent = ({ dialogRef, getData, submit }: Props): ReactElement => {
    const formLayout: FormGroup<FormModel>[] = [
        {
            title: 'Datos del servicio',
            grid: [
                [{ name: 'persona_referencia', label: 'Nombre de la persona de referencia', type: 'text' }],
                [{ name: 'codigo_solicitud', label: 'Código de solicitud de servicio', type: 'text' }],
            ],
        },
        {
            title: 'Detalle del reclamo',
            grid: [[{ name: 'detalle', label: 'Detalle del reclamo', type: 'textarea', rows: 5 }]],
        },
    ];

    const validationSchema = yup.object({
        // persona_referencia: yup.string().required(),
        codigo_solicitud: yup.string().required(),
        detalle: yup.string().required(),
    });

    return (
        <FormDialog
            ref={dialogRef}
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            validationSchema={validationSchema}
            fullwidth
            maxWidth={'sm'}
            // debug
        />
    );
};

export const ReclamoFormDialog = FormDialogComponent;
