import { Typography } from '@material-ui/core';
import { ActionColumn, ActionItem, ActiveColumn } from 'components/core/Columns';
import { DataTable, DataTableRef, GetRows, TableHeader } from 'components/core/DataTable';
import { ReactElement, RefObject } from 'react';
import { UsuarioTableItem } from './UsuarioTableItem';

type TableModel = Usuario.UsuarioTableModel;

type Props = {
    tableRef: RefObject<DataTableRef>;
    getRows: GetRows<TableModel>;
    editUser: (row: TableModel) => Promise<boolean>;
    deleteUser: (row: TableModel) => Promise<boolean>;
    resetPass: (row: TableModel) => Promise<boolean>;
    activeUser: (userId: string, activa: boolean) => Promise<boolean>;
    addUser: () => void;
};

export const TableComponent = (props: Props): ReactElement => {
    const { tableRef, getRows, editUser, deleteUser, addUser, resetPass, activeUser } = props;
    const tableHeaders: TableHeader<TableModel>[] = [
        { id: 'nro', label: 'Nro.', width: 80 },
        { id: 'activo', label: 'Activo', align: 'center', minWidth: 100, render: renderColumnActive },
        {
            id: 'nombre_completo',
            label: 'Nombre completo',
            align: 'left',
            minWidth: 150,
            sort: true,
            render: renderColumnNombre,
        },
        { id: 'cedula_identidad', label: 'Cédula de identidad', align: 'left', minWidth: 150, sort: true },
        { id: 'cargo', label: 'Cargo', align: 'left', minWidth: 150, sort: true },
        { id: 'roles', label: 'Rol', align: 'left', render: renderColumnRol, truncate: true },
        { id: 'regional', label: 'Regional', align: 'left' },
        { id: 'actions', label: 'Acciones', render: renderColumnActions, width: 140 },
    ];

    function renderColumnActions(data: TableModel): ReactElement {
        const deleteMsg = (
            <>
                ¿Desea eliminar al usuario de nombre <strong>{data.nombre_completo}</strong>?
            </>
        );
        const resetMsg = (
            <>
                ¿Desea restaurar la contraseña del usuario de nombre <strong>{data.nombre_completo}</strong>?
            </>
        );
        const items: ActionItem[] = [
            { icon: 'EditIcon', onClick: async () => editUser(data), title: 'Editar usuario' },
            {
                icon: 'LockOpenIcon',
                onClick: async () => resetPass(data),
                confirm: true,
                confirmMessage: resetMsg,
                title: 'Restaurar contraseña',
            },
            {
                icon: 'DeleteIcon',
                color: 'secondary',
                onClick: async () => deleteUser(data),
                confirm: true,
                confirmMessage: deleteMsg,
                title: 'Eliminar usuario',
            },
        ];
        return <ActionColumn items={items} size="small" />;
    }

    function renderColumnNombre(data: UsuarioTableModel): ReactElement {
        return (
            <>
                <Typography variant="body2">
                    <strong>{data.nombre_completo}</strong>
                </Typography>
                <Typography variant="caption">{data.username}</Typography>
            </>
        );
    }

    function renderColumnActive(data: UsuarioTableModel): ReactElement {
        return <ActiveColumn active={data.activo} onActiveChange={(newValue) => activeUser(data.id, newValue)} />;
    }

    function renderColumnRol(data: UsuarioTableModel): ReactElement {
        return (
            <>
                {data.roles.map((rolName, index) => (
                    <Typography key={index} variant="body2">
                        {rolName}
                    </Typography>
                ))}
            </>
        );
    }

    const mobileComponent = (row: TableModel): ReactElement => {
        return <UsuarioTableItem data={row} renderActions={renderColumnActions} />;
    };

    return (
        <DataTable
            title="Usuarios"
            ref={tableRef}
            headers={tableHeaders}
            getRows={getRows}
            onActionAddClick={addUser}
            mobileComponent={mobileComponent}
        />
    );
};

export const UsuarioTable = TableComponent;
export type UsuarioTableModel = TableModel;
