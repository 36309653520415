import { Typography } from '@material-ui/core';
import { ReactElement } from 'react';

type Props = {
    value: string;
};

const MyComponent = (props: Props): ReactElement => {
    return (
        <Typography variant="h4" gutterBottom>
            {props.value}
        </Typography>
    );
};

export const PageTitle = MyComponent;
