import { Box, Typography, useTheme } from '@material-ui/core';
import { useNotify } from 'hooks';
import './Login.css';
import { ReactElement, useCallback, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { RUTAS } from 'routes';
import { AuthService } from 'services/authentication';
import { LoginForm } from './components/forms/LoginForm';

const PageComponent = (): ReactElement => {
    const history = useHistory();
    const notify = useNotify();
    const theme = useTheme();

    const autenticate = async (username: string, password: string): Promise<boolean> => {
        const response = await AuthService.login(username.trim(), password.trim());
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        return true;
    };

    const load = async () => {
        if (AuthService.isAuthenticated()) {
            history.push(RUTAS.inicio);
        }
    };

    // INI - loadCallback
    const loadCallback = useCallback(load, []);
    useEffect(() => {
        loadCallback();
    }, [loadCallback]);
    // END - loadCallback

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            style={{
                backgroundColor: theme.palette.background.default,
                backgroundImage: 'url(Lanas.jpg)',
                backgroundBlendMode: 'luminosity',
            }}
        >
            <div className="container" id="container">
                <div className="form-container sign-in-container">
                    <LoginForm autenticate={autenticate} />
                </div>
                <div className="overlay-container">
                    <div className="overlay">
                        <div className="overlay-panel overlay-right">
                            <Box pb={3}>
                                <img src="./cetip256.png" width="150px" />
                            </Box>
                            <Typography variant="h5" gutterBottom>
                                CETIP Digital
                            </Typography>
                            <br />
                            <Typography variant="body2">
                                Los CETIP’s son Centros especializados de apoyo a las unidades productivas a través de
                                la prestación de servicios de maquinado, laboratorio, asistencia técnica y capacitación
                                especializada, destinados a lograr la incorporación de innovaciones productivas y la
                                transferencia tecnológica hacia las micro y pequeñas unidades productivas.
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );
};

export const LoginPage = withRouter(PageComponent);
