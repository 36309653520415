import { Typography } from '@material-ui/core';
import { ReactElement } from 'react';
import { withRouter } from 'react-router-dom';

const PageComponent = (): ReactElement => {
    return (
        <div>
            <Typography>Help Page</Typography>
        </div>
    );
};

export const HelpPage = withRouter(PageComponent);
