import { ActionColumn, ActionItem, ActiveColumn } from 'components/core/Columns';
import { DataTable, DataTableRef, HeaderFilter, TableHeader, UpdateParams } from 'components/core/DataTable';
import { ReactElement, useEffect, useRef } from 'react';
import { timer } from 'utils';
import { DataTableItemSample } from './DataTableItemSample';

type TableModel = {
    id: string;
    activo: boolean;
    nombre_completo: string;
    cedula_identidad: string;
    roles: string;
    regional: string;
    estado: string;
    actions: unknown;
};

const nombreFilter: HeaderFilter = { type: 'text' };
const carnetFilter: HeaderFilter = { type: 'text' };
const rolesFilter: HeaderFilter = {
    type: 'select',
    options: [
        { value: 'ADMINISTRADOR', label: 'Administrador' },
        { value: 'TECNICO', label: 'Técnico' },
    ],
};
const regionalFilter: HeaderFilter = {
    type: 'select',
    options: [
        { value: 'LA_PAZ', label: 'La Paz' },
        { value: 'COCHABAMBA', label: 'Cochabamba' },
        { value: 'POTOSI', label: 'Potosí' },
    ],
};
const estadoFilter: HeaderFilter = {
    type: 'select',
    options: [
        { value: 'ACTIVO', label: 'ACTIVO' },
        { value: 'INACTIVO', label: 'INACTIVO' },
    ],
};

const MyComponent = (): ReactElement => {
    const tableRef = useRef<DataTableRef>(null);

    const headers: TableHeader<TableModel>[] = [
        { id: 'actions', label: 'Acciones', render: renderColumnActions, width: 230 },
        { id: 'activo', label: 'Activo', align: 'left', minWidth: 150, render: renderColumnActive },
        { id: 'nombre_completo', label: 'Nombre completo', align: 'left', minWidth: 150, filter: nombreFilter },
        { id: 'cedula_identidad', label: 'Cédula de identidad', align: 'left', minWidth: 150, filter: carnetFilter },
        { id: 'roles', label: 'Rol', align: 'left', filter: rolesFilter },
        { id: 'regional', label: 'Regional', align: 'left', filter: regionalFilter },
        { id: 'estado', label: 'Estado', align: 'left', filter: estadoFilter },
    ];

    const getRows = async (params: UpdateParams<TableModel>) => {
        await timer(1000);
        const tableParams: UpdateParams<TableModel> = {
            ...params,
            rows: [
                {
                    id: '1',
                    activo: true,
                    nombre_completo: 'John Smith 1',
                    cedula_identidad: '1111',
                    roles: 'admin',
                    regional: 'El Alto',
                    estado: 'ACTIVO',
                    actions: undefined,
                },
                {
                    id: '2',
                    activo: true,
                    nombre_completo: 'John Smith 2',
                    cedula_identidad: '3333',
                    roles: 'admin',
                    regional: 'Cochabamba',
                    estado: 'ACTIVO',
                    actions: undefined,
                },
                {
                    id: '3',
                    activo: false,
                    nombre_completo: 'John Smith 3',
                    cedula_identidad: '2222',
                    roles: 'admin',
                    regional: 'Sucre',
                    estado: 'INACTIVO',
                    actions: undefined,
                },
            ],
            count: 1,
        };
        return tableParams;
    };

    const editRow = async (row: TableModel) => {
        console.log(row);
        await timer(1000);
        return true;
    };

    const deleteRow = async (row: TableModel) => {
        console.log(row);
        await timer(1000);
        return true;
    };

    function renderColumnActions(data: TableModel): ReactElement {
        const confirmMessage = (
            <>
                ¿Desea eliminar al usuario de nombre <strong>{data.nombre_completo}</strong>?
            </>
        );
        const items: ActionItem[] = [
            { icon: 'EditIcon', onClick: async () => editRow(data) },
            { icon: 'DeleteIcon', onClick: async () => deleteRow(data), confirm: true, confirmMessage },
        ];
        return <ActionColumn items={items} size="small" />;
    }

    function renderColumnActive(data: TableModel): ReactElement {
        return <ActiveColumn active={data.activo} />;
    }

    const mobileComponent = (row: TableModel): ReactElement => {
        return <DataTableItemSample data={row} />;
    };

    useEffect(() => {
        tableRef.current?.refresh();
    }, [tableRef]);

    return (
        <>
            <DataTable
                ref={tableRef}
                headers={headers}
                getRows={getRows}
                mobileComponent={mobileComponent}
                onActionAddClick={() => console.log('add')}
            />
        </>
    );
};

export const DataTableSample = MyComponent;
export type DataTableSampleModel = TableModel;
