import { AppBar, BottomNavigation, BottomNavigationAction, Hidden, makeStyles, Tab, Theme } from '@material-ui/core';
import React, { forwardRef, ReactElement, useImperativeHandle } from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useHistory } from 'react-router';
import { getIcon } from 'helpers';

export type SectionItem = {
    id: string;
    label: string;
    shortName?: string;
    icon?: string;
    color?: string;
    content: ReactElement;
};

export type SectionNavRefProps = {
    getTabSelected: () => string;
    setTabSelected: (id: string) => void;
};

type Props = {
    sections: SectionItem[];
};

const SectionNavComponent = (props: Props, ref: React.Ref<SectionNavRefProps>): ReactElement => {
    const sections = props.sections;
    const classes = useStyles();
    const history = useHistory();

    const searchParams = new URLSearchParams(history.location.search);
    const SECTION = searchParams.get('section');
    const INITIAL_TAB_SELECTED =
        SECTION && sections.map((s) => s.id).includes(SECTION) ? SECTION : sections.length > 0 ? sections[0].id : '-1';

    const [tabSelected, setTabSelected] = React.useState(INITIAL_TAB_SELECTED);

    function handleChange(evt: React.ChangeEvent<unknown>, value: string) {
        setTabSelected(value);
        window.history.replaceState(null, '', `${history.location.pathname}?section=${value}`);
    }

    const refHandler: () => SectionNavRefProps = () => ({
        getTabSelected: () => tabSelected,
        setTabSelected: (id: string) => setTabSelected(id),
    });
    useImperativeHandle(ref, refHandler, [tabSelected]);

    return (
        <div className={classes.root}>
            {sections.map((s) => s.id).includes(tabSelected) && (
                <TabContext value={tabSelected}>
                    <Hidden xsDown>
                        <TabList
                            className={classes.tabs}
                            value={tabSelected}
                            variant="scrollable"
                            scrollButtons="off"
                            onChange={handleChange}
                            classes={{ indicator: classes.indicator }}
                        >
                            {sections.map((section, index) => {
                                return (
                                    <Tab
                                        key={index}
                                        className={classes.tab}
                                        label={section.label}
                                        value={section.id}
                                        icon={section.icon ? getIcon(section.icon) : undefined}
                                    />
                                );
                            })}
                        </TabList>
                    </Hidden>

                    {sections.map((section, index) => (
                        <TabPanel key={index} value={section.id} className={classes.tabPanel}>
                            {section.content}
                        </TabPanel>
                    ))}

                    <Hidden smUp>
                        <AppBar position="fixed" style={{ top: 'auto', bottom: 0 }}>
                            <BottomNavigation value={tabSelected} onChange={handleChange} showLabels>
                                {sections.map((section, index) => {
                                    return (
                                        <BottomNavigationAction
                                            key={index}
                                            label={section.shortName || section.label}
                                            value={section.id}
                                            icon={section.icon ? getIcon(section.icon) : undefined}
                                            className={classes.navAction}
                                        />
                                    );
                                })}
                            </BottomNavigation>
                        </AppBar>
                    </Hidden>
                </TabContext>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    tabs: {
        minHeight: theme.spacing(0),
        padding: theme.spacing(0),
        margin: theme.spacing(0, 0, 2, 0),
    },
    tab: {
        minHeight: theme.spacing(6),
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
        '&.Mui-selected': {
            background: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
    },
    indicator: {
        backgroundColor: 'transparent',
    },
    tabPanel: {
        padding: theme.spacing(0),
        margin: theme.spacing(0),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(0, 0, 7, 0),
        },
    },
    navAction: {
        padding: '0px',
        minWidth: '48px',
        '& .MuiBottomNavigationAction-label': {
            textOverflow: 'ellipsis',
            width: '100%',
            overflowX: 'hidden',
            padding: '0 4px',
            whiteSpace: 'nowrap',
        },
    },
}));

export const SectionNav = forwardRef(SectionNavComponent);
