import { ROLES } from './authBase';

export const ETAPA_INICIO = {
    id: '1',
    titulo: 'INICIO',
    roles: [ROLES.plataforma.id],
    anterior: null,
    siguiente: '2',
};

export const ETAPA_REGISTRO_RECLAMO = {
    id: '2',
    titulo: 'Registro del reclamo',
    roles: [ROLES.plataforma.id],
    anterior: '1',
    siguiente: '3',
};

export const ETAPA_APROBACION_COORDINADOR = {
    id: '3',
    titulo: 'Aprobación del coordinador',
    roles: [ROLES.coordinador.id],
    anterior: '2',
    siguiente: '4',
};

export const ETAPA_ATENCION_RECLAMO = {
    id: '4',
    titulo: 'Atención del reclamo',
    roles: [ROLES.administracion.id, ROLES.plataforma.id, ROLES.tecnicolaboratorio.id],
    anterior: '3',
    siguiente: '5',
};

export const ETAPA_FIN = {
    id: '5',
    titulo: 'FIN',
    roles: [ROLES.plataforma.id],
    anterior: '4',
    siguiente: null,
};

export const ETAPAS = [
    ETAPA_INICIO,
    ETAPA_REGISTRO_RECLAMO,
    ETAPA_APROBACION_COORDINADOR,
    ETAPA_ATENCION_RECLAMO,
    ETAPA_FIN,
];
