import { forwardRef, Fragment, ReactElement, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { DialogBox, DialogBoxRef } from '../DialogBox';
import {
    FormDialogProps,
    MyFormModel,
    FormGroup,
    FormRow,
    FormControl,
    FormDialogOptions,
    InitialParams,
    FormDialogRef,
} from './Types';
import { Form, Formik, FormikProps } from 'formik';
import {
    MyAutocomplete,
    MyCheckbox,
    MyCheckboxGroup,
    MyChipInput,
    MyDatePicker,
    MyDateTimePicker,
    MyDropzone,
    MyMultipleSelect,
    MyPasswordTextField,
    MyRadioGroup,
    MySelect,
    MyTextArea,
    MyTextField,
    MyTimePicker,
} from '../FormFields';
import { useIsMounted, useLoading, useNotify } from 'hooks';
import { setLocale } from 'yup';

setLocale({
    mixed: {
        default: 'No es válido ',
        required: 'El campo es obligatorio ',
        notType: 'El campo no tiene el formato correcto ',
    },
});

const MyComponent = (props: FormDialogProps, ref: Ref<FormDialogRef>): ReactElement => {
    const { formLayout, getData, validationSchema, submit, debug, variant, dialogHeader, maxWidth, fullwidth } = props;
    const classes = useStyles();
    const notify = useNotify();
    const isMounted = useIsMounted();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [{ isLoading }, { start, stop }] = useLoading();
    const dialogRef = useRef<DialogBoxRef<InitialParams>>(null);
    const [openOptions, setOpenOptions] = useState<FormDialogOptions>({});
    const [formModel, setFormModel] = useState<MyFormModel>({});
    const formikRef = useRef<FormikProps<MyFormModel>>(null);

    const handleGetData = async (params: InitialParams) => {
        start();
        const data = getData ? await getData(params) : {};
        stop();
        if (data) setFormModel(data);
        return data;
    };

    useEffect(() => {
        formikRef.current?.resetForm({});
    }, [formikRef, formModel]);

    const renderControl = (
        row: FormRow<MyFormModel>,
        col: FormControl<MyFormModel>,
        colIndex: number,
        formik?: FormikProps<typeof formModel>,
    ): ReactElement => {
        let formControl = <div key="0" />;

        const name = col.name as string;
        const label = col.label as string;
        const delay = col.delay;
        const disabled = col.disabled as boolean;
        const multiline = col.multiline as boolean;
        const fieldRequired = col.fieldRequired as string;

        if (col.type === 'text') {
            formControl = (
                <MyTextField
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    multiline={multiline}
                    formik={formik}
                    type={col.inputType}
                    onChange={col.onChange}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    delay={delay}
                />
            );
        }
        if (col.type === 'password') {
            formControl = (
                <MyPasswordTextField
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    formik={formik}
                    onChange={col.onChange}
                />
            );
        }
        if (col.type === 'select') {
            formControl = (
                <MySelect
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    formik={formik}
                    options={col.options || []}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    onChange={col.onChange}
                />
            );
        }
        if (col.type === 'autocomplete') {
            formControl = (
                <MyAutocomplete
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    formik={formik}
                    options={col.options || []}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    onChange={col.onChange}
                />
            );
        }
        if (col.type === 'multiselect') {
            formControl = (
                <MyMultipleSelect
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    formik={formik}
                    options={col.options || []}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    onChange={col.onChange}
                />
            );
        }
        if (col.type === 'dropzone') {
            formControl = (
                <MyDropzone
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    formik={formik}
                    filesLimit={col.filesLimit}
                    filesExt={col.filesExt}
                    acceptedFiles={col.acceptedFiles}
                    maxFileSize={col.maxFileSize}
                    dropzoneText={col.dropzoneText}
                    onChange={col.onChange}
                    // ref={controlRef}
                />
            );
        }
        // if (col.type === 'editor') {
        //     formControl = (
        //         <MyEditor
        //             label={label}
        //             name={name}
        //             disabled={disabled || isLoading}
        //             fieldRequired={fieldRequired}
        //             variant={col.variant}
        //             formik={formik}
        //             onChange={col.onChange}
        //             delay={delay}
        //         />
        //     );
        // }
        if (col.type === 'chip') {
            formControl = (
                <MyChipInput
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    formik={formik}
                    onChange={col.onChange}
                />
            );
        }
        if (col.type === 'date') {
            formControl = (
                <MyDatePicker
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    formik={formik}
                    disablePast={col.disablePast}
                    format={col.format}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    onChange={col.onChange}
                />
            );
        }
        if (col.type === 'time') {
            formControl = (
                <MyTimePicker
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    formik={formik}
                    format={col.format}
                    ampm={col.ampm}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    onChange={col.onChange}
                    delay={col.delay}
                />
            );
        }
        if (col.type === 'datetime') {
            formControl = (
                <MyDateTimePicker
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    formik={formik}
                    disablePast={col.disablePast}
                    format={col.format}
                    ampm={col.ampm}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    onChange={col.onChange}
                />
            );
        }
        if (col.type === 'checkbox') {
            formControl = (
                <MyCheckbox
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    formik={formik}
                    onChange={col.onChange}
                />
            );
        }
        if (col.type === 'checkbox-group') {
            formControl = (
                <MyCheckboxGroup
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    formik={formik}
                    options={col.options || []}
                    onChange={col.onChange}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    inlineDisplay={col.inlineDisplay}
                />
            );
        }
        if (col.type === 'radio-group') {
            formControl = (
                <MyRadioGroup
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    formik={formik}
                    options={col.options || []}
                    onChange={col.onChange}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    inlineDisplay={col.inlineDisplay}
                />
            );
        }
        if (col.type === 'textarea') {
            formControl = (
                <MyTextArea
                    label={label}
                    name={name}
                    disabled={disabled || isLoading}
                    formik={formik}
                    onChange={col.onChange}
                    fieldRequired={fieldRequired}
                    variant={col.variant}
                    rows={col.rows}
                    delay={delay}
                />
            );
        }
        if (col.type === 'empty' && col.render && formikRef.current) {
            formControl = col.render(formikRef.current);
        }

        const HIDDEN = typeof col.hidden === 'function' ? col.hidden(formik?.values || {}) : col.hidden;
        if (HIDDEN) formControl = <></>;
        return (
            <Fragment key={colIndex}>
                <div className={classes.col} style={{ width: fullScreen ? '100%' : `${100 / row.length}%` }}>
                    <div>{formControl}</div>
                    {!!col.infoText && <div className={classes.infoText}>{col.infoText}</div>}
                </div>
            </Fragment>
        );
    };

    function renderFormLayout(formik: FormikProps<typeof formModel>): ReactElement {
        const formLayoutFinal = typeof formLayout === 'function' ? formLayout(formik.values, formik) : formLayout;
        const form = formLayoutFinal.map((layout: FormGroup<MyFormModel>, layoutIndex: number): ReactElement => {
            return (
                <div key={layoutIndex} className={classes.grid}>
                    {layout.title && <div className={classes.controlTitle}>{layout.title}</div>}

                    {layout.grid.map((row: FormRow<MyFormModel>, rowIndex: number) => {
                        return (
                            <div key={rowIndex} className={classes.row}>
                                {row.map((col: FormControl<MyFormModel>, colIndex: number): ReactElement => {
                                    return renderControl(row, col, colIndex, formik);
                                })}
                            </div>
                        );
                    })}
                </div>
            );
        });
        return <>{form}</>;
    }

    const renderValues = (formik: FormikProps<typeof formModel>) => {
        return (
            <div className={classes.debugBar}>
                <strong>Values:</strong>
                <pre>{JSON.stringify(formik.values, null, 2)}</pre>
                <strong>Errors:</strong>
                <pre>{JSON.stringify(formik.errors, null, 2)}</pre>
            </div>
        );
    };

    const dialogContent = (data: unknown, formik: FormikProps<typeof formModel>): ReactElement => {
        return (
            <div className={classes.formContent}>
                <Form style={{ backgroundColor: 'unset', padding: '0px', textAlign: 'left' }}>
                    {renderFormLayout(formik)}
                </Form>
            </div>
        );
    };

    const refHandler: () => FormDialogRef = () => ({
        open: (options?: FormDialogOptions) => {
            if (!isMounted()) return;
            const newFormOptions = options ? options : {};
            setOpenOptions(newFormOptions);

            dialogRef.current?.open(options);
        },
        refresh: () => dialogRef.current?.refresh(),
    });
    useImperativeHandle(ref, refHandler, [dialogRef]);

    return (
        <Formik
            initialValues={formModel}
            onSubmit={() => {
                ({});
            }}
            innerRef={formikRef}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {(formik) => {
                return (
                    <DialogBox
                        ref={dialogRef}
                        title={openOptions.params?.title || 'Crear'}
                        titleIcon={openOptions.params?.titleIcon}
                        subtitle={openOptions.params?.subtitle}
                        dialogContent={(data) => dialogContent(data, formik)}
                        dialogFooter={debug ? () => renderValues(formik) : undefined}
                        dialogHeader={dialogHeader ? () => dialogHeader(formik) : undefined}
                        variant={variant}
                        maxWidth={maxWidth}
                        fullwidth={fullwidth}
                        getData={handleGetData}
                        actions={[
                            {
                                on: props.cancelButton || 'CancelButton',
                                click: async () => {
                                    setFormModel({});
                                    return true;
                                },
                            },
                            {
                                on: props.submitButton || 'AcceptButton',
                                click: async () => {
                                    if (!submit) return true;

                                    formik.setSubmitting(true);
                                    formik.handleSubmit();

                                    const errors = await formik.validateForm();
                                    if (!isMounted()) return false;

                                    const isValid = Object.keys(errors).length === 0;
                                    if (!isValid) {
                                        formik.setSubmitting(false);
                                        notify.error('Por favor complete el formulario.');
                                        return false;
                                    }
                                    const success = await submit(formik.values);
                                    if (!isMounted()) return false;

                                    formik.setSubmitting(false);

                                    if (success) {
                                        setFormModel({});
                                    }

                                    return success;
                                },
                            },
                        ]}
                    />
                );
            }}
        </Formik>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    formContent: {
        padding: theme.spacing(),
    },
    grid: {
        width: '100%',
        margin: theme.spacing(0, 0, 2, 0),
    },
    row: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        width: '100%',
        padding: theme.spacing(0),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    col: {
        flexGrow: 1,
        padding: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 0),
        },
    },
    infoText: {
        margin: '3px 14px 0',
        fontSize: '0.75rem',
    },
    controlTitle: {
        fontWeight: 'bold',
        margin: theme.spacing(0, 1, 0, 1),
        padding: theme.spacing(0.5, 1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    debugBar: {
        overflow: 'auto',
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1),
    },
}));

export const FormDialog = forwardRef(MyComponent);
