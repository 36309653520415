import React, { ReactElement } from 'react';

// material-ui
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

// types
import { DataListProps } from './Types';

export const DataList = ({ updateParams, mobileComponent, loading }: DataListProps): ReactElement => {
    const { rows } = updateParams;
    const classes = useStyles();

    const renderEmptyTable = () => {
        return (
            <Box className={classes.info}>
                <Typography align="center">{loading ? 'Cargando...' : 'Sin registros'}</Typography>
            </Box>
        );
    };

    return (
        <Box>
            {rows.map((row, index) => {
                const rowId = `${row.id || index}`;
                return (
                    <React.Fragment key={rowId}>
                        <Box>{mobileComponent(row)}</Box>
                    </React.Fragment>
                );
            })}
            {rows.length === 0 && renderEmptyTable()}
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    info: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: theme.spacing(6),
    },
}));
