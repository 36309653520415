import { Box, Card, CardContent, Divider, Link, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { ENUM_TIPO_TRABAJADOR } from 'base/enums';
import { MyButton } from 'components/core/Buttons';
import { SelectOption } from 'components/core/FormDialog/Types';
import { getIcon } from 'helpers';
import { ReactElement } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

type DetalleSolicitudModel = Solicitud.DetalleSolicitudModel;

type Props = {
    data: DetalleSolicitudModel;
    enviarInforme?: () => Promise<void>;
    enviarBoleta?: () => Promise<void>;
};

const MyComponent = ({ data, enviarInforme, enviarBoleta }: Props): ReactElement => {
    const classes = useStyles();
    const theme = useTheme();

    const tipoTrabajadorOptions: SelectOption[] = Object.keys(ENUM_TIPO_TRABAJADOR)
        .map((key) => ({
            value: (ENUM_TIPO_TRABAJADOR as EnumValue)[key].value,
            label: (ENUM_TIPO_TRABAJADOR as EnumValue)[key].label,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const encabezado = (data: DetalleSolicitudModel) => (
        <>
            <Box mb={5} mt={3}>
                <Typography variant="h4" noWrap align="center">
                    Informe de Servicio
                </Typography>
                <Box my={1}>
                    <Typography variant="h5" noWrap align="center">
                        CIP - {data.rubro.nombre}
                    </Typography>
                </Box>
            </Box>
            <Box display="flex" width="100%" mb={3}>
                <Box>
                    <Typography>Responsable Pro Bolivia</Typography>
                    <Typography>
                        <strong>{data.usuario_registro_solicitud.nombre_completo}</strong>
                    </Typography>
                </Box>
                <Box ml="auto">
                    <Typography align="right">Fecha de solicitud</Typography>
                    <Typography align="right">
                        <strong>{data.fecha_solicitud}</strong>
                    </Typography>
                </Box>
            </Box>
            <Box display="flex" width="100%" mb={5}>
                <Box>
                    <Typography>Código de solicitud</Typography>
                    <Typography>
                        <strong>{data.codigo_solicitud}</strong>
                    </Typography>
                </Box>
            </Box>
        </>
    );

    const solicitanteCard = (solicitante: typeof data.solicitante) => (
        <Card>
            <div className={classes.sectionTitle}>Solicitante</div>
            <CardContent>
                <Box width="100%" overflow="auto">
                    <Scrollbars autoHeight autoHeightMax="100%">
                        <table width="100%" cellPadding={2}>
                            <thead>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Nombre</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.nombre}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Apellido Paterno</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.apellido_paterno || '-'}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Apellido materno</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.apellido_materno || '-'}</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Cédula de identidad</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.cedula_identidad || '-'}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Teléfono</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.telefono || '-'}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Correo electrónico</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.email || '-'}</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography variant="body2">¿Es dueño de la UP?</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{solicitante.es_duenio_up}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Tipo de trabajador</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>
                                                {tipoTrabajadorOptions.find(
                                                    (x) => x.value === solicitante.tipo_trabajador,
                                                )?.label || '-'}
                                            </strong>
                                        </Typography>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </Scrollbars>
                </Box>
            </CardContent>
        </Card>
    );

    const unidadProductivaCard = (up: typeof data.solicitante.unidad_productiva) => (
        <Card>
            <div className={classes.sectionTitle}>Unidad Productiva</div>
            <CardContent>
                <Box width="100%" overflow="auto">
                    <Scrollbars autoHeight autoHeightMax="100%">
                        <table width="100%" cellPadding={2}>
                            <thead>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Nombre o Razón Social</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{up?.nombre_razon_social}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Dirección</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{up?.direccion || '-'}</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Rubro</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{up?.rubro?.nombre || '-'}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Rubro específico</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{up?.subtipo_rubro?.nombre || '-'}</strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Producto principal</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{up?.producto || '-'}</strong>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2">Antigüedad</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>
                                                {up?.antiguedad_anios || '-'} años {up?.antiguedad_meses || '-'} meses
                                            </strong>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Asociación/Gremio</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{up?.asociacion_gremio || '-'}</strong>
                                        </Typography>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </Scrollbars>
                </Box>
            </CardContent>
        </Card>
    );

    const servicioRequeridoCard = (data: DetalleSolicitudModel) => (
        <Card>
            <div className={classes.sectionTitle}>Servicio requerido</div>
            <CardContent>
                <Box width="100%" overflow="auto">
                    <table width="100%" cellPadding={2}>
                        <tbody>
                            <tr>
                                <td>
                                    <Typography variant="body2">Rubro</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{data.rubro.nombre}</strong>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="body2">Tipo de servicio</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{data.servicio.nombre}</strong>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="body2">Fecha estimada de entrega</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{data.fecha_entrega}</strong>
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>

                <Box width="100%" overflow="auto" my={3}>
                    <Typography variant="body2">Parámetros</Typography>
                    <table width="100%" cellPadding={2}>
                        <tbody>
                            {data.parametros.map((param, index) => (
                                <tr key={index}>
                                    <td>
                                        <Typography variant="body2">
                                            <strong> - {param.parametro}</strong>
                                        </Typography>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            </CardContent>
        </Card>
    );

    const materialEntregadoCard = (material: typeof data.material_entregado) => (
        <Card>
            <div className={classes.sectionTitle}>Material entregado</div>
            <CardContent>
                {material?.tipo && (
                    <Box width="100%" overflow="auto">
                        <table width="100%" cellPadding={2}>
                            <thead>
                                <tr>
                                    <td>
                                        <Typography variant="body2">Tipo de material</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{material?.tipo || '-'}</strong>
                                        </Typography>
                                    </td>
                                    {material?.tipo.toUpperCase() === 'OTRO' && (
                                        <td>
                                            <Typography variant="body2">Tipo de material (Otro)</Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <strong>{material?.tipo_otro || ''}</strong>
                                            </Typography>
                                        </td>
                                    )}
                                </tr>
                            </thead>
                        </table>
                    </Box>
                )}
                <Box width="100%" overflow="auto" my={3}>
                    <Typography variant="body2" gutterBottom>
                        Materiales
                    </Typography>
                    <Scrollbars autoHeight autoHeightMax="100%">
                        <table width="100%" cellPadding={2} style={{ borderCollapse: 'collapse', marginBottom: '8px' }}>
                            <thead>
                                <tr>
                                    {material?.material_columns.map((col, index) => {
                                        return (
                                            <td
                                                key={index}
                                                style={{
                                                    border: `solid 1px ${theme.palette.divider}`,
                                                    padding: '0 8px',
                                                    backgroundColor: theme.palette.background.default,
                                                }}
                                            >
                                                <strong>{col}</strong>
                                            </td>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {material?.material_items.length === 0 && (
                                    <tr key="0">
                                        <td
                                            colSpan={999}
                                            style={{
                                                border: `solid 1px ${theme.palette.divider}`,
                                                padding: '0 8px',
                                                backgroundColor: theme.palette.background.paper,
                                                textAlign: 'center',
                                            }}
                                        >
                                            Sin registros
                                        </td>
                                    </tr>
                                )}
                                {material?.material_items.map((row, rowIndex) => {
                                    return (
                                        <tr key={rowIndex}>
                                            {row.map((value, colIndex) => {
                                                return (
                                                    <td
                                                        key={colIndex}
                                                        style={{
                                                            border: `solid 1px ${theme.palette.divider}`,
                                                            padding: '0 8px',
                                                            backgroundColor: theme.palette.background.paper,
                                                        }}
                                                    >
                                                        {value}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Scrollbars>
                </Box>
            </CardContent>
        </Card>
    );

    const reservaEquiposCard = (data: DetalleSolicitudModel) => (
        <Card>
            <div className={classes.sectionTitle}>Reserva de equipos</div>
            <CardContent>
                <Box width="100%" overflow="auto" my={3}>
                    <Scrollbars autoHeight autoHeightMax="100%">
                        <table width="100%" cellPadding={2} style={{ borderCollapse: 'collapse', marginBottom: '8px' }}>
                            <thead>
                                <tr>
                                    <td className={classes.headerCell}>
                                        <strong>Fecha</strong>
                                    </td>
                                    <td className={classes.headerCell}>
                                        <strong>Hora inicio</strong>
                                    </td>
                                    <td className={classes.headerCell}>
                                        <strong>Hora fin</strong>
                                    </td>
                                    <td className={classes.headerCell}>
                                        <strong>Maquinaria</strong>
                                    </td>
                                    <td className={classes.headerCell}>
                                        <strong>Observacion</strong>
                                    </td>
                                </tr>
                                {data.reserva_items.map((item, rowIndex) => {
                                    return (
                                        <tr key={rowIndex}>
                                            <td className={classes.bodyCell}>{item.fecha}</td>
                                            <td className={classes.bodyCell}>{item.hora_inicio}</td>
                                            <td className={classes.bodyCell}>{item.hora_fin}</td>
                                            <td className={classes.bodyCell}>{item.maquinaria.nombre}</td>
                                            <td className={classes.bodyCell}>{item.observacion}</td>
                                        </tr>
                                    );
                                })}
                            </thead>
                        </table>
                    </Scrollbars>
                </Box>
            </CardContent>
        </Card>
    );

    const informeProduccionCard = (informe: typeof data.informe_produccion) => (
        <Card>
            <div className={classes.sectionTitle2}>Informe de Producción</div>
            <CardContent>
                <Box width="100%" overflow="auto">
                    <table width="100%" cellPadding={2}>
                        <thead>
                            <tr>
                                <td>
                                    <Typography variant="body2">Responsable Pro Bolivia</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{informe?.usuario_registro.nombre_completo || '-'}</strong>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="body2" align="right">
                                        Fecha de registro de informe
                                    </Typography>
                                    <Typography variant="body2" align="right" gutterBottom>
                                        <strong>{informe?.fecha_informe || '-'}</strong>
                                    </Typography>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </Box>

                <Divider />

                <Box width="100%" overflow="auto" my={3}>
                    <Typography variant="body2" gutterBottom>
                        Resultados
                    </Typography>
                    <Scrollbars autoHeight autoHeightMax="100%">
                        <table width="100%" cellPadding={2} style={{ borderCollapse: 'collapse', marginBottom: '8px' }}>
                            <thead>
                                <tr>
                                    {informe?.resultado_columns.map((col, index) => {
                                        return (
                                            <td
                                                key={index}
                                                style={{
                                                    border: `solid 1px ${theme.palette.divider}`,
                                                    padding: '0 8px',
                                                    backgroundColor: theme.palette.background.default,
                                                }}
                                            >
                                                <strong>{col}</strong>
                                            </td>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {informe?.resultado_items.length === 0 && (
                                    <tr key="0">
                                        <td
                                            colSpan={999}
                                            style={{
                                                border: `solid 1px ${theme.palette.divider}`,
                                                padding: '0 8px',
                                                backgroundColor: theme.palette.background.paper,
                                                textAlign: 'center',
                                            }}
                                        >
                                            Sin registros
                                        </td>
                                    </tr>
                                )}
                                {informe?.resultado_items.map((row, rowIndex) => {
                                    return (
                                        <tr key={rowIndex}>
                                            {row.map((value, colIndex) => {
                                                return (
                                                    <td
                                                        key={colIndex}
                                                        style={{
                                                            border: `solid 1px ${theme.palette.divider}`,
                                                            padding: '0 8px',
                                                            backgroundColor: theme.palette.background.paper,
                                                        }}
                                                    >
                                                        {value}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Scrollbars>
                    <Typography align="right">
                        <strong>Precio total:</strong> {informe?.precio_final} Bs.
                    </Typography>
                </Box>

                {informe?.horario_uso_maquinaria && informe?.horario_uso_maquinaria.length > 0 && (
                    <>
                        <Box width="100%" overflow="auto" my={3}>
                            <Typography variant="body2" gutterBottom>
                                Registro de uso de maquinaria
                            </Typography>
                            <Scrollbars autoHeight autoHeightMax="100%">
                                <table
                                    width="100%"
                                    cellPadding={2}
                                    style={{ borderCollapse: 'collapse', marginBottom: '8px' }}
                                >
                                    <thead>
                                        <tr>
                                            <td className={classes.headerCell}>
                                                <strong>Fecha</strong>
                                            </td>
                                            <td className={classes.headerCell}>
                                                <strong>Maquinaria</strong>
                                            </td>
                                            <td className={classes.headerCell}>
                                                <strong>Hora inicio</strong>
                                            </td>
                                            <td className={classes.headerCell}>
                                                <strong>Hora fin</strong>
                                            </td>
                                            <td className={classes.headerCell}>
                                                <strong>Cantidad de horas</strong>
                                            </td>
                                            <td className={classes.headerCell}>
                                                <strong>Observacion</strong>
                                            </td>
                                        </tr>
                                        {informe?.horario_uso_maquinaria.map((item, rowIndex) => {
                                            return (
                                                <tr key={rowIndex}>
                                                    <td className={classes.bodyCell}>{item.fecha}</td>
                                                    <td className={classes.bodyCell}>{item.maquinaria.nombre}</td>
                                                    <td className={classes.bodyCell}>{item.hora_inicio}</td>
                                                    <td className={classes.bodyCell}>{item.hora_fin}</td>
                                                    <td className={classes.bodyCell}>{item.cant_horas}</td>
                                                    <td className={classes.bodyCell}>{item.observacion}</td>
                                                </tr>
                                            );
                                        })}
                                    </thead>
                                </table>
                            </Scrollbars>
                            <Typography align="right">
                                <strong>Tiempo total:</strong> {informe?.total_horas} horas
                            </Typography>
                        </Box>
                    </>
                )}

                {informe && informe.adjuntos && informe.adjuntos.length > 0 && (
                    <Box width="100%" overflow="auto">
                        <table width="100%" cellPadding={2}>
                            <thead>
                                <tr>
                                    <td>
                                        <Typography variant="body2" gutterBottom>
                                            Archivos adjuntos
                                        </Typography>
                                        {informe.adjuntos.map((adjuntoItem, index) => {
                                            return (
                                                <Typography variant="body2" gutterBottom key={index}>
                                                    <strong>
                                                        <Link
                                                            color="textPrimary"
                                                            href={adjuntoItem.url}
                                                            target="_blank"
                                                        >
                                                            {getIcon('AttachmentIcon', {
                                                                fontSize: 'small',
                                                                color: 'secondary',
                                                            })}{' '}
                                                            {adjuntoItem.title}
                                                        </Link>
                                                    </strong>
                                                </Typography>
                                            );
                                        })}
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </Box>
                )}
                {enviarInforme && (
                    <Box display="flex" justifyContent="flex-end">
                        <MyButton label="Enviar informe" color="secondary" icon="SendIcon" onClick={enviarInforme} />
                    </Box>
                )}
            </CardContent>
        </Card>
    );

    const boletaPagoCard = (boleta: typeof data.boleta) => (
        <Card>
            <div className={classes.sectionTitle3}>Boleta de pago</div>
            <CardContent>
                <Box width="100%" overflow="auto">
                    <table width="100%" cellPadding={2}>
                        <thead>
                            <tr>
                                <td>
                                    <Typography variant="body2">Responsable Pro Bolivia</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{boleta?.usuario_registro.nombre_completo || '-'}</strong>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="body2" align="right">
                                        Fecha de registro de boleta
                                    </Typography>
                                    <Typography variant="body2" align="right" gutterBottom>
                                        <strong>{boleta?.fecha_registro || '-'}</strong>
                                    </Typography>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </Box>

                <Divider />

                <Box width="100%" overflow="auto" my={3}>
                    <table width="100%" cellPadding={2}>
                        <thead>
                            <tr>
                                <td>
                                    <Typography variant="body2">Nro. boleta</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{boleta?.nro_boleta_pago || '-'}</strong>
                                    </Typography>
                                </td>
                                {/* <td>
                                                <Typography variant="body2">Nro. factura</Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{boleta?.nro_factura || '-'}</strong>
                                                </Typography>
                                            </td> */}
                                <td>
                                    <Typography variant="body2">Fecha pago</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{boleta?.fecha_pago || '-'}</strong>
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography variant="body2">Monto (Bs)</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{boleta?.monto_pago || '-'}</strong>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="body2">Nro. cuenta</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{boleta?.cuenta || '-'}</strong>
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography variant="body2" gutterBottom>
                                        Escaneado
                                    </Typography>
                                    {boleta?.escaneado.map((adjuntoItem, index) => {
                                        return (
                                            <Typography variant="body2" gutterBottom key={index}>
                                                <strong>
                                                    <Link color="textPrimary" href={adjuntoItem.url} target="_blank">
                                                        {getIcon('AttachmentIcon', {
                                                            fontSize: 'small',
                                                            color: 'secondary',
                                                        })}{' '}
                                                        {adjuntoItem.title}
                                                    </Link>
                                                </strong>
                                            </Typography>
                                        );
                                    })}
                                </td>
                            </tr>
                        </thead>
                    </table>
                </Box>
                {enviarBoleta && (
                    <Box display="flex" justifyContent="flex-end">
                        <MyButton label="Enviar boleta" color="secondary" icon="SendIcon" onClick={enviarBoleta} />
                    </Box>
                )}
            </CardContent>
        </Card>
    );

    return (
        <div className={classes.root}>
            <Divider />

            {encabezado(data)}

            {data.solicitante && (
                <>
                    <Box mb={5}>{solicitanteCard(data.solicitante)}</Box>

                    {data.solicitante.unidad_productiva && (
                        <Box mb={5}>{unidadProductivaCard(data.solicitante.unidad_productiva)}</Box>
                    )}
                </>
            )}

            <Box mb={5}>{servicioRequeridoCard(data)}</Box>

            {data.material_entregado && data.rubro.material_columns && (
                <Box mb={5}>{materialEntregadoCard(data.material_entregado)}</Box>
            )}

            {data.reserva_items && data.reserva_items.length > 0 && <Box mb={5}>{reservaEquiposCard(data)}</Box>}

            {data.informe_produccion && <Box mb={5}>{informeProduccionCard(data.informe_produccion)}</Box>}

            {data.boleta && <Box mb={5}>{boletaPagoCard(data.boleta)}</Box>}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& td': { verticalAlign: 'top' },
    },
    sectionTitle: {
        fontWeight: 'bold',
        margin: theme.spacing(0, 0, 1, 0),
        padding: theme.spacing(0.5, 1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    sectionTitle2: {
        fontWeight: 'bold',
        margin: theme.spacing(0, 0, 1, 0),
        padding: theme.spacing(0.5, 1),
        backgroundColor: theme.palette.grey[700],
        color: theme.palette.getContrastText(theme.palette.grey[700]),
    },
    sectionTitle3: {
        fontWeight: 'bold',
        margin: theme.spacing(0, 0, 1, 0),
        padding: theme.spacing(0.5, 1),
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.getContrastText(theme.palette.secondary.dark),
    },
    headerCell: {
        border: `solid 1px ${theme.palette.divider}`,
        padding: '0 8px',
        backgroundColor: theme.palette.background.default,
    },
    bodyCell: {
        border: `solid 1px ${theme.palette.divider}`,
        padding: '0 8px',
        backgroundColor: theme.palette.background.paper,
    },
}));

export const DetalleSolicitud = MyComponent;
