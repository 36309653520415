/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useIsMounted } from 'hooks';
import { useLoading } from 'hooks/useLoading/useLoading';
import { FormEvent, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { RUTAS } from 'routes';

type Props = {
    autenticate: (username: string, password: string) => Promise<boolean>;
};

const PageComponent = (props: Props): ReactElement => {
    const history = useHistory();
    const theme = useTheme();
    const isMounted = useIsMounted();
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
    const [{ isLoading }, { start, stop }] = useLoading();

    const handleSubmit = async (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        evt.stopPropagation();

        if (isLoading) return;

        const $form: any = evt.target as HTMLInputElement;
        const username = $form.username.value;
        const password = $form.password.value;

        start();
        const success = await props.autenticate(username, password);
        stop();
        if (!isMounted()) return;

        if (success) {
            history.push(RUTAS.inicio);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {!matchesMD && (
                <Box className="logo">
                    <img src="./cetip256.png" width="150px" />
                </Box>
            )}
            {!matchesMD && <Box m={8} />}
            <Box pb={2}>
                <Typography variant="h5">Inicio de sesión</Typography>
            </Box>
            <input name="username" type="text" placeholder="Usuario" />
            <input name="password" type="password" placeholder="Contraseña" />
            <Box p={2}>
                <Typography variant="body2">
                    <a href="#">¿Olvidaste tu contraseña?</a>
                </Typography>
            </Box>
            <Button
                type="submit"
                variant="contained"
                color={matchesMD ? 'primary' : 'primary'}
                startIcon={isLoading ? <CircularProgress color="inherit" size={18} /> : undefined}
            >
                Ingresar
            </Button>
        </form>
    );
};

export const LoginForm = PageComponent;
