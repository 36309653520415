import React, { ReactElement, CSSProperties } from 'react';
import { makeStyles, Theme, TableCell, TableSortLabel, TableHead, TableRow, Box } from '@material-ui/core';
import { DataTableHeaderProps, TableHeader, MyTableModel } from './Types';

// constants
export const DEFAULT_COLUMN_WIDTH = 'auto';
export const DEFAULT_COLUMN_MIN_WIDTH = 'auto';
export const DEFAULT_COLUMN_MAX_WIDTH = 'auto';
export const DEFAULT_COLUMN_ALIGN = 'center';

export const DataTableHeader = ({ headers, updateParams, onOrderChange }: DataTableHeaderProps): ReactElement => {
    const { order, orderBy } = updateParams;
    const classes = useStyles();

    const handleChangeOrder = (property: string) => () => {
        const newOrderBy = property as string;
        const isAsc = orderBy === newOrderBy && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        onOrderChange(newOrder, newOrderBy);
    };

    const renderTitle = (column: TableHeader<MyTableModel>): ReactElement => {
        if (typeof column.sort !== 'undefined' && column.sort) {
            return (
                <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={handleChangeOrder(column.id as string)}
                    style={buildJustifyContentStyle(column)}
                >
                    <Box className={classes.titleLabel} style={buildTextAlignStyle(column)}>
                        {column.label}
                    </Box>
                    {orderBy === column.id && <span className={classes.visuallyHidden} />}
                </TableSortLabel>
            );
        }
        if (typeof column.sort === 'boolean' || !column.sort) {
            return (
                <Box className={classes.titleLabel} style={buildTextAlignStyle(column)}>
                    {column.label}
                </Box>
            );
        }
        return <Box />;
    };

    function buildTextAlignStyle(column: TableHeader<MyTableModel>): CSSProperties {
        return { textAlign: column.align || DEFAULT_COLUMN_ALIGN };
    }

    function buildJustifyContentStyle(column: TableHeader<MyTableModel>): CSSProperties {
        const textAlign = column.align || DEFAULT_COLUMN_ALIGN;
        if (textAlign === 'left') return { justifyContent: 'flex-start' };
        if (textAlign === 'right') return { justifyContent: 'flex-end' };
        return { justifyContent: 'center' };
    }

    function buildWidthStyle(column: TableHeader<MyTableModel>): CSSProperties {
        return {
            width: column.width || DEFAULT_COLUMN_WIDTH,
            minWidth: column.minWidth || (column.width ? column.width : DEFAULT_COLUMN_MIN_WIDTH),
            maxWidth: column.maxWidth || (column.width ? column.width : DEFAULT_COLUMN_MAX_WIDTH),
        };
    }

    const renderTableCell = (column: TableHeader<MyTableModel>, columnIndex: number): ReactElement => {
        return (
            <TableCell
                className={classes.cell}
                style={buildWidthStyle(column)}
                key={columnIndex}
                sortDirection={orderBy === column.id ? order : false}
            >
                <Box className={classes.titleBox} style={buildJustifyContentStyle(column)}>
                    {renderTitle(column)}
                </Box>
            </TableCell>
        );
    };

    return (
        <TableHead>
            <TableRow>{headers.map((column, columnIndex) => renderTableCell(column, columnIndex))}</TableRow>
        </TableHead>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    cell: {
        padding: theme.spacing(0),
        fontWeight: 'bold',
        // background: theme.palette.background.default,
        // color: theme.palette.getContrastText(theme.palette.background.default),
    },
    titleBox: {
        height: '50px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    titleLabel: {
        padding: theme.spacing(0, 2),
    },
}));
