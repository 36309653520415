import { Box } from '@material-ui/core';
import { DataSearch } from 'components/core/DataSearch';
import { ReactElement } from 'react';

const MyComponent = (): ReactElement => {
    return (
        <>
            <Box my={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box display="flex">
                    <DataSearch variant="light" />
                    <Box p={1} />
                    <DataSearch variant="dark" />
                </Box>
            </Box>
        </>
    );
};

export const DataSearchSample = MyComponent;
