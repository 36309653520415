import { ROLES } from 'base/authBase';
import { SectionItem, SectionNav } from 'components/core/SectionNav';
import { useSession } from 'hooks';
import { ReactElement } from 'react';
import { withRouter } from 'react-router-dom';
import { SolicitantesSection, SolicitudesServiciosSection, UnidadesProductivasSection } from './sections';
import { PendientesSection } from './sections/Pendientes';

const PageComponent = (): ReactElement => {
    const sections: SectionItem[] = [];
    const session = useSession();

    const esTecnico = session.roles.some((rol) => rol.id === ROLES.tecnicooperador.id);
    const esLaboratorio = session.roles.some((rol) => rol.id === ROLES.tecnicolaboratorio.id);
    const esPlataforma = session.roles.some((rol) => rol.id === ROLES.plataforma.id);
    const esCoordinador = session.roles.some((rol) => rol.id === ROLES.coordinador.id);
    const esAdministracion = session.roles.some((rol) => rol.id === ROLES.administracion.id);

    if (esCoordinador || esTecnico || esLaboratorio || esAdministracion) {
        sections.push({
            id: 'pendientes',
            label: 'Pendientes',
            icon: 'AssignmentTurnedInIcon',
            content: <PendientesSection />,
        });
    }

    if (esPlataforma || esCoordinador || esTecnico || esLaboratorio || esAdministracion) {
        sections.push({
            id: 'servicios',
            label: 'Solicitudes de Servicio',
            icon: 'AssignmentTurnedInIcon',
            content: <SolicitudesServiciosSection />,
        });
    }

    if (esPlataforma) {
        sections.push({
            id: 'unidades-productivas',
            label: 'Unidades productivas',
            icon: 'StoreIcon',
            content: <UnidadesProductivasSection />,
        });
    }

    if (esPlataforma) {
        sections.push({
            id: 'solicitantes',
            label: 'Solicitantes',
            icon: 'GroupIcon',
            content: <SolicitantesSection />,
        });
    }

    return (
        <div>
            <SectionNav sections={sections} />
        </div>
    );
};

export const SolicitudServicioModule = withRouter(PageComponent);
