import { ActionColumn, ActionItem, ActiveColumn } from 'components/core/Columns';
import { SimpleTable, TableHeader } from 'components/core/SimpleTable';
import { ReactElement } from 'react';
import { timer } from 'utils';
import { SimpleTableItemSample } from './SimpleTableItemSample';

type TableModel = {
    id: string;
    activo: boolean;
    nombre_completo: string;
    cedula_identidad: string;
    roles: string;
    regional: string;
    estado: string;
    actions: unknown;
};

const MyComponent = (): ReactElement => {
    const headers: TableHeader<TableModel>[] = [
        { id: 'actions', label: 'Acciones', render: renderColumnActions, width: 230 },
        { id: 'activo', label: 'Activo', align: 'left', minWidth: 150, render: renderColumnActive },
        { id: 'nombre_completo', label: 'Nombre completo', align: 'left', minWidth: 150 },
        { id: 'cedula_identidad', label: 'Cédula de identidad', align: 'left', minWidth: 150 },
        { id: 'roles', label: 'Rol', align: 'left' },
        { id: 'regional', label: 'Regional', align: 'left' },
        { id: 'estado', label: 'Estado', align: 'left' },
    ];

    const rows: TableModel[] = [
        {
            id: '1',
            activo: true,
            nombre_completo: 'John Smith 1',
            cedula_identidad: '1111',
            roles: 'admin',
            regional: 'El Alto',
            estado: 'ACTIVO',
            actions: undefined,
        },
        {
            id: '2',
            activo: true,
            nombre_completo: 'John Smith 2',
            cedula_identidad: '3333',
            roles: 'admin',
            regional: 'Cochabamba',
            estado: 'ACTIVO',
            actions: undefined,
        },
        {
            id: '3',
            activo: false,
            nombre_completo: 'John Smith 3',
            cedula_identidad: '2222',
            roles: 'admin',
            regional: 'Sucre',
            estado: 'INACTIVO',
            actions: undefined,
        },
    ];

    const editRow = async (row: TableModel) => {
        console.log(row);
        await timer(1000);
        return true;
    };

    const deleteRow = async (row: TableModel) => {
        console.log(row);
        await timer(1000);
        return true;
    };

    function renderColumnActions(data: TableModel): ReactElement {
        const confirmMessage = (
            <>
                ¿Desea eliminar al usuario de nombre <strong>{data.nombre_completo}</strong>?
            </>
        );
        const items: ActionItem[] = [
            { icon: 'EditIcon', onClick: async () => editRow(data) },
            { icon: 'DeleteIcon', onClick: async () => deleteRow(data), confirm: true, confirmMessage },
        ];
        return <ActionColumn items={items} size="small" />;
    }

    function renderColumnActive(data: TableModel): ReactElement {
        return <ActiveColumn active={data.activo} />;
    }

    const mobileComponent = (row: TableModel): ReactElement => {
        return <SimpleTableItemSample data={row} />;
    };

    return (
        <>
            <SimpleTable headers={headers} rows={rows} mobileComponent={mobileComponent} />
        </>
    );
};

export const SimpleTableSample = MyComponent;
export type SimpleTableSampleModel = TableModel;
