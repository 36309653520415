import { ActionColumn, ActionItem } from 'components/core/Columns';
import { GetRows, DataTable, DataTableRef, TableHeader } from 'components/core/DataTable';
import { ReactElement, RefObject } from 'react';

type TableModel = Costo.ServicioTableModel;

type Props = {
    tableRef: RefObject<DataTableRef>;
    modoCreacion?: boolean;
    getRows: GetRows<TableModel>;
    editServicio: (row: TableModel) => Promise<boolean>;
    deleteServicio: (row: TableModel) => Promise<boolean>;
    addServicio: () => void;
};

export const TableComponent = (props: Props): ReactElement => {
    const { tableRef, getRows, deleteServicio, addServicio, editServicio, modoCreacion } = props;

    const headersCreacion: TableHeader<TableModel>[] = [
        { id: 'actions', label: 'Acciones', render: renderColumnActions, width: 130 },
    ];

    const tableHeaders: TableHeader<TableModel>[] = [
        { id: 'nro', label: 'Nro.', width: 80 },
        { id: 'codigo_servicio', label: 'Código de servicio', align: 'left', sort: true },
        { id: 'nombre', label: 'Nombre del servicio', align: 'left', sort: true },
        { id: 'categoria', label: 'Categoría', align: 'left', sort: true },
        { id: 'rubro', label: 'Rubro', align: 'left' },
        ...headersCreacion,
    ];

    function renderColumnActions(data: TableModel): ReactElement {
        const confirmMessage = (
            <>
                ¿Desea eliminar el servicio <strong>{data.nombre}</strong>?
            </>
        );
        const items: ActionItem[] = [];

        if (modoCreacion) {
            items.push({
                title: 'Editar',
                icon: 'EditIcon',
                color: 'primary',
                onClick: async () => editServicio(data),
            });
            items.push({
                title: 'Eliminar',
                icon: 'DeleteIcon',
                color: 'secondary',
                onClick: async () => deleteServicio(data),
                confirm: true,
                confirmMessage,
            });
        }

        return <ActionColumn items={items} size="small" />;
    }

    return (
        <DataTable
            title="Servicios"
            ref={tableRef}
            headers={tableHeaders}
            getRows={getRows}
            onActionAddClick={modoCreacion ? addServicio : undefined}
            hideFilters
        />
    );
};

export const ServicioTable = TableComponent;
