import { Box } from '@material-ui/core';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { SolicitudServicioService } from 'modules/solicitudServicio';
import { BoletaPagoFormDialog } from './BoletaPagoFormDialog';
import { FormDialogRef } from 'components/core/FormDialog';
import { useNotify } from 'hooks';

type BoletaPagoFormModel = Solicitud.BoletaPagoFormModel;
type BoletaFormParams = Solicitud.BoletaFormParams;

const PageComponent = (): ReactElement => {
    const history = useHistory();
    const notify = useNotify();

    const boletaRef = useRef<FormDialogRef>(null);

    const searchParams = new URLSearchParams(history.location.search.replace('?', ''));
    const idparam = searchParams.get('id');
    const SOLICITUD_SERVICIO_ID = idparam ? decodeURIComponent(String(idparam)) : '';

    const [formParams, setFormParams] = useState<BoletaFormParams>({ cuentas: [] });

    const getData = async (): Promise<BoletaPagoFormModel | undefined> => {
        const response = await SolicitudServicioService.getBoletaPagoForm(SOLICITUD_SERVICIO_ID);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };

    const getFormParams = async (): Promise<BoletaFormParams | undefined> => {
        const response = await SolicitudServicioService.getboletaFormParams();
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };
    const getFormParamsCallback = useCallback(getFormParams, []);

    const submit = async (data: BoletaPagoFormModel): Promise<boolean> => {
        data.id_solicitud_servicio = SOLICITUD_SERVICIO_ID;
        const response = await SolicitudServicioService.createOrUpdateBoletaPago(data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Boleta de pago registrada exitosamente');
        return true;
    };

    useEffect(() => {
        boletaRef.current?.open({
            params: {
                title: `Registro de Boleta de Pago`,
            },
            actions: [
                {
                    on: 'SaveButton',
                    click: () => boletaRef.current?.refresh(),
                },
                {
                    on: 'BackButton',
                    click: () => history.goBack(),
                },
            ],
        });
        getFormParamsCallback().then((result) => {
            if (result) setFormParams(result);
        });
    }, []);

    return (
        <Box display="flex" flexDirection="column">
            <BoletaPagoFormDialog dialogRef={boletaRef} getData={getData} submit={submit} params={formParams} />
        </Box>
    );
};

export const BoletaPage = withRouter(PageComponent);
