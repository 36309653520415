import { ReactElement, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    FormControl,
    FormControlLabel,
    makeStyles,
    Radio,
    RadioGroup,
    Theme,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { AppConfig, ThemeMode } from '../../Types';
import { setThemeMode } from 'redux/config/configActions';

type Props = {
    config: AppConfig;
};

const MyComponent = ({ config }: Props): ReactElement => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [value, setValue] = useState<ThemeMode>(config.theme.mode);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = (event.target as HTMLInputElement).value as ThemeMode;
        dispatch(setThemeMode(newValue));
        setValue(newValue);
    };

    return (
        <Card>
            <div className={classes.sectionTitle}>Selecciona el color del tema</div>
            <CardContent>
                <Box width="100%" overflow="auto">
                    <FormControl component="fieldset">
                        <RadioGroup value={value} onChange={handleChange}>
                            <FormControlLabel value="automatic" control={<Radio />} label="Automático" />
                            <FormControlLabel value="dark" control={<Radio />} label="Oscuro" />
                            <FormControlLabel value="light" control={<Radio />} label="Claro" />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </CardContent>
        </Card>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    sectionTitle: {
        fontWeight: 'bold',
        margin: theme.spacing(0, 0, 1, 0),
        padding: theme.spacing(0.5, 1),
        backgroundColor: theme.palette.grey[700],
        color: theme.palette.getContrastText(theme.palette.grey[700]),
    },
}));

export const ThemeSection = MyComponent;
