import { ReactElement } from 'react';
import { makeStyles, Theme, Box, CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';

type Props = {
    isLoading: boolean;
};

export const PageLoader = ({ isLoading }: Props): ReactElement => {
    const classes = useStyles();

    if (!isLoading) return <></>;

    return (
        <div className={clsx(classes.root)}>
            <Box display="flex" alignItems="center" justifyContent="center" p={3} minHeight="100px">
                <CircularProgress size={20} color="primary" />
                <Typography variant="h5">&nbsp; Cargando...</Typography>
            </Box>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(0, 1),
        margin: theme.spacing(0, 2),
        display: 'flex',
        justifyContent: 'center',
    },
}));
