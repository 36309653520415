import { Box, Card, CardContent, makeStyles, Theme, Typography } from '@material-ui/core';
import { getIcon } from 'helpers';
import { useSession } from 'hooks';
import { ReactElement } from 'react';
import { withRouter } from 'react-router-dom';

const PageComponent = (): ReactElement => {
    const classes = useStyles();

    const session = useSession();

    return (
        <div className={classes.root}>
            <div className={classes.pageContent}>
                <Box className={classes.logo}>{getIcon('AccountCircleIcon', { fontSize: 'inherit' })}</Box>

                <Typography variant="h3" align="center">
                    {session.displayName}
                </Typography>
                <Typography variant="h5" align="center" gutterBottom>
                    {session.userName}
                </Typography>

                <Box m={2} />

                <Box mb={5} width="100%">
                    <Card>
                        <div className={classes.sectionTitle}>Datos de usuario</div>
                        <CardContent>
                            <Box width="100%" overflow="auto">
                                <table width="100%" cellPadding={2}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Typography variant="body2">Correo electrónico</Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{session.email}</strong>
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">Rol</Typography>

                                                {session.roles.map((rol, index) => (
                                                    <Typography key={index} variant="body2" gutterBottom>
                                                        <strong>{rol.nombre || '-'}</strong>
                                                    </Typography>
                                                ))}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <Typography variant="body2">Cargo</Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{session.cargo || '-'}</strong>
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">Regional</Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{session.regional?.ciudad}</strong>
                                                </Typography>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>

                <Box mb={5} width="100%">
                    <Card>
                        <div className={classes.sectionTitle}>Datos personales</div>
                        <CardContent>
                            <Box width="100%" overflow="auto">
                                <table width="100%" cellPadding={2}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Typography variant="body2">Nombre(s)</Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{session.nombre}</strong>
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">Primer apellido</Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{session.apellido_paterno || '-'}</strong>
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">Segundo apellido</Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{session.apellido_materno || '-'}</strong>
                                                </Typography>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <Typography variant="body2">Género</Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{session.genero}</strong>
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">Cédula de identidad</Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{session.cedula_identidad}</strong>
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">Expedido en</Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{session.cedula_identidad_expedido_en}</strong>
                                                </Typography>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0),
        '& td': {
            verticalAlign: 'top',
        },
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        display: 'inline-block',
        fontSize: '150px',
        color: '#ffffff',
        width: '100%',
        lineHeight: 0,
        textAlign: 'center',
        marginBottom: '20px',
        background: theme.palette.grey[700],
        [theme.breakpoints.down('sm')]: {
            fontSize: '100px',
        },
    },
    sectionTitle: {
        fontWeight: 'bold',
        margin: theme.spacing(0, 0, 1, 0),
        padding: theme.spacing(0.5, 1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
}));

export const ProfilePage = withRouter(PageComponent);
