export const login = '/';
export const inicio = '/home';
export const guiaEstilos = '/guidelines';
export const usuarios = '/usuarios';
export const solicitudServicio = '/solicitud-servicio';
export const solicitudServicioFormulario = '/solicitud-servicio/formulario';
export const solicitudServicioDetalle = '/solicitud-servicio/detalle';
export const solicitudServicioBoleta = '/solicitud-servicio/boleta';
export const solicitudServicioInformeProduccion = '/solicitud-servicio/informe-produccion';
export const inventario = '/inventario';
export const mantenimiento = '/matenimiento';
export const costoServicios = '/costo-servicios';
export const reportes = '/reportes';
export const acercaDe = '/about';
export const config = '/settings';
export const perfil = '/profile';
export const ayuda = '/help';
export const notificaciones = '/notifications';
export const digitalizacion = '/digital';
export const reclamos = '/reclamos';
export const reclamosDetalle = '/reclamos/detalle';
export const digitalizacionFormulario = '/digital/formulario';
