import { createContext, ReactElement, ReactNode } from 'react';
import { useSnackbar } from 'notistack';

export type Notify = {
    success : (msg: string) => void;
    info    : (msg: string) => void;
    warning : (msg: string) => void;
    error   : (msg: string) => void;
};

const initialValue: Notify = {
    success : () => ({}),
    info    : () => ({}),
    warning : () => ({}),
    error   : () => ({}),
};

export const NotifyContext = createContext<Notify>(initialValue);

type Props = {
    children: ReactNode;
};

export const NotifyProvider = ({ children }: Props): ReactElement => {
    const { enqueueSnackbar } = useSnackbar();
    const notify: Notify = {
        success : (msg) => enqueueSnackbar(msg, { variant: 'success' }),
        info    : (msg) => enqueueSnackbar(msg, { variant: 'info' }),
        warning : (msg) => enqueueSnackbar(msg, { variant: 'warning' }),
        error   : (msg) => enqueueSnackbar(msg, { variant: 'error', autoHideDuration: 10000 }),
    };

    return <NotifyContext.Provider value={notify}>{children}</NotifyContext.Provider>;
};
