import { ReactElement, Fragment } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Typography,
    Theme,
    Hidden,
    Box,
    Button,
    Badge,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useSession } from 'hooks';
import { ConfigMenu } from './components/ConfigMenu';
import { getIcon } from 'helpers';

type Props = {
    handleDrawerOpen: () => void;
    handleClickProfile: () => void;
    handleClickNotifications: () => void;
    handleClickClose: () => void;
    notificationAlert: NotificationAlert;
    menu: MenuModel;
};

export const Topbar = (props: Props): ReactElement => {
    const classes = useStyles();
    const session = useSession();

    const buzonEntradaAlert = props.notificationAlert.buzonEntrada;
    const buzonEntradaBadge =
        buzonEntradaAlert > 0 ? (buzonEntradaAlert > 10 ? '+9' : `${buzonEntradaAlert}`) : undefined;

    const configMenu: MenuListModel = [
        {
            icon: 'AccountCircleIcon',
            label: session.displayName,
            onClick: props.handleClickProfile,
        },
        {
            icon: 'NotificationsIcon',
            label: 'Notificaciones',
            onClick: props.handleClickNotifications,
            badge: buzonEntradaBadge,
        },
        {
            icon: 'ExitToAppIcon',
            label: 'Salir',
            onClick: props.handleClickClose,
        },
    ];

    const renderMenuIcon = () => {
        const hasAlert = props.menu.some((group) => group.items.some((item) => item.badge));
        return (
            <>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.handleDrawerOpen}
                    edge="start"
                    className={classes.menuButton}
                >
                    <Badge
                        color="secondary"
                        variant="dot"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        invisible={!hasAlert}
                    >
                        <MenuIcon />
                    </Badge>
                </IconButton>
            </>
        );
    };

    const renderLogo = () => {
        return (
            <>
                <Typography variant="h6" noWrap>
                    CETIP Digital
                </Typography>
            </>
        );
    };

    const renderDesktop = () => {
        return (
            <>
                {configMenu.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <Button
                                color="inherit"
                                onClick={item.onClick}
                                startIcon={
                                    item.badge ? (
                                        <Badge badgeContent={item.badge} color="secondary">
                                            {getIcon(item.icon)}
                                        </Badge>
                                    ) : (
                                        getIcon(item.icon)
                                    )
                                }
                            >
                                <Typography noWrap>{item.label}</Typography>
                            </Button>
                            <Box px={index < configMenu.length - 1 ? 1 : 0} />
                        </Fragment>
                    );
                })}
            </>
        );
    };

    const renderMobile = () => {
        return (
            <>
                <ConfigMenu items={configMenu} />
            </>
        );
    };

    return (
        <AppBar elevation={0} position="fixed" className={classes.appBar}>
            <Toolbar>
                {renderMenuIcon()}
                {renderLogo()}
                <Box marginLeft="auto" />
                <Hidden xsDown>{renderDesktop()}</Hidden>
                <Hidden smUp>{renderMobile()}</Hidden>
            </Toolbar>
        </AppBar>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));
