import { ReactElement } from 'react';
import { Switch, Route } from 'react-router-dom';
import {
    HomePage,
    LoginPage,
    NotFoundPage,
    GuidelinesPage,
    SettingsPage,
    AboutPage,
    ProfilePage,
    HelpPage,
    NotificationsPage,
} from 'pages';
import { MainLayout } from 'layouts';
import { PrivateRoute } from './PrivateRoute';
import { RUTAS } from 'routes';
import {
    CostoServiciosModule,
    DigitalizacionModule,
    InventarioModule,
    MantenimientoModule,
    ReportesModule,
    SolicitudServicioModule,
    UsuariosModule,
    ReclamosModule,
} from 'modules';
import {
    SolicitudServicioFormularioPage,
    SolicitudServicioDetallePage,
    SolicitudServicioBoletaPage,
    SolicitudServicioInformeProduccionPage,
} from 'modules/solicitudServicio';
import { DigitalizacionFormularioPage } from 'modules/digitalizacion';
import { ReclamosDetallePage } from 'modules/reclamos';

export const Routes = (): ReactElement => {
    return (
        <Switch>
            <Route component={LoginPage} exact={true} path={RUTAS.login} />
            <PrivateRoute component={HomePage} exact={true} layout={MainLayout} path={RUTAS.inicio} />
            <PrivateRoute component={UsuariosModule} exact={true} layout={MainLayout} path={RUTAS.usuarios} />
            <PrivateRoute component={CostoServiciosModule} exact={true} layout={MainLayout} path={RUTAS.costoServicios} />
            <PrivateRoute component={InventarioModule} exact={true} layout={MainLayout} path={RUTAS.inventario} />
            <PrivateRoute component={MantenimientoModule} exact={true} layout={MainLayout} path={RUTAS.mantenimiento} />
            <PrivateRoute component={ReportesModule} exact={true} layout={MainLayout} path={RUTAS.reportes} />
            <PrivateRoute component={SolicitudServicioModule} exact={true} layout={MainLayout} path={RUTAS.solicitudServicio} />
            <PrivateRoute component={SolicitudServicioFormularioPage} exact={true} layout={MainLayout} path={RUTAS.solicitudServicioFormulario} />
            <PrivateRoute component={SolicitudServicioDetallePage} exact={true} layout={MainLayout} path={RUTAS.solicitudServicioDetalle} />
            <PrivateRoute component={SolicitudServicioBoletaPage} exact={true} layout={MainLayout} path={RUTAS.solicitudServicioBoleta} />
            <PrivateRoute component={SolicitudServicioInformeProduccionPage} exact={true} layout={MainLayout} path={RUTAS.solicitudServicioInformeProduccion} />
            <PrivateRoute component={GuidelinesPage} exact={true} layout={MainLayout} path={RUTAS.guiaEstilos} />
            <PrivateRoute component={SettingsPage} exact={true} layout={MainLayout} path={RUTAS.config} />
            <PrivateRoute component={ProfilePage} exact={true} layout={MainLayout} path={RUTAS.perfil} />
            <PrivateRoute component={AboutPage} exact={true} layout={MainLayout} path={RUTAS.acercaDe} />
            <PrivateRoute component={HelpPage} exact={true} layout={MainLayout} path={RUTAS.ayuda} />
            <PrivateRoute component={NotificationsPage} exact={true} layout={MainLayout} path={RUTAS.notificaciones} />
            <PrivateRoute component={DigitalizacionModule} exact={true} layout={MainLayout} path={RUTAS.digitalizacion} />
            <PrivateRoute component={DigitalizacionFormularioPage} exact={true} layout={MainLayout} path={RUTAS.digitalizacionFormulario} />
            <PrivateRoute component={ReclamosModule} exact={true} layout={MainLayout} path={RUTAS.reclamos} />
            <PrivateRoute component={ReclamosDetallePage} exact={true} layout={MainLayout} path={RUTAS.reclamosDetalle} />
            <Route component={NotFoundPage} />
        </Switch>
    );
};
