import { Fragment, ReactElement } from 'react';
import { makeStyles, Theme, IconButton, Tooltip, Typography } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import clsx from 'clsx';
import { StepColumnProps, StepItem } from './Types';
import { getIcon } from 'helpers';

export const StepColumn = ({ items, size }: StepColumnProps): ReactElement => {
    const classes = useStyles();

    const renderButton = (item: StepItem) => {
        return (
            <IconButton color={'primary'} component="span" onClick={item.onClick} size={size}>
                <div className={classes.icon} style={{ background: item.complete ? undefined : 'lightgrey' }}>
                    {item.nro || '0'}
                </div>
                {item.complete && (
                    <div className={classes.check}>{getIcon('CheckCircleIcon', { fontSize: 'inherit' })}</div>
                )}
            </IconButton>
        );
    };

    return (
        <div className={clsx(classes.root)}>
            {items.map((item, index) => (
                <Fragment key={index}>
                    {item.title && (
                        <Tooltip
                            title={<Typography variant="body2">{item.title}</Typography>}
                            placement="top"
                            TransitionComponent={Zoom}
                            enterDelay={200}
                        >
                            {renderButton(item)}
                        </Tooltip>
                    )}
                    {!item.title && renderButton(item)}
                </Fragment>
            ))}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        borderRadius: '50%',
        width: '15px',
        fontSize: '12px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    check: {
        color: theme.palette.success.main,
        fontSize: '12px',
        position: 'absolute',
        bottom: 0,
        right: 4,
    },
}));
