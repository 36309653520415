/* eslint-disable @typescript-eslint/no-explicit-any */
import { REGIONALES, ROLES } from 'base/authBase';
import { ENUM_DEPARTAMENTO, ENUM_GENERO } from 'base/enums';
import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams, SelectOption } from 'components/core/FormDialog/Types';
import { isEmailValidTest } from 'components/core/FormDialog/yup-tests';
import { ReactElement, RefObject } from 'react';
import * as yup from 'yup';

type FormModel = Usuario.UsuarioFormModel;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
};

const FormDialogComponent = ({ dialogRef, getData, submit }: Props): ReactElement => {
    const rolOptions: SelectOption[] = Object.keys(ROLES)
        .map((key) => ({
            value: (ROLES as any)[key].id,
            label: (ROLES as any)[key].nombre,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const regionalOptions: SelectOption[] = Object.keys(REGIONALES)
        .map((key) => ({
            value: (REGIONALES as any)[key].id,
            label: (REGIONALES as any)[key].ciudad,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const expedidoOptions: SelectOption[] = Object.keys(ENUM_DEPARTAMENTO)
        .map((key) => ({
            value: (ENUM_DEPARTAMENTO as EnumValue)[key].value,
            label: (ENUM_DEPARTAMENTO as EnumValue)[key].label,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const generoOptions: SelectOption[] = Object.keys(ENUM_GENERO)
        .map((key) => ({
            value: (ENUM_GENERO as EnumValue)[key].value,
            label: (ENUM_GENERO as EnumValue)[key].label,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const formLayout: FormGroup<FormModel>[] = [
        {
            title: 'Datos de usuario',
            grid: [
                [{ name: 'email', label: 'Correo electrónico', type: 'text' }],
                [
                    { name: 'rol', label: 'Rol', type: 'multiselect', options: rolOptions },
                    { name: 'regional', label: 'Regional', type: 'select', options: regionalOptions },
                ],
                [{ name: 'cargo', label: 'Cargo', type: 'text' }],
            ],
        },
        {
            title: 'Datos personales',
            grid: [
                [{ name: 'nombre', label: 'Nombre(s)', type: 'text' }],
                [
                    { name: 'primer_apellido', label: 'Primer apellido', type: 'text' },
                    { name: 'segundo_apellido', label: 'Segundo apellido', type: 'text' },
                ],
                [
                    { name: 'genero', label: 'Género', type: 'select', options: generoOptions },
                    { name: 'cedula_identidad', label: 'Cédula de identidad', type: 'text' },
                    {
                        name: 'cedula_identidad_expedido_en',
                        label: 'Expedido en',
                        type: 'autocomplete',
                        options: expedidoOptions,
                    },
                ],
            ],
        },
    ];

    const validationSchema = yup.object({
        email: yup.string().required().test(isEmailValidTest()),
        nombre: yup.string().required(),
        primer_apellido: yup.string().nullable(),
        segundo_apellido: yup.string().nullable(),
        cedula_identidad: yup.string().required(),
        rol: yup.array().of(yup.string()).min(1, 'Debe seleccionar al menos 1 item'),
        regional: yup.string().required(),
        genero: yup.string().required(),
    });

    return (
        <FormDialog
            ref={dialogRef}
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            validationSchema={validationSchema}
            maxWidth={'sm'}
            fullwidth={true}
            // debug
        />
    );
};

export const UsuarioFormDialog = FormDialogComponent;
export type UsuarioFormModel = FormModel;
