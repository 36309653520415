/* eslint-disable @typescript-eslint/no-explicit-any */
import { RESPONSE_MESSAGES as MSG } from './ResponseMessages';
import { getToken } from 'services/authentication/AuthService';
import { API_URL } from 'config/app.config';
import { timer } from 'utils';

const getHeaders = async (baseHeaders?: RequestHeaders): Promise<RequestHeaders> => {
    const DEFAULT_HEADERS = { Accept: 'application/json', 'Content-Type': 'application/json' };
    const HEADERS: RequestHeaders = baseHeaders ? baseHeaders : DEFAULT_HEADERS;
    return new Promise((resolve) => {
        const token = getToken();
        if (token) HEADERS['Authorization'] = `Bearer ${token}`;
        resolve(HEADERS);
    });
};

const buildQueryFilters = (filters: RequestFilters): string[] => {
    return Object.keys(filters)
        .filter((key) => !['_limit', '_page', '_sort', '_order', 'q'].includes(key))
        .map((key) => {
            const filterValue = filters[key];
            return Array.isArray(filterValue)
                ? filterValue.map((val) => `${key}=${encodeURIComponent(val)}`).join('&')
                : `${key}=${encodeURIComponent(filterValue)}`;
        });
};

/**
 * Utilizado para construir una URL con filtros (diseñado para el componente DataTable).
 * @return queryParamsText
 * @example "?escuela=1&_limit=10&_page=1&q=some%20value&_sort=id&_order=asc"
 */
export function buildQueryParamsText(queryParams: QueryParams = {}): string {
    const params = queryParams.filters ? buildQueryFilters(queryParams.filters) : [];
    if (queryParams.rowsPerPage) params.push(`_limit=${queryParams.rowsPerPage}`);
    if (queryParams.page) params.push(`_page=${queryParams.page}`);
    if (queryParams.searchText) params.push(`q=${encodeURIComponent(queryParams.searchText)}`);
    if (queryParams.orderBy) params.push(`_sort=${queryParams.orderBy}`);
    if (queryParams.order) params.push(`_order=${queryParams.order}`);
    const queryParamsText = params.length > 0 ? `?${params.join('&')}` : '';
    return queryParamsText;
}

/**
 * Utilizado para cualquier tipo de petición.
 * @return {Object} { success, msg, data }
 */
export async function request<Model>(
    method: RequestMethod,
    routePath: string,
    data?: unknown | FormData,
    delay?: number,
): Promise<BaseResponse<Model>> {
    const result: BaseResponse<Model> = { success: true, failure: false, data: {} as Model };
    try {
        if (delay) await timer(delay);
        const url = `${API_URL}${routePath}`;
        const hasFormData = data instanceof FormData;
        const response = await fetch(url, {
            method,
            headers: await getHeaders(hasFormData ? {} : undefined),
            body: data ? (hasFormData ? (data as FormData) : JSON.stringify(data)) : undefined,
        });
        result.data = await validateResponse(response);
    } catch (e) {
        result.success = false;
        result.failure = true;
        result.error = errMsg(e);
    }
    return result;
}

/**
 * Utilizado para descargar archivos directamente desde el navegador.
 * @return {Object} { success, msg }
 */
export async function download(
    method: RequestMethod,
    routePath: string,
    data?: unknown,
    filename?: string,
): Promise<BaseResponse<unknown>> {
    try {
        const url = `${API_URL}${routePath}`;
        const response = await fetch(url, {
            method: method.toUpperCase(),
            headers: await getHeaders(),
            body: data ? JSON.stringify(data) : undefined,
        });
        const resObj = {
            filename: filename || getFileNameFromContentDispostion(String(response.headers.get('content-disposition'))),
            blob: await response.blob(),
        };
        const newBlob = new Blob([resObj.blob], {
            type: `${response.headers.get('content-type')}`,
        });

        // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return sendSuccess({});
        }

        // For other browsers: create a link pointing to the ObjectURL containing the blob.
        const objUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = objUrl;
        link.download = resObj.filename;
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
        }, 250);

        return sendSuccess({});
    } catch (err) {
        return sendError(err.message);
    }
}

function getFileNameFromContentDispostion(header: string) {
    const contentDispostion = header ? header.split(';') : [];
    const fileNameToken = `filename=`;
    let filename = 'doc.txt';
    for (const thisValue of contentDispostion) {
        if (thisValue.trim().indexOf(fileNameToken) === 0) {
            filename = decodeURIComponent(thisValue.trim().replace(fileNameToken, ''));
            break;
        }
    }
    return filename;
}

/**
 * Utilizado para guardar archivos en Google Cloud Storage.
 * @return {Object} { success, msg, data }
 */
export async function uploadFile(
    file: File,
    fileName: string,
    onProgress?: (progress: number, uploaded: boolean) => void,
): Promise<BaseResponse<UploadResponseModel>> {
    const FILE = file;
    const FILENAME = fileName;
    const formdata = new FormData();
    formdata.append('fileItem', FILE, FILENAME);
    formdata.append('fileName', FILENAME);
    if (onProgress) onProgress(0, false);
    return request<UploadResponseModel>('post', '/storage/upload', formdata).then((registerResult) => {
        if (onProgress) onProgress(100, true);
        if (!registerResult.success) {
            return sendError(registerResult.error);
        }
        return sendSuccess(registerResult.data);
    });
}

/** Utilizado para eliminar archivos de Google Cloud Storage */
export function removeFile(fileName: string): Promise<BaseResponse<unknown>> {
    return request('delete', '/storage/upload', { fileName });
}

/**
 * Utilizado para construir respuestas exitosas.
 * @return {Object} BaseResponse<Model>
 */
export async function sendSuccess<Model>(data: Model, delay?: number): Promise<BaseResponse<Model>> {
    if (delay) await timer(delay);
    const newResult: BaseResponse<Model> = {
        success: true,
        failure: false,
        data: data,
    };
    return Promise.resolve(newResult);
}

/**
 * Utilizado para construir respuestas fallidas.
 * @return {Object} BaseResponse<Model>
 */
export async function sendError<Model>(error?: string, delay?: number): Promise<BaseResponse<Model>> {
    if (delay) await timer(delay);
    const newResult: BaseResponse<Model> = {
        data: {} as Model,
        success: false,
        failure: true,
        error: error || MSG.DEFAULT_ERROR,
    };
    return Promise.resolve(newResult);
}

async function validateResponse(response: Response): Promise<any> {
    let json: any = {};
    try {
        json = await response.json();
    } catch (e) {}
    if (response.status === 200) return json;
    if (response.status === 201) return json;
    if (response.status === 404) throw new Error(json.error || json.message || MSG.ERROR_404);
    if (response.status === 401) throw new Error(json.error || json.message || MSG.ERROR_401);
    if (response.status === 403) throw new Error(json.error || json.message || MSG.ERROR_403);
    if (response.status === 400) throw new Error(json.error || json.message || MSG.ERROR_400);
    if (response.status === 409) throw new Error(json.error || json.message || MSG.ERROR_409);
    if (response.status === 412) throw new Error(json.error || json.message || MSG.ERROR_412);
    throw new Error(json.error || json.message || MSG.ERROR_500);
}

function errMsg(e: Error): string {
    return e.message && e.message === 'Failed to fetch' ? MSG.FETCH_ERROR : e.message || MSG.DEFAULT_ERROR;
}
