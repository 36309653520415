import { Fragment, ReactElement } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { makeStyles, Theme, Drawer, List, ListItem, Divider, Badge, useTheme } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router';
import { getIcon } from 'helpers';
import clsx from 'clsx';

const drawerWidth = 240;

type Props = {
    open: boolean;
    menu: MenuModel;
};

export const Sidebar = (props: Props): ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const handleClickItem = (item: MenuItemModel): void => {
        if (item.onClick) return item.onClick();
        if (item.path) return history.push(item.path);
        return;
    };

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={props.open}
            classes={{ paper: classes.drawerPaper }}
        >
            <div className={classes.drawerContainer}>
                <Scrollbars autoHide autoHideDuration={500}>
                    {props.menu.map((group, groupIndex) => {
                        if (group.items.length === 0) return <Fragment key={groupIndex}></Fragment>;
                        return (
                            <Fragment key={groupIndex}>
                                <List>
                                    {group.items
                                        .filter((item) => !item.hidden)
                                        .map((item, index) => {
                                            const isDarkMode = theme.palette.type === 'dark';
                                            const pathSelected = history.location.pathname.startsWith(`${item.path}`);
                                            const background = isDarkMode
                                                ? theme.palette.background.default
                                                : '#f5f5f5'; // item.color
                                            const color = isDarkMode
                                                ? theme.palette.grey[200]
                                                : theme.palette.primary.main;
                                            const itemStyle = pathSelected ? { background, color } : undefined;
                                            const iconStyle = pathSelected ? { color } : undefined;
                                            return (
                                                <ListItem
                                                    button
                                                    key={index}
                                                    onClick={() => handleClickItem(item)}
                                                    style={itemStyle}
                                                >
                                                    <ListItemIcon>
                                                        <Badge
                                                            color="secondary"
                                                            variant="dot"
                                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                            invisible={!item.badge}
                                                            style={iconStyle}
                                                        >
                                                            {getIcon(item.icon, { color: 'inherit' })}
                                                        </Badge>
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.label} secondary={item.caption} />
                                                    <div
                                                        className={clsx(classes.item, {
                                                            [classes.itemSelected]: pathSelected,
                                                        })}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                </List>
                                {groupIndex < props.menu.length - 1 && <Divider />}
                            </Fragment>
                        );
                    })}
                </Scrollbars>
            </div>
        </Drawer>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: '64px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '56px',
        },
    },
    drawerContainer: {
        overflow: 'auto',
        height: 'calc(100vh - 65px)',
    },
    item: {
        position: 'absolute',
        background: 'unset',
        height: '100%',
        width: theme.spacing(1),
        top: 0,
        left: 0,
        transition: 'background .2s',
    },
    itemSelected: {
        background: theme.palette.primary.main,
    },
}));
