import { Box } from '@material-ui/core';
import { DialogBoxRef } from 'components/core/DialogBox';
import { InitialParams } from 'components/core/FormDialog/Types';
import { useNotify } from 'hooks';
import { DigitalizacionService } from 'modules/digitalizacion';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { RUTAS } from 'routes';
import { DigitalFormDialog } from './DigitalFormDialog';

type DigitalFormParams = Digital.DigitalFormParams;
type DigitalFormModel = Digital.DigitalFormModel;

const PageComponent = (): ReactElement => {
    const notify = useNotify();
    const history = useHistory();
    const solicitudServicioFormRef = useRef<DialogBoxRef<InitialParams>>(null);

    const searchParams = new URLSearchParams(history.location.search.replace('?', ''));
    const idparam = searchParams.get('id');
    const DIGITAL_ID = idparam ? decodeURIComponent(String(idparam)) : '';

    const initialParams: DigitalFormParams = {
        rubros: [],
        regionales: [],
        servicios: [],
        solicitantes: [],
        unidades_productivas: [],
    };
    const [formParams, setFormParams] = useState<DigitalFormParams>(initialParams);

    const getData = async (): Promise<DigitalFormModel | undefined> => {
        const response = await DigitalizacionService.getDigitalForm(DIGITAL_ID);
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };

    const getFormParams = async (): Promise<DigitalFormParams | undefined> => {
        const response = await DigitalizacionService.getDigitalFormParams();
        if (response.error) {
            notify.error(response.error);
            return;
        }
        return response.data;
    };
    const getFormParamsCallback = useCallback(getFormParams, []);

    const submit = async (data: DigitalFormModel): Promise<boolean> => {
        const response = await DigitalizacionService.createOrUpdateDigital(data);
        if (response.error) {
            notify.error(response.error);
            return false;
        }
        notify.success('Tarea completada exitosamente');
        return true;
    };

    useEffect(() => {
        solicitudServicioFormRef.current?.open({
            params: {
                title: `Formulario de Solicitud de Servicio${DIGITAL_ID ? ' (ACTUALIZACIÓN)' : ''}`,
            },
            actions: [
                { on: 'AcceptButton', click: () => history.push(RUTAS.digitalizacion) },
                { on: 'CancelButton', click: () => history.push(RUTAS.digitalizacion) },
            ],
        });
        getFormParamsCallback().then((result) => {
            if (result) setFormParams(result);
        });
    }, []);

    return (
        <Box display="flex" justifyContent="center">
            <DigitalFormDialog
                dialogRef={solicitudServicioFormRef}
                getData={getData}
                submit={submit}
                params={formParams}
            />
        </Box>
    );
};

export const FormularioPage = withRouter(PageComponent);
