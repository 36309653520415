// import { DialogBoxRef } from 'components/core/DialogBox';
import { Typography } from '@material-ui/core';
import { FormDialog } from 'components/core/FormDialog';
import { FormDialogRef, FormGroup, InitialParams } from 'components/core/FormDialog/Types';
import { dateTest } from 'components/core/FormDialog/yup-tests';
import { ReactElement, RefObject } from 'react';
import * as yup from 'yup';

type FormModel = Mantenimiento.MantenimientoFormModel;

type Props = {
    dialogRef: RefObject<FormDialogRef>;
    getData: (params?: InitialParams) => Promise<FormModel | undefined>;
    submit: (data: FormModel) => Promise<boolean>;
};

const FormDialogComponent = ({ dialogRef, getData, submit }: Props): ReactElement => {
    const formLayout = (formValue: FormModel) => {
        const formGroup: FormGroup<FormModel>[] = [
            {
                title: 'Datos del equipo',
                grid: [
                    [
                        {
                            type: 'empty',
                            render: () => {
                                return (
                                    <>
                                        <Typography>Equipo</Typography>
                                        <Typography>
                                            <strong>{formValue.equipo_nombre}</strong>
                                        </Typography>
                                    </>
                                );
                            },
                        },
                        {
                            type: 'empty',
                            render: () => {
                                return (
                                    <>
                                        <Typography>Código</Typography>
                                        <Typography>
                                            <strong>{formValue.equipo_codigo}</strong>
                                        </Typography>
                                    </>
                                );
                            },
                        },
                    ],
                    [
                        {
                            type: 'empty',
                            render: () => {
                                return (
                                    <>
                                        <Typography>Fecha actual</Typography>
                                        <Typography>
                                            <strong>{formValue.fecha_actual}</strong>
                                        </Typography>
                                    </>
                                );
                            },
                        },
                    ],
                    [{ name: 'observacion', label: 'Observación', type: 'text', multiline: true, delay: 500 }],
                ],
            },
            {
                title: 'Próximo mantenimiento',
                grid: [[{ name: 'fecha_proximo', label: 'Fecha del próximo mantenimiento', type: 'date' }]],
            },
        ];

        return formGroup;
    };

    const DATE_FORMAT = 'dd/MM/yyyy';
    const validationSchema = yup.object({
        codigo: yup.string(),
        nombre: yup.string(),
        fecha_actual: yup.string().required().test(dateTest(DATE_FORMAT)),
        fecha_proximo: yup.string().required().test(dateTest(DATE_FORMAT)),
        observacion: yup.string().nullable(),
        fid_equipoindustrial: yup.string().required(),
    });

    return (
        <FormDialog
            ref={dialogRef}
            formLayout={formLayout}
            getData={getData}
            submit={submit}
            validationSchema={validationSchema}
            fullwidth={true}
            maxWidth={'sm'}
            // debug
        />
    );
};

export const MantenimientoFormDialog = FormDialogComponent;
