import { ReactElement } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

export const Footer = (): ReactElement => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="caption" noWrap>&copy; 2021 CETIP</Typography>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '40px',
        margin: theme.spacing(0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 4),
        color: '#969696',
    },
}));
